/* eslint-disable @typescript-eslint/no-explicit-any */

import { SYSTEM_ADMIN_ID } from '@targetx/tx-usermgmt-api-lib/lib/constants/system';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Tooltip from '@targetx/mineral-ui/Tooltip';
import { MessageStatus } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import IconExclamationTriangle from '@targetx/tx-web-ui-lib/lib/icons/IconExclamationTriangle';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import dateFormat from 'dateformat';
import get from 'lodash.get';
import React, { ReactElement } from 'react';
import theme from '../theme';
import { ContactMappingEntity } from '../types';
import { getFullName as getContactMappingFullName } from '../utils/ContactMappingUtils';
import { getFullName as getUserFullName } from '../utils/UserUtils';
import copyText from './MessageList.copyText';

export namespace MessageList {
  export interface UserEntity {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
  }

  export interface MessageEntity {
    id: string;
    canonicalPhoneNumber: string;
    contactMapping?: ContactMappingEntity;
    content: string;
    status: MessageStatus;
    timeCreated: string;
    user?: UserEntity;
  }

  export interface Props {
    isLoadingMessages?: boolean;
    messages: MessageEntity[];
    inboxName: string;
  }
}

export function MessageList({
  isLoadingMessages,
  messages,
  inboxName
}: MessageList.Props): ReactElement {
  if (isLoadingMessages) {
    return (
      <Flex alignItems="center" height="100%" justifyContent="center">
        <SVGSpinner />
      </Flex>
    );
  }

  return (
    <Flex direction="column-reverse" height="100%" scrollable>
      {messages.map(message => renderMessageBubble(message, inboxName))}
    </Flex>
  );
}

function renderMessageBubble(
  message: MessageList.MessageEntity,
  inboxName: string
): ReactElement {
  const {
    id,
    canonicalPhoneNumber,
    contactMapping,
    content,
    status,
    timeCreated,
    user
  } = message;
  const hasFailed = [
    MessageStatus.OUTGOING_DELIVERY_FAILED,
    MessageStatus.OUTGOING_SEND_FAILED
  ].includes(status);

  let isOutgoingMessage = false;
  let sender;

  if (user) {
    isOutgoingMessage = true;
    sender = {
      id: user.id,
      displayName: getUserFullName(user)
    };
    if (user.id === SYSTEM_ADMIN_ID) {
      sender.displayName = inboxName;
    }
  } else {
    sender = {
      id: contactMapping ? contactMapping.contactRID : canonicalPhoneNumber,
      displayName: contactMapping?.attributes
        ? getContactMappingFullName(contactMapping)
        : canonicalPhoneNumber
    };
  }

  function getBackgroundColor(): string {
    if (hasFailed) return palette.red[10];
    if (isOutgoingMessage) return palette.green[10];
    return palette.white;
  }

  return (
    <Flex
      key={id}
      alignItems="center"
      justifyContent={isOutgoingMessage ? 'end' : 'start'}
      marginBottom={theme.space_stack_md}
      marginRight={0}
    >
      {hasFailed ? renderFailedIcon(status) : null}
      <Flex
        backgroundColor={getBackgroundColor()}
        borderRadius="16px"
        border={`1px solid ${hasFailed ? palette.red[40] : palette.gray[40]}`}
        boxShadow="0 1px 8px 0 rgba(0,0,0,0.10)"
        paddingHorizontal={theme.space_inline_lg}
        paddingVertical={theme.space_stack_md}
        minWidth={250}
        maxWidth="85%"
      >
        <FlexItem>
          <Text
            fontSize={theme.fontSize_ui_sm}
            fontWeight={theme.fontWeight_semiBold}
            marginBottom={theme.space_stack_sm}
          >
            {sender.displayName}
          </Text>
          <Text
            marginBottom={theme.space_stack_sm}
            style={{ wordBreak: 'break-word' }}
          >
            {content}
          </Text>
          <Text color={palette.gray[70]} fontSize={theme.fontSize_ui_sm}>
            {dateFormat(timeCreated, 'mm/dd/yyyy hh:MMTT')}
          </Text>
        </FlexItem>
      </Flex>
    </Flex>
  );
}

function renderFailedIcon(status: MessageStatus): ReactElement {
  const content = `${copyText.statusReasonLabel} ${get(
    copyText,
    `statusReason_${status}`
  )}`;

  return (
    <Flex>
      <Tooltip content={content}>
        <Flex
          alignItems="center"
          border={`1px solid ${palette.red[40]}`}
          borderRadius="100%"
          cursor="pointer"
          height={24}
          justifyContent="center"
          width={24}
        >
          <IconExclamationTriangle color={palette.red[60]} size={12} />
        </Flex>
      </Tooltip>
    </Flex>
  );
}

export default MessageList;
