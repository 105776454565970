export default {
  bulkMessagesTitle: 'Bulk Messages',
  headerFirstName: 'FIRST NAME',
  headerLastName: 'LAST NAME',
  headerMobilePhone: 'MOBILE PHONE',
  headerMessageStatus: 'MESSAGE STATUS',
  noRecipients: 'No recipients to display',
  recipientsTitle: 'Recipients',
  searchInputLabel: 'Search Recipients'
};
