/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';

interface State {
  readonly data?: string[];
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (reportID: string) => Promise<void>;
}

export default function useDataAPIGetReportColumnNamesByReportID(
  reportID?: string
): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(reportID: string): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getReportColumnNamesByReportID(reportID);

      setState({ data, isLoading: false });
    } catch (error: any) {
      setState({ error, isLoading: false });
    }
  }

  useEffect(() => {
    if (!reportID) return;

    fetch(reportID);
  }, [reportID]);

  return { ...state, refetch: fetch };
}
