/* eslint-disable @typescript-eslint/no-explicit-any */

import Flex from '@targetx/mineral-ui/Flex';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNavExtended';
import IconArrowLeft from '@targetx/tx-web-ui-lib/lib/icons/IconArrowLeft';
import IconCommentLines from '@targetx/tx-web-ui-lib/lib/icons/IconCommentLines';
import IconInbox from '@targetx/tx-web-ui-lib/lib/icons/IconInbox';
import IconList from '@targetx/tx-web-ui-lib/lib/icons/IconList';
import IconQuestionCircle from '@targetx/tx-web-ui-lib/lib/icons/IconQuestionCircle';
import IconTwilio from '@targetx/tx-web-ui-lib/lib/icons/IconTwilio';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, {
  Children,
  PropsWithChildren,
  ReactElement,
  useContext
} from 'react';
import NavigateCommand from '../commands/NavigateCommand';
import paths from '../constants/paths';
import DispatcherContext from '../DispatcherContext';
import theme from '../theme';
import { UserEntity } from '../types';
import copyText from './SettingsScreenLayout.copyText';

export namespace SettingsScreenLayout {
  export interface Props {
    authenticatedUser: UserEntity;
    blur?: number;
    currentPath?: string;
    homeBaseURL: string;
    signOutPath: string;
    onClick?: () => void;
  }
}

export function SettingsScreenLayout({
  authenticatedUser,
  blur,
  children,
  currentPath,
  homeBaseURL,
  signOutPath,
  onClick = noop
}: PropsWithChildren<SettingsScreenLayout.Props>): ReactElement {
  const dispatcher = useContext(DispatcherContext);

  function navigate(path: string): void {
    dispatcher.dispatch(new NavigateCommand({ path }));
  }

  function handleClickBackArrow(): void {
    navigate(paths.inboxes);
  }

  function handleClickSideNavItem(path: string): void {
    if (path === currentPath) return;

    navigate(path);
  }

  let body, header;

  Children.forEach(children, (child): void => {
    const type = get(child, 'type');

    if (type === Header) header = child;
    else if (type === Body) body = child;
  });

  return (
    <Layout height="100vh">
      <Layout.Body blur={blur} flex onClick={onClick}>
        <SideNav
          authenticatedUser={authenticatedUser}
          headerIconStart={
            <MinimalButton
              aria-label={copyText.backButtonAriaLabel}
              iconStart={<IconArrowLeft color={palette.gray[40]} />}
              onClick={handleClickBackArrow}
            />
          }
          homeBaseURL={homeBaseURL}
          signOutPath={signOutPath}
          title={copyText.sideNavTitle}
        >
          {authenticatedUser.role === UserRole.ORG_ADMIN ? (
            <>
              <SideNav.Item
                href={paths.settingsInboxes}
                iconStart={<IconInbox color={palette.white} />}
                selected={currentPath === paths.settingsInboxes}
                title={copyText.sideNavItemLabelInbox}
                onClick={handleClickSideNavItem}
              />
              <SideNav.Item
                href={paths.settingsMessagingProvider}
                iconStart={<IconTwilio color={palette.white} />}
                selected={currentPath === paths.settingsMessagingProvider}
                title={copyText.sideNavItemLabelMessagingProvider}
                onClick={handleClickSideNavItem}
              />
            </>
          ) : null}
          <SideNav.Item
            href={paths.settingsMessageTemplates}
            iconStart={<IconCommentLines color={palette.white} />}
            selected={currentPath === paths.settingsMessageTemplates}
            title={copyText.sideNavItemLabelMessageTemplates}
            onClick={handleClickSideNavItem}
          />
          <SideNav.Item
            href={paths.settingsReports}
            iconStart={<IconList color={palette.white} />}
            selected={currentPath === paths.settingsReports}
            title={copyText.sideNavItemLabelReports}
            onClick={handleClickSideNavItem}
          />
          {authenticatedUser.role === UserRole.ORG_ADMIN ? (
            <SideNav.Item
              href={paths.settingsOther}
              iconStart={<IconQuestionCircle color={palette.white} />}
              selected={currentPath === paths.settingsOther}
              title={copyText.sideNavItemLabelOtherSettings}
              onClick={handleClickSideNavItem}
            />
          ) : null}
        </SideNav>
        <Layout height="100vh" minWidth={300} width="100%">
          <Layout.Header
            minHeight={theme.space_stack_xxl}
            paddingHorizontal={theme.space_inline_md}
            paddingVertical={theme.space_stack_sm}
          >
            {header}
          </Layout.Header>
          <Layout.Body
            backgroundColor={palette.gray[20]}
            padding={theme.space_inset_lg}
            scrollable
          >
            {body}
          </Layout.Body>
        </Layout>
      </Layout.Body>
    </Layout>
  );
}

const Body = ({
  children,
  ...props
}: PropsWithChildren<{ [key: string]: any }>): ReactElement => (
  <Flex {...props}>{children}</Flex>
);

const Header = ({
  children,
  ...props
}: PropsWithChildren<{ [key: string]: any }>): ReactElement => (
  <Flex {...props}>{children}</Flex>
);

Body.defaultProps = {
  alignItems: 'center',
  direction: 'column'
};

Header.defaultProps = {
  alignItems: 'center',
  height: '100%',
  justifyContent: 'between'
};

SettingsScreenLayout.Body = Body;
SettingsScreenLayout.Header = Header;

export default SettingsScreenLayout;
