export default {
  countryNameAM: 'Armenia +374',
  countryNameCA: 'Canada +1',
  countryNameES: 'Spain +34',
  countryNameGB: 'United Kingdom +44',
  countryNameIT: 'Italy +39',
  countryNameMX: 'Mexico +52',
  countryNameUS: 'United States +1',
  headerCountryCode: 'Country Code',
  submitButtonLabel: 'Save'
};
