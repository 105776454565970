export default {
  cancelButtonLabel: 'CANCEL',
  columnMappingDescription:
    'Certain TX SMS features require the system to understand the semantics of the data in your report. To enable these features, please identify and map the appropriate columns below.',
  contactIDColumnNameInputLabel: 'Contact ID',
  contactIDColumnNameInputSecondaryText:
    'Required by the following features:<br>&nbsp;» **Send Bulk Message**&nbsp;',
  firstNameColumnNameInputLabel: 'First Name',
  firstNameColumnNameInputSecondaryText:
    'Required by the following features:<br>&nbsp;» **Send Bulk Message**&nbsp;',
  lastNameColumnNameInputLabel: 'Last Name',
  lastNameColumnNameInputSecondaryText:
    'Required by the following features:<br>&nbsp;» **Send Bulk Message**&nbsp;',
  mobilePhoneNumberColumnNameInputLabel: 'Mobile Phone Number',
  mobilePhoneNumberColumnNameInputSecondaryText:
    'Required by the following features:<br>&nbsp;» **Send Bulk Message**&nbsp;',
  nameInputLabel: 'Name',
  optionPlaceholder: 'Choose One',
  salesforceReportLabel: 'Salesforce Report',
  submitButtonLabel: 'SAVE',
  title: 'Edit Report'
};
