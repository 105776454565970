import { ConversationContact } from '../types';

export function getContactFullName(contact: ConversationContact): string {
  if (!contact) return '';
  return `${contact.firstName} ${contact.lastName}`;
}

export function getContactInitials(contact: ConversationContact): string {
  if (!contact) return '';
  const firstNameInitial = contact.firstName ? contact.firstName[0] : '';
  const lastNameInitial = contact.lastName ? contact.lastName[0] : '';
  return `${firstNameInitial}${lastNameInitial}`;
}

export default {
  getContactFullName,
  getContactInitials
};
