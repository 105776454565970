/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';
import { CreateReportParameters, DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';

interface State {
  data?: string;
  error?: DataAPIClientError;
  isProcessing: boolean;
}

interface Result extends State {
  invoke: (params: CreateReportParameters) => void;
}

export default function useDataAPICreateReport(): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isProcessing: false });

  async function invoke(params: CreateReportParameters): Promise<void> {
    setState({ isProcessing: true });

    try {
      const data = await client.createReport(params);

      setState({ data, isProcessing: false });
    } catch (error: any) {
      setState({ error, isProcessing: false });
    }
  }

  return { ...state, invoke };
}
