export default {
  apiKeyNotVerifiedMessage:
    'Your credentials could not be verified at this time.',
  checkCredentialsButtonLabel: 'CHECK AGAIN',
  confirmationDialogMessage:
    "Changing your Twilio Account SID will disable all inboxes that are currently connected to Twilio services under the previously specified Twilio account. If you proceed, you will need to manually reconfigure those affected inboxes by connecting them to Twilio services under the new Twilio account you've specified.",
  confirmationDialogTitle: 'WARNING',
  FAILURE_title: 'Operation Failed.',
  FAILURE_LOADING_API_KEY_UNEXPECTED_message:
    'An unexpected error occured. Please try again.',
  FAILURE_UPDATING_API_KEY_INVALID_message:
    'The credentials you provided are invalid. Please try again.',
  FAILURE_UPDATING_API_KEY_UNEXPECTED_message:
    'An unexpected error occured when updating your API credentials. Please try again.',
  SUCCESS_title: 'Operation Successful!',
  SUCCESS_API_KEY_UPDATED_message:
    'You have successfully integrated with Twilio.',
  timeLastFailedVerificationLabel:
    'Your Twilio API credentials failed verification at %dateTime%',
  timeLastPassedVerificationLabel:
    'Your Twilio API credentials passed verification at %dateTime%',
  title: 'Messaging Provider'
};
