export default {
  cancelButtonLabel: 'CANCEL',
  headerActions: 'ACTIONS',
  headerBroadcast: 'BULK SEND',
  headerUsers: 'USERS',
  noUsersSelectedLabel: 'No Users Selected',
  placeholderAddUsers: 'Add Users',
  removeButtonLabel: 'REMOVE',
  submitButtonLabel: 'SUBMIT',
  title: 'Add Users'
};
