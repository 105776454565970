export default {
  cancelButtonLabel: 'CANCEL',
  noServicesMessage: `There are no messaging services available from the currently configured provider. Either all of your messaging services are already being used by existing inboxes, or you don't yet have access to messaging services for this provider.`, // TODO: Add (%providerName%) at the end of first sentence.
  nameInputLabel: 'Name',
  providerRIDInputCaption_0001:
    'Your organization is currently using Twilio as a messaging provider.',
  providerRIDInputLabel: 'Messaging Service',
  providerRIDInputPlaceholder: 'Choose One',
  submitButtonLabel: 'CREATE',
  title: 'Create Inbox'
};
