import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Image from '@targetx/tx-web-ui-lib/lib/components/Image';
import dateFormat from 'dateformat';
import React, { ReactElement } from 'react';
import theme from '../theme';
import { CampaignSchedule } from '../types';
import copyText from './BroadcastMessageBubble.copyText';

export namespace BroadcastMessageBubble {
  export interface BroadcastEntity {
    id: string;
    name: string;
    content: string;
    timeCreated: string;
    schedule?: CampaignSchedule;
  }

  export interface Props {
    broadcast: BroadcastEntity;
  }
}

export function BroadcastMessageBubble({
  broadcast
}: BroadcastMessageBubble.Props): ReactElement {
  const { content, timeCreated } = broadcast;

  return (
    <Flex
      backgroundColor={palette.gray[10]}
      borderRadius="8px"
      border={`1px solid ${palette.gray[50]}`}
      paddingHorizontal={theme.space_inline_lg}
      paddingVertical={theme.space_stack_md}
    >
      <FlexItem>
        <Image
          alt={copyText.title}
          height={32}
          src="tx-sms-app-logo.3f630761.svg"
          width={32}
        />
      </FlexItem>
      <FlexItem>
        <Text
          fontWeight={theme.fontWeight_semiBold}
          fontSize={theme.fontSize_ui_sm}
        >
          {copyText.title}
        </Text>
        <Text marginBottom={theme.space_stack_sm}>{content}</Text>
        {!broadcast.schedule && (
          <Text color={palette.gray[70]} fontSize={theme.fontSize_ui_sm}>
            {dateFormat(timeCreated, 'mm/dd/yyyy hh:MMTT')}
          </Text>
        )}
      </FlexItem>
    </Flex>
  );
}

export default BroadcastMessageBubble;
