import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  BroadcastStatus,
  ReportStatus
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import Image from '@targetx/tx-web-ui-lib/lib/components/Image';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import { utcToZonedTime } from 'date-fns-tz';
import dateFormat from 'dateformat';
import noop from 'lodash.noop';
import Markdown from 'markdown-to-jsx';
import React, { ReactElement } from 'react';
import theme from '../theme';
import { CampaignSchedule, ContactMappingEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import getScheduleSummary from '../utils/getScheduleSummary';
import copyText from './BroadcastInfoComponent.copyText';
import IconUsers from '@targetx/tx-web-ui-lib/lib/icons/IconUsers';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';

export namespace BroadcastInfoComponent {
  export interface BroadcastEntity {
    id: string;
    name: string;
    timeCreated: string;
    status: BroadcastStatus;
    schedule?: CampaignSchedule;
    scheduleStartTime?: string;
    scheduleEndTime?: string;
  }

  export interface MessageEntity {
    id: string;
    canonicalPhoneNumber: string;
    contactMapping?: ContactMappingEntity;
  }

  export interface ReportEntity {
    id: string;
    name: string;
    status: ReportStatus;
  }

  export interface ReportRecord {
    tx_report_record_md5: string;
    first_name: string;
    last_name: string;
  }

  export interface Props {
    broadcast: BroadcastEntity;
    isLoadingRecipients: boolean;
    recipients: ContactMappingEntity[];
    onInteraction?: InteractionDelegate;
    report: ReportEntity;
  }
}

export function BroadcastInfoComponent({
  broadcast,
  onInteraction = noop,
  isLoadingRecipients,
  recipients,
  report
}: BroadcastInfoComponent.Props): ReactElement {
  const scheduleSummary =
    broadcast.schedule &&
    broadcast.schedule.frequency &&
    broadcast.schedule.executionTimeZone &&
    broadcast.schedule.executionTime
      ? getScheduleSummary(
          {
            frequency: broadcast.schedule.frequency,
            dayOfMonth: broadcast.schedule.dayOfMonth,
            daysOfWeek: broadcast.schedule.daysOfWeek,
            executionTimeZone: broadcast.schedule.executionTimeZone,
            executionTime: broadcast.schedule.executionTime
          },
          {
            ...(broadcast?.scheduleStartTime
              ? {
                  scheduleStartDate: utcToZonedTime(
                    new Date(broadcast.scheduleStartTime),
                    broadcast.schedule?.executionTimeZone || ''
                  ).toISOString()
                }
              : {}),
            ...(broadcast?.scheduleEndTime
              ? {
                  scheduleEndDate: utcToZonedTime(
                    new Date(broadcast.scheduleEndTime),
                    broadcast.schedule?.executionTimeZone || ''
                  ).toISOString()
                }
              : {})
          }
        )
      : null;

  const broadcastStatus = getBroadcastStatus();

  const handleActionKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e = e || window.event;
    e.preventDefault();
    if (e.key === 'Enter' || e.key === ' ') {
      handleClickViewRecipientList();
    }
  };

  function handleRecipientCount(): void {
    onInteraction({
      type: BroadcastInfoComponent.INTERACTION_COUNT_RECIPIENT_BUTTON_CLICKED,
      reportID: report.id
    });
  }

  function handleClickViewRecipientList(): void {
    onInteraction({
      type: BroadcastInfoComponent.INTERACTION_VIEW_RECIPIENT_LIST_CLICKED
    });
  }

  function getBroadcastStatus() {
    let statusColor;
    let statusText;

    switch (broadcast.status) {
      case 'POST_PROCESSING':
      case 'POST_PROCESSING_COMPLETED':
      case 'COMPLETED':
        statusText = copyText.statusComplete;
        statusColor = palette.green[80];
        break;
      case 'CREATION_FAILED':
      case 'QUEUING_FAILED':
      case 'VALIDATION_FAILED':
      case 'PROCESSING_FAILED':
      case 'POST_PROCESSING_FAILED':
        statusText = copyText.statusFailed;
        statusColor = palette.red[80];
        break;
      case 'PENDING':
      case 'PROCESSING':
      case 'QUEUED':
        statusText = copyText.statusProcessing;
        statusColor = palette.blue[80];
        break;
      case 'ZERO_RECIPIENTS':
        statusText = copyText.zeroRecipients;
        statusColor = palette.gray[80];
        break;
      default:
        statusText = copyText.statusUnknown;
        break;
    }

    return { color: statusColor, text: statusText };
  }

  return (
    <Flex
      backgroundColor={palette.white}
      borderRadius="8px"
      boxShadow="0 1px 8px 0 rgba(0,0,0,0.10)"
      direction="column"
      marginLeft={theme.space_inline_lg}
      overflow="hidden"
      paddingHorizontal={theme.space_inline_md}
      paddingVertical={theme.space_stack_lg}
      width="100%"
    >
      <Flex
        alignItems="center"
        marginBottom={theme.space_stack_md}
        paddingBottom={theme.space_stack_md}
      >
        <Image height={32} src="tx-sms-app-logo.3f630761.svg" />
        <FlexItem marginLeft={theme.space_inline_md}>
          <Text
            fontSize="24px"
            lineHeight={1}
            style={{ wordBreak: 'break-word' }}
          >
            {broadcast.name}
          </Text>
          <Text color={palette.gray[70]} fontSize={theme.fontSize_ui_sm}>
            {`${
              broadcast.schedule
                ? copyText.timeCreatedLabel
                : copyText.timeSentLabel
            } ${dateFormat(broadcast.timeCreated, 'mm/dd/yyyy hh:MMTT')}`}
          </Text>
        </FlexItem>
      </Flex>
      <Box
        borderBottom={`1px solid ${palette.gray[50]}`}
        marginBottom={theme.space_stack_md}
      >
        <Text bold fontSize={theme.h4_fontSize}>
          {copyText.infoSectionHeading}
        </Text>
      </Box>
      <Box>
        <Text fontWeight={theme.fontWeight_semiBold}>
          {copyText.reportNameLabel}
        </Text>
        <Text as="span" fontSize={theme.fontSize_prose}>
          {report.name}
        </Text>
        {report.status === ReportStatus.DEACTIVATED && (
          <Text
            as="span"
            fontSize={theme.fontSize_prose}
            color={palette.red[60]}
          >
            &nbsp;(Deleted)
          </Text>
        )}
      </Box>
      {!broadcast.schedule && (
        <Box marginTop={theme.space_stack_md}>
          <Text fontWeight={theme.fontWeight_semiBold}>
            {copyText.broadcastIdLabel}
          </Text>
          <Text fontSize="0.85rem">{broadcast.id}</Text>
        </Box>
      )}
      {scheduleSummary && (
        <Box marginTop={theme.space_stack_md}>
          <Text fontWeight={theme.fontWeight_semiBold}>
            {copyText.messageScheduleLabel}
          </Text>
          <Text fontSize={theme.fontSize_prose}>
            <Markdown>{scheduleSummary}</Markdown>
          </Text>
          <Box marginTop={theme.space_stack_md}>
            <Button
              primary
              type="reset"
              width="60%"
              onClick={handleRecipientCount}
            >
              {copyText.recipientCountButton}
            </Button>
          </Box>
        </Box>
      )}
      {!broadcast.schedule && (
        <>
          <Box marginTop={theme.space_stack_md}>
            <Text fontWeight={theme.fontWeight_semiBold}>
              {copyText.messageStatusLabel}
            </Text>
            <Text
              as="span"
              fontSize={theme.fontSize_prose}
              color={broadcastStatus.color}
              fontWeight={theme.fontWeight_semiBold}
            >
              {broadcastStatus.text}&nbsp;
            </Text>
            {broadcastStatus.text === copyText.statusFailed && (
              <Text
                as="span"
                color={broadcastStatus.color}
                style={{ textTransform: 'capitalize' }}
              >
                - {broadcast.status.toLowerCase().replace(/_/g, ' ')}
              </Text>
            )}
          </Box>
          {isLoadingRecipients ? (
            <Flex justifyContent="center" padding={theme.space_inset_md}>
              <SVGSpinner fill={palette.gray[60]} size="2em" />
            </Flex>
          ) : (
            recipients.length > 0 && (
              <Box
                backgroundColor={palette.white}
                borderRadius={theme.borderRadius_2}
                boxShadow={`0 0 4px 0 ${palette.gray[30]}`}
                border={`0.5px solid ${palette.gray[10]}`}
                onClick={handleClickViewRecipientList}
                cursor="pointer"
                marginBottom={theme.space_stack_xl}
                marginTop={theme.space_stack_lg}
                tabindex="0"
                aria-label={copyText.recipientListLabel}
                css={{
                  '&:focus': {
                    outline: `1px solid ${palette.blue[60]}`,
                    outlineOffset: `-1px`
                  }
                }}
                onKeyPress={handleActionKeyPress}
              >
                <Flex
                  alignItems="center"
                  justifyContent="between"
                  padding={theme.space_inset_md}
                  paddingRight={theme.space_inset_sm}
                >
                  <Flex alignItems="center">
                    <IconUsers color={palette.blue[50]} size={24} />
                    <FlexItem
                      margin="0"
                      paddingLeft={theme.space_inset_md}
                      width="100%"
                    >
                      <Text fontWeight={theme.fontWeight_semiBold}>
                        {copyText.recipientListLabel}
                      </Text>
                      <Text>View {recipients.length} Recipients</Text>
                    </FlexItem>
                  </Flex>
                  <MinimalButton
                    iconStart={<IconChevronRight color={palette.gray[60]} />}
                    title={`View ${recipients.length} Recipients`}
                    size="small"
                  />
                </Flex>
              </Box>
            )
          )}
        </>
      )}
    </Flex>
  );
}

BroadcastInfoComponent.INTERACTION_COUNT_RECIPIENT_BUTTON_CLICKED = `${BroadcastInfoComponent.name}.INTERACTION_COUNT_RECIPIENT_BUTTON_CLICKED`;
BroadcastInfoComponent.INTERACTION_VIEW_RECIPIENT_LIST_CLICKED = `${BroadcastInfoComponent.name}.INTERACTION_VIEW_RECIPIENT_LIST_CLICKED`;
export default BroadcastInfoComponent;
