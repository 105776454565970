/* eslint-disable @typescript-eslint/no-explicit-any */

import _Popover from '@targetx/mineral-ui/Popover';
import { themed } from '@targetx/mineral-ui/themes';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import IconInfoCircle from '@targetx/tx-web-ui-lib/lib/icons/IconInfoCircle';
import Markdown from 'markdown-to-jsx';
import React, { PropsWithChildren, ReactElement } from 'react';
import theme from '../theme';

export const Popover = themed(_Popover)({
  PopoverArrow_backgroundColor: palette.black,
  PopoverArrow_borderColor: palette.black,
  PopoverContent_backgroundColor: palette.black,
  PopoverContent_borderColor: 'none',
  PopoverContent_color: palette.white,
  PopoverContent_paddingVertical: 'none',
  PopoverContent_borderRadius: theme.borderRadius_3
});

export namespace MoreInfoPopover {
  export interface Props {
    color?: string;
    hasMarkdown?: boolean;
    placement?: string;
  }
}

export function MoreInfoPopover({
  color,
  hasMarkdown = false,
  placement = 'top-end',
  children
}: PropsWithChildren<MoreInfoPopover.Props>): ReactElement {
  const content = typeof children === 'string' ? children : String(children);

  return (
    <Popover
      content={hasMarkdown ? <Markdown>{content}</Markdown> : children}
      placement={placement}
      onClick={(event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <IconInfoCircle color={color} />
    </Popover>
  );
}

export default MoreInfoPopover;
