import Box from '@targetx/mineral-ui/Box';
import Flex from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import Markdown from 'markdown-to-jsx';
import React, { ReactElement } from 'react';
import {
  ContactMappingEntity,
  MessageEntity,
  UserEntity
} from '../data-api/types';
import { getFullName as getContactMappingFullName } from '../utils/ContactMappingUtils';
import { getFullName as getUserFullName } from '../utils/UserUtils';
import copyText from './MessageTranscriptComponent.copyText';

interface MessageTranscriptComponentProps {
  isLoadingMessages: boolean;
  messages: (MessageEntity & {
    contactMapping?: ContactMappingEntity;
    user?: UserEntity;
  })[];
}

export default function MessageTranscriptComponent({
  isLoadingMessages,
  messages
}: MessageTranscriptComponentProps): ReactElement {
  if (isLoadingMessages) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        padding={theme.space_inset_md}
      >
        <SVGSpinner size="2.5em" />
      </Flex>
    );
  }

  if (messages.length === 0) {
    return (
      <Text align="center" padding={theme.space_inset_md}>
        {copyText.noMessagesLabel}
      </Text>
    );
  }

  return (
    <Box>
      {messages.map(message => {
        let displayName = message.canonicalPhoneNumber;

        if (message.user) {
          displayName = getUserFullName(message.user);
        } else if (message.contactMapping) {
          displayName = getContactMappingFullName(message.contactMapping);
        }

        return (
          <Box key={message.id} marginBottom={theme.space_stack_md}>
            <Text
              color={palette.blue[60]}
              fontWeight={theme.fontWeight_semiBold}
            >
              {dateFormat(message.timeCreated, 'mm/dd/yy hh:MM:ss TT')}
            </Text>
            <Markdown>{`**${displayName}:** ${message.content}`}</Markdown>
          </Box>
        );
      })}
    </Box>
  );
}
