/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  CampaignScheduleStatus,
  CampaignStatus
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import CreateCampaignCommand, {
  CreateCampaignCommandAck,
  CreateCampaignCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateCampaignCommand';
import UpdateCampaignCommand, {
  UpdateCampaignCommandAck,
  UpdateCampaignCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateCampaignCommand';
import { UserInboxPermissionType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import {
  BroadcastsQueryByInboxID,
  BroadcastsQueryFailure,
  BroadcastsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/BroadcastsQuery';
import {
  CampaignsQueryByInboxID,
  CampaignsQueryFailure,
  CampaignsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/CampaignsQuery';
import {
  ContactMappingsQueryByBroadcastID,
  ContactMappingsQueryFailure,
  ContactMappingsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactMappingsQuery';
import {
  InboxQueryByID,
  InboxQueryFailure,
  InboxQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxQuery';
import {
  InboxUsersQueryByInboxID,
  InboxUsersQueryFailure,
  InboxUsersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxUsersQuery';
import {
  MessageTemplatesQueryByOwnerID,
  MessageTemplatesQueryFailure,
  MessageTemplatesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageTemplatesQuery';
import {
  ReportColumnNamesQueryByReportID,
  ReportColumnNamesQueryFailure,
  ReportColumnNamesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportColumnNamesQuery';
import {
  ReportQueryByID,
  ReportQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportQuery';
import {
  ReportRecordCountQueryByReportID,
  ReportRecordCountQueryFailure,
  ReportRecordCountQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportRecordCountQuery';
import {
  ReportsQueryByOrgID,
  ReportsQueryFailure,
  ReportsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportsQuery';
import {
  UserPermissionsQueryByInboxID,
  UserPermissionsQueryByUserID,
  UserPermissionsQueryFailure,
  UserPermissionsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/UserPermissionsQuery';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import IconArrowLeft from '@targetx/tx-web-ui-lib/lib/icons/IconArrowLeft';
import IconCog from '@targetx/tx-web-ui-lib/lib/icons/IconCog';
import IconComments from '@targetx/tx-web-ui-lib/lib/icons/IconComments';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconSchedule from '@targetx/tx-web-ui-lib/lib/icons/IconSchedule';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import get from 'lodash.get';
import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import NavigateCommand from '../commands/NavigateCommand';
import BroadcastInfoComponent from '../components/BroadcastInfoComponent';
import BroadcastList from '../components/BroadcastList';
import BroadcastMessageBubble from '../components/BroadcastMessageBubble';
import BulkMessageDetails from '../components/BulkMessageDetails';
import CreateCampaignForm from '../components/CreateCampaignForm';
import ScreenLevelAlert from '../components/ScreenLevelAlert';
import paths from '../constants/paths';
import DispatcherContext from '../DispatcherContext';
import { actionPanelStyles, modalStyles } from '../styles';
import {
  BaseUserEntity,
  BroadcastEntity,
  CampaignEntity,
  ContactMappingEntity,
  InboxEntity,
  MessageEntity,
  ReportEntity,
  UserEntity,
  UserPermissionEntity
} from '../types';
import Interaction from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import { getFullName as userFullName } from '../utils/UserUtils';
import getScheduleSummary from '../utils/getScheduleSummary';
import copyText from './BroadcastsScreen.copyText';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import {
  UserQueryByID,
  UserQueryFailure,
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import BroadcastRecipients from '../components/BroadcastRecipients';

const ACTION_PANEL_BROADCAST_EDIT_FORM = 'BROADCAST_EDIT_FORM';
const ACTION_PANEL_BROADCAST_MESSAGE_DETAILS = 'BROADCAST_MESSAGE_DETAILS';
const ACTION_PANEL_CAMPAIGN_FORM = 'CAMPAIGN_FORM';
const ACTION_PANEL_RECIPIENTS = 'RECIPIENTS';

const ERROR_LOADING_ORG_SETTINGS = 'ERROR_LOADING_ORG_SETTINGS';

const FAILURE_CREATING_CAMPAIGN = 'FAILURE_CREATING_CAMPAIGN';
const FAILURE_UPDATING_CAMPAIGN = 'FAILURE_UPDATING_CAMPAIGN';
const FAILURE_INBOX_NOT_FOUND = 'FAILURE_INBOX_NOT_FOUND';
const FAILURE_INBOX_PERMISSION_DENIED = 'FAILURE_INBOX_PERMISSION_DENIED';
const FAILURE_INITIALIZING = 'FAILURE_INITIALIZING';
const FAILURE_LOADING_BROADCASTS = 'FAILURE_LOADING_BROADCASTS';
const FAILURE_LOADING_CAMPAIGNS = 'FAILURE_LOADING_CAMPAIGNS';
const FAILURE_LOADING_MESSAGES = 'FAILURE_LOADING_MESSAGES';
const FAILURE_LOADING_RECIPIENTS = 'FAILURE_LOADING_RECIPIENTS';
const FAILURE_LOADING_REPORT = 'FAILURE_LOADING_REPORT';
const FAILURE_LOADING_REPORT_COLUMN_NAMES =
  'FAILURE_LOADING_REPORT_COLUMN_NAMES';
const FAILURE_LOADING_REPORT_RECORD_COUNT =
  'FAILURE_LOADING_REPORT_RECORD_COUNT';

const MODAL_DISCARD_CAMPAIGN_CONFIRMATION = 'DISCARD_CAMPAIGN_CONFIRMATION';
const MODAL_RECIPIENT_COUNT_REPORT = 'MODAL_RECIPIENT_COUNT_REPORT';
const MODAL_SEND_BROADCAST_CONFIRMATION = 'SEND_BROADCAST_CONFIRMATION';
const MODAL_SEND_CAMPAIGN_CONFIRMATION = 'SEND_CAMPAIGN_CONFIRMATION';

const SUCCESS_CAMPAIGN_CREATED = 'SUCCESS_CAMPAIGN_CREATED';
const SUCCESS_CAMPAIGN_UPDATED = 'SUCCESS_CAMPAIGN_UPDATED';

export namespace BroadcastsScreen {
  export interface Props {
    authenticatedUser: UserEntity;
    homeBaseURL: string;
    inboxID?: string;
    path?: string;
    signOutPath: string;
  }
}

interface CreateCampaignParameters {
  reportID: string;
  name: string;
  content: string;
  options: { [key: string]: any };
  campaignID: string;
  scheduleStatus: CampaignScheduleStatus;
  schedule?: CreateCampaignCommand.Schedule;
  scheduleStartDate?: string;
  scheduleEndDate?: string;
  userID: string;
}

interface LastModifiedCampaignInfo {
  id: string;
  name: string;
}

interface TemplateRecord {
  id: string;
  orgID: string;
  ownerID?: string;
  name: string;
  content: string;
}

interface State {
  actionPanelKey: string;
  alertKey: string;
  broadcasts: BroadcastEntity[];
  campaigns: CampaignEntity[];
  campaignEdited: boolean;
  contactMappings: ContactMappingEntity[];
  createCampaignParameters: CreateCampaignParameters | null;
  inbox: InboxEntity | null;
  isSavingCampaign: boolean;
  isInitializing: boolean;
  isLoadingBroadcasts: boolean;
  isLoadingCampaigns: boolean;
  isLoadingRecipients: boolean;
  isLoadingReportColumnNames: boolean;
  isLoadingReportRecordCount: boolean;
  isLoadingReports: boolean;
  isLoadingSelectedReportColumnMapping: boolean;
  isLoadingUsers: boolean;
  lastModifiedCampaign: LastModifiedCampaignInfo | null;
  messages: MessageEntity[];
  modalComponentKey: string;
  otherError: string;
  reportColumnNames: string[];
  reportRecordCount: number;
  reports: ReportEntity[];
  searchTerm: string;
  selectedBroadcastID: string;
  selectedCampaignID: string;
  selectedReportColumnMapping?: { [key: string]: string };
  templates: TemplateRecord[];
  users: BaseUserEntity[];
  userPermissions: UserPermissionEntity[];
  broadcastFilterLabel: string;
}

const initialState = {
  actionPanelKey: '',
  alertKey: '',
  campaignEdited: false,
  broadcasts: [],
  campaigns: [],
  contactMappings: [],
  createCampaignParameters: null,
  inbox: null,
  isSavingCampaign: false,
  isInitializing: false,
  isLoadingBroadcasts: false,
  isLoadingCampaigns: false,
  isLoadingRecipients: false,
  isLoadingReportColumnNames: false,
  isLoadingReportRecordCount: false,
  isLoadingReports: false,
  isLoadingSelectedReportColumnMapping: true,
  isLoadingUsers: false,
  lastModifiedCampaign: null,
  otherError: '',
  messages: [],
  modalComponentKey: '',
  reportColumnNames: [],
  reportRecordCount: 0,
  reports: [],
  searchTerm: '',
  selectedBroadcastID: '',
  selectedCampaignID: '',
  templates: [],
  users: [],
  userPermissions: [],
  broadcastFilterLabel: copyText.broadcastsFilterLabelAll
};

export function BroadcastsScreen({
  authenticatedUser,
  homeBaseURL,
  inboxID = '',
  signOutPath
}: BroadcastsScreen.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    actionPanelKey,
    alertKey,
    campaignEdited,
    contactMappings,
    createCampaignParameters,
    inbox,
    isSavingCampaign,
    isInitializing,
    isLoadingBroadcasts,
    isLoadingCampaigns,
    isLoadingRecipients,
    isLoadingReportColumnNames,
    isLoadingReportRecordCount,
    isLoadingReports,
    isLoadingSelectedReportColumnMapping,
    isLoadingUsers,
    lastModifiedCampaign,
    modalComponentKey,
    otherError,
    reportColumnNames,
    reportRecordCount,
    reports,
    searchTerm,
    selectedBroadcastID,
    selectedCampaignID,
    selectedReportColumnMapping,
    templates,
    users,
    userPermissions
  } = state;

  let { broadcasts, campaigns } = state;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function createCampaign(
    inboxID: string,
    parameters: CreateCampaignParameters
  ): Promise<void> {
    const {
      reportID,
      name,
      content,
      options,
      schedule,
      scheduleStatus,
      scheduleEndDate,
      scheduleStartDate
    } = parameters;

    changeState({ isSavingCampaign: true });

    const ack = await dispatcher.dispatch<
      CreateCampaignCommandAck | CreateCampaignCommandFailure
    >(
      new CreateCampaignCommand({
        orgID: authenticatedUser.orgID,
        inboxID,
        userID: authenticatedUser.id,
        status: CampaignStatus.ACTIVE,
        reportID,
        name,
        content,
        options,
        scheduleStatus,
        schedule,
        scheduleEndDate,
        scheduleStartDate
      })
    );

    if (ack instanceof CreateCampaignCommandFailure) {
      changeState({
        actionPanelKey: '',
        createCampaignParameters: null,
        alertKey: FAILURE_CREATING_CAMPAIGN,
        otherError: ack.reason.replace(/_/g, ' '),
        modalComponentKey: '',
        isSavingCampaign: false
      });
      return;
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_CAMPAIGN_CREATED,
      otherError: '',
      createCampaignParameters: null,
      isSavingCampaign: false,
      lastModifiedCampaign: { id: ack.campaignID, name },
      modalComponentKey: '',
      campaignEdited: false
    });

    loadBroadcastsByInboxID(inboxID);
    loadCampaignsByInboxID(inboxID);
  }

  async function updateCampaign(
    inboxID: string,
    parameters: CreateCampaignParameters
  ): Promise<void> {
    const {
      campaignID,
      reportID,
      name,
      content,
      options,
      schedule,
      scheduleStatus,
      scheduleEndDate,
      scheduleStartDate,
      userID
    } = parameters;

    changeState({ isSavingCampaign: true });

    const ack = await dispatcher.dispatch<
      UpdateCampaignCommandAck | UpdateCampaignCommandFailure
    >(
      new UpdateCampaignCommand({
        campaignID,
        orgID: authenticatedUser.orgID,
        inboxID,
        userID: userID || authenticatedUser.id,
        status: CampaignStatus.ACTIVE,
        reportID,
        name,
        content,
        options,
        scheduleStatus,
        schedule,
        scheduleEndDate,
        scheduleStartDate
      })
    );

    if (ack instanceof UpdateCampaignCommandFailure) {
      changeState({
        actionPanelKey: '',
        createCampaignParameters: null,
        alertKey: FAILURE_UPDATING_CAMPAIGN,
        otherError: ack.reason.replace(/_/g, ' '),
        modalComponentKey: '',
        isSavingCampaign: false
      });
      return;
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_CAMPAIGN_UPDATED,
      createCampaignParameters: null,
      isSavingCampaign: false,
      lastModifiedCampaign: { id: ack.campaignID, name },
      modalComponentKey: '',
      campaignEdited: false
    });

    loadBroadcastsByInboxID(inboxID);
    loadCampaignsByInboxID(inboxID);
  }

  async function initialize(inboxID: string): Promise<void> {
    changeState({ isInitializing: true });

    let query;

    try {
      query = new InboxQueryByID({ inboxID });
    } catch {
      changeState({ alertKey: FAILURE_INBOX_NOT_FOUND, isInitializing: false });
      return;
    }

    const inboxQueryResult = await dispatcher.dispatch<
      InboxQueryResult | InboxQueryFailure
    >(query);

    if (inboxQueryResult instanceof InboxQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const permissionsQueryResult = await dispatcher.dispatch<
      UserPermissionsQueryResult | UserPermissionsQueryFailure
    >(new UserPermissionsQueryByUserID({ userID: authenticatedUser.id }));

    if (permissionsQueryResult instanceof UserPermissionsQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const permission = permissionsQueryResult.permissions.find(
      permission =>
        permission.targetID === inboxID &&
        permission.type === UserInboxPermissionType.CAN_SEND_BROADCAST
    );

    if (!permission) {
      changeState({
        alertKey: FAILURE_INBOX_PERMISSION_DENIED,
        isInitializing: false
      });
      return;
    }

    const broadcastsQueryResult = await dispatcher.dispatch<
      BroadcastsQueryResult | BroadcastsQueryFailure
    >(new BroadcastsQueryByInboxID({ inboxID }));

    if (broadcastsQueryResult instanceof BroadcastsQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const campaignsQueryResult = await dispatcher.dispatch<
      CampaignsQueryResult | CampaignsQueryFailure
    >(new CampaignsQueryByInboxID({ inboxID }));

    if (campaignsQueryResult instanceof CampaignsQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const reportsQueryResult = await dispatcher.dispatch<
      ReportsQueryResult | ReportsQueryFailure
    >(new ReportsQueryByOrgID({ orgID: authenticatedUser.orgID }));

    if (reportsQueryResult instanceof ReportsQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const messageTemplatesQueryResult = await dispatcher.dispatch<
      MessageTemplatesQueryResult | MessageTemplatesQueryFailure
    >(new MessageTemplatesQueryByOwnerID({ ownerID: authenticatedUser.id }));

    if (messageTemplatesQueryResult instanceof MessageTemplatesQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    changeState({
      broadcasts: broadcastsQueryResult.broadcasts,
      campaigns: campaignsQueryResult.campaigns,
      inbox: inboxQueryResult.inbox,
      isInitializing: false,
      reports: reportsQueryResult.reports,
      templates: messageTemplatesQueryResult.templates
    });
  }

  async function loadBroadcastsByInboxID(inboxID: string): Promise<void> {
    changeState({ isLoadingBroadcasts: true });

    const result = await dispatcher.dispatch<
      BroadcastsQueryResult | BroadcastsQueryFailure
    >(new BroadcastsQueryByInboxID({ inboxID }));

    if (result instanceof BroadcastsQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_BROADCASTS,
        isLoadingBroadcasts: false
      });
      return;
    }

    changeState({ broadcasts: result.broadcasts, isLoadingBroadcasts: false });
  }

  async function loadCampaignsByInboxID(inboxID: string): Promise<void> {
    changeState({ isLoadingCampaigns: true });

    const result = await dispatcher.dispatch<
      CampaignsQueryResult | CampaignsQueryFailure
    >(new CampaignsQueryByInboxID({ inboxID }));

    if (result instanceof CampaignsQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_CAMPAIGNS,
        isLoadingCampaigns: false
      });
      return;
    }

    changeState({ campaigns: result.campaigns, isLoadingCampaigns: false });
  }

  async function loadRecipientsByBroadcastID(
    broadcastID: string
  ): Promise<void> {
    changeState({ isLoadingRecipients: true });

    const contactMappingsQueryResult = await dispatcher.dispatch<
      ContactMappingsQueryResult | ContactMappingsQueryFailure
    >(new ContactMappingsQueryByBroadcastID({ broadcastID }));

    if (contactMappingsQueryResult instanceof ContactMappingsQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_RECIPIENTS,
        isLoadingRecipients: false
      });
      return;
    }

    changeState({
      contactMappings: contactMappingsQueryResult.mappings,
      isLoadingRecipients: false
    });
  }

  async function loadUsersByCampaignID(campaignID: string): Promise<void> {
    changeState({ isLoadingUsers: true });

    const usersQueryResult = await dispatcher.dispatch<
      InboxUsersQueryResult | InboxUsersQueryFailure
    >(
      new InboxUsersQueryByInboxID({
        inboxID,
        userIDs: [campaignsKeyedByID[campaignID].userID]
      })
    );

    if (usersQueryResult instanceof InboxUsersQueryFailure) {
      changeState({
        alertKey: FAILURE_INITIALIZING,
        isInitializing: false,
        isLoadingUsers: false
      });
      return;
    }

    const userList = usersQueryResult.users;

    const userPermissionsQueryResult = await dispatcher.dispatch<
      UserPermissionsQueryResult | UserPermissionsQueryFailure
    >(new UserPermissionsQueryByInboxID({ inboxID }));

    if (userPermissionsQueryResult instanceof UserPermissionsQueryFailure) {
      changeState({
        alertKey: FAILURE_INITIALIZING,
        isInitializing: false,
        isLoadingUsers: false
      });
      return;
    }

    const userPermissions = userPermissionsQueryResult.permissions;

    if (authenticatedUser.role === UserRole.ORG_ADMIN) {
      const userQueryResult = await dispatcher.dispatch<
        UserQueryResult | UserQueryFailure
      >(
        new UserQueryByID({
          userID: campaignsKeyedByID[campaignID].userID
        })
      );
      if (userQueryResult instanceof UserQueryFailure) {
        changeState({
          alertKey: FAILURE_INITIALIZING,
          isInitializing: false,
          isLoadingUsers: false
        });
      }
      if (!userList.some(user => user.id === userQueryResult.user.id)) {
        userList.push(userQueryResult.user);
      }
    }

    changeState({
      isLoadingUsers: false,
      users: userList.sort((a, b) =>
        userFullName(a).localeCompare(userFullName(b))
      ),
      userPermissions
    });
  }

  async function loadReportColumnNamesByReportID(
    reportID: string
  ): Promise<void> {
    changeState({ alertKey: '', isLoadingReportColumnNames: true });

    const result = await dispatcher.dispatch<
      ReportColumnNamesQueryResult | ReportColumnNamesQueryFailure
    >(new ReportColumnNamesQueryByReportID({ reportID }));

    if (result instanceof ReportColumnNamesQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_REPORT_COLUMN_NAMES,
        isLoadingReportColumnNames: false
      });
      return;
    }

    changeState({
      isLoadingReportColumnNames: false,
      reportColumnNames: result.columnNames
    });
  }

  async function loadReportRecordCountByReportID(
    reportID: string,
    userID?: string
  ): Promise<void> {
    changeState({ isLoadingReportRecordCount: true });

    const result = await dispatcher.dispatch<
      ReportRecordCountQueryResult | ReportRecordCountQueryFailure
    >(new ReportRecordCountQueryByReportID({ reportID, userID }));

    if (result instanceof ReportRecordCountQueryFailure) {
      changeState({
        actionPanelKey: '',
        alertKey: FAILURE_LOADING_REPORT_RECORD_COUNT,
        isLoadingReportRecordCount: false,
        modalComponentKey: ''
      });
      return;
    }

    changeState({
      isLoadingReportRecordCount: false,
      reportRecordCount: result.count
    });
  }

  async function loadSelectedReportColumnMapping(
    reportID: string
  ): Promise<void> {
    const result = await dispatcher.dispatch(new ReportQueryByID({ reportID }));

    if (result instanceof ReportQueryResult) {
      changeState({
        isLoadingSelectedReportColumnMapping: false,
        selectedReportColumnMapping: result.report.columnMapping
      });
      return;
    }

    changeState({
      alertKey: FAILURE_LOADING_REPORT,
      isLoadingSelectedReportColumnMapping: false
    });
  }

  function navigate(path: string): void {
    dispatcher.dispatch(new NavigateCommand({ path }));
  }

  //
  // Interaction Handlers
  //

  const handleActionPanelKeyDown = useCallback(
    e => {
      e = e || window.event;

      if (e.key === 'Escape') {
        handleCloseActionPanel();
      }
    },
    [actionPanelKey, campaignEdited]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleActionPanelKeyDown);
    return () => {
      window.removeEventListener('keydown', handleActionPanelKeyDown);
    };
  }, [handleActionPanelKeyDown]);

  useEffect(() => {
    if (actionPanelKey === ACTION_PANEL_RECIPIENTS) {
      document.body.style.overflowX = 'hidden';
    } else {
      document.body.style.overflowX = '';
    }
  }, [actionPanelKey]);

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;
    changeState({ actionPanelKey: '' });
  }

  function handleClickBackArrow(): void {
    if (
      [FAILURE_INBOX_NOT_FOUND, FAILURE_INBOX_PERMISSION_DENIED].includes(
        alertKey
      )
    ) {
      navigate(paths.inboxes);
      return;
    }

    navigate(paths.inbox.replace(':inboxID', inboxID));
  }

  function handleClickOpenCreateCampaignForm(): void {
    changeState({ actionPanelKey: ACTION_PANEL_CAMPAIGN_FORM });
  }

  function handleClickOpenEditCampaignForm(): void {
    changeState({ actionPanelKey: ACTION_PANEL_BROADCAST_EDIT_FORM });
  }

  function handleClickOpenBulkMessageDetails(): void {
    changeState({ actionPanelKey: ACTION_PANEL_BROADCAST_MESSAGE_DETAILS });
  }

  function handleClickRootContainer(): void {
    if (
      [
        '',
        FAILURE_INBOX_NOT_FOUND,
        FAILURE_INBOX_PERMISSION_DENIED,
        FAILURE_INITIALIZING,
        FAILURE_LOADING_BROADCASTS,
        FAILURE_LOADING_CAMPAIGNS,
        FAILURE_LOADING_REPORT_COLUMN_NAMES
      ].includes(alertKey)
    ) {
      return;
    }

    changeState({ alertKey: '' });
  }

  function handleClickSideNavItem(path: string): void {
    navigate(path);
  }

  function handleCloseActionPanel(): void {
    if (
      campaignEdited &&
      [ACTION_PANEL_CAMPAIGN_FORM, ACTION_PANEL_BROADCAST_EDIT_FORM].includes(
        actionPanelKey
      )
    ) {
      changeState({ modalComponentKey: MODAL_DISCARD_CAMPAIGN_CONFIRMATION });
    } else {
      changeState({ actionPanelKey: '' });
    }
  }

  function handleCloseModal(): void {
    switch (modalComponentKey) {
      case MODAL_RECIPIENT_COUNT_REPORT:
      case MODAL_DISCARD_CAMPAIGN_CONFIRMATION: {
        changeState({ modalComponentKey: '' });
        return;
      }
      case MODAL_SEND_BROADCAST_CONFIRMATION:
      case MODAL_SEND_CAMPAIGN_CONFIRMATION: {
        changeState({ createCampaignParameters: null, modalComponentKey: '' });
        return;
      }
    }
  }

  function handleConfirmModal(): void {
    switch (modalComponentKey) {
      case MODAL_RECIPIENT_COUNT_REPORT:
      case MODAL_DISCARD_CAMPAIGN_CONFIRMATION: {
        changeState({
          actionPanelKey: '',
          modalComponentKey: '',
          campaignEdited: false
        });
        return;
      }
      case MODAL_SEND_BROADCAST_CONFIRMATION:
      case MODAL_SEND_CAMPAIGN_CONFIRMATION: {
        if (!isSavingCampaign) {
          if (createCampaignParameters?.campaignID) {
            updateCampaign(
              inboxID,
              createCampaignParameters as CreateCampaignParameters
            );
          } else {
            createCampaign(
              inboxID,
              createCampaignParameters as CreateCampaignParameters
            );
          }
        }
        return;
      }
    }
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case BroadcastList.INTERACTION_ITEM_SELECTED: {
        const broadcast = broadcastsKeyedByID[data.broadcastID];

        changeState({
          contactMappings: [],
          selectedBroadcastID: broadcast.id,
          selectedCampaignID: undefined
        });

        loadRecipientsByBroadcastID(broadcast.id);
        return;
      }
      case BroadcastList.INTERACTION_CAMPAIGN_ITEM_SELECTED: {
        const campaign = campaignsKeyedByID[data.campaignID];

        loadSelectedReportColumnMapping(campaign.reportID);
        loadReportColumnNamesByReportID(campaign.reportID);

        changeState({
          contactMappings: [],
          selectedBroadcastID: undefined,
          selectedCampaignID: campaign.id
        });

        loadUsersByCampaignID(campaign.id);
        return;
      }
      case BroadcastInfoComponent.INTERACTION_COUNT_RECIPIENT_BUTTON_CLICKED: {
        if (!selectedCampaignID) return;
        const { userID } = campaignsKeyedByID[selectedCampaignID];
        loadReportRecordCountByReportID(data.reportID, userID);
        changeState({
          createCampaignParameters,
          modalComponentKey: MODAL_RECIPIENT_COUNT_REPORT
        });
      }
      case BroadcastInfoComponent.INTERACTION_VIEW_RECIPIENT_LIST_CLICKED: {
        if (!selectedBroadcastID) return;

        changeState({ actionPanelKey: ACTION_PANEL_RECIPIENTS });
      }
      case CreateCampaignForm.INTERACTION_INPUT_CHANGED: {
        if (!campaignEdited) {
          changeState({ campaignEdited: true });
        }
        return;
      }
      case CreateCampaignForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        if (campaignEdited) {
          changeState({
            modalComponentKey: MODAL_DISCARD_CAMPAIGN_CONFIRMATION
          });
        } else {
          changeState({ actionPanelKey: '' });
        }
        return;
      }
      case CreateCampaignForm.INTERACTION_RELOAD_BUTTON_CLICKED: {
        loadSelectedReportColumnMapping(data.reportID);
        return;
      }
      case CreateCampaignForm.INTERACTION_REPORT_SELECTED: {
        loadSelectedReportColumnMapping(data.reportID);
        loadReportColumnNamesByReportID(data.reportID);
        return;
      }
      case CreateCampaignForm.INTERACTION_UPDATE_BUTTON_CLICKED:
      case CreateCampaignForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        const createCampaignParameters = {
          reportID: data.reportID,
          name: data.name,
          content: data.content,
          options: {
            ...(isNil(data.enableContactActivityLogging)
              ? {}
              : {
                  enableContactActivityLogging:
                    data.enableContactActivityLogging
                })
          },
          campaignID: data.campaignID,
          scheduleStatus: data.recurring
            ? CampaignScheduleStatus.ENABLED
            : CampaignScheduleStatus.DISABLED,
          ...(!isNil(data.scheduleStartDate) && {
            scheduleStartDate: data.scheduleStartDate
          }),
          ...(!isNil(data.scheduleEndDate) && {
            scheduleEndDate: data.scheduleEndDate
          }),
          ...(!isNil(data.frequency) &&
            !isNil(data.executionTimeZone) &&
            !isNil(data.executionTime) && {
              schedule: {
                sendDuplicates: data.sendDuplicates,
                frequency: data.frequency,
                executionTimeZone: data.executionTimeZone,
                executionTime: data.executionTime,
                ...(!isNil(data.dayOfMonth) && {
                  dayOfMonth: data.dayOfMonth
                }),
                ...(data.daysOfWeek.length > 0 && {
                  daysOfWeek: data.daysOfWeek
                })
              }
            }),
          userID: data.userID || authenticatedUser.id
        };
        if (
          createCampaignParameters.scheduleStatus ===
          CampaignScheduleStatus.ENABLED
        ) {
          changeState({
            createCampaignParameters,
            modalComponentKey: MODAL_SEND_CAMPAIGN_CONFIRMATION
          });
        } else if (
          type === CreateCampaignForm.INTERACTION_SUBMIT_BUTTON_CLICKED
        ) {
          changeState({
            createCampaignParameters,
            modalComponentKey: MODAL_SEND_BROADCAST_CONFIRMATION
          });
          loadReportRecordCountByReportID(data.reportID);
        } else if (
          type === CreateCampaignForm.INTERACTION_UPDATE_BUTTON_CLICKED
        ) {
          changeState({ createCampaignParameters });
          updateCampaign(
            inboxID,
            createCampaignParameters as CreateCampaignParameters
          );
        }

        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        changeState({ searchTerm: data.searchTerm });
        return;
      }
      case BulkMessageDetails.INTERACTION_BROADCAST_EDIT: {
        handleClickOpenEditCampaignForm();
        return;
      }
      case BroadcastList.INTERACTION_FILTER_BROADCAST: {
        changeState({ broadcastFilterLabel: data.event.target.value });
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    initialize(inboxID);
  }, [inboxID]);

  //
  // Render
  //

  const broadcastsKeyedByID = useMemo(
    () => keyBy(broadcasts, 'id'),
    [broadcasts]
  );

  const campaignsKeyedByID = useMemo(() => keyBy(campaigns, 'id'), [campaigns]);

  const reportsKeyedByID = useMemo(() => keyBy(reports, 'id'), [reports]);

  broadcasts = useMemo(
    () => orderBy(broadcasts, 'timeCreated', 'desc'),
    [broadcasts]
  );

  if (searchTerm.length > 0) {
    broadcasts = broadcasts.filter(({ name }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  campaigns = useMemo(
    () => orderBy(campaigns, 'timeCreated', 'desc'),
    [campaigns]
  );

  if (searchTerm.length > 0) {
    campaigns = campaigns.filter(({ name }) =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  let broadcast: any = broadcastsKeyedByID[selectedBroadcastID];

  if (selectedCampaignID) {
    broadcast = campaignsKeyedByID[selectedCampaignID];
  }

  const report = reportsKeyedByID[broadcast?.reportID];

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_CAMPAIGN_FORM: {
        return (
          <CreateCampaignForm
            isDisabledForSelectedReport={
              alertKey === FAILURE_LOADING_REPORT_COLUMN_NAMES
            }
            isLoadingReports={isLoadingReports}
            isLoadingSelectedReportColumnMapping={
              isLoadingSelectedReportColumnMapping
            }
            isLoadingSelectedReportColumnNames={isLoadingReportColumnNames}
            isProcessing={isSavingCampaign || isLoadingReportColumnNames}
            message={renderAlert()}
            reports={reports}
            reportsBaseURL={paths.settingsReports}
            selectedReportColumnMapping={selectedReportColumnMapping}
            selectedReportColumnNames={reportColumnNames}
            templates={templates}
            onInteraction={handleInteraction}
            campaign={null}
            authenticatedUser={authenticatedUser}
          />
        );
      }
      case ACTION_PANEL_BROADCAST_EDIT_FORM: {
        if (!selectedCampaignID) return;
        const currentCampaign = campaignsKeyedByID[selectedCampaignID];
        if (!currentCampaign) return;
        return (
          <CreateCampaignForm
            isDisabledForSelectedReport={
              alertKey === FAILURE_LOADING_REPORT_COLUMN_NAMES
            }
            isLoadingReports={isLoadingReports}
            isLoadingSelectedReportColumnMapping={
              isLoadingSelectedReportColumnMapping
            }
            isLoadingSelectedReportColumnNames={isLoadingReportColumnNames}
            isLoadingUsers={isLoadingUsers}
            isProcessing={isSavingCampaign || isLoadingReportColumnNames}
            message={renderAlert()}
            reports={reports}
            reportsBaseURL={paths.settingsReports}
            selectedReportColumnMapping={selectedReportColumnMapping}
            selectedReportColumnNames={reportColumnNames}
            templates={templates}
            users={users}
            userPermissions={userPermissions}
            onInteraction={handleInteraction}
            campaign={currentCampaign}
            authenticatedUser={authenticatedUser}
          />
        );
      }
      case ACTION_PANEL_BROADCAST_MESSAGE_DETAILS: {
        return (
          <BulkMessageDetails
            broadcasts={broadcasts}
            campaigns={campaigns}
            users={users}
            isLoadingExecutions={
              isInitializing || isLoadingBroadcasts || isLoadingCampaigns
            }
            selectedCampaignID={selectedCampaignID}
            onInteraction={handleInteraction}
          ></BulkMessageDetails>
        );
      }
      case ACTION_PANEL_RECIPIENTS: {
        return (
          <BroadcastRecipients
            recipients={contactMappings}
            handleClose={handleCloseActionPanel}
          ></BroadcastRecipients>
        );
      }
    }
  }

  function renderAlert(): ReactNode {
    if (
      ![FAILURE_LOADING_REPORT_COLUMN_NAMES, FAILURE_LOADING_REPORT].includes(
        alertKey
      )
    ) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_md}
        title={copyText.FAILURE_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_DISCARD_CAMPAIGN_CONFIRMATION: {
        return (
          <ConfirmationDialog
            message={copyText.discardBroadcastConfirmationMessage}
            title={copyText.discardBroadcastConfirmationTitle}
            variant="warning"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        );
      }
      case MODAL_SEND_BROADCAST_CONFIRMATION: {
        return (
          <ConfirmationDialog
            isLoading={isLoadingReportRecordCount}
            message={copyText.sendBroadcastConfirmationMessage.replace(
              '%count%',
              String(reportRecordCount)
            )}
            title={copyText.sendBroadcastConfirmationTitle}
            variant="warning"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        );
      }
      case MODAL_RECIPIENT_COUNT_REPORT: {
        return (
          <ConfirmationDialog
            isLoading={isLoadingReportRecordCount}
            message={copyText.recipient_count_button.replace(
              '%count%',
              String(reportRecordCount)
            )}
            title={copyText.report_recipient_button}
            variant="warning"
            confirmMessage="OK"
            onClose={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        );
      }
      case MODAL_SEND_CAMPAIGN_CONFIRMATION: {
        return (
          <ConfirmationDialog
            isLoading={isLoadingReportRecordCount}
            message={copyText.sendCampaignConfirmationMessage.replace(
              '%schedule%',
              getScheduleSummary(
                {
                  frequency:
                    createCampaignParameters?.schedule?.frequency || '',
                  dayOfMonth: createCampaignParameters?.schedule?.dayOfMonth,
                  daysOfWeek: createCampaignParameters?.schedule?.daysOfWeek,
                  executionTimeZone:
                    createCampaignParameters?.schedule?.executionTimeZone || '',
                  executionTime:
                    createCampaignParameters?.schedule?.executionTime || ''
                },
                {
                  scheduleStartDate:
                    createCampaignParameters?.scheduleStartDate,
                  ...(createCampaignParameters?.scheduleEndDate
                    ? {
                        scheduleEndDate:
                          createCampaignParameters?.scheduleEndDate
                      }
                    : {})
                }
              )
            )}
            title={copyText.sendCampaignConfirmationTitle}
            variant="warning"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        );
      }
      default:
        return null;
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        FAILURE_CREATING_CAMPAIGN,
        FAILURE_UPDATING_CAMPAIGN,
        FAILURE_INBOX_NOT_FOUND,
        FAILURE_INBOX_PERMISSION_DENIED,
        FAILURE_INITIALIZING,
        FAILURE_LOADING_BROADCASTS,
        FAILURE_LOADING_CAMPAIGNS,
        FAILURE_LOADING_MESSAGES,
        FAILURE_LOADING_RECIPIENTS,
        ERROR_LOADING_ORG_SETTINGS,
        FAILURE_LOADING_REPORT_RECORD_COUNT,
        SUCCESS_CAMPAIGN_CREATED,
        SUCCESS_CAMPAIGN_UPDATED
      ].includes(alertKey)
    ) {
      return null;
    }

    let message = get(copyText, `${alertKey}_message`) || '';

    message =
      (lastModifiedCampaign
        ? message.replace('%name%', lastModifiedCampaign.name)
        : message) +
      ' ' +
      otherError;

    const showCloseButton = ![
      FAILURE_INBOX_NOT_FOUND,
      FAILURE_INBOX_PERMISSION_DENIED,
      FAILURE_INITIALIZING,
      FAILURE_LOADING_BROADCASTS,
      FAILURE_LOADING_CAMPAIGNS
    ].includes(alertKey);

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={message}
        showCloseButton={showCloseButton}
      />
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: get(inbox, 'name', '...') },
    { label: copyText.title, color: palette.gray[100] }
  ];

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={
          actionPanelKey === ACTION_PANEL_RECIPIENTS ? false : <IconTimes />
        }
        isOpen={actionPanelKey.length > 0}
        right
        styles={{
          ...actionPanelStyles,
          ...(actionPanelKey === ACTION_PANEL_RECIPIENTS
            ? { bmMenuWrap: { ...actionPanelStyles.bmMenuWrap, zIndex: '100' } }
            : {})
        }}
        noTransition={actionPanelKey === ACTION_PANEL_RECIPIENTS}
        noOverlay={actionPanelKey === ACTION_PANEL_RECIPIENTS}
        width={
          actionPanelKey === ACTION_PANEL_RECIPIENTS ? 'calc(100% - 50px)' : 500
        }
        onClose={handleCloseActionPanel}
        onStateChange={handleChangeActionPanelState}
        customOnKeyDown={handleActionPanelKeyDown}
      >
        {renderActionPanel()}
      </Menu>
      <Layout height="100vh">
        <Layout.Body flex>
          {renderScreenAlert()}
          <SideNav
            authenticatedUser={authenticatedUser}
            homeBaseURL={homeBaseURL}
            justifyContent="end"
            signOutPath={signOutPath}
          >
            <SideNav.Item
              href={paths.settings}
              label={copyText.settingsLinkLabel}
              onClick={handleClickSideNavItem}
            >
              <IconCog color={palette.gray[40]} size={24} />
            </SideNav.Item>
          </SideNav>
          <Flex
            backgroundColor={theme.color_ebony_clay}
            direction="column"
            height="100vh"
            minWidth={350}
            style={{
              visibility:
                actionPanelKey === ACTION_PANEL_RECIPIENTS
                  ? 'hidden'
                  : 'visible'
            }}
          >
            <Box
              borderBottom={`1px solid ${palette.gray[80]}`}
              padding={theme.space_inset_md}
            >
              <Flex alignItems="center" marginBottom={theme.space_stack_md}>
                <MinimalButton
                  aria-label={copyText.backButtonLabel}
                  iconStart={<IconArrowLeft color={palette.gray[40]} />}
                  onClick={handleClickBackArrow}
                />
                <Box
                  borderRight={`1px solid ${palette.gray[40]}`}
                  height={32}
                  marginLeft={theme.space_inline_sm}
                />
                <IconComments color={palette.white} size={32} />
                <Text
                  color={palette.white}
                  fontSize={theme.h2_fontSize}
                  marginLeft={theme.space_inline_sm}
                >
                  {copyText.title}
                </Text>
              </Flex>
              <SearchInput
                aria-label={copyText.searchInputPlaceholder}
                name="searchTerm"
                placeholder={copyText.searchInputPlaceholder}
                variant="dark"
                onInteraction={handleInteraction}
              />
            </Box>
            <BroadcastList
              broadcasts={broadcasts}
              campaigns={campaigns}
              isLoadingBroadcasts={isInitializing || isLoadingBroadcasts}
              selectedID={selectedBroadcastID || selectedCampaignID}
              onInteraction={handleInteraction}
            />
          </Flex>
          <Layout
            height="100vh"
            width="100%"
            style={{
              visibility:
                actionPanelKey === ACTION_PANEL_RECIPIENTS
                  ? 'hidden'
                  : 'visible'
            }}
          >
            <Layout.Header
              alignItems="center"
              flex
              justifyContent="between"
              minHeight={theme.space_stack_xxl}
              paddingLeft={theme.space_inline_lg}
              paddingRight={theme.space_inline_md}
              paddingVertical={theme.space_stack_sm}
            >
              <Breadcrumb items={breadcrumbItems} />
              <FlexItem>
                {selectedCampaignID ? (
                  <>
                    <Button
                      title={copyText.bulkMessageDetails}
                      aria-label={copyText.scheduleButtonLabel}
                      iconStart={<IconSchedule color={palette.gray[60]} />}
                      marginLeft={theme.space_inline_xs}
                      minimal
                      onClick={handleClickOpenBulkMessageDetails}
                    />
                  </>
                ) : (
                  ''
                )}
                <Button
                  title={copyText.createBulkMessage}
                  aria-label={copyText.createBulkMessage}
                  iconStart={<IconPlus color={palette.green[60]} />}
                  marginLeft={theme.space_inline_xs}
                  minimal
                  onClick={handleClickOpenCreateCampaignForm}
                />
              </FlexItem>
            </Layout.Header>
            <Layout.Body
              backgroundColor={palette.gray[20]}
              flex
              padding={theme.space_inset_lg}
              scrollable
            >
              {broadcast ? (
                <>
                  <Flex direction="column" minWidth={550} width="100%">
                    <BroadcastMessageBubble broadcast={broadcast} />
                  </Flex>
                  <Flex minWidth={350} width={350}>
                    <BroadcastInfoComponent
                      broadcast={broadcast}
                      onInteraction={handleInteraction}
                      isLoadingRecipients={isLoadingRecipients}
                      recipients={contactMappings}
                      report={report}
                    />
                  </Flex>
                </>
              ) : (
                <Flex alignItems="center" justifyContent="center" width="100%">
                  <IconComments color={palette.gray[40]} size={200} />
                </Flex>
              )}
            </Layout.Body>
          </Layout>
        </Layout.Body>
      </Layout>
    </Box>
  );
}

export default BroadcastsScreen;
