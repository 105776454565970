export default {
  bulkMessagesLabel: 'Bulk Messages',
  conversationFilterAriaLabel:
    'Show all conversaitons or unread conversations.',
  conversationFilterLabelAll: 'All',
  conversationFilterLabelUnread: 'Unread',
  createButtonLabel: 'CREATE',
  discardModalTitle: 'Merge Conversations',
  discardModalBody: 'Do you want to merge these conversations? ',
  FAILURE_title: 'Operation Failed.',
  FAILURE_INITIALIZING_message:
    'Sorry, the system failed to initialize this screen. Please reload the current browser window and try again.',
  FAILURE_LOADING_CONTACT_message:
    'Sorry, the system failed to load the selected contact from your CRM. Please reload the current browser window and try again.',
  FAILURE_LOADING_CONTACTS_message:
    'Sorry, the system failed to load contacts from your CRM. Please reload the current browser window and try again.',
  FAILURE_LOADING_MESSAGES_message:
    'Sorry, the system failed to load messages for this conversation. Please reload the current browser window and try again.',
  ERROR_LOADING_ORG_SETTINGS_message:
    'An unexpected error occured while attempting to load settings for your org. Please reload the current browser window and try again.',
  FAILURE_LOADING_MESSAGES_AFTER_SEND_message: `Your last message was sent to your messaging provider, but the system failed to reload the latest messages in this conversation. To be sure you're seeing the latest messages, please reload the browser window.`,
  FAILURE_MERGING_CONTACT_message: `Sorry, the system failed to merge the selected contact.`,
  FAILURE_SENDING_MESSAGE_message: `Sorry, the system failed to send your last message. Before trying again, please confirm the following: (1) the intended recipient's phone number is formatted correctly, (2) your messaging provider's API key is still valid, and (3) you have sufficient credit on your messaging provider's account to send a message.`,
  settingsLinkLabel: 'Navigate To Settings',
  newConversationPreview: 'New Conversation',
  noConversationsMessage: 'No Conversations',
  noInboxesCreatedMessage:
    'You do not have access to any inboxes at this time. Please contact your administrator to request access to the desired inboxes.',
  noInboxesMessage: 'No Inboxes',
  noInboxSelectedMessage: 'Please select an inbox to begin messaging.',
  searchInputPlaceholder: 'Search Conversations',
  selectInboxLabel: 'Select Inbox',
  title: 'SMS Inbox',
  WARNING_LOADING_TEMPLATES_message:
    'Sorry, the system failed to load your message templates. However, you may still send messages without using a template. If you want to use a message template, please reload the current browser window and try again.'
};
