import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextArea from '@targetx/mineral-ui/TextArea';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import Switch from 'react-switch';
import isEmpty from 'validator/lib/isEmpty';
import styleProps from '../styles/props';
import theme from '../theme';
import { UserEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './CreateMessageTemplateForm.copyText';

const MESSAGE_TEMPLATE_CONTENT_MAX_LENGTH = 1000;

export namespace CreateMessageTemplateForm {
  export interface Props {
    isProcessing?: boolean;
    message?: ReactNode;
    user: UserEntity;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  contentInput: { value: string; isValid: boolean };
  isGlobalInput: { value: boolean; isValid: boolean };
  nameInput: { value: string; isValid: boolean };
}

export function CreateMessageTemplateForm({
  isProcessing,
  message,
  user,
  onInteraction = noop
}: CreateMessageTemplateForm.Props): ReactElement {
  const initialState = {
    contentInput: { value: '', isValid: false },
    isGlobalInput: { value: false, isValid: true },
    nameInput: { value: '', isValid: false }
  };

  const [state, setState] = useState<State>(initialState);

  const { contentInput, isGlobalInput, nameInput } = state;

  const canSubmit = Object.values(state).every(
    (input): boolean => input.isValid
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;

    let value: boolean | string = event.target.value;
    let isValid = false;

    switch (name) {
      case 'content':
        isValid =
          !isEmpty(value, { ignore_whitespace: true }) && value.length < 1001;
        break;
      case 'isGlobal':
        isValid = true;
        value = value === 'true';
        break;
      case 'name':
        isValid =
          !isEmpty(value, { ignore_whitespace: true }) && value.length < 101;
        break;
    }

    setState(currentState => ({
      ...currentState,
      [`${name}Input`]: { value, isValid }
    }));
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: CreateMessageTemplateForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      content: contentInput.value.trim(),
      name: nameInput.value.trim(),
      isGlobal: isGlobalInput.value
    });
  }

  function handleReset(): void {
    onInteraction({
      type: CreateMessageTemplateForm.INTERACTION_CANCEL_BUTTON_CLICKED
    });
  }

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleReset}
        />
      </Layout.Header>
      <Layout.Body {...styleProps.ActionPanelLayoutBody}>
        {message}
        <FormField
          name="name"
          input={TextInput}
          label={
            <Text color={isValidInput(nameInput) ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={nameInput.value}
          variant={isValidInput(nameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="content"
          input={TextArea}
          caption={
            <Flex justifyContent="end">
              <Text color={palette.gray[80]} fontSize={theme.fontSize_mouse}>
                {getContentInputCount(contentInput.value.length)}
              </Text>
            </Flex>
          }
          label={
            <Text
              color={isValidInput(contentInput) ? undefined : palette.red[60]}
            >
              {copyText.contentInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginVertical={theme.space_stack_md}
          required
          resizeable={false}
          value={contentInput.value}
          variant={isValidInput(contentInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        {user.role === UserRole.ORG_ADMIN ? (
          <Flex alignItems="center" justifyContent="between">
            <FormField
              labelFor={`${CreateMessageTemplateForm.name}-isGlobal`}
              label={copyText.isGlobalInputLabel}
              caption={copyText.isGlobalInputDescription}
            />
            <Switch
              id={`${CreateMessageTemplateForm.name}-isGlobal`} // TODO: uniquefy DOM identifier
              checked={isGlobalInput.value}
              checkedIcon={false}
              offColor={palette.gray[60]}
              onColor={palette.green[60]}
              uncheckedIcon={false}
              onChange={(checked): void =>
                handleChange({
                  target: { name: 'isGlobal', value: String(checked) }
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </Flex>
        ) : null}
      </Layout.Body>
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button
          width="48%"
          type="reset"
          variant="grayscale"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          width="48%"
          primary
          type="submit"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function getContentInputCount(length: number): string {
  return copyText.contentInputCaption.replace(
    '%count%',
    `${MESSAGE_TEMPLATE_CONTENT_MAX_LENGTH - length}`
  );
}

function isValidInput({
  value,
  isValid
}: {
  value: string;
  isValid: boolean;
}): boolean {
  return isEmpty(value) || isValid;
}

CreateMessageTemplateForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${CreateMessageTemplateForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
CreateMessageTemplateForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${CreateMessageTemplateForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default CreateMessageTemplateForm;
