export default {
  confirmationDialogDangerMessage:
    'You are about to remove **%fullName%** from the Inbox. <br /> This User will no longer be able to send or receive messages from this Inbox.',
  confirmationDialogWarningMessage:
    'You are about to update **%fullName%** bulk send permissions. <br /> Are you sure you want to do this?',
  confirmationDialogDangerTitle: 'DANGER',
  confirmationDialogWarningTitle: 'WARNING',
  FAILURE_GRANTING_USERS_ACCESS_message:
    'An error occured while attempting to grant users access to the inbox. Please try again.',
  FAILURE_INITIALIZING_message:
    'An error occurred while initializing this screen. Please try again.',
  FAILURE_LOADING_INBOX_USERS_message:
    'There was an error loading users for the inbox. Please try again.',
  FAILURE_REVOKING_USER_ACCESS_message:
    'An error occured while attempting to revoke user access. Please try again.',
  FAILURE_UPDATING_USER_PERMISSION_message:
    "An error occured while trying to update the users's permission. Please try again.",
  FAILURE_title: 'Operation Failed.',
  inboxesNavLabel: 'Inboxes',
  searchInputLabel: 'Search Entries',
  searchToggleButtonLabel: 'Toggle Search',
  SUCCESS_GRANTING_USERS_ACCESS_message:
    'Users have been successfully granted access to the inbox!',
  SUCCESS_REVOKING_USER_ACCESS_message:
    'User %fullName% access revoked from the inbox.',
  SUCCESS_UPDATING_USER_PERMISSION_message:
    'User %fullName% bulk send permissions have been updated.',
  SUCCESS_title: 'Operation Successful!',
  updateButtonLabel: 'UPDATE',
  usersLabel: 'Users'
};
