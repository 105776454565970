import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Pagination from '@targetx/mineral-ui/Pagination';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import GrantUserInboxPermissionCommand, {
  GrantUserInboxPermissionCommandAck,
  GrantUserInboxPermissionCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/GrantUserInboxPermissionCommand';
import GrantUserPermissionsToInboxCommand, {
  GrantUserPermissionsToInboxCommandAck,
  GrantUserPermissionsToInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/GrantUserPermissionsToInboxCommand';
import RevokeUserInboxPermissionCommand, {
  RevokeUserInboxPermissionCommandAck,
  RevokeUserInboxPermissionCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/RevokeUserInboxPermissionCommand';
import { UserInboxPermissionType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import {
  InboxQueryByID,
  InboxQueryFailure,
  InboxQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxQuery';
import {
  InboxUsersQueryByInboxID,
  InboxUsersQueryFailure,
  InboxUsersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxUsersQuery';
import {
  UpdateInboxCommand,
  UpdateInboxCommandAck,
  UpdateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateInboxCommand';
import {
  UserPermissionsQueryByInboxID,
  UserPermissionsQueryFailure,
  UserPermissionsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/UserPermissionsQuery';
import { APP_ID_SMS } from '@targetx/tx-usermgmt-api-lib/lib/constants/apps';
import {
  UserAppPermissionType,
  UserStatus
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import {
  UsersQueryByOrgID,
  UsersQueryFailure,
  UsersQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UsersQuery';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import NavigateCommand from '../commands/NavigateCommand';
import AddInboxUsersComponent from '../components/AddInboxUsersComponent';
import InboxUserList from '../components/InboxUserList';
import ScreenLevelAlert from '../components/ScreenLevelAlert';
import paths from '../constants/paths';
import DispatcherContext from '../DispatcherContext';
import SettingsScreenLayout from '../layouts/SettingsScreenLayout';
import { bmStyles, modalStyles } from '../styles';
import theme from '../theme';
import {
  BaseUserEntity,
  InboxEntity,
  UserEntity,
  UserPermissionEntity
} from '../types';
import Interaction from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import { getFullName } from '../utils/UserUtils';
import copyText from './InboxUserManagementScreen.copyText';

const ACTION_PANEL_ADD_USERS_COMPONENT = 'ADD_USERS_COMPONENT';

const MODAL_DELETE_CONFIRMATION = 'DELETE_CONFIRMATION';
const MODAL_UPDATE_CAN_SEND_BROADCAST_CONFIRMATION = 'UPDATE_CONFIRMATION';

const FAILURE_GRANTING_USERS_ACCESS = 'FAILURE_GRANTING_USERS_ACCESS';
const FAILURE_INITIALIZING = 'FAILURE_INITIALIZING';
const FAILURE_LOADING_INBOX_USERS = 'FAILURE_LOADING_INBOX_USERS';
const FAILURE_REVOKING_USER_ACCCESS = 'FAILURE_REVOKING_USER_ACCCESS';
const FAILURE_UPDATING_USER_PERMISSION = 'FAILURE_UPDATING_USER_PERMISSION';

const SUCCESS_GRANTING_USERS_ACCESS = 'SUCCESS_GRANTING_USERS_ACCESS';
const SUCCESS_REVOKING_USER_ACCESS = 'SUCCESS_REVOKING_USER_ACCESS';
const SUCCESS_UPDATING_USER_PERMISSION = 'SUCCESS_UPDATING_USER_PERMISSION';

const pageSize = 15;

export namespace InboxUserManagementScreen {
  export interface Props {
    authenticatedUser: UserEntity;
    homeBaseURL: string;
    inboxID?: string;
    path?: string;
    signOutPath: string;
  }
}

interface UserPermissionStructure {
  userID: string;
  type: UserInboxPermissionType;
}

interface State {
  actionPanelKey: string;
  alertKey: string;
  editingUserID: string;
  inbox: InboxEntity | null;
  isGrantingUserPermissions: boolean;
  isInitializing: boolean;
  isLoadingInboxUsers: boolean;
  isLoadingSearchResultUsers: boolean;
  isRevokingUserInboxAccess: boolean;
  isUpdatingUserPermission: boolean;
  lastModifiedUser: BaseUserEntity | null;
  modalComponentKey: string;
  pageNumber: number;
  permissions: UserPermissionEntity[];
  searchResultUsers: BaseUserEntity[];
  searchTerm: string;
  showSearchInput: boolean;
  users: BaseUserEntity[];
}

const initialState: State = {
  actionPanelKey: '',
  alertKey: '',
  editingUserID: '',
  inbox: null,
  isGrantingUserPermissions: false,
  isInitializing: false,
  isLoadingInboxUsers: false,
  isLoadingSearchResultUsers: false,
  isRevokingUserInboxAccess: false,
  isUpdatingUserPermission: false,
  lastModifiedUser: null,
  modalComponentKey: '',
  pageNumber: 1,
  permissions: [],
  searchResultUsers: [],
  searchTerm: '',
  showSearchInput: false,
  users: []
};

export function InboxUserManagementScreen({
  authenticatedUser,
  homeBaseURL,
  inboxID = '',
  signOutPath
}: InboxUserManagementScreen.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    actionPanelKey,
    alertKey,
    editingUserID,
    inbox,
    isGrantingUserPermissions,
    isInitializing,
    isLoadingInboxUsers,
    isLoadingSearchResultUsers,
    lastModifiedUser,
    modalComponentKey,
    pageNumber,
    permissions,
    searchResultUsers,
    searchTerm,
    showSearchInput
  } = state;
  let { users } = state;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function grantUserPermissionsToInbox(
    inboxID: string,
    permissions: UserPermissionStructure[]
  ): Promise<void> {
    changeState({ isGrantingUserPermissions: true });

    const ack = await dispatcher.dispatch<
      | GrantUserPermissionsToInboxCommandAck
      | GrantUserPermissionsToInboxCommandFailure
    >(new GrantUserPermissionsToInboxCommand({ inboxID, permissions }));

    if (ack instanceof GrantUserPermissionsToInboxCommandFailure) {
      changeState({
        alertKey: FAILURE_GRANTING_USERS_ACCESS,
        isGrantingUserPermissions: false
      });
      return;
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_GRANTING_USERS_ACCESS,
      isGrantingUserPermissions: false,
      searchResultUsers: []
    });

    loadInboxUsers(inboxID);
  }

  async function initialize(inboxID: string): Promise<void> {
    changeState({ isInitializing: true });

    let query;

    try {
      query = new InboxQueryByID({ inboxID });
    } catch {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const inboxQueryResult = await dispatcher.dispatch<
      InboxQueryResult | InboxQueryFailure
    >(query);

    if (inboxQueryResult instanceof InboxQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const usersQueryResult = await dispatcher.dispatch<
      InboxUsersQueryResult | InboxUsersQueryFailure
    >(new InboxUsersQueryByInboxID({ inboxID }));

    if (usersQueryResult instanceof InboxUsersQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const userPermissionsQueryResult = await dispatcher.dispatch<
      UserPermissionsQueryResult | UserPermissionsQueryFailure
    >(new UserPermissionsQueryByInboxID({ inboxID }));

    if (userPermissionsQueryResult instanceof UserPermissionsQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    changeState({
      inbox: inboxQueryResult.inbox,
      isInitializing: false,
      permissions: userPermissionsQueryResult.permissions,
      users: usersQueryResult.users
    });
  }

  async function loadInboxUsers(inboxID: string): Promise<void> {
    changeState({ isLoadingInboxUsers: true });

    const usersQueryResult = await dispatcher.dispatch<
      InboxUsersQueryResult | InboxUsersQueryFailure
    >(new InboxUsersQueryByInboxID({ inboxID }));

    if (usersQueryResult instanceof InboxUsersQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_INBOX_USERS,
        isLoadingInboxUsers: false
      });
      return;
    }

    const userPermissionsQueryResult = await dispatcher.dispatch<
      UserPermissionsQueryResult | UserPermissionsQueryFailure
    >(new UserPermissionsQueryByInboxID({ inboxID }));

    if (userPermissionsQueryResult instanceof UserPermissionsQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_INBOX_USERS,
        isLoadingInboxUsers: false
      });
      return;
    }

    changeState({
      isLoadingInboxUsers: false,
      permissions: userPermissionsQueryResult.permissions,
      users: usersQueryResult.users
    });
  }

  async function loadSearchResultUsers(): Promise<void> {
    changeState({ isLoadingSearchResultUsers: true });

    // TODO: Change this to UsersQueryByOrgIDAndName
    const result = await dispatcher.dispatch<
      UsersQueryResult | UsersQueryFailure
    >(
      new UsersQueryByOrgID({
        orgID: authenticatedUser.orgID,
        status: UserStatus.ACTIVE
      })
    );

    if (result instanceof UsersQueryFailure) {
      changeState({ isLoadingSearchResultUsers: false });
      return;
    }

    setState(currentState => {
      const userIDs = currentState.users.map(({ id }) => id);

      const searchResultUsers = result.users.filter(
        user =>
          !userIDs.includes(user.id) &&
          user.permissions?.some(
            p =>
              p.targetID === APP_ID_SMS &&
              p.type === UserAppPermissionType.CAN_ACCESS
          )
      );

      return {
        ...currentState,
        isLoadingSearchResultUsers: false,
        searchResultUsers
      };
    });
  }

  function navigate(path: string): void {
    dispatcher.dispatch(new NavigateCommand({ path }));
  }

  async function revokeUserInboxAccess(userID: string): Promise<void> {
    changeState({ isRevokingUserInboxAccess: true });

    const permission = permissions.find(
      permission =>
        permission.userID === userID &&
        permission.type === UserInboxPermissionType.CAN_ACCESS
    );

    if (!permission) {
      changeState({
        alertKey: FAILURE_REVOKING_USER_ACCCESS,
        editingUserID: '',
        isRevokingUserInboxAccess: false,
        modalComponentKey: ''
      });
      return;
    }

    const ack = await dispatcher.dispatch<
      | RevokeUserInboxPermissionCommandAck
      | RevokeUserInboxPermissionCommandFailure
    >(new RevokeUserInboxPermissionCommand({ permissionID: permission.id }));

    if (ack instanceof RevokeUserInboxPermissionCommandFailure) {
      changeState({
        alertKey: FAILURE_REVOKING_USER_ACCCESS,
        editingUserID: '',
        isRevokingUserInboxAccess: false,
        modalComponentKey: ''
      });
      return;
    }

    if (inbox?.attributes?.inboxRecipients?.includes(userID)) {
      inbox.attributes.inboxRecipients =
        inbox.attributes.inboxRecipients.filter((i: string) => i !== userID);

      if (
        inbox.attributes.inboxRecipients.length === 0 &&
        !inbox.attributes.additionalRecipients
      ) {
        inbox.attributes.emailNotifications = false;
      }

      await dispatcher.dispatch<
        UpdateInboxCommandAck | UpdateInboxCommandFailure
      >(
        new UpdateInboxCommand({
          inboxID: inbox?.id,
          attributes: inbox?.attributes
        })
      );
    }

    changeState({
      alertKey: SUCCESS_REVOKING_USER_ACCESS,
      editingUserID: '',
      isRevokingUserInboxAccess: false,
      lastModifiedUser: usersKeyedByID[editingUserID],
      modalComponentKey: '',
      searchResultUsers: []
    });

    loadInboxUsers(inboxID);
  }

  async function updateUserPermission(
    userID: string,
    type: UserInboxPermissionType,
    permissionID?: string
  ): Promise<void> {
    changeState({ isUpdatingUserPermission: true });

    if (!permissionID) {
      const ack = await dispatcher.dispatch<
        | GrantUserInboxPermissionCommandAck
        | GrantUserInboxPermissionCommandFailure
      >(new GrantUserInboxPermissionCommand({ userID, inboxID, type }));

      if (ack instanceof GrantUserInboxPermissionCommandFailure) {
        changeState({
          alertKey: FAILURE_UPDATING_USER_PERMISSION,
          editingUserID: '',
          isUpdatingUserPermission: false,
          modalComponentKey: ''
        });
        return;
      }
    } else {
      const ack = await dispatcher.dispatch<
        | RevokeUserInboxPermissionCommandAck
        | RevokeUserInboxPermissionCommandFailure
      >(new RevokeUserInboxPermissionCommand({ userID, permissionID }));

      if (ack instanceof RevokeUserInboxPermissionCommandFailure) {
        changeState({
          alertKey: FAILURE_UPDATING_USER_PERMISSION,
          editingUserID: '',
          isUpdatingUserPermission: false,
          modalComponentKey: ''
        });
        return;
      }
    }

    changeState({
      alertKey: SUCCESS_UPDATING_USER_PERMISSION,
      editingUserID: '',
      isUpdatingUserPermission: false,
      lastModifiedUser: usersKeyedByID[editingUserID],
      modalComponentKey: ''
    });

    loadInboxUsers(inboxID);
  }

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;
    changeState({ actionPanelKey: '' });
  }

  function handleChangePage(pageNumber: number): void {
    changeState({ pageNumber });
  }

  function handleClickBreadcrumb(path: string): void {
    navigate(path);
  }

  function handleClickOpenAddInboxUsersComponent(): void {
    changeState({ actionPanelKey: ACTION_PANEL_ADD_USERS_COMPONENT });
  }

  function handleClickRootContainer(): void {
    if (
      ['', FAILURE_INITIALIZING, FAILURE_LOADING_INBOX_USERS].includes(alertKey)
    ) {
      return;
    }

    changeState({ alertKey: '' });
  }

  function handleClickToggleSearch(): void {
    changeState({ alertKey: '', searchTerm: '', showSearchInput: true });
  }

  function handleCloseModal(): void {
    changeState({ editingUserID: '', modalComponentKey: '' });
  }

  function handleConfirmDeleteModal(userID: string): void {
    revokeUserInboxAccess(userID);
  }

  function handleConfirmUpdateModal(
    userID: string,
    type: UserInboxPermissionType,
    permissionID?: string
  ): void {
    updateUserPermission(userID, type, permissionID);
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case AddInboxUsersComponent.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ actionPanelKey: '' });
        return;
      }
      case AddInboxUsersComponent.INTERACTION_USERS_FILTER_CHANGED: {
        loadSearchResultUsers();
        return;
      }
      case AddInboxUsersComponent.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        grantUserPermissionsToInbox(inboxID, data.permissions);
        return;
      }
      case InboxUserList.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED: {
        changeState({
          editingUserID: data.userID,
          modalComponentKey: MODAL_UPDATE_CAN_SEND_BROADCAST_CONFIRMATION
        });
        return;
      }
      case InboxUserList.INTERACTION_REMOVE_BUTTON_CLICKED: {
        changeState({
          editingUserID: data.userID,
          modalComponentKey: MODAL_DELETE_CONFIRMATION
        });
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        changeState({ alertKey: '', searchTerm: '', showSearchInput: false });
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        changeState({ pageNumber: 1, searchTerm: data.searchTerm });
        return;
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    initialize(inboxID);
  }, [inboxID]);

  //
  // Render
  //

  users = useMemo(() => orderBy(users, getFullName), [users]);

  const usersKeyedByID = useMemo(() => keyBy(users, 'id'), [users]);

  if (searchTerm.length > 0) {
    users = users.filter((user): boolean =>
      [user.firstName, user.lastName, user.username]
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }

  const totalUsers = users.length;

  users = useMemo(
    () => users.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize),
    [users, pageNumber]
  );

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_ADD_USERS_COMPONENT:
        return (
          <AddInboxUsersComponent
            isLoadingUsers={isLoadingSearchResultUsers}
            isProcessing={isGrantingUserPermissions}
            message={renderAlert()}
            searchResultUsers={searchResultUsers}
            onInteraction={handleInteraction}
          />
        );
    }
  }

  function renderAlert(): ReactNode {
    if (alertKey !== FAILURE_GRANTING_USERS_ACCESS) return null;

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton
        title={copyText.FAILURE_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderModalComponent(): ReactNode {
    const user = usersKeyedByID[editingUserID];

    switch (modalComponentKey) {
      case MODAL_DELETE_CONFIRMATION: {
        const message = copyText.confirmationDialogDangerMessage.replace(
          '%fullName%',
          getFullName(user)
        );

        return (
          <ConfirmationDialog
            message={message}
            title={copyText.confirmationDialogDangerTitle}
            variant="danger"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmDeleteModal.bind({}, user.id)}
          />
        );
      }
      case MODAL_UPDATE_CAN_SEND_BROADCAST_CONFIRMATION: {
        const allowBroadcastPermission = permissions.find(
          ({ type, userID }) =>
            userID === editingUserID &&
            type === UserInboxPermissionType.CAN_SEND_BROADCAST
        );

        const message = copyText.confirmationDialogWarningMessage.replace(
          '%fullName%',
          getFullName(user)
        );

        return (
          <ConfirmationDialog
            message={message}
            title={copyText.confirmationDialogWarningTitle}
            variant="warning"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmUpdateModal.bind(
              {},
              user.id,
              UserInboxPermissionType.CAN_SEND_BROADCAST,
              allowBroadcastPermission?.id
            )}
          />
        );
      }
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        FAILURE_INITIALIZING,
        FAILURE_LOADING_INBOX_USERS,
        FAILURE_REVOKING_USER_ACCCESS,
        FAILURE_UPDATING_USER_PERMISSION,
        SUCCESS_GRANTING_USERS_ACCESS,
        SUCCESS_REVOKING_USER_ACCESS,
        SUCCESS_UPDATING_USER_PERMISSION
      ].includes(alertKey)
    ) {
      return null;
    }

    let message = get(copyText, `${alertKey}_message`) || '';

    message = lastModifiedUser
      ? message.replace('%fullName%', getFullName(lastModifiedUser))
      : message;

    const showCloseButton = ![
      FAILURE_INITIALIZING,
      FAILURE_LOADING_INBOX_USERS
    ].includes(alertKey);

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={message}
        showCloseButton={showCloseButton}
      />
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { href: paths.settingsInboxes, label: copyText.inboxesNavLabel },
    { label: get(inbox, 'name', '...') },
    { label: copyText.usersLabel, color: palette.gray[100] }
  ];

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={actionPanelKey.length > 0}
        right
        styles={bmStyles}
        width={500}
        onStateChange={handleChangeActionPanelState}
      >
        {renderActionPanel()}
      </Menu>
      <SettingsScreenLayout
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        signOutPath={signOutPath}
      >
        <SettingsScreenLayout.Header>
          <Breadcrumb
            items={breadcrumbItems}
            onNavigate={handleClickBreadcrumb}
          />
          <FlexItem flex>
            {showSearchInput ? (
              <SearchInput
                aria-label={copyText.searchInputLabel}
                name="searchTerm"
                clearable
                onInteraction={handleInteraction}
              />
            ) : (
              <Button
                aria-label={copyText.searchToggleButtonLabel}
                iconStart={<IconSearch color={palette.gray[60]} />}
                minimal
                onClick={handleClickToggleSearch}
              />
            )}
            <Button
              aria-label={copyText.updateButtonLabel}
              iconStart={<IconPlus color={palette.green[60]} />}
              marginLeft={theme.space_inline_xs}
              minimal
              onClick={handleClickOpenAddInboxUsersComponent}
            />
          </FlexItem>
        </SettingsScreenLayout.Header>
        <SettingsScreenLayout.Body>
          {renderScreenAlert()}
          <Flex width="100%">
            <InboxUserList
              isLoading={isInitializing || isLoadingInboxUsers}
              permissions={permissions}
              users={users}
              onInteraction={handleInteraction}
            />
            {totalUsers > pageSize ? (
              <Box
                alignSelf="end"
                marginVertical={theme.space_stack_md}
                width="100%"
              >
                <Pagination
                  currentPage={pageNumber}
                  onPageChange={handleChangePage}
                  pageSize={pageSize}
                  totalCount={totalUsers}
                />
              </Box>
            ) : null}
          </Flex>
        </SettingsScreenLayout.Body>
      </SettingsScreenLayout>
    </Box>
  );
}

export default InboxUserManagementScreen;
