import Box from '@targetx/mineral-ui/Box';
import Flex from '@targetx/mineral-ui/Flex';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import EditCountryCodeForm from '../../components/EditCountryCodeForm';
import ScreenLevelAlert from '../../components/ScreenLevelAlert';
import paths from '../../constants/paths';
import useDataAPIGetOrgSettingsByOrgID from '../../data-api/useDataAPIGetOrgSettingsByOrgID';
import useDataAPIUpdateOrgSetting from '../../data-api/useDataAPIUpdateOrgSetting';
import SettingsScreenLayout from '../../layouts/SettingsScreenLayout';
import { UserEntity } from '../../types';
import Interaction from '../../types/Interaction';
import copyText from './copyText';

const ERROR_LOADING_ORG_SETTINGS = 'ERROR_LOADING_ORG_SETTINGS';
const ERROR_UPDATING_ORG_SETTING = 'ERROR_UPDATING_ORG_SETTING';

const SUCCESS_UPDATING_ORG_SETTING = 'SUCCESS_UPDATING_ORG_SETTING';

interface Props {
  authenticatedUser: UserEntity;
  homeBaseURL: string;
  path?: string;
  signOutPath: string;
}

export default function OtherSettingsScreen({
  authenticatedUser,
  homeBaseURL,
  signOutPath
}: Props): ReactElement {
  const [alertKey, setAlertKey] = useState('');

  //
  // Hooks
  //

  const {
    data: settings,
    error: errorLoadingOrgSettings,
    isLoading: isLoadingOrgSettings,
    refetch: refetchSettings
  } = useDataAPIGetOrgSettingsByOrgID(authenticatedUser.orgID);

  const {
    data: lastUpdatedSettingID,
    error: errorUpdatingOrgSetting,
    isProcessing: isUpdatingOrgSetting,
    invoke: updateOrgSetting
  } = useDataAPIUpdateOrgSetting();

  //
  // Side Effects
  //

  useEffect(() => {
    if (!alertKey.startsWith('SUCCESS_')) return;

    refetchSettings();
  }, [alertKey]);

  useEffect(() => {
    if (!lastUpdatedSettingID) return;

    setAlertKey(SUCCESS_UPDATING_ORG_SETTING);
  }, [lastUpdatedSettingID]);

  useEffect(() => {
    if (!errorLoadingOrgSettings) return;

    setAlertKey(ERROR_LOADING_ORG_SETTINGS);
  }, [errorLoadingOrgSettings]);

  useEffect(() => {
    if (!errorUpdatingOrgSetting) return;

    setAlertKey(ERROR_UPDATING_ORG_SETTING);
  }, [errorUpdatingOrgSetting]);

  //
  // Interaction Handlers
  //

  function handleClickRootContainer(): void {
    if (['', ERROR_LOADING_ORG_SETTINGS].includes(alertKey)) return;

    setAlertKey('');
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case EditCountryCodeForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateOrgSetting(
          authenticatedUser.orgID,
          'defaultCountryCode',
          data.inputs.countryCode
        );
        return;
      }
    }
  }

  //
  // Render
  //

  const settingsKeyedByName = useMemo(
    () => keyBy(settings, 'name'),
    [settings]
  );

  const countryCode = get(settingsKeyedByName['defaultCountryCode'], 'value');

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: copyText.title, color: palette.gray[100] }
  ];

  function renderScreenAlert(): ReactNode {
    if (
      ![
        ERROR_LOADING_ORG_SETTINGS,
        ERROR_UPDATING_ORG_SETTING,
        SUCCESS_UPDATING_ORG_SETTING
      ].includes(alertKey)
    ) {
      return null;
    }

    const message = get(copyText, `${alertKey}_message`) || '';

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={message}
        showCloseButton={alertKey !== ERROR_LOADING_ORG_SETTINGS}
      />
    );
  }

  return (
    <SettingsScreenLayout
      authenticatedUser={authenticatedUser}
      currentPath={paths.settingsOther}
      homeBaseURL={homeBaseURL}
      signOutPath={signOutPath}
      onClick={handleClickRootContainer}
    >
      <SettingsScreenLayout.Header>
        <Breadcrumb items={breadcrumbItems} />
      </SettingsScreenLayout.Header>
      <SettingsScreenLayout.Body>
        {renderScreenAlert()}
        <Flex alignItems="start" width="100%">
          <Box width={450}>
            <EditCountryCodeForm
              defaults={{ countryCode }}
              isLoadingDefaults={isLoadingOrgSettings}
              isProcessing={isUpdatingOrgSetting}
              onInteraction={handleInteraction}
            />
          </Box>
        </Flex>
      </SettingsScreenLayout.Body>
    </SettingsScreenLayout>
  );
}
