/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';
import { MessagingServiceEntity } from './types';

interface State {
  readonly data?: MessagingServiceEntity[];
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (
    orgID: string,
    options?: { isConnectedToInbox?: boolean }
  ) => Promise<void>;
}

export default function useDataAPIGetMessagingServicesByOrgID(
  orgID?: string,
  options?: { isConnectedToInbox?: boolean }
): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(
    orgID: string,
    options?: { isConnectedToInbox?: boolean }
  ): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getMessagingServicesByOrgID(orgID, options);

      setState({ isLoading: false, data });
    } catch (error: any) {
      setState({ isLoading: false, error });
    }
  }

  useEffect(() => {
    if (!orgID) return;

    fetch(orgID, options);
  }, [orgID]);

  return { ...state, refetch: fetch };
}
