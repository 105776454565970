import Avatar from '@targetx/mineral-ui/Avatar';
import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import noop from 'lodash.noop';
import { formatLocal } from 'phoneformat.js';
import React, { ReactElement, ReactNode, useState } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import styleProps from '../styles/props';
import theme from '../theme';
import { ContactRecord } from '../types';
import Interaction, { InteractionDelegate } from '../types/Interaction';
import { getFullName, getInitials } from '../utils/ContactRecordUtils';
import copyText from './SearchContactsForm.copyText';

const KEY_NAME_ENTER = 'Enter';
const MAX_SEARCH_TERM_LENGTH = 500;

export namespace SearchContactsForm {
  export interface Props {
    contacts: ContactRecord[];
    countryCode: string;
    isSearching?: boolean;
    message?: ReactNode;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  searchTermInput: { value: string; isValid: boolean };
  selectedContactID: string;
}

const initialState = {
  searchTermInput: { value: '', isValid: false },
  selectedContactID: ''
};

export function SearchContactsForm({
  contacts,
  countryCode,
  isSearching,
  message,
  onInteraction = noop
}: SearchContactsForm.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  const { searchTermInput, selectedContactID } = state;

  function handleClickCancelButton(): void {
    onInteraction({
      type: SearchContactsForm.INTERACTION_CANCEL_BUTTON_CLICKED
    });
  }

  function handleClickContactItem(contactID: string): void {
    setState(currentState => ({
      ...currentState,
      selectedContactID: contactID
    }));
  }

  function handleClickContinueButton(): void {
    onInteraction({
      type: SearchContactsForm.INTERACTION_CONTINUE_BUTTON_CLICKED,
      contactID: selectedContactID
    });
  }

  function handleClickSearchButton(): void {
    if (searchTermInput.isValid) {
      onInteraction({
        type: SearchContactsForm.INTERACTION_SEARCH_BUTTON_CLICKED,
        searchTerm: searchTermInput.value
      });
    }
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        const { searchTerm } = data;

        const isValid =
          searchTerm.length > 2 && searchTerm.length < MAX_SEARCH_TERM_LENGTH;

        setState(currentState => ({
          ...currentState,
          searchTermInput: { value: searchTerm, isValid }
        }));
        return;
      }
      case SearchInput.INTERACTION_INPUT_KEY_PRESSED: {
        const { key } = data;

        if (key !== KEY_NAME_ENTER) return;

        handleClickSearchButton();
        return;
      }
    }
  }

  function renderItem({ index, style }: ListChildComponentProps): ReactElement {
    const contact = contacts[index];

    const { rid } = contact;

    const fullName = getFullName(contact);

    return (
      <Flex
        alignItems="center"
        backgroundColor={rid === selectedContactID ? palette.gray[40] : null}
        backgroundColorOnHover={palette.gray[40]}
        cursor="pointer"
        padding={theme.space_inset_sm}
        onClick={handleClickContactItem.bind({}, rid)}
        {...style}
        aria-label={fullName}
      >
        <Avatar
          abbr={getInitials(contact)}
          background={palette.gray[80]}
          size="medium"
        >
          {fullName}
        </Avatar>
        <Text
          color={palette.gray[90]}
          fontWeight={theme.fontWeight_semiBold}
          marginLeft={theme.space_inline_md}
        >
          {fullName}
        </Text>
        <Text color={palette.gray[90]} marginLeft={theme.space_inline_sm}>
          {formatLocal(countryCode, contact.mobilePhone)}
        </Text>
      </Flex>
    );
  }

  return (
    <Layout backgroundColor={palette.white} height="100%">
      <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleClickCancelButton}
        />
      </Layout.Header>
      <Layout.Body
        direction="column"
        flex
        {...styleProps.ActionPanelLayoutBody}
      >
        {message}
        <Flex direction="column" overflow="hidden" padding={2}>
          <Flex marginRight={0}>
            <SearchInput
              name="searchTerm"
              placeholder={copyText.searchInputPlaceholder}
              onInteraction={handleInteraction}
            />
            <Button
              disabled={!searchTermInput.isValid}
              marginLeft={theme.space_inline_md}
              primary
              size="medium"
              width="25%"
              onClick={handleClickSearchButton}
            >
              {copyText.searchButtonLabel}
            </Button>
          </Flex>
          <Text
            color={palette.gray[90]}
            fontWeight={theme.fontWeight_semiBold}
            marginTop={theme.space_stack_md}
          >
            {contacts.length === 1
              ? copyText.searchResultLabel
              : copyText.searchResultsLabel.replace(
                  '%count%',
                  contacts.length.toString()
                )}
          </Text>
          <Box borderTop={`1px solid ${palette.gray[40]}`} scrollable>
            {isSearching ? (
              <Flex justifyContent="center" paddingTop={theme.space_stack_lg}>
                <SVGSpinner size="3em" />
              </Flex>
            ) : (
              <FixedSizeList
                height={1500}
                itemCount={contacts.length}
                itemSize={45}
                width="100%"
              >
                {renderItem}
              </FixedSizeList>
            )}
          </Box>
        </Flex>
      </Layout.Body>
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button
          width="48%"
          variant="grayscale"
          onClick={handleClickCancelButton}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!selectedContactID || isSearching}
          width="48%"
          primary
          onClick={handleClickContinueButton}
        >
          {copyText.continueButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

SearchContactsForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${SearchContactsForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
SearchContactsForm.INTERACTION_CONTINUE_BUTTON_CLICKED = `${SearchContactsForm.name}.INTERACTION_CONTINUE_BUTTON_CLICKED`;
SearchContactsForm.INTERACTION_SEARCH_BUTTON_CLICKED = `${SearchContactsForm.name}.INTERACTION_SEARCH_BUTTON_CLICKED`;

export default SearchContactsForm;
