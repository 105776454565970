import Button from '@targetx/mineral-ui/Button';
import { FlexItem } from '@targetx/mineral-ui/Flex';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import Pagination from '@targetx/tx-web-ui-lib/lib/components/ThemedPagination';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import theme from '../theme';
import React, { ReactElement, useMemo, useState } from 'react';
import { CampaignSchedule, ContactMappingEntity, RowData } from '../types';
import copyText from './BroadcastRecipients.copyText';
import { PartialState } from '../types/PartialState';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import Interaction from '../types/Interaction';
import IconArrowLeft from '@targetx/tx-web-ui-lib/lib/icons/IconArrowLeft';
import orderBy from 'lodash.orderby';
import noop from 'lodash.noop';

export namespace BroadcastRecipients {
  export interface BroadcastEntity {
    id: string;
    name: string;
    content: string;
    timeCreated: string;
    schedule?: CampaignSchedule;
  }

  export interface Props {
    handleClose: () => void;
    recipients: ContactMappingEntity[];
  }
}

interface State {
  pageNumber: number;
  pagedRecipients: ContactMappingEntity[];
  searchFilter: string;
  showSearchInput: boolean;
  sortDirection: boolean | 'asc' | 'desc';
  sortKey: string;
}

export function BroadcastRecipients({
  handleClose = noop,
  recipients
}: BroadcastRecipients.Props): ReactElement {
  const [state, setState] = useState<State>({
    pageNumber: 1,
    pagedRecipients: recipients,
    searchFilter: '',
    showSearchInput: false,
    sortDirection: 'asc',
    sortKey: 'lastName'
  });

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const { pageNumber, searchFilter, showSearchInput, sortDirection, sortKey } =
    state;
  let { pagedRecipients } = state;

  const PAGE_SIZE = 15;

  const columns = [
    {
      borderless: true,
      content: copyText.headerFirstName,
      key: copyText.headerFirstName,
      width: '25%'
    },
    {
      borderless: true,
      content: copyText.headerLastName,
      key: copyText.headerLastName,
      width: '45%'
    },
    {
      borderless: true,
      content: copyText.headerMobilePhone,
      key: copyText.headerMobilePhone,
      width: '15%'
    },
    {
      borderless: true,
      content: copyText.headerMessageStatus,
      key: copyText.headerMessageStatus,
      width: '15%'
    }
  ];

  const handleChangePage = (pageNumber: number): void => {
    changeState({ pageNumber });
  };

  const handleSearchInputToggle = (): void => {
    changeState({ searchFilter: '', showSearchInput: true });
  };

  const handleSort = ({
    key = '',
    descending
  }: {
    key: string;
    descending: boolean;
  }): void => {
    switch (key) {
      case copyText.headerFirstName:
        key = 'firstName';
        break;
      case copyText.headerLastName:
        key = 'lastName';
        break;
      case copyText.headerMobilePhone:
        key = 'canonicalPhoneNumber';
        break;
      case copyText.headerMessageStatus:
        key = 'status';
        break;
      default:
        break;
    }

    changeState({
      sortDirection: descending ? 'desc' : 'asc',
      sortKey: key
    });
    return;
  };

  const getOrderByValue = (
    recipient: ContactMappingEntity,
    key: string
  ): string => {
    switch (key) {
      case 'firstName':
        return (recipient.attributes?.firstName || '').toLowerCase();
      case 'lastName':
        return (recipient.attributes?.lastName || '').toLowerCase();
      case 'canonicalPhoneNumber':
        return (recipient.canonicalPhoneNumber || '').toLowerCase();
      case 'status':
        return getMessageStatus(
          recipient.attributes?.messageStatus || ''
        ).toLowerCase();
      default:
        return (recipient.attributes?.lastName || '').toLowerCase();
    }
  };

  const getMessageStatus = (status: string) => {
    return status.includes('FAILED') ? 'Failed' : 'Received';
  };

  async function handleInteraction(interaction: Interaction): Promise<void> {
    switch (interaction.type) {
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        changeState({ searchFilter: '', showSearchInput: false });
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        const { searchTerm } = interaction;
        changeState({ pageNumber: 1, searchFilter: searchTerm });
        return;
      }
      default:
        return;
    }
  }

  recipients = useMemo(
    (): ContactMappingEntity[] =>
      orderBy(
        recipients,
        (recipient): string => getOrderByValue(recipient, sortKey),
        sortDirection
      ),
    [recipients, sortDirection, sortKey]
  );

  if (searchFilter) {
    recipients = recipients.filter(recipient =>
      [
        recipient.attributes?.firstName,
        recipient.attributes?.lastName,
        recipient.canonicalPhoneNumber
      ]
        .join(' ')
        .toLowerCase()
        .includes(searchFilter.toLowerCase())
    );
  }

  pagedRecipients = useMemo(
    (): ContactMappingEntity[] =>
      recipients.slice(
        pageNumber * PAGE_SIZE - PAGE_SIZE,
        pageNumber * PAGE_SIZE
      ),
    [recipients, pageNumber]
  );

  let data: RowData[];

  if (recipients.length === 0) {
    const render = (): ReactElement => (
      <TableRow>
        <TableCell colSpan={columns.length}>{copyText.noRecipients}</TableCell>
      </TableRow>
    );
    data = [{ id: '_', row: render }];
  } else {
    data = pagedRecipients.map(recipient => {
      const render = (): ReactElement => (
        <TableRow>
          <TableCell verticalAlign="middle">
            <Text>{recipient.attributes?.firstName}</Text>
          </TableCell>
          <TableCell verticalAlign="middle">
            <Text>{recipient.attributes?.lastName}</Text>
          </TableCell>

          <TableCell verticalAlign="middle">
            <Text>{recipient.canonicalPhoneNumber}</Text>
          </TableCell>
          <TableCell verticalAlign="middle">
            <Text>{getMessageStatus(recipient.attributes?.messageStatus)}</Text>
          </TableCell>
        </TableRow>
      );
      return { id: recipient.id, row: render };
    });
  }

  return (
    <Layout backgroundColor={palette.white} height="100%">
      <Layout.Header
        flex
        alignItems="center"
        justifyContent="between"
        minHeight={theme.space_stack_xxl}
        paddingHorizontal={theme.space_stack_md}
      >
        <FlexItem flex alignItems="center">
          <Button
            className="bm-item"
            iconStart={<IconArrowLeft color={palette.gray[60]} />}
            marginHorizontal={theme.space_inline_xs}
            minimal
            size="small"
            title="Close"
            onClick={handleClose}
          />
          <Text appearance="h3" as="h1" bold>
            {copyText.bulkMessagesTitle} / {copyText.recipientsTitle} (
            {recipients.length})
          </Text>
        </FlexItem>
        <FlexItem flex alignItems="center">
          {showSearchInput ? (
            <SearchInput
              aria-label={copyText.searchInputLabel}
              name="searchTerm"
              clearable
              onInteraction={handleInteraction}
            />
          ) : (
            <Button
              title={copyText.searchInputLabel}
              iconStart={<IconSearch color={palette.gray[60]} />}
              minimal
              onClick={handleSearchInputToggle}
            />
          )}
        </FlexItem>
      </Layout.Header>
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        <Table
          rowKey="id"
          border={`1px solid ${palette.gray[50]}`}
          borderRadius={theme.borderRadius_2}
          columns={columns}
          data={data}
          sortable
          striped
          title={copyText.recipientsTitle}
          onSort={handleSort}
        />
        {recipients.length > PAGE_SIZE ? (
          <FlexItem alignSelf="end" marginVertical={theme.space_stack_md}>
            <Pagination
              color={palette.blue[70]}
              currentPage={pageNumber}
              pageSize={PAGE_SIZE}
              totalCount={recipients.length}
              onPageChange={handleChangePage}
            />
          </FlexItem>
        ) : null}
      </Layout.Body>
    </Layout>
  );
}

export default BroadcastRecipients;
