/* eslint-disable @typescript-eslint/no-explicit-any */

import Avatar from '@targetx/mineral-ui/Avatar';
import Flex from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import IconQuestionCircle from '@targetx/tx-web-ui-lib/lib/icons/IconQuestionCircle';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import noop from 'lodash.noop';
import { formatLocal } from 'phoneformat.js';
import React, { ReactElement } from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ContactMappingEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import {
  getContactFullName,
  getContactInitials
} from '../utils/getConversationContact';
import { getFullName, getInitials } from '../utils/ContactMappingUtils';
import copyText from './ConversationList.copyText';

export namespace ConversationList {
  export interface ConversationEntity {
    id: string;
    canonicalPhoneNumber: string;
    contactMapping?: ContactMappingEntity;
    timeLastMarkedAsRead?: string;
    lastMessage: {
      timeCreated: string;
      content: string;
    };
    lastIncomingMessage?: {
      timeCreated: string;
    };
    contact?: {
      firstName: string;
      lastName: string;
    };
  }

  export interface Props {
    conversations: ConversationEntity[];
    countryCode: string;
    isLoadingConversations?: boolean;
    selectedConversationID: string;
    onInteraction?: InteractionDelegate;
    isNextPageLoading: boolean;
    hasNextPage: boolean;
    showUnreadConversationsOnly: boolean;

    loadNextPage(): Promise<any>;
  }
}

export function ConversationList({
  conversations,
  countryCode,
  isLoadingConversations,
  selectedConversationID,
  onInteraction = noop,
  isNextPageLoading,
  loadNextPage,
  hasNextPage,
  showUnreadConversationsOnly
}: ConversationList.Props): ReactElement {
  function handleClickConversation(conversationID: string): void {
    onInteraction({
      type: ConversationList.INTERACTION_ITEM_SELECTED,
      conversationID
    });
  }
  if (isLoadingConversations) {
    return (
      <Flex justifyContent="center" padding={theme.space_inset_md}>
        <SVGSpinner size="2.5em" />
      </Flex>
    );
  }

  if (conversations.length === 0) {
    return (
      <Text
        align="center"
        color={palette.white}
        marginTop={theme.space_stack_sm}
      >
        {copyText.noConversationsMessage}
      </Text>
    );
  }

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage
    ? conversations.length + 1
    : conversations.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) =>
    !hasNextPage || index < conversations.length;

  function renderItem({ index, style }: ListChildComponentProps) {
    const conversation = conversations[index];

    if (!isItemLoaded(index)) {
      return (
        <Flex
          style={style}
          justifyContent="center"
          padding={theme.space_inset_md}
        >
          <SVGSpinner size="2.5em" />
        </Flex>
      );
    } else {
      const {
        id,
        canonicalPhoneNumber,
        contactMapping,
        timeLastMarkedAsRead,
        lastMessage,
        lastIncomingMessage,
        contact
      } = conversation;

      let displayName = formatLocal(countryCode, canonicalPhoneNumber);
      let abbr = null;

      if (contact) {
        displayName = getContactFullName(contact);
        abbr = getContactInitials(contact);
      } else if (contactMapping?.attributes) {
        displayName = getFullName(contactMapping);
        abbr = getInitials(contactMapping);
      }
      const isSelected = selectedConversationID === id;
      const isUnread =
        lastIncomingMessage &&
        id !== selectedConversationID &&
        (timeLastMarkedAsRead === undefined ||
          lastIncomingMessage.timeCreated > timeLastMarkedAsRead);

      return (
        <Flex
          alignItems="center"
          backgroundColor={isSelected ? theme.color_mirage : null}
          backgroundColorOnHover={theme.color_mirage}
          borderBottom={`1px solid ${palette.gray[90]}`}
          borderLeft={
            isUnread || showUnreadConversationsOnly
              ? `5px solid ${palette.blue[60]}`
              : `5px solid transparent`
          }
          borderRight={
            isSelected ? `10px solid ${theme.color_jungle_green}` : null
          }
          cursor="pointer"
          justifyContent="between"
          paddingHorizontal={theme.space_inset_md}
          onClick={handleClickConversation.bind({}, id)}
          {...style}
          key={`key${index}`}
        >
          <Flex alignItems="center">
            <Avatar abbr={abbr} size={32}>
              {abbr ? displayName : <IconQuestionCircle />}
            </Avatar>
            <Flex
              direction="column"
              marginLeft={theme.space_inline_sm}
              overflow="hidden"
            >
              <Text
                color={palette.white}
                fontSize={theme.fontSize_base}
                fontWeight={theme.fontWeight_semiBold}
                truncate={250}
              >
                {displayName}
              </Text>
              <Text
                color={palette.white}
                fontSize={theme.fontSize_ui_sm}
                truncate={250}
              >
                {lastMessage.content}
              </Text>
              <Text color={palette.white} fontSize={theme.fontSize_mouse}>
                {dateFormat(lastMessage.timeCreated, 'mm/dd/yyyy hh:MMTT')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      );
    }
  }

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <List
          height={700}
          itemCount={itemCount}
          itemSize={75}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width="100%"
        >
          {renderItem}
        </List>
      )}
    </InfiniteLoader>
  );
}

ConversationList.INTERACTION_ITEM_SELECTED = `${ConversationList.name}.INTERACTION_ITEM_SELECTED`;

export default ConversationList;
