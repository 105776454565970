export const ContactSourceType = {
  SALESFORCE: 'SALESFORCE'
};

export const SYSTEM_DEFAULT_COUNTRY_CODE = 'US';

export default {
  ContactSourceType,
  SYSTEM_DEFAULT_COUNTRY_CODE
};
