export default {
  editButtonAriaLabel: 'Edit this report',
  headerAction: 'ACTION',
  headerName: 'NAME',
  headerTimeCreated: 'CREATED',
  headerTimeModified: 'LAST MODIFIED',
  headerType: 'TYPE',
  NA: 'N/A',
  noReportsMessage: 'No Reports have been created.',
  reportType_SALESFORCE_REPORT: 'Salesforce Report',
  reportType_SAMPLE_REPORT: 'Sample Report',
  title: 'Report List'
};
