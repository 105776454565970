export default {
  broadcasts: '/inboxes/:inboxID/broadcasts',
  inbox: '/inboxes/:inboxID',
  inboxes: '/inboxes',
  settings: '/settings',
  settingsInboxes: '/settings/inboxes',
  settingsInboxUsers: '/settings/inboxes/:inboxID/users',
  settingsMessageTemplates: '/settings/message-templates',
  settingsMessagingProvider: '/settings/messaging-provider',
  settingsOther: '/settings/other',
  settingsReport: '/settings/reports/:reportID',
  settingsReports: '/settings/reports',
  transcripts: '/transcripts/:phoneNumber'
};
