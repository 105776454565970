import Box from '@targetx/mineral-ui/Box';
import Flex from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconPencil from '@targetx/tx-web-ui-lib/lib/icons/IconPencil';
import IconTrash from '@targetx/tx-web-ui-lib/lib/icons/IconTrash';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import noop from 'lodash.noop';
import React, { ReactElement } from 'react';
import theme from '../theme';
import { UserEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './MessageTemplateList.copyText';

export namespace MessageTemplateList {
  export interface MessageTemplateEntity {
    id: string;
    ownerID?: string;
    name: string;
    content: string;
  }

  export interface Props {
    isLoadingTemplates?: boolean;
    templates: MessageTemplateEntity[];
    user: UserEntity;
    onInteraction?: InteractionDelegate;
  }
}

export function MessageTemplateList({
  isLoadingTemplates,
  templates,
  user,
  onInteraction = noop
}: MessageTemplateList.Props): ReactElement {
  function handleClickDeleteButton(templateID: string): void {
    onInteraction({
      type: MessageTemplateList.INTERACTION_DELETE_BUTTON_CLICKED,
      templateID
    });
  }

  function handleClickEditButton(templateID: string): void {
    onInteraction({
      type: MessageTemplateList.INTERACTION_EDIT_BUTTON_CLICKED,
      templateID
    });
  }

  if (isLoadingTemplates || templates.length === 0) {
    return (
      <Flex
        alignItems="center"
        backgroundColor={palette.white}
        border={`1px solid ${palette.gray[50]}`}
        borderRadius={theme.borderRadius_2}
        justifyContent="center"
        minHeight="100px"
        padding={theme.space_inset_lg}
        width="100%"
      >
        {isLoadingTemplates ? (
          <SVGSpinner size="2.5em" />
        ) : (
          <Text color={palette.gray[70]}>{copyText.noTemplatesMessage}</Text>
        )}
      </Flex>
    );
  }

  return (
    <Box
      backgroundColor={palette.white}
      border={`1px solid ${palette.gray[50]}`}
      borderRadius={theme.borderRadius_2}
      width="100%"
    >
      {templates.map((template, i) => (
        <Flex
          key={template.id}
          alignItems="center"
          borderBottom={
            i === templates.length - 1
              ? 'none'
              : `1px solid ${palette.gray[50]}`
          }
          justifyContent="between"
          padding={theme.space_inline_md}
        >
          <Box>
            <Text
              fontWeight={theme.fontWeight_semiBold}
              marginBottom={theme.space_stack_xs}
            >
              {template.name}
            </Text>
            <Text>
              {template.content.length > 200
                ? `${template.content.slice(0, 200)}...`
                : template.content}
            </Text>
          </Box>
          {user.role === UserRole.ORG_MEMBER && !template.ownerID ? null : (
            <Flex>
              <MinimalButton
                aria-label={copyText.editButtonLabel}
                iconStart={<IconPencil color={palette.gray[60]} />}
                marginRight={theme.space_inline_sm}
                size="small"
                onClick={handleClickEditButton.bind({}, template.id)}
              />
              <MinimalButton
                aria-label={copyText.deleteButtonLabel}
                iconStart={<IconTrash color={palette.gray[60]} />}
                size="small"
                onClick={handleClickDeleteButton.bind({}, template.id)}
              />
            </Flex>
          )}
        </Flex>
      ))}
    </Box>
  );
}

MessageTemplateList.INTERACTION_DELETE_BUTTON_CLICKED = `${MessageTemplateList.name}.INTERACTION_DELETE_BUTTON_CLICKED`;
MessageTemplateList.INTERACTION_EDIT_BUTTON_CLICKED = `${MessageTemplateList.name}.INTERACTION_EDIT_BUTTON_CLICKED`;

export default MessageTemplateList;
