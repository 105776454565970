export default {
  createButtonLabel: 'CREATE',
  confirmationDialogDangerMessage:
    'You are about to remove the report **%name%** from TX SMS. This cannot be undone. Are you sure you want to continue?',
  confirmationDialogDangerTitle: 'DANGER',
  ERROR_CREATING_REPORT_message:
    'An error occurred while creating the report. Please try again.',
  ERROR_DEACTIVATING_REPORT_message:
    'The system failed to delete this report. Please try again shortly.',
  ERROR_INITIALIZING_message:
    'An error occurred while initializing this screen. Please try again.',
  ERROR_LOADING_REPORT_COLUMN_NAMES_message:
    'The system failed to load the column names for this report. Please try again.',
  ERROR_LOADING_REPORT_message: `The system failed to load the selected report's column mapping configuration. Please change the report and try again.`,
  ERROR_LOADING_REPORTS_message:
    'An error occurred while loading reports. Please try again.',
  ERROR_LOADING_SALESFORCE_REPORT_FOLDERS_message:
    'An error occurred while attempting to retrieve folders from Salesforce. Please try again.',
  ERROR_LOADING_SALESFORCE_REPORTS_message:
    'An error occurred while attempting to retrieve reports from Salesforce. Please try again.',
  ERROR_LOADING_SALESFORCE_REPORTS_BY_FOLDER_message:
    'An error occurred while attempting to retrieve reports from the selected folder from Salesforce. Please try again.',
  ERROR_title: 'Operation Failed.',
  ERROR_UPDATING_REPORT_DUPLICATE_NAME_message:
    'The system failed to update this report because the specified name conflicts with that of an existing report. Please use a different name and try again.',
  ERROR_UPDATING_REPORT_message:
    'The system failed to update this report. Please try again shortly.',
  menuOptionDeactivateLabel: 'Delete',
  menuOptionEditLabel: 'Edit',
  openMenuButtonLabel: 'Open the action menu for this report',
  searchInputLabel: 'Search Reports',
  searchToggleButtonLabel: 'Toggle Search',
  SUCCESS_CREATING_REPORT_message:
    'Report **%reportName%** has been created successfully.',
  SUCCESS_DEACTIVATING_REPORT_message:
    'Report **%reportName%** has been successfully deleted.',
  SUCCESS_UPDATING_REPORT_message:
    'Report **%reportName%** has been updated successfully.',
  SUCCESS_title: 'Operation Successful!',
  title: 'Reports'
};
