export default {
  confirmationDialogDangerMessage:
    'You are about to delete template **%name%**. Are you sure you want to do this?',
  confirmationDialogDangerTitle: 'DANGER',
  confirmationDialogWarningMessage:
    'This message template includes a merge field. If you try to use this template with a report that does not include the field, you will be required to replace or remove the field before you can send it.',
  confirmationDialogWarningTitle: 'WARNING',
  createButtonLabel: 'CREATE',
  ERROR_CREATING_MESSAGE_TEMPLATE_message:
    'An unexpected error occured while creating the template. Please try again.',
  ERROR_DELETING_MESSAGE_TEMPLATE_message:
    'An unexpected error occured while deleting the template. Please try again.',
  ERROR_LOADING_MESSAGE_TEMPLATES_message:
    'An unexpected error occured while attempting to load message templates. Please try again.',
  ERROR_UPDATING_MESSAGE_TEMPLATE_message:
    'An unexpected error occured while updating the template. Please try again.',
  ERROR_title: 'Operation Failed.',
  globalTemplatesFilterLabel: 'GLOBAL',
  personalTemplatesFilterLabel: 'PERSONAL',
  searchInputLabel: 'Search Inboxes',
  searchToggleButtonLabel: 'Toggle Search',
  SUCCESS_CREATING_MESSAGE_TEMPLATE_message: 'Template successfully created.',
  SUCCESS_DELETING_MESSAGE_TEMPLATE_message: 'Template successfully deleted.',
  SUCCESS_UPDATING_MESSAGE_TEMPLATE_message: 'Template successfully updated.',
  SUCCESS_title: 'Operation Successful!',
  templateFilterLabel: 'Filter Templates By Type',
  title: 'Message Templates'
};
