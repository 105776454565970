/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';
import { TwilioServiceObject } from './types';

interface State {
  readonly data?: TwilioServiceObject;
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (serviceSID: string) => Promise<void>;
}

export default function useDataAPIGetTwilioMessagingServiceBySID(
  serviceSID?: string
): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(serviceSID: string): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getTwilioMessagingServiceBySID(serviceSID);

      setState({ data, isLoading: false });
    } catch (error: any) {
      setState({ error, isLoading: false });
    }
  }

  useEffect(() => {
    if (!serviceSID) return;

    fetch(serviceSID);
  }, [serviceSID]);

  return { ...state, refetch: fetch };
}
