/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    heap?: {
      addUserProperties: (properties: { [key: string]: any }) => void;
      identify: (id: string) => void;
      track: (event: string, properties: { [key: string]: any }) => void;
    };
    chmln?: {
      identify: (id: string, properties: { [key: string]: any }) => void;
    };
  }
}

export function trackUser(
  id: string,
  properties: { [key: string]: any }
): void {
  if (window.heap) {
    const heap = window.heap;

    if (typeof heap !== 'object') return;
    if (typeof heap.identify !== 'function') return;
    if (typeof heap.addUserProperties !== 'function') return;

    heap.identify(id);
    heap.addUserProperties(properties);
  }

  if (window.chmln) {
    const chmln = window.chmln;

    if (typeof chmln !== 'object') return;
    if (typeof chmln.identify !== 'function') return;

    chmln.identify(id, { ...properties, email: properties.Username });
  }
}

export default trackUser;

export function trackQueries(addUserProperties: { [key: string]: any }): void {
  if (window.heap) {
    const heap = window.heap;

    if (typeof heap !== 'object') return;
    if (typeof heap.addUserProperties !== 'function') return;

    heap.track('Query Data Report', addUserProperties);
  }
}
