import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  MessagingProviderType,
  MessagingServiceType
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import { decodeRID } from '@targetx/tx-sms-api-lib/lib/utils/MessagingProviderUtils';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';
import { ValueType } from 'react-select/src/types';
import isEmpty from 'validator/lib/isEmpty';
import styleProps from '../styles/props';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './CreateInboxForm.copyText';

export namespace CreateInboxForm {
  export interface ServiceEntity {
    providerRID: string;
    name: string;
  }

  export interface Props {
    isDisabled?: boolean;
    isLoadingServices?: boolean;
    isProcessing?: boolean;
    message?: ReactNode;
    messagingProviderType?: MessagingProviderType;
    services: ServiceEntity[];
    onInteraction?: InteractionDelegate;
  }
}

interface Option {
  label: string;
  value: string;
}

interface State {
  nameInput: { value: string; isValid: boolean };
  providerRIDInput: { value: string; isValid: boolean };
}

const initialState = {
  nameInput: { value: '', isValid: false },
  providerRIDInput: { value: '', isValid: false }
};

export function CreateInboxForm({
  isDisabled: _isDisabled,
  isLoadingServices,
  isProcessing,
  message,
  messagingProviderType,
  services,
  onInteraction = noop
}: CreateInboxForm.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  const { nameInput, providerRIDInput } = state;

  const servicesKeyedByProviderRID = keyBy(services, 'providerRID');

  const canSubmit = Object.values(state).every(
    (input): boolean => input.isValid
  );

  useEffect(() => {
    onInteraction({
      type: CreateInboxForm.INTERACTION_INBOX_EDITED,
      edited: Object.values(state).some((input): boolean => input.value)
    });
  }, [state]);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    const value = event.target.value;

    let isValid = false;

    switch (name) {
      case 'name':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      default:
        break;
    }

    setState(currentState => ({
      ...currentState,
      [`${name}Input`]: { value, isValid }
    }));
  }

  function handleChangeOption(
    name: string,
    option: ValueType<Option, false>
  ): void {
    setState(currentState => ({
      ...currentState,
      [`${name}Input`]: {
        value: option ? option.value : '',
        isValid: true
      }
    }));
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    const service = servicesKeyedByProviderRID[providerRIDInput.value];

    const { serviceType } = decodeRID(providerRIDInput.value);

    let attributes;

    switch (serviceType) {
      case MessagingServiceType.TWILIO_MESSAGING_SERVICE: {
        attributes = { friendlyName: service.name };
        break;
      }
    }

    onInteraction({
      type: CreateInboxForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      name: nameInput.value.trim(),
      providerRID: providerRIDInput.value,
      ...(attributes ? { attributes } : {})
    });
  }

  function handleReset(): void {
    onInteraction({ type: CreateInboxForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  }

  const options = services.map(service => ({
    label: formatOptionLabel(service),
    value: service.providerRID
  }));

  const showInfoMessage = services.length === 0 && _isDisabled;

  const isDisabled = _isDisabled || Boolean(message) || showInfoMessage;

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleReset}
        />
      </Layout.Header>
      <Layout.Body {...styleProps.ActionPanelLayoutBody}>
        {message}
        {renderInfoMessage({ showInfoMessage })}
        <FormField
          name="name"
          disabled={isDisabled}
          input={TextInput}
          label={
            <Text color={isValidInput(nameInput) ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={nameInput.value}
          variant={isValidInput(nameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="providerRID"
          caption={get(
            copyText,
            `providerRIDInputCaption_${messagingProviderType}`
          )}
          disabled={isDisabled}
          label={
            <Text
              color={
                isValidInput(providerRIDInput) ? undefined : palette.red[60]
              }
            >
              {copyText.providerRIDInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
        >
          <Select
            isLoading={isLoadingServices}
            options={options}
            placeholder={copyText.providerRIDInputPlaceholder}
            onChange={handleChangeOption.bind({}, 'providerRID')}
          />
        </FormField>
      </Layout.Body>
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button
          width="48%"
          type="reset"
          variant="grayscale"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing || isDisabled}
          width="48%"
          primary
          type="submit"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function renderInfoMessage({
  showInfoMessage // eslint-disable-line react/prop-types
}: {
  showInfoMessage?: boolean;
}): ReactNode {
  return showInfoMessage ? (
    <Box data-testid="alertMessageWrapper" paddingHorizontal="5%" width="100%">
      <Alert variant="info" zIndex={100}>
        {copyText.noServicesMessage}
      </Alert>
    </Box>
  ) : null;
}

function isValidInput({
  value,
  isValid
}: {
  value: string;
  isValid: boolean;
}): boolean {
  return isEmpty(value) || isValid;
}

function formatOptionLabel(service: CreateInboxForm.ServiceEntity): string {
  let label = service.name;

  const { serviceID, serviceType } = decodeRID(service.providerRID);

  if (serviceType === MessagingServiceType.TWILIO_MESSAGING_SERVICE) {
    label = `${label} (${serviceID})`;
  }

  return label;
}

CreateInboxForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${CreateInboxForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
CreateInboxForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${CreateInboxForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;
CreateInboxForm.INTERACTION_INBOX_EDITED = `${CreateInboxForm.name}.INTERACTION_INBOX_EDITED`;

export default CreateInboxForm;
