/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';
import { UserEntity } from './types';

interface State {
  readonly data?: UserEntity[];
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (inboxID: string) => Promise<void>;
}

export default function useDataAPIGetMessageSendersByInboxID(
  inboxID?: string
): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(inboxID: string): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getMessageSendersByInboxID(inboxID);

      setState({ isLoading: false, data });
    } catch (error: any) {
      setState({ isLoading: false, error });
    }
  }

  useEffect(() => {
    if (!inboxID) return;

    fetch(inboxID);
  }, [inboxID]);

  return { ...state, refetch: fetch };
}
