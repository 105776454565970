import Avatar from '@targetx/mineral-ui/Avatar';
import Box from '@targetx/mineral-ui/Box';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Link from '@targetx/mineral-ui/Link';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import ThemedButton from '@targetx/tx-web-ui-lib/lib/components/ThemedButton';
import IconQuestionCircle from '@targetx/tx-web-ui-lib/lib/icons/IconQuestionCircle';
import React, { ReactElement } from 'react';
import theme from '../theme';
import { ContactRecord } from '../types';
import { getFullName, getInitials } from '../utils/ContactRecordUtils';
import copyText from './ContactInfoComponent.copyText';

export namespace ContactInfoComponent {
  export interface Props {
    canonicalPhoneNumber: string;
    contact?: ContactRecord;
    messageTranscriptsBasePath?: string;
    salesforceInstanceURL?: string;
  }
}

export function ContactInfoComponent({
  canonicalPhoneNumber,
  contact,
  messageTranscriptsBasePath,
  salesforceInstanceURL
}: ContactInfoComponent.Props): ReactElement {
  const abbr = contact ? getInitials(contact) : null;
  const displayName = contact ? getFullName(contact) : canonicalPhoneNumber;

  return (
    <Flex direction="column" height="100%" justifyContent="between">
      <Box>
        <Flex
          alignItems="center"
          marginBottom={theme.space_stack_md}
          paddingBottom={theme.space_stack_md}
        >
          <Avatar abbr={abbr} size="large">
            {abbr ? displayName : <IconQuestionCircle />}
          </Avatar>
          <FlexItem marginLeft={theme.space_inline_md}>
            <Text fontSize="24px">{displayName}</Text>
          </FlexItem>
        </Flex>
        <Box borderBottom={`1px solid ${palette.gray[50]}`}>
          <Text bold fontSize={theme.h4_fontSize}>
            {copyText.personalInfoSectionHeader}
          </Text>
        </Box>
        {contact ? (
          <>
            <Box marginTop={theme.space_stack_md}>
              <Text fontWeight={theme.fontWeight_semiBold}>
                {copyText.labelMobilePhone}
              </Text>
              <Text color={palette.gray[90]} fontSize={theme.fontSize_ui}>
                {contact.mobilePhone}
              </Text>
            </Box>
            <Box marginTop={theme.space_stack_md}>
              <Text fontWeight={theme.fontWeight_semiBold}>
                {copyText.labelEmailAddress}
              </Text>
              <Text
                as={Link}
                color={palette.gray[90]}
                fontSize={theme.fontSize_ui}
                href={`mailto:${contact.emailAddress}`}
              >
                {contact.emailAddress ?? copyText.noEmailAddressPlaceholder}
              </Text>
            </Box>
            <Box marginTop={theme.space_stack_md}>
              <Text fontWeight={theme.fontWeight_semiBold}>
                {copyText.labelAssignedTo}
              </Text>
              <Text color={palette.gray[90]} fontSize={theme.fontSize_ui}>
                {contact.assignedTo ?? copyText.noAssignedToPlaceholder}
              </Text>
            </Box>
          </>
        ) : (
          <Box marginTop={theme.space_stack_xxl}>
            <Text
              align="center"
              color={palette.gray[70]}
              fontWeight={theme.fontWeight_semiBold}
            >
              {copyText.noContactLabel}
            </Text>
          </Box>
        )}
      </Box>
      <Flex alignItems="center" direction="column">
        {messageTranscriptsBasePath ? (
          <ThemedButton
            as={Link}
            css={{ display: 'flex' }}
            backgroundColor={palette.blue[20]}
            borderColor={palette.blue[20]}
            color={palette.blue[60]}
            fullWidth
            hoverEffect="darken"
            href={messageTranscriptsBasePath.replace(
              ':phoneNumber',
              canonicalPhoneNumber
            )}
            marginTop={theme.space_stack_md}
          >
            <Text
              color={palette.blue[60]}
              fontSize={theme.fontSize_base}
              fontWeight={theme.fontWeight_regular}
            >
              {copyText.linkMessageTranscripts}
            </Text>
          </ThemedButton>
        ) : null}
        {contact && salesforceInstanceURL ? (
          <ThemedButton
            as={Link}
            css={{ display: 'flex' }}
            backgroundColor={palette.blue[20]}
            borderColor={palette.blue[20]}
            color={palette.blue[60]}
            fullWidth
            hoverEffect="darken"
            href={`${salesforceInstanceURL}/${contact.rid}`}
            marginTop={theme.space_stack_md}
            target="_blank"
          >
            <Text
              color={palette.blue[60]}
              fontSize={theme.fontSize_base}
              fontWeight={theme.fontWeight_regular}
            >
              {copyText.linkFullProfile}
            </Text>
          </ThemedButton>
        ) : null}
      </Flex>
    </Flex>
  );
}

export default ContactInfoComponent;
