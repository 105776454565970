export default {
  dateLabel: 'Date',
  recipientsLabel: 'Recipients',
  statusLabel: 'Status',
  noExecutionsMessage: 'No broadcasts have completed at this time.',
  statusComplete: 'Completed',
  statusProcessing: 'Processing',
  statusFailed: 'Failed',
  statusUnknown: 'Unknown',
  headerDate: 'DATE',
  headerRecipients: 'RECIPIENTS',
  headerStatus: 'STATUS',
  title: 'List of Bulk Message schedules',
  zeroRecipients: '0 Recipients'
};
