export default {
  infoSectionHeading: 'Info',
  broadcastIdLabel: 'Broadcast ID',
  messageScheduleLabel: 'Message Schedule',
  messageStatusLabel: 'Message Status',
  recipientListLabel: 'Recipient List',
  recipientCountButton: 'View Recipient Count',
  reportNameLabel: 'Report',
  statusComplete: 'Completed',
  statusProcessing: 'Processing',
  statusFailed: 'Failed',
  statusUnknown: 'Unknown',
  timeCreatedLabel: 'Created',
  timeSentLabel: 'Sent',
  zeroRecipients: '0 Recipients'
};
