/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { components } from 'react-select';
import FlagIcon from 'react-world-flags';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './EditCountryCodeForm.copyText';

const countryCodes = ['AM', 'CA', 'IT', 'MX', 'ES', 'GB', 'US'];

const countryCodeOptions = countryCodes.map(code => ({
  label: get(copyText, `countryName${code}`) || '',
  value: code
}));

interface Props {
  defaults?: { countryCode: string };
  isLoadingDefaults: boolean;
  isProcessing: boolean;
  onInteraction?: InteractionDelegate;
}

export default function EditCountryCodeForm({
  defaults,
  isLoadingDefaults,
  isProcessing,
  onInteraction = noop
}: Props): ReactElement {
  const [countryCodeInput, setCountryCodeInput] = useState({ value: '' });

  useEffect(() => {
    if (!defaults?.countryCode) return;

    setCountryCodeInput({ value: defaults.countryCode });
  }, [defaults?.countryCode]);

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    onInteraction({
      type: EditCountryCodeForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      inputs: { countryCode: countryCodeInput.value }
    });
  }

  const countryCodeOption = countryCodeOptions.find(
    option => option.value === countryCodeInput.value
  );

  const isDisabled =
    defaults?.countryCode === countryCodeInput.value || isProcessing;

  return (
    <Form onSubmit={handleSubmit}>
      <Box
        backgroundColor={palette.white}
        border={`1px solid ${palette.gray[40]}`}
        borderRadius={theme.borderRadius_2}
        boxShadow="0 1px 4px 0 rgb(0 0 0 / 14%)"
        padding={theme.space_inset_lg}
      >
        <Text bold fontSize={theme.h4_fontSize}>
          {copyText.headerCountryCode}
        </Text>
        <Box marginTop={theme.space_stack_md} width="60%">
          <Select
            components={{ Option, SingleValue }}
            isLoading={isLoadingDefaults}
            options={countryCodeOptions}
            value={countryCodeOption}
            onChange={option =>
              setCountryCodeInput({ value: option?.value ?? '' })
            }
          />
        </Box>
      </Box>
      <Flex justifyContent="end" marginTop={theme.space_stack_md} width="100%">
        <Button disabled={isDisabled} primary width="100px">
          {copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

EditCountryCodeForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditCountryCodeForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

interface Option {
  label: string;
  value: string;
}

function IconOption({ label, value }: Option): ReactElement {
  return (
    <Flex alignItems="center">
      <FlagIcon code={value} height={16} width={32} />
      <Text marginLeft={theme.space_inline_md}>{label}</Text>
    </Flex>
  );
}

function Option(props: any): ReactElement {
  return (
    <components.Option {...props}>
      <IconOption label={props.data.label} value={props.data.value} />
    </components.Option>
  );
}

function SingleValue(props: any) {
  return (
    <components.SingleValue {...props}>
      <IconOption label={props.data.label} value={props.data.value} />
    </components.SingleValue>
  );
}
