// prettier-ignore

export default [
  {
    label: 'USA',
    options: [
      { value: 'Pacific/Apia', label: 'Midway Islands Time (MIT)', offset: 'GMT-11' },
      { value: 'America/Adak', label: 'Hawaii Time (HST)', offset: 'GMT-10' },
      { value: 'America/Juneau', label: 'Alaska Time (AST)', offset: 'GMT-9' },
      { value: 'America/Los_Angeles', label: 'Pacific Time (PT)', offset: 'GMT-8' },
      { value: 'America/Phoenix', label: 'Arizona Time (MST)', offset: 'GMT-7' },
      { value: 'America/Denver', label: 'Mountain Time (MT)', offset: 'GMT-7' },
      { value: 'America/Chicago', label: 'Central Time (CT)', offset: 'GMT-6' },
      { value: 'America/New_York', label: 'Eastern Time (ET)', offset: 'GMT-5' },
    ]
  },
  {
    label: 'International',
    options: [
      { value: 'America/Halifax', label: 'Atlantic Time (AT)', offset: 'GMT-4' },
      { value: 'America/St_Johns', label: 'Newfoundland Time (NT)', offset: 'GMT-3:30' },
      { value: 'America/Sao_Paulo', label: 'Argentina Time (AGT)', offset: 'GMT-3' },
      { value: 'Atlantic/Cape_Verde', label: 'Central African Time (CAT)', offset: 'GMT-1' },
      { value: 'Etc/GMT', label: 'Greenwich Mean Time (GMT)', offset: 'GMT' },
      { value: 'Europe/Paris', label: 'Central European Time (CET)', offset: 'GMT+1' },
      { value: 'Europe/Kiev', label: 'Eastern European Time (EET)', offset: 'GMT+2' },
      { value: 'Europe/Istanbul', label: 'Eastern African Time (EAT)', offset: 'GMT+3' },
      { value: 'Asia/Tehran', label: 'Middle East Time (MET)', offset: 'GMT+3:30' },
      { value: 'Europe/Moscow', label: 'Near East Time (NET)', offset: 'GMT+4' },
      { value: 'Asia/Tashkent', label: 'Pakistan Lahore Time (PLT)', offset: 'GMT+5' },
      { value: 'Asia/Kolkata', label: 'India Time (IST)', offset: 'GMT+5:30' },
      { value: 'Asia/Dhaka', label: 'Bangladesh Time (BST)', offset: 'GMT+6' },
      { value: 'Asia/Bangkok', label: 'Vietnam Time (VST)', offset: 'GMT+7' },
      { value: 'Asia/Shanghai', label: 'China Taiwan Time (CTT)', offset: 'GMT+8' },
      { value: 'Asia/Tokyo', label: 'Japan Standard Time (JST)', offset: 'GMT+9' },
      { value: 'Australia/Darwin', label: 'Australia Central Time (ACT)', offset: 'GMT+9:30' },
      { value: 'Australia/Brisbane', label: 'Australia Eastern Time (AET)', offset: 'GMT+10' },
      { value: 'Pacific/Guadalcanal', label: 'Solomon Time (SST)', offset: 'GMT+11' },
      { value: 'Pacific/Auckland', label: 'New Zealand Time (NST)', offset: 'GMT+12' }
    ]
  }
];
