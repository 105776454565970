import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import Dropdown from '@targetx/mineral-ui/Dropdown';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import { FlexItem } from '@targetx/mineral-ui/Flex';
import Markdown from 'markdown-to-jsx';
import IconEllipsisVertical from '@targetx/tx-web-ui-lib/lib/icons/IconEllipsisVertical';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { format, utcToZonedTime } from 'date-fns-tz';
import { InteractionDelegate } from '@targetx/tx-web-ui-lib/lib/types/Interaction';
import Text from '@targetx/mineral-ui/Text';
import React, { ReactNode, useMemo } from 'react';
import { ReactElement } from 'react';
import copyText from './BulkMessageDetails.copyText';
import noop from 'lodash.noop';
import theme from '../theme';
import keyBy from 'lodash.keyby';
import BroadcastDetailsExecutionList from './BroadcastDetailsExecutionList';
import { BaseUserEntity, BroadcastEntity, CampaignEntity } from '../types';
import getScheduleSummary from '../utils/getScheduleSummary';
import { getFullName } from '../utils/UserUtils';
import { UserStatus } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';

export namespace BulkMessageDetails {
  export interface Props {
    broadcasts: BroadcastEntity[];
    campaigns: CampaignEntity[];
    isLoadingExecutions: boolean;
    selectedCampaignID: string;
    users: BaseUserEntity[];
    message?: ReactNode;
    onInteraction?: InteractionDelegate;
  }
}

export function BulkMessageDetails({
  broadcasts,
  campaigns,
  isLoadingExecutions,
  selectedCampaignID,
  users,
  message,
  onInteraction = noop
}: BulkMessageDetails.Props): ReactElement {
  const campaignsKeyedByID = useMemo(() => keyBy(campaigns, 'id'), [campaigns]);
  const campaign = campaignsKeyedByID[selectedCampaignID];

  const owner = users.find(user => user.id === campaign.userID);
  const deactivated =
    owner?.status === UserStatus.DEACTIVATED ? ' (Deactivated)' : '';
  const ownerName = owner ? getFullName(owner) + deactivated : '';

  function handleClickUpdateButton(): void {
    onInteraction({
      type: BulkMessageDetails.INTERACTION_BROADCAST_EDIT
    });
  }

  function renderHeader(): ReactElement {
    const menuOptions = [
      {
        text: copyText.menuOptionUpdateCampaign,
        onClick: handleClickUpdateButton
      }
    ];

    return (
      <Layout.Header
        flex
        alignItems="center"
        justifyContent="between"
        minHeight={theme.space_stack_xxl}
        paddingHorizontal={theme.space_stack_md}
      >
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <FlexItem marginRight={theme.space_inline_xl}>
          <Dropdown data={menuOptions} placement="bottom-end">
            <MinimalButton
              aria-label={copyText.openMenuButtonLabel}
              iconStart={<IconEllipsisVertical color={palette.gray[60]} />}
              size="small"
              type="button"
            />
          </Dropdown>
        </FlexItem>
      </Layout.Header>
    );
  }

  function renderBulkMessageDetails(): ReactElement {
    const executions = broadcasts.filter(b => b.campaignID === campaign.id);

    const scheduleSummary = getScheduleSummary(
      {
        frequency: campaign?.schedule?.frequency || '',
        dayOfMonth: campaign?.schedule?.dayOfMonth,
        daysOfWeek: campaign?.schedule?.daysOfWeek,
        executionTimeZone: campaign?.schedule?.executionTimeZone || '',
        executionTime: campaign?.schedule?.executionTime || ''
      },
      {
        ...(campaign?.scheduleStartTime
          ? {
              scheduleStartDate: utcToZonedTime(
                new Date(campaign.scheduleStartTime),
                campaign.schedule?.executionTimeZone || ''
              ).toISOString()
            }
          : {}),
        ...(campaign?.scheduleEndTime
          ? {
              scheduleEndDate: utcToZonedTime(
                new Date(campaign.scheduleEndTime),
                campaign.schedule?.executionTimeZone || ''
              ).toISOString()
            }
          : {})
      }
    );

    return (
      <>
        <Text bold as="h3">
          {campaign.name}
        </Text>
        <Text color={palette.gray[70]}>{campaign.id}</Text>
        <Text color={palette.gray[70]}>
          {`${copyText.timeCreatedLabel} ${format(
            new Date(campaign.timeCreated),
            'MMMM d, yyyy'
          )}`}
        </Text>
        <Text color={palette.gray[70]}>
          {`${copyText.bulkMessageOwnerName} ${ownerName}`}
        </Text>
        <Text
          as="h4"
          fontWeight={theme.fontWeight_semiBold}
          marginTop={theme.space_stack_md}
          marginBottom={theme.space_stack_sm}
          style={{ borderBottom: `1px solid ${palette.gray[50]}` }}
        >
          {copyText.scheduleSummaryTitle}
        </Text>
        <Text marginBottom={theme.space_stack_md}>
          <Markdown>{scheduleSummary}</Markdown>
        </Text>

        <BroadcastDetailsExecutionList
          isLoading={isLoadingExecutions}
          executions={executions}
        />
      </>
    );
  }

  return (
    <Layout as={Form} backgroundColor={palette.white}>
      {renderHeader()}
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}
        {renderBulkMessageDetails()}
      </Layout.Body>
    </Layout>
  );
}

BulkMessageDetails.INPUT_ACTIVE_JOB = `status`;
BulkMessageDetails.INTERACTION_BROADCAST_EDIT = `${BulkMessageDetails.name}.INTERACTION_BROADCAST_EDIT`;

export default BulkMessageDetails;
