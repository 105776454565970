/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReportStatus } from '@targetx/tx-sms-api-lib/lib/constants/enums';

export enum InboxStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED'
}

export enum MessageStatus {
  INCOMING_RECEIVED = 'INCOMING_RECEIVED',
  OUTGOING_QUEUED = 'OUTGOING_QUEUED',
  OUTGOING_QUEUING = 'OUTGOING_QUEUING',
  OUTGOING_QUEUING_FAILED = 'OUTGOING_QUEUING_FAILED',
  OUTGOING_DELIVERED = 'OUTGOING_DELIVERED',
  OUTGOING_DELIVERY_FAILED = 'OUTGOING_DELIVERY_FAILED',
  OUTGOING_SEND_FAILED = 'OUTGOING_SEND_FAILED',
  OUTGOING_SENT = 'OUTGOING_SENT'
}

export enum MessagingProviderType {
  TWILIO = '0001'
}

export enum MessagingServiceType {
  TWILIO_MESSAGING_SERVICE = '0001'
}

export enum ReportType {
  CSV_FILE = 'CSV_FILE',
  SALESFORCE_REPORT = 'SALESFORCE_REPORT'
}

export enum SettingName {
  DEFAULT_COUNTRY_CODE = 'defaultCountryCode'
}

export enum UserInboxPermissionType {
  CAN_ACCESS = 'Inbox/CAN_ACCESS',
  CAN_SEND_BROADCAST = 'Inbox/CAN_SEND_BROADCAST'
}

// TODO: Flatten attributes
export interface ContactMappingEntity {
  id: string;
  canonicalPhoneNumber: string;
  contactRID: string;
  attributes?: { [key: string]: any };
}

export interface ContactRecord {
  rid: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  emailAddress?: string;
  assignedTo?: string;
}

// TODO: Flatten attributes
export interface InboxEntity {
  id: string;
  providerRID: string;
  name: string;
  status: InboxStatus;
  attributes?: { [key: string]: any };
}

export interface MessageEntity {
  id: string;
  inboxID: string;
  rid: string;
  content: string;
  canonicalPhoneNumber: string;
  status: MessageStatus;
  timeCreated: string;
  broadcastID?: string;
  contactMappingID?: string;
  userID?: string;
  timeModified?: string;
}

export interface MessageTemplateEntity {
  id: string;
  ownerID?: string;
  name: string;
  content: string;
}

export interface MessagingAPIKeyEntity {
  id: string;
  providerRID: string;
  key: string;
  secretPreview: string;
  isValid: boolean;
}

export interface MessagingServiceEntity {
  providerRID: string;
  name: string;
  inboxID?: string;
}

export interface OrgSettingEntity {
  id: string;
  name: string;
  value: string;
}

export interface BaseReportEntity {
  id: string;
  name: string;
  status: ReportStatus;
  timeCreated: string;
  timeModified?: string;
  type: ReportType;
}

export interface ReportEntity extends BaseReportEntity {
  rid: string;
  columnMapping?: {
    [key: string]: string;
  };
}

export interface SalesforceReportObject {
  id: string;
  name: string;
}

export interface SalesforceReportFolderObject {
  id: string;
  name: string;
  developerName: string;
}

export interface TwilioServiceObject {
  sid: string;
  phoneNumbers: string[];
}

export interface UserEntity {
  id: string;
  firstName: string;
  lastName: string;
  username: string;
}

export interface UserPermissionEntity {
  id: string;
  userID: string;
  targetID: string;
  type: UserInboxPermissionType;
}
