export default {
  downloadButtonLabel: 'DOWNLOAD MESSAGE TRANSCRIPT',
  ERROR_LOADING_CONTACT_message:
    'Sorry, the system failed to load the selected contact from your CRM. Please reload the current browser window and try again.',
  ERROR_LOADING_CONTACT_MAPPINGS_message:
    'Sorry, the system failed to load contact information for this inbox. Please reload the current browser window and try again.',
  ERROR_LOADING_INBOXES_message:
    'Sorry, the system failed to load your list of inboxes. Please reload the current browser window and try again.',
  ERROR_LOADING_MESSAGES_message:
    'Sorry, the system failed to load messages for this inbox. Please reload the current browser window and try again.',
  ERROR_LOADING_ORG_SETTINGS_message:
    'An unexpected error occured while attempting to load settings for your org. Please reload the current browser window and try again.',
  ERROR_LOADING_PERMISSIONS_message:
    'Sorry, the system failed to load your inbox permissions. Please reload the current browser window and try again.',
  ERROR_LOADING_USERS_message:
    'Sorry, the system failed to load users. Please reload the current browser window and try again.',
  noInboxesLabel: 'No Inboxes',
  settingsLinkLabel: 'Navigate To Settings',
  title: 'Message Transcripts'
};
