/* eslint-disable @typescript-eslint/no-explicit-any */

import Flex from '@targetx/mineral-ui/Flex';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  InboxStatus,
  MessagingServiceType
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import { decodeRID } from '@targetx/tx-sms-api-lib/lib/utils/MessagingProviderUtils';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, { ReactElement } from 'react';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './InboxList.copyText';

export namespace InboxList {
  export interface InboxEntity {
    id: string;
    name: string;
    providerRID: string;
    status: InboxStatus;
    attributes?: { [key: string]: any };
  }

  export interface Props {
    isLoading?: boolean;
    inboxes: InboxEntity[];
    renderItemMenu?: ({ inboxID }: { inboxID: string }) => ReactElement;
    onInteraction?: InteractionDelegate;
  }
}

interface RowData {
  id: string;
  row: () => ReactElement;
}

export function InboxList({
  isLoading,
  inboxes,
  renderItemMenu,
  onInteraction = noop
}: InboxList.Props): ReactElement {
  let data;

  const columns = [
    {
      key: copyText.headerName,
      content: copyText.headerName,
      borderless: true,
      minWidth: 200,
      width: '20%'
    },
    {
      key: copyText.headerAccountID,
      content: copyText.headerAccountID,
      borderless: true,
      minWidth: 150,
      width: '28%'
    },
    {
      key: copyText.headerServiceType,
      content: copyText.headerServiceType,
      borderless: true,
      minWidth: 170,
      width: 170
    },
    {
      key: copyText.headerServiceID,
      content: copyText.headerServiceID,
      borderless: true,
      minWidth: 150,
      width: '28%'
    },
    {
      key: copyText.headerActions,
      content: <Text hidden>{copyText.headerActions}</Text>,
      label: copyText.headerActions,
      borderless: true,
      minWidth: 80,
      width: '5%'
    }
  ];

  if (isLoading || inboxes.length === 0) {
    const render = (): ReactElement => (
      <TableRow>
        <TableCell colSpan={5}>
          <Flex alignItems="center" height="75px" justifyContent="center">
            {isLoading ? (
              <SVGSpinner size="2.5em" />
            ) : (
              <Text color={palette.gray[70]}>{copyText.noInboxesMessage}</Text>
            )}
          </Flex>
        </TableCell>
      </TableRow>
    );

    data = [{ id: '_', row: render }];
  } else {
    data = inboxes.map((inbox: InboxList.InboxEntity): RowData => {
      const render = (): ReactElement => (
        <InboxListItem
          inbox={inbox}
          renderItemMenu={renderItemMenu}
          onInteraction={onInteraction}
        />
      );
      return { id: inbox.id, row: render };
    });
  }

  return (
    <Table
      rowKey="id"
      border={`1px solid ${palette.gray[50]}`}
      borderRadius={theme.borderRadius_2}
      columns={columns}
      data={data}
      striped
      title={copyText.title}
      titleAs="h2"
      width="100%"
    />
  );
}

function InboxListItem({
  inbox,
  renderItemMenu,
  onInteraction = noop
}: {
  inbox: InboxList.InboxEntity;
  renderItemMenu?: ({ inboxID }: { inboxID: string }) => ReactElement;
  onInteraction: InteractionDelegate;
}): ReactElement {
  const { id: inboxID, providerRID, name } = inbox;

  const { accountID, serviceType, serviceID } = decodeRID(providerRID);

  const handleClickEditInbox = (): void => {
    onInteraction({
      type: InboxList.INTERACTION_ITEM_CLICKED,
      inboxID,
      providerRID
    });
  };

  return (
    <TableRow>
      <TableCell truncate={200} verticalAlign="middle">
        {name}
      </TableCell>
      <TableCell truncate={150} verticalAlign="middle">
        {accountID}
      </TableCell>
      <TableCell verticalAlign="middle">
        {formatServiceType(serviceType)}
      </TableCell>
      <TableCell truncate={150} verticalAlign="middle">
        {serviceID}
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex justifyContent="between">
          {renderItemMenu ? renderItemMenu({ inboxID }) : null}
          <MinimalButton
            aria-label={copyText.editButtonLabel}
            iconStart={<IconChevronRight color={palette.gray[60]} />}
            size="small"
            onClick={handleClickEditInbox}
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
}

function formatServiceType(serviceType?: MessagingServiceType): string {
  if (!serviceType) return 'N/A';

  return get(copyText, `serviceType_${serviceType}`);
}

InboxList.INTERACTION_ITEM_CLICKED = `${InboxList.name}.INTERACTION_ITEM_CLICKED`;

export default InboxList;
