import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Link from '@targetx/mineral-ui/Link';
import Text from '@targetx/mineral-ui/Text';
import TextArea from '@targetx/mineral-ui/TextArea';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  CampaignScheduleFrequency,
  CampaignScheduleStatus,
  ReportStatus,
  ReportType,
  UserInboxPermissionType
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import DateInput from '@targetx/tx-web-ui-lib/lib/components/DateInput';
import DaysOfWeekInputGroup from '@targetx/tx-web-ui-lib/lib/components/DaysOfWeekInputGroup';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import IconChevronLeft from '@targetx/tx-web-ui-lib/lib/icons/IconChevronLeft';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import IconSchedule from '@targetx/tx-web-ui-lib/lib/icons/IconSchedule';
import dateFormat from 'dateformat';
import { utcToZonedTime } from 'date-fns-tz';
import timeZones from '../constants/timeZones';
import isNil from 'lodash.isnil';
import noop from 'lodash.noop';
import Markdown from 'markdown-to-jsx';
import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import { GroupTypeBase, ValueType } from 'react-select';
import Switch from 'react-switch';
import isEmpty from 'validator/lib/isEmpty';
import styleProps from '../styles/props';
import theme from '../theme';
import { BaseUserEntity, CampaignEntity, UserPermissionEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import isBlank from '../utils/isBlank';
import getDaysOfMonthOptions from '../utils/getDaysOfMonthOptions';
import getScheduleSummary from '../utils/getScheduleSummary';
import getTimeOptions from '../utils/getTimeOptions';
import {
  extractMergeFields,
  hasMergeFields,
  isMissingMergeFields
} from '../utils/MergeFieldUtils';
import copyText from './CreateCampaignForm.copyText';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import { getFullName } from '../utils/UserUtils';
import {
  UserRole,
  UserStatus
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';

const COLUMN_MAPPING_KEYS = [
  'contactID',
  'firstName',
  'lastName',
  'mobilePhoneNumber'
];

const MESSAGE_CONTENT_MAX_LENGTH = 1000;
const CHILD_VIEW_CONFIGURE_SCHEDULE = 'CONFIGURE_SCHEDULE';
type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

const frequencyOptions = [
  { label: copyText.frequencyOnce, value: CampaignScheduleFrequency.ONCE },
  { label: copyText.frequencyDaily, value: CampaignScheduleFrequency.DAILY },
  { label: copyText.frequencyWeekly, value: CampaignScheduleFrequency.WEEKLY },
  { label: copyText.frequencyMonthly, value: CampaignScheduleFrequency.MONTHLY }
];

export namespace CreateCampaignForm {
  export interface ReportEntity {
    id: string;
    name: string;
    status: ReportStatus;
    type: ReportType;
  }

  export interface TemplateEntity {
    id: string;
    name: string;
    content: string;
  }

  export interface AuthenticatedUser {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    role: string;
  }

  export interface Props {
    isDisabledForSelectedReport?: boolean;
    isLoadingReports?: boolean;
    isLoadingSelectedReportColumnMapping?: boolean;
    isLoadingSelectedReportColumnNames?: boolean;
    isLoadingUsers?: boolean;
    isProcessing?: boolean;
    message?: ReactNode;
    reports: ReportEntity[];
    reportsBaseURL: string;
    selectedReportColumnMapping?: { [key: string]: string };
    selectedReportColumnNames: string[];
    templates: TemplateEntity[];
    users?: BaseUserEntity[];
    userPermissions?: UserPermissionEntity[];
    onInteraction?: InteractionDelegate;
    campaign: CampaignEntity | null;
    authenticatedUser: AuthenticatedUser | null;
  }
}

interface Option {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export function CreateCampaignForm({
  isDisabledForSelectedReport,
  isLoadingReports,
  isLoadingSelectedReportColumnMapping,
  isLoadingSelectedReportColumnNames,
  isLoadingUsers,
  isProcessing,
  message,
  reports: _reports,
  reportsBaseURL,
  users,
  userPermissions,
  selectedReportColumnMapping,
  selectedReportColumnNames,
  templates,
  onInteraction = noop,
  campaign,
  authenticatedUser
}: CreateCampaignForm.Props): ReactElement {
  let daysOfWeek: (0 | 1 | 2 | 3 | 4 | 5 | 6)[];
  let dayOfMonth = '';
  let endDate = null;
  let executionTime = '';
  let executionTimeZone = '';
  let frequency = '';
  let startDate = new Date().toISOString();

  daysOfWeek = [];
  if (campaign) {
    endDate = campaign.scheduleEndTime
      ? utcToZonedTime(
          new Date(campaign.scheduleEndTime),
          campaign.schedule?.executionTimeZone || ''
        ).toISOString()
      : endDate;

    startDate = campaign.scheduleStartTime
      ? utcToZonedTime(
          new Date(campaign.scheduleStartTime),
          campaign.schedule?.executionTimeZone || ''
        ).toISOString()
      : startDate;
    if (campaign.schedule) {
      const schedule = Object.assign(campaign.schedule);
      dayOfMonth = schedule.dayOfMonth ? schedule.dayOfMonth : dayOfMonth;
      daysOfWeek = schedule.daysOfWeek ? [...schedule.daysOfWeek] : daysOfWeek;
      executionTime = schedule?.executionTime || executionTime;
      frequency = schedule?.frequency || frequency;
      executionTimeZone = schedule?.executionTimeZone || executionTimeZone;
    }
  }

  const initialState = {
    contentInput: {
      value: campaign?.content || '',
      isValid: campaign?.content ? true : false
    },
    nameInput: {
      value: campaign?.name || '',
      isValid: campaign?.name ? true : false
    },
    reportIDInput: {
      value: campaign?.reportID || '',
      isValid: campaign?.reportID ? true : false
    },
    enableContactActivityLoggingInput: {
      value: campaign?.options?.enableContactActivityLogging || false,
      isValid: true
    },
    dayOfMonthInput: { value: dayOfMonth, isValid: true },
    daysOfWeekInput: { value: daysOfWeek, isValid: true },
    endDateInput: { value: endDate, isValid: true },
    executionTimeInput: { value: executionTime, isValid: true },
    executionTimeZoneInput: { value: executionTimeZone, isValid: true },
    frequencyInput: { value: frequency, isValid: true },
    recurringInput: {
      value: campaign?.scheduleStatus === CampaignScheduleStatus.ENABLED,
      isValid: true
    },
    startDateInput: { value: startDate, isValid: true },
    sendDuplicatesInput: {
      value: campaign?.schedule?.sendDuplicates || false,
      isValid: true
    },
    userIDInput: {
      value: campaign?.userID || authenticatedUser?.id || '',
      isValid: campaign?.userID || authenticatedUser?.id ? true : false
    },
    templateID: { label: '', value: '', isValid: true }
  };

  const campaignOriginalState = Object.assign({}, initialState);

  const [state, setState] = useState(initialState);
  const [childView, setChildViewState] = useState<string | null>(null);

  const {
    contentInput,
    nameInput,
    reportIDInput,
    enableContactActivityLoggingInput,
    recurringInput,
    sendDuplicatesInput,
    dayOfMonthInput,
    daysOfWeekInput,
    endDateInput,
    executionTimeInput,
    executionTimeZoneInput,
    frequencyInput,
    startDateInput,
    userIDInput,
    templateID
  } = state;

  const reports = _reports.filter(
    report => report.status !== ReportStatus.DEACTIVATED
  );

  let isReportMissingMergeFields = false;

  const selectedReport = reports.find(
    report => report.id === reportIDInput.value
  );

  const isDisabledContactActivityLoggingFeature =
    selectedReport?.type === ReportType.SALESFORCE_REPORT &&
    !isLoadingSelectedReportColumnMapping &&
    isBlank(selectedReportColumnMapping?.contactID);

  if (
    selectedReportColumnNames.length > 0 &&
    hasMergeFields(contentInput.value)
  ) {
    isReportMissingMergeFields = isMissingMergeFields(
      selectedReportColumnNames,
      extractMergeFields(contentInput.value)
    );
  }

  function handleClickViewScheduleMessage(): void {
    setChildViewState(CHILD_VIEW_CONFIGURE_SCHEDULE);
  }

  function handleChangeInput(event: ChangeEvent<HTMLInputElement>): void {
    const { name } = event.target;
    let value: boolean | string = event.target.value;
    let isValid = false;
    let derivedState = {};

    switch (name) {
      case CreateCampaignForm.INPUT_CONTENT:
        isValid =
          !isEmpty(value, { ignore_whitespace: true }) &&
          value.length <= MESSAGE_CONTENT_MAX_LENGTH;
        break;
      case CreateCampaignForm.INPUT_NAME:
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case CreateCampaignForm.INPUT_ENABLE_CONTACT_ACTIVITY_LOGGING:
      case CreateCampaignForm.INPUT_RECURRING:
      case CreateCampaignForm.INPUT_SEND_DUPLICATES:
        isValid = true;
        value = value === 'true';
        break;
      case CreateCampaignForm.INPUT_FREQUENCY:
        derivedState = {
          dayOfMonthInput: { value: '', isValid: true },
          daysOfWeekInput: { value: [], isValid: true },
          endDateInput: { value: new Date().toISOString(), isValid: true },
          executionTimeInput: { value: '', isValid: true },
          executionTimeZoneInput: { value: '', isValid: true },
          startDateInput: { value: new Date().toISOString(), isValid: true }
        };
        isValid = true;
        break;
      case CreateCampaignForm.INPUT_START_DATE:
        const startDateAux = new Date(value);
        const endDateAux = new Date(endDateInput.value || '');
        if (startDateAux >= endDateAux) {
          derivedState = {
            endDateInput: { value: value, isValid: true }
          };
        }
        isValid = true;
        break;
      default:
        isValid = true;
        break;
    }

    setState(currentState => ({
      ...currentState,
      ...derivedState,
      [`${name}Input`]: { value, isValid }
    }));

    onInteraction({
      type: CreateCampaignForm.INTERACTION_INPUT_CHANGED
    });
  }

  function handleDaysOfWeekInputChange(values: DayOfWeek[]) {
    setState(currentState => ({
      ...currentState,
      daysOfWeekInput: { value: values, isValid: true }
    }));
  }

  function handleChangeOption(
    name: string,
    option: ValueType<Option, false>
  ): void {
    const value = option ? option.value : '';
    const label = option ? option.label : '';

    switch (name) {
      case CreateCampaignForm.INPUT_MERGE_FIELD: {
        setState(currentState => ({
          ...currentState,
          contentInput: {
            value: `${currentState.contentInput.value} ${value}`,
            isValid: true
          }
        }));
        break;
      }
      case CreateCampaignForm.INPUT_REPORT_ID: {
        onInteraction({
          type: CreateCampaignForm.INTERACTION_REPORT_SELECTED,
          reportID: value
        });

        setState(currentState => ({
          ...currentState,
          nameInput: {
            value: `${label} (${dateFormat(new Date(), 'mm-dd-yyyy')})`,
            isValid: true
          },
          reportIDInput: { value, isValid: true }
        }));
        break;
      }
      case CreateCampaignForm.INPUT_TEMPLATE_CONTENT: {
        setState(currentState => ({
          ...currentState,
          templateID: {
            label: option ? option.label : '',
            value: option ? option.value : '',
            isValid: true
          },
          contentInput: { value, isValid: true }
        }));
        break;
      }
      case CreateCampaignForm.INPUT_USER_ID: {
        onInteraction({
          type: CreateCampaignForm.INTERACTION_USER_SELECTED,
          userID: value
        });

        setState(currentState => ({
          ...currentState,
          userIDInput: { value, isValid: true }
        }));
        break;
      }
    }

    onInteraction({
      type: CreateCampaignForm.INTERACTION_INPUT_CHANGED
    });
  }

  function handleClickReloadButton(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();

    onInteraction({
      type: CreateCampaignForm.INTERACTION_RELOAD_BUTTON_CLICKED,
      reportID: reportIDInput.value
    });
  }

  function handleReset(): void {
    onInteraction({
      type: CreateCampaignForm.INTERACTION_CANCEL_BUTTON_CLICKED
    });
  }

  function handleClickBack(): void {
    setChildViewState(null);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    if (!canSubmit) return;

    let actionType = CreateCampaignForm.INTERACTION_SUBMIT_BUTTON_CLICKED;

    if (campaign) {
      actionType = CreateCampaignForm.INTERACTION_UPDATE_BUTTON_CLICKED;
    }

    onInteraction({
      campaignID: campaign?.id,
      type: actionType,
      content: contentInput.value.trim(),
      name: nameInput.value.trim(),
      reportID: reportIDInput.value,
      userID: userIDInput.value,
      ...(selectedReport?.type === ReportType.SALESFORCE_REPORT
        ? {
            enableContactActivityLogging:
              enableContactActivityLoggingInput.value
          }
        : {}),
      recurring: recurringInput.value,
      sendDuplicates: sendDuplicatesInput.value,
      ...(!isBlank(frequencyInput.value)
        ? {
            scheduleStartDate: dateFormat(startDateInput.value, 'yyyy-mm-dd'),
            scheduleEndDate:
              endDateInput.value &&
              dateFormat(endDateInput.value, 'yyyy-mm-dd'),
            frequency: frequencyInput.value,
            dayOfMonth: dayOfMonthInput.value,
            daysOfWeek: daysOfWeekInput.value,
            executionTimeZone: executionTimeZoneInput.value,
            executionTime: executionTimeInput.value
          }
        : {})
    });
  }

  const frequencyOption = frequencyOptions.find(
    (option): boolean => option.value === frequencyInput.value
  );

  const scheduleSummary = getScheduleSummary(
    {
      frequency: frequencyInput.value,
      dayOfMonth: dayOfMonthInput.value
        ? parseFloat(dayOfMonthInput.value)
        : undefined,
      daysOfWeek: daysOfWeekInput.value,
      executionTimeZone: executionTimeZoneInput.value,
      executionTime: executionTimeInput.value
    },
    {
      scheduleStartDate: startDateInput.value,
      ...(endDateInput.value ? { scheduleEndDate: endDateInput.value } : {})
    }
  );

  const executionTimeOptions = getTimeOptions(15);

  const executionTimeOption =
    executionTimeOptions.find(
      option => option.value === executionTimeInput.value
    ) || null;

  const dayOfMonthOptions = getDaysOfMonthOptions();

  const dayOfMonthOption =
    dayOfMonthOptions.find(
      (day: Option) => day.value === dayOfMonthInput.value
    ) || null;

  const formatTimeZoneGroupLabel = (
    data: GroupTypeBase<{ label: string; value: string }>
  ) => <Text appearance="h5">{data.label}</Text>;

  const formatTimeZoneOptionLabel = (data: {
    label: string;
    offset?: string;
  }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>{data.label}</span>
      <span className="timezone-offset">{data.offset}</span>
    </div>
  );

  const executionTimeZoneOption =
    timeZones
      .flatMap(obj => obj.options)
      .find(option => option.value === executionTimeZoneInput.value) || null;

  function getMessageScheduleIconColor(): string {
    if (isBlank(frequencyInput.value)) {
      return palette.green[60];
    } else if (isScheduleMessageInputsValid()) {
      return palette.purple[70];
    }
    return palette.red[60];
  }

  function isScheduleMessageInputsValid(): boolean {
    const isRequiredInputsFilled =
      !isBlank(startDateInput.value) &&
      !isBlank(endDateInput.value) &&
      !isBlank(executionTimeInput.value) &&
      !isBlank(executionTimeZoneInput.value);

    switch (frequencyInput.value) {
      case CampaignScheduleFrequency.ONCE:
      case CampaignScheduleFrequency.DAILY:
        return isRequiredInputsFilled;
      case CampaignScheduleFrequency.WEEKLY:
        return isRequiredInputsFilled && daysOfWeekInput.value.length > 0;
      case CampaignScheduleFrequency.MONTHLY:
        return isRequiredInputsFilled && !isBlank(dayOfMonthInput.value);
      default:
        return false;
    }
  }

  let requiredColumnNames: string[] = [];

  if (selectedReportColumnMapping) {
    requiredColumnNames = COLUMN_MAPPING_KEYS.map(
      key => selectedReportColumnMapping[key]
    );
  }

  const isDisabledSendBroadcast =
    !isLoadingSelectedReportColumnMapping &&
    (requiredColumnNames.length === 0 || requiredColumnNames.some(isBlank)) &&
    !isBlank(reportIDInput.value) &&
    !isBlank(userIDInput.value);

  let missingColumnNames: string[];

  if (isDisabledSendBroadcast) {
    missingColumnNames =
      requiredColumnNames.length === 0
        ? COLUMN_MAPPING_KEYS
        : requiredColumnNames.reduce(
            (accum: string[], columnName, i) =>
              columnName ? accum : [...accum, COLUMN_MAPPING_KEYS[i]],
            []
          );
  }

  let campaignSubmit = true;

  if (campaign) {
    // check if any inputs have changed
    const originalCampaign = Object.fromEntries(
      Object.entries(campaignOriginalState).map(([k, v]) => [k, v.value])
    );
    const newCampaign = Object.fromEntries(
      Object.entries(state).map(([k, v]) => [k, v.value])
    );
    campaignSubmit =
      JSON.stringify(originalCampaign) !== JSON.stringify(newCampaign);
  }

  const canSubmit =
    campaignSubmit &&
    Object.values(state).every((input): boolean => input.isValid) &&
    selectedReport &&
    !isReportMissingMergeFields &&
    !isDisabledForSelectedReport &&
    (!recurringInput.value ||
      (!isBlank(frequencyInput.value) &&
        isScheduleMessageInputsValid() &&
        recurringInput.value)) &&
    !isDisabledSendBroadcast;

  const reportOptions = reports.map(report => ({
    label: report.name,
    value: report.id
  }));

  const templateOptions = templates.map(template => ({
    label: template.name,
    value: template.content
  }));

  const userOptions = users?.map(user => ({
    label: getFullName(user),
    value: user.id,
    isDisabled:
      user.status === UserStatus.DEACTIVATED ||
      !userPermissions?.some(
        u =>
          u.userID === user.id && u.type === UserInboxPermissionType.CAN_ACCESS
      )
  }));

  const mergeFieldOptions = selectedReportColumnNames.map(columnName => ({
    label: columnName,
    value: `{{${columnName}}}`
  }));

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      {renderHeader(childView)}
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}

        {campaign &&
          userOptions?.find(option => option?.value === userIDInput.value)
            ?.isDisabled && (
            <Alert marginBottom={theme.space_stack_md} variant="warning">
              {copyText.deactivatedUserWarning}
            </Alert>
          )}

        {isNil(childView) ? renderCreateBroadcast() : renderConfigureSchedule()}
      </Layout.Body>
      {isNil(childView) ? renderFooter() : null}
    </Layout>
  );

  function renderHeader(childView: string | null): ReactElement {
    const isChildViewNull = isNil(childView);
    const handleActionKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
      e = e || window.event;
      e.preventDefault();
      if (e.key === 'Enter' || e.key === ' ') {
        handleClickBack();
      }
    };
    return (
      <Layout.Header
        flex
        alignItems="center"
        justifyContent={isChildViewNull ? 'flex-start' : 'between'}
        minHeight={theme.space_stack_xxl}
        paddingHorizontal={isChildViewNull ? theme.space_stack_md : 0}
      >
        {isChildViewNull ? (
          <Text appearance="h3" as="h1" bold>
            {campaign ? copyText.titleUpdate : copyText.title}
          </Text>
        ) : (
          <FlexItem flex alignItems="center">
            <Button
              iconStart={
                <Flex
                  tabIndex="0"
                  onKeyPress={handleActionKeyPress}
                  css={{
                    '&:focus': {
                      outline: `1px solid ${palette.blue[60]}`,
                      outlineOffset: `-1px`,
                      height: `min-content`
                    }
                  }}
                >
                  <IconChevronLeft color={palette.gray[60]} />
                </Flex>
              }
              marginHorizontal={theme.space_inline_xs}
              minimal
              size="small"
              title={copyText.closeButtonLabel}
              onClick={handleClickBack}
              aria-label={copyText.closeButtonLabel}
            />
            <Text appearance="h3" as="h1" bold>
              {campaign ? copyText.titleUpdate : copyText.title}
            </Text>
          </FlexItem>
        )}
      </Layout.Header>
    );
  }

  function renderFooter(): ReactElement {
    return (
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button
          type="reset"
          variant="grayscale"
          width="48%"
          onClick={handleReset}
          aria-label={copyText.cancelButtonLabel}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          primary
          type="submit"
          width="48%"
          justifyContent="center"
          aria-label={
            campaign ? copyText.saveButtonLabel : copyText.submitButtonLabel
          }
        >
          {isProcessing ? (
            <SVGSpinner fill={palette.white} size="2em" />
          ) : campaign ? (
            copyText.saveButtonLabel
          ) : (
            copyText.submitButtonLabel
          )}
        </Button>
      </Layout.Footer>
    );
  }

  function renderExecutionTimeAndTimeZoneInput(): ReactElement {
    return (
      <FlexItem flex justifyContent="between">
        <FormField
          label={
            <Text>
              {copyText.executionTimeOptionLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          width="48%"
        >
          <Select
            name={CreateCampaignForm.INPUT_EXECUTION_TIME}
            options={executionTimeOptions}
            placeholder={copyText.optionPlaceholder}
            searchable
            value={executionTimeOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChangeInput({
                target: {
                  name: CreateCampaignForm.INPUT_EXECUTION_TIME,
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={
            <Text>
              {copyText.executionTimeZoneLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          width="48%"
        >
          <Select
            styles={{
              groupHeading: props => ({
                ...props,
                textTransform: 'unset',
                borderBottom: `1px solid ${palette.gray[60]}`,
                marginLeft: '12px',
                marginRight: '12px',
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: '0.25rem'
              }),
              menu: props => ({
                ...props,
                borderColor: palette.gray[40],
                borderRadius: '3px',
                borderWidth: '1px',
                zIndex: 10,
                position: 'absolute',
                right: 0,
                minWidth: '300px',
                width: 'max-content'
              }),
              singleValue: () => ({
                '& .timezone-offset': {
                  display: 'none'
                }
              })
            }}
            instanceId="executionTimeZoneSelector"
            name={CreateCampaignForm.INPUT_EXECUTION_TIME_ZONE}
            menuPlacement="auto"
            searchable
            options={timeZones}
            formatGroupLabel={formatTimeZoneGroupLabel}
            formatOptionLabel={formatTimeZoneOptionLabel}
            placeholder={copyText.optionPlaceholder}
            value={executionTimeZoneOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChangeInput({
                target: {
                  name: CreateCampaignForm.INPUT_EXECUTION_TIME_ZONE,
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
      </FlexItem>
    );
  }

  function renderDatesInputs(): ReactElement {
    return (
      <FlexItem flex justifyContent="between">
        <FormField
          label={
            <Text>
              {copyText.startDateOptionLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          width={
            frequencyInput.value === CampaignScheduleFrequency.ONCE
              ? '100%'
              : '48%'
          }
        >
          <DateInput
            name={CreateCampaignForm.INPUT_START_DATE}
            defaultValue={startDateInput.value}
            placeholder={copyText.optionPlaceholder}
            minDate={new Date()}
            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
              handleChangeInput({
                target: {
                  name: CreateCampaignForm.INPUT_START_DATE,
                  value: event.target.value
                }
              } as ChangeEvent<HTMLInputElement>);
            }}
          />
        </FormField>
        {frequencyInput.value !== CampaignScheduleFrequency.ONCE ? (
          <FormField
            label={
              <Text>
                {copyText.endDateOptionLabel}
                <Asterisk color={palette.red[60]} />
              </Text>
            }
            marginTop={theme.space_stack_md}
            width="48%"
          >
            <DateInput
              name={CreateCampaignForm.INPUT_END_DATE}
              defaultValue={endDateInput.value}
              placeholder={copyText.optionPlaceholder}
              minDate={new Date(startDateInput.value)}
              placement="bottom-end"
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                handleChangeInput({
                  target: {
                    name: CreateCampaignForm.INPUT_END_DATE,
                    value: event.target.value
                  }
                } as ChangeEvent<HTMLInputElement>);
              }}
            />
          </FormField>
        ) : null}
      </FlexItem>
    );
  }

  function renderDayOfMonthInput(): ReactElement {
    return (
      <FormField
        label={
          <Text>
            {copyText.dayOfMonthOptionLabel}
            <Asterisk color={palette.red[60]} />
          </Text>
        }
        marginTop={theme.space_stack_md}
        width="48%"
      >
        <Select
          instanceId="dayOfMonthSelector"
          name={CreateCampaignForm.INPUT_DAY_OF_MONTH}
          menuPlacement="auto"
          options={dayOfMonthOptions}
          placeholder={copyText.optionPlaceholder}
          value={dayOfMonthOption}
          onChange={(option: ValueType<Option, false>): void =>
            handleChangeInput({
              target: {
                name: CreateCampaignForm.INPUT_DAY_OF_MONTH,
                value: option?.value || ''
              }
            } as ChangeEvent<HTMLInputElement>)
          }
        />
      </FormField>
    );
  }

  function renderDaysOfWeekInput(): ReactElement {
    return (
      <FormField
        label={
          <Text>
            {copyText.weekDayLabel}
            <Asterisk color={palette.red[60]} />
          </Text>
        }
        marginTop={theme.space_stack_md}
      >
        <DaysOfWeekInputGroup
          defaultValues={daysOfWeekInput.value}
          onChange={handleDaysOfWeekInputChange}
        />
      </FormField>
    );
  }

  function renderConfigureSchedule(): ReactElement {
    return (
      <>
        <FormField
          key={CreateCampaignForm.INPUT_FREQUENCY}
          label={
            <Text>
              {copyText.frequencyOptionLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
        >
          <Select
            instanceId="frequencySelector"
            isClearable
            name={CreateCampaignForm.INPUT_FREQUENCY}
            menuPlacement="auto"
            options={frequencyOptions}
            placeholder={copyText.optionPlaceholder}
            value={frequencyOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChangeInput({
                target: {
                  name: CreateCampaignForm.INPUT_FREQUENCY,
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        {!isBlank(frequencyInput.value) ? (
          <>
            {renderDatesInputs()}
            {renderExecutionTimeAndTimeZoneInput()}
            {frequencyInput.value === CampaignScheduleFrequency.MONTHLY
              ? renderDayOfMonthInput()
              : null}
            {frequencyInput.value === CampaignScheduleFrequency.WEEKLY
              ? renderDaysOfWeekInput()
              : null}
            {isScheduleMessageInputsValid() ? (
              <FlexItem bold marginTop={theme.space_stack_md}>
                <Text fontWeight={theme.fontWeight_semiBold}>
                  {copyText.scheduleSummaryLabel}
                </Text>
                <Text marginTop={theme.space_stack_sm}>
                  <Markdown>{scheduleSummary}</Markdown>
                </Text>
              </FlexItem>
            ) : null}
          </>
        ) : null}
      </>
    );
  }

  function renderCreateBroadcast(): ReactElement {
    return (
      <>
        <FormField
          key={CreateCampaignForm.INPUT_REPORT_ID}
          name={CreateCampaignForm.INPUT_REPORT_ID}
          label={
            <Text>
              {copyText.reportInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginBottom={theme.space_stack_md}
        >
          <Select
            isLoading={isLoadingReports}
            isSearchable
            options={reportOptions}
            placeholder={copyText.reportIDInputPlaceholder}
            value={reportOptions.filter(
              option => option.value === reportIDInput.value
            )}
            onChange={e =>
              handleChangeOption(CreateCampaignForm.INPUT_REPORT_ID, e)
            }
          />
        </FormField>
        {isDisabledSendBroadcast && missingColumnNames ? (
          <Alert marginBottom={theme.space_stack_md} variant="danger">
            <Text fontSize={theme.fontSize_mouse} lineHeight={1.25}>
              <Markdown>
                {copyText.sendBroadcastWarning.replace(
                  '%missingColumnNames%',
                  missingColumnNames.join(', ')
                )}
              </Markdown>
            </Text>
            <Flex justifyContent="center" marginTop={theme.space_stack_md}>
              <Button
                as={Link}
                href={`${reportsBaseURL}/${reportIDInput.value}`}
                size="small"
                width="100px"
                target="_blank"
              >
                {copyText.configureColumnMappingButtonLabel}
              </Button>
              <Button
                marginLeft={theme.space_inline_sm}
                size="small"
                width="100px"
                onClick={handleClickReloadButton}
              >
                {copyText.reloadButtonLabel}
              </Button>
            </Flex>
          </Alert>
        ) : null}
        <FormField
          name={CreateCampaignForm.INPUT_NAME}
          disabled={isDisabledForSelectedReport || isDisabledSendBroadcast}
          input={TextInput}
          label={
            <Text color={isValidInput(nameInput) ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginBottom={theme.space_stack_md}
          required
          value={nameInput.value}
          onChange={handleChangeInput}
        />
        {campaign && userOptions && (
          <FormField
            key={CreateCampaignForm.INPUT_USER_ID}
            name={CreateCampaignForm.INPUT_USER_ID}
            label={
              <Text>
                {copyText.userInputLabel}
                <Asterisk color={palette.red[60]} />
              </Text>
            }
            marginBottom={theme.space_stack_md}
          >
            <Select
              isLoading={isLoadingUsers}
              isDisabled={authenticatedUser?.role !== UserRole.ORG_ADMIN}
              isSearchable
              options={userOptions}
              placeholder={copyText.userIDInputPlaceholder}
              value={userOptions.filter(
                option => option?.value === userIDInput.value
              )}
              onChange={e =>
                handleChangeOption(CreateCampaignForm.INPUT_USER_ID, e)
              }
            />
          </FormField>
        )}
        {selectedReport?.type === ReportType.SALESFORCE_REPORT ? (
          <>
            <Text
              fontWeight={theme.fontWeight_semiBold}
              marginTop={theme.space_stack_sm}
            >
              {copyText.salesforceIntegrationOptionsHeading}
            </Text>
            <Flex
              alignItems="center"
              justifyContent="between"
              marginBottom={theme.space_stack_md}
            >
              <FormField
                name={CreateCampaignForm.INPUT_ENABLE_CONTACT_ACTIVITY_LOGGING}
                label={
                  <Text
                    fontWeight={theme.fontWeight_regular}
                    marginTop={theme.space_stack_sm}
                  >
                    {copyText.enableContactActivityLoggingInputLabel}
                  </Text>
                }
              />
              <Switch
                aria-label={copyText.enableContactActivityLoggingInputLabel}
                checked={enableContactActivityLoggingInput.value}
                checkedIcon={false}
                disabled={isDisabledContactActivityLoggingFeature}
                height={25}
                offColor={palette.gray[60]}
                onColor={palette.green[60]}
                uncheckedIcon={false}
                width={53}
                onChange={(checked): void =>
                  handleChangeInput({
                    target: {
                      name: CreateCampaignForm.INPUT_ENABLE_CONTACT_ACTIVITY_LOGGING,
                      value: String(checked)
                    }
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </Flex>
          </>
        ) : null}
        <Text bold fontSize="18px">
          {copyText.messageLabel}
        </Text>
        <Box
          borderTop={`1px solid ${palette.gray[50]}`}
          marginBottom={theme.space_stack_md}
        />
        <Select
          aria-label={copyText.templateSelectPlaceholder}
          disabled={
            reportIDInput.value === '' ||
            isDisabledForSelectedReport ||
            isDisabledSendBroadcast
          }
          isLoading={isLoadingSelectedReportColumnNames}
          isSearchable
          value={templateID.value ? templateID : null}
          menuPlacement="auto"
          options={templateOptions}
          placeholder={copyText.templateSelectPlaceholder}
          onChange={e =>
            handleChangeOption(CreateCampaignForm.INPUT_TEMPLATE_CONTENT, e)
          }
        />
        <Box marginBottom={theme.space_stack_md} />
        <Select
          aria-label={copyText.mergeFieldPlaceholder}
          disabled={
            reportIDInput.value === '' ||
            isDisabledForSelectedReport ||
            isDisabledSendBroadcast
          }
          isLoading={isLoadingSelectedReportColumnNames}
          isSearchable
          menuPlacement="auto"
          options={mergeFieldOptions}
          placeholder={copyText.mergeFieldPlaceholder}
          value={null}
          onChange={e =>
            handleChangeOption(CreateCampaignForm.INPUT_MERGE_FIELD, e)
          }
        />
        <FormField
          name={CreateCampaignForm.INPUT_CONTENT}
          disabled={isDisabledForSelectedReport || isDisabledSendBroadcast}
          input={TextArea}
          caption={
            <Flex justifyContent="end">
              <Text color={palette.gray[80]} fontSize={theme.fontSize_mouse}>
                {getContentInputCount(contentInput.value.length)}
              </Text>
            </Flex>
          }
          hideLabel
          label={copyText.contentInputLabel}
          marginTop={theme.space_stack_md}
          placeholder={copyText.contentInputPlaceholder}
          required
          resizeable={false}
          rows={6}
          value={contentInput.value}
          onChange={handleChangeInput}
        />
        {isReportMissingMergeFields ? (
          <Text
            color={palette.red[60]}
            fontSize={theme.fontSize_mouse}
            marginTop={theme.space_stack_sm}
          >
            {copyText.contentInputWarning}
          </Text>
        ) : null}

        <Text bold fontSize="18px">
          {copyText.additionalSettingsLabel}
        </Text>
        <Box
          borderTop={`1px solid ${palette.gray[50]}`}
          marginBottom={theme.space_stack_md}
        />
        <Flex
          alignItems="center"
          justifyContent="between"
          marginBottom={theme.space_stack_md}
          marginRight={0}
        >
          <FormField
            name={CreateCampaignForm.INPUT_RECURRING}
            label={
              <Text
                fontWeight={theme.fontWeight_regular}
                marginTop={theme.space_stack_sm}
              >
                {copyText.enableScheduleMessageInputLabel}
              </Text>
            }
          />
          <Switch
            aria-label={copyText.enableScheduleMessageInputLabel}
            checked={recurringInput.value}
            checkedIcon={false}
            height={25}
            offColor={palette.gray[60]}
            onColor={palette.green[60]}
            uncheckedIcon={false}
            width={53}
            disabled={
              userOptions?.find(option => option?.value === userIDInput.value)
                ?.isDisabled
            }
            onChange={(checked): void =>
              handleChangeInput({
                target: {
                  name: CreateCampaignForm.INPUT_RECURRING,
                  value: String(checked)
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </Flex>
        {recurringInput.value && (
          <>
            <Flex
              alignItems="center"
              justifyContent="between"
              marginBottom={theme.space_stack_md}
              marginRight={0}
            >
              <FormField
                name={CreateCampaignForm.INPUT_SEND_DUPLICATES}
                label={
                  <Text
                    fontWeight={theme.fontWeight_regular}
                    marginTop={theme.space_stack_sm}
                  >
                    {copyText.enableDuplicateMessagesInputLabel}
                  </Text>
                }
              />
              <Switch
                aria-label={copyText.enableDuplicateMessagesInputLabel}
                checked={sendDuplicatesInput.value}
                checkedIcon={false}
                height={25}
                offColor={palette.gray[60]}
                onColor={palette.green[60]}
                uncheckedIcon={false}
                width={53}
                onChange={(checked): void =>
                  handleChangeInput({
                    target: {
                      name: CreateCampaignForm.INPUT_SEND_DUPLICATES,
                      value: String(checked)
                    }
                  } as ChangeEvent<HTMLInputElement>)
                }
              />
            </Flex>
            <Box
              backgroundColor={palette.white}
              borderRadius={theme.borderRadius_2}
              boxShadow={`0 0 4px 0 ${palette.gray[30]}`}
              border={`0.5px solid ${palette.gray[10]}`}
              onClick={handleClickViewScheduleMessage}
              cursor="pointer"
              marginBottom={theme.space_stack_xl}
              aria-label={copyText.messageScheduledTitle}
            >
              <Flex
                alignItems="center"
                justifyContent="between"
                padding={theme.space_inset_md}
                paddingRight={theme.space_inset_sm}
              >
                <Flex alignItems="center">
                  <IconSchedule
                    color={getMessageScheduleIconColor()}
                    size={24}
                  />
                  <FlexItem
                    margin="0"
                    paddingLeft={theme.space_inset_md}
                    width="100%"
                  >
                    {!isBlank(frequencyInput.value) &&
                    isScheduleMessageInputsValid() ? (
                      <>
                        <Text fontWeight={theme.fontWeight_semiBold}>
                          {copyText.messageScheduledTitle}
                        </Text>
                        <Text>
                          <Markdown>{scheduleSummary}</Markdown>
                        </Text>
                      </>
                    ) : (
                      <>
                        <Text fontWeight={theme.fontWeight_semiBold}>
                          {copyText.scheduleMessageTitle}
                        </Text>
                        <Text>{copyText.scheduleMessageDescription}</Text>
                      </>
                    )}
                  </FlexItem>
                </Flex>
                <MinimalButton
                  iconStart={<IconChevronRight color={palette.gray[60]} />}
                  title={copyText.viewScheduleMessageLabel}
                  size="small"
                />
              </Flex>
            </Box>
          </>
        )}
      </>
    );
  }
}

function getContentInputCount(length: number): string {
  return copyText.contentInputCaption.replace(
    '%count%',
    `${MESSAGE_CONTENT_MAX_LENGTH - length}`
  );
}

function isValidInput(input: { value: string; isValid: boolean }): boolean {
  return isEmpty(input.value) || input.isValid;
}

CreateCampaignForm.INPUT_CONTENT = 'content';
CreateCampaignForm.INPUT_SEND_DUPLICATES = 'sendDuplicates';
CreateCampaignForm.INPUT_ENABLE_CONTACT_ACTIVITY_LOGGING =
  'enableContactActivityLogging';
CreateCampaignForm.INPUT_MERGE_FIELD = 'mergeField';
CreateCampaignForm.INPUT_NAME = 'name';
CreateCampaignForm.INPUT_RECURRING = 'recurring';
CreateCampaignForm.INPUT_REPORT_ID = 'reportID';
CreateCampaignForm.INPUT_TEMPLATE_CONTENT = 'templateContent';
CreateCampaignForm.INPUT_USER_ID = 'userID';

CreateCampaignForm.INPUT_DAY_OF_MONTH = 'dayOfMonth';
CreateCampaignForm.INPUT_DAYS_OF_WEEK = 'daysOfWeek';
CreateCampaignForm.INPUT_FREQUENCY = 'frequency';
CreateCampaignForm.INPUT_END_DATE = 'endDate';
CreateCampaignForm.INPUT_START_DATE = 'startDate';
CreateCampaignForm.INPUT_EXECUTION_TIME = 'executionTime';
CreateCampaignForm.INPUT_EXECUTION_TIME_ZONE = 'executionTimeZone';

CreateCampaignForm.INTERACTION_INPUT_CHANGED = `${CreateCampaignForm.name}.INTERACTION_INPUT_CHANGED`;
CreateCampaignForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${CreateCampaignForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
CreateCampaignForm.INTERACTION_RELOAD_BUTTON_CLICKED = `${CreateCampaignForm.name}.INTERACTION_RELOAD_BUTTON_CLICKED`;
CreateCampaignForm.INTERACTION_REPORT_SELECTED = `${CreateCampaignForm.name}.INTERACTION_REPORT_SELECTED`;
CreateCampaignForm.INTERACTION_USER_SELECTED = `${CreateCampaignForm.name}.INTERACTION_USER_SELECTED`;
CreateCampaignForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${CreateCampaignForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;
CreateCampaignForm.INTERACTION_UPDATE_BUTTON_CLICKED = `${CreateCampaignForm.name}.INTERACTION_UPDATE_BUTTON_CLICKED`;

export default CreateCampaignForm;
