import { RouteComponentProps, useNavigate } from '@reach/router';
import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import ButtonGroup from '@targetx/mineral-ui/ButtonGroup';
import Dropdown from '@targetx/mineral-ui/Dropdown';
import isEmpty from 'validator/lib/isEmpty';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Pagination from '@targetx/mineral-ui/Pagination';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { decodeRID } from '@targetx/tx-sms-api-lib/lib/utils/MessagingProviderUtils';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import IconEllipsisVertical from '@targetx/tx-web-ui-lib/lib/icons/IconEllipsisVertical';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import CreateInboxForm from '../../components/CreateInboxForm';
import EditInboxForm from '../../components/EditInboxForm';
import InboxList from '../../components/InboxList';
import ScreenLevelAlert from '../../components/ScreenLevelAlert';
import { SYSTEM_DEFAULT_COUNTRY_CODE } from '../../constants';
import paths from '../../constants/paths';
import {
  InboxEntity,
  InboxStatus,
  MessagingAPIKeyEntity,
  MessagingProviderType,
  MessagingServiceType,
  SettingName
} from '../../data-api/types';
import useDataAPIActivateInbox from '../../data-api/useDataAPIActivateInbox';
import useDataAPICreateInbox from '../../data-api/useDataAPICreateInbox';
import useDataAPIDeactivateInbox from '../../data-api/useDataAPIDeactivateInbox';
import useDataAPIGetInboxesByOrgID from '../../data-api/useDataAPIGetInboxesByOrgID';
import useDataAPIGetMessagingAPIKeyByOrgID from '../../data-api/useDataAPIGetMessagingAPIKeyByOrgID';
import useDataAPIGetMessagingServicesByOrgID from '../../data-api/useDataAPIGetMessagingServicesByOrgID';
import useDataAPIGetOrgSettingsByOrgID from '../../data-api/useDataAPIGetOrgSettingsByOrgID';
import useDataAPIGetTwilioMessagingServiceBySID from '../../data-api/useDataAPIGetTwilioMessagingServiceBySID';
import useDataAPIUpdateInbox from '../../data-api/useDataAPIUpdateInbox';
import SettingsScreenLayout from '../../layouts/SettingsScreenLayout';
import { bmStyles, modalStyles } from '../../styles';
import theme from '../../theme';
import { UserEntity } from '../../types';
import Interaction from '../../types/Interaction';
import copyText from './copyText';

const ERROR_ACTIVATING_INBOX = 'ERROR_ACTIVATING_INBOX';
const ERROR_CREATING_INBOX_DUPLICATE_PROVIDER_RID =
  'ERROR_CREATING_INBOX_DUPLICATE_PROVIDER_RID';
const ERROR_CREATING_INBOX_UNEXPECTED = 'ERROR_CREATING_INBOX_UNEXPECTED';
const ERROR_DEACTIVATING_INBOX = 'ERROR_DEACTIVATING_INBOX';
const ERROR_LOADING_API_KEY = 'ERROR_LOADING_API_KEY';
const ERROR_LOADING_INBOXES = 'ERROR_LOADING_INBOXES';
const ERROR_LOADING_MESSAGING_SERVICE_PHONE_NUMBERS =
  'ERROR_LOADING_MESSAGING_SERVICE_PHONE_NUMBERS';
const ERROR_LOADING_MESSAGING_SERVICES = 'ERROR_LOADING_MESSAGING_SERVICES';
const ERROR_LOADING_ORG_SETTINGS = 'ERROR_LOADING_ORG_SETTINGS';
const ERROR_UPDATING_INBOX_DUPLICATE_PROVIDER_RID =
  'ERROR_UPDATING_INBOX_DUPLICATE_PROVIDER_RID';
const ERROR_UPDATING_INBOX_UNEXPECTED = 'ERROR_UPDATING_INBOX_UNEXPECTED';

const SUCCESS_INBOX_ACTIVATED = 'SUCCESS_INBOX_ACTIVATED';
const SUCCESS_INBOX_CREATED = 'SUCCESS_INBOX_CREATED';
const SUCCESS_INBOX_DEACTIVATED = 'SUCCESS_INBOX_DEACTIVATED';
const SUCCESS_INBOX_UPDATED = 'SUCCESS_INBOX_UPDATED';

const ACTION_PANEL_CREATE_FORM = 'CREATE_FORM';
const ACTION_PANEL_EDIT_FORM = 'EDIT_FORM';

const MODAL_DEACTIVATE_CONFIRMATION = 'DEACTIVATE_CONFIRMATION';
const MODAL_DISCARD_CONFIRMATION = 'DISCARD_CONFIRMATION';

const pageSize = 15;

interface Props extends RouteComponentProps {
  authenticatedUser: UserEntity;
  homeBaseURL: string;
  signOutPath: string;
}

export function InboxManagementScreen({
  authenticatedUser,
  homeBaseURL,
  signOutPath
}: Props): ReactElement {
  const navigate = useNavigate();

  //
  // State
  //

  const [actionPanelKey, setActionPanelKey] = useState('');
  const [alertKey, setAlertKey] = useState('');
  const [editingInboxID, setEditingInboxID] = useState('');
  const [inboxEdited, setInboxEdited] = useState(false);
  const [isDisabledCreateInboxForm, setIsDisabledCreateInboxForm] =
    useState(false);
  const [modalComponentKey, setModalComponentKey] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string>(InboxStatus.ACTIVE);

  //
  // Data API
  //

  const {
    data: _inboxes,
    error: errorLoadingInboxes,
    isLoading: isLoadingInboxes,
    refetch: refetchInboxes
  } = useDataAPIGetInboxesByOrgID(authenticatedUser.orgID);

  const { data: apiKey, error: errorLoadingAPIKey } =
    useDataAPIGetMessagingAPIKeyByOrgID(authenticatedUser.orgID);

  const { data: orgSettings, error: errorLoadingOrgSettings } =
    useDataAPIGetOrgSettingsByOrgID(authenticatedUser.orgID);

  const {
    data: services,
    error: errorLoadingServices,
    isLoading: isLoadingServices,
    refetch: fetchMessagingServices
  } = useDataAPIGetMessagingServicesByOrgID();

  const {
    data: service,
    error: errorLoadingTwilioMessagingService,
    refetch: fetchTwilioMessagingService
  } = useDataAPIGetTwilioMessagingServiceBySID();

  const phoneNumbers = useMemo(
    () => (service ? service.phoneNumbers : undefined),
    [service]
  );

  const {
    data: lastActivatedInboxID,
    error: errorActivatingInbox,
    isProcessing: isActivatingInbox
  } = useDataAPIActivateInbox();

  const {
    data: lastCreatedInboxID,
    error: errorCreatingInbox,
    invoke: createInbox,
    isProcessing: isCreatingInbox
  } = useDataAPICreateInbox();

  const {
    data: lastDeactivatedInboxID,
    error: errorDeactivatingInbox,
    invoke: deactivateInbox,
    isProcessing: isDeactivatingInbox
  } = useDataAPIDeactivateInbox();

  const {
    data: lastUpdatedInboxID,
    error: errorUpdatingInbox,
    invoke: updateInbox,
    isProcessing: isUpdatingInbox
  } = useDataAPIUpdateInbox();

  //
  // Computed Values
  //

  const inboxesKeyedByID = useMemo(() => keyBy(_inboxes, 'id'), [_inboxes]);

  const countryCode = useMemo(
    () =>
      orgSettings?.find(
        setting => setting.name === SettingName.DEFAULT_COUNTRY_CODE
      )?.value ?? SYSTEM_DEFAULT_COUNTRY_CODE,
    [orgSettings]
  );

  const messagingProviderType = getMessagingProviderType(apiKey);

  let inboxes = _inboxes ?? [];

  inboxes = useMemo(
    () =>
      searchTerm.length > 0
        ? inboxes.filter((inbox): boolean =>
            inbox.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : inboxes,
    [inboxes, searchTerm]
  );

  const inboxesGroupedByStatus = useMemo(
    (): {
      [key: string]: InboxEntity[];
    } => ({
      [InboxStatus.ACTIVE]: [],
      [InboxStatus.DEACTIVATED]: [],
      ...groupBy(inboxes, 'status')
    }),
    [inboxes]
  );

  inboxes = inboxesGroupedByStatus[statusFilter];

  const totalInboxes = inboxes.length;

  inboxes = useMemo(
    () =>
      inboxes.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize),
    [inboxes, pageNumber]
  );

  //
  // Side Effects
  //

  useEffect(() => {
    if (!lastActivatedInboxID) return;
    setActionPanelKey('');
    setAlertKey(SUCCESS_INBOX_ACTIVATED);
    setEditingInboxID('');
  }, [lastActivatedInboxID]);

  useEffect(() => {
    if (!lastCreatedInboxID) return;
    setActionPanelKey('');
    setAlertKey(SUCCESS_INBOX_CREATED);
  }, [lastCreatedInboxID]);

  useEffect(() => {
    if (!lastDeactivatedInboxID) return;
    setActionPanelKey('');
    setAlertKey(SUCCESS_INBOX_DEACTIVATED);
    setEditingInboxID('');
    setModalComponentKey('');
  }, [lastDeactivatedInboxID]);

  useEffect(() => {
    if (!lastUpdatedInboxID) return;
    setActionPanelKey('');
    setAlertKey(SUCCESS_INBOX_UPDATED);
    setEditingInboxID('');
  }, [lastUpdatedInboxID]);

  useEffect(() => {
    if (!actionPanelKey) return;
    fetchMessagingServices(authenticatedUser.orgID, {
      isConnectedToInbox: false
    });
  }, [actionPanelKey]);

  useEffect(() => {
    if (!alertKey.startsWith('SUCCESS_')) return;
    refetchInboxes(authenticatedUser.orgID);
  }, [alertKey]);

  useEffect(() => {
    if (!services || services.length > 0) return;
    setIsDisabledCreateInboxForm(true);
  }, [services]);

  useEffect(() => {
    if (!errorActivatingInbox) return;
    setAlertKey(ERROR_ACTIVATING_INBOX);
  }, [errorActivatingInbox]);

  useEffect(() => {
    if (!errorCreatingInbox) return;
    setAlertKey(
      errorCreatingInbox.reason === 'DUPLICATE_PROVIDER_RID'
        ? ERROR_CREATING_INBOX_DUPLICATE_PROVIDER_RID
        : ERROR_CREATING_INBOX_UNEXPECTED
    );
  }, [errorCreatingInbox]);

  useEffect(() => {
    if (!errorDeactivatingInbox) return;
    setAlertKey(ERROR_DEACTIVATING_INBOX);
    setModalComponentKey('');
  }, [errorDeactivatingInbox]);

  useEffect(() => {
    if (!errorLoadingAPIKey) return;
    setAlertKey(ERROR_LOADING_API_KEY);
  }, [errorLoadingAPIKey]);

  useEffect(() => {
    if (!errorLoadingInboxes) return;
    setAlertKey(ERROR_LOADING_INBOXES);
  }, [errorLoadingInboxes]);

  useEffect(() => {
    if (!errorLoadingTwilioMessagingService) return;
    setAlertKey(ERROR_LOADING_MESSAGING_SERVICE_PHONE_NUMBERS);
  }, [errorLoadingTwilioMessagingService]);

  useEffect(() => {
    if (!errorLoadingOrgSettings) return;
    setAlertKey(ERROR_LOADING_ORG_SETTINGS);
  }, [errorLoadingOrgSettings]);

  useEffect(() => {
    if (!errorLoadingServices) return;
    setAlertKey(ERROR_LOADING_MESSAGING_SERVICES);
  }, [errorLoadingServices]);

  useEffect(() => {
    if (!errorUpdatingInbox) return;
    setAlertKey(
      errorUpdatingInbox.reason === 'DUPLICATE_PROVIDER_RID'
        ? ERROR_UPDATING_INBOX_DUPLICATE_PROVIDER_RID
        : ERROR_UPDATING_INBOX_UNEXPECTED
    );
  }, [errorUpdatingInbox]);

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;

    setActionPanelKey('');
    setAlertKey('');
    setEditingInboxID('');
    setIsDisabledCreateInboxForm(false);
  }

  function handleActionPanelClose() {
    switch (actionPanelKey) {
      case ACTION_PANEL_CREATE_FORM:
      case ACTION_PANEL_EDIT_FORM:
        if (inboxEdited) {
          setModalComponentKey(MODAL_DISCARD_CONFIRMATION);
        } else {
          handleChangeActionPanelState({ isOpen: false });
        }

        break;
      default:
        handleChangeActionPanelState({ isOpen: false });
    }
  }

  const handleActionPanelKeyDown = useCallback(
    e => {
      e = e || window.event;
      if (e.key === 'Escape') {
        handleActionPanelClose();
      }
    },
    [actionPanelKey, inboxEdited]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleActionPanelKeyDown);
    return () => {
      window.removeEventListener('keydown', handleActionPanelKeyDown);
    };
  }, [handleActionPanelKeyDown]);

  function handleClickRootContainer(): void {
    if (
      ['', ERROR_LOADING_INBOXES, ERROR_LOADING_MESSAGING_SERVICES].includes(
        alertKey
      )
    ) {
      return;
    }

    setAlertKey('');
  }

  function handleClickStatusFilter(event: ChangeEvent<HTMLInputElement>): void {
    setPageNumber(1);
    setStatusFilter(event.target.value);
  }

  function handleCloseModal(): void {
    if (actionPanelKey === '') setEditingInboxID('');

    setModalComponentKey('');
  }

  function handleConfirmModal(): void {
    setActionPanelKey('');
    setModalComponentKey('');
    setEditingInboxID('');
    setIsDisabledCreateInboxForm(false);
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case CreateInboxForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        if (inboxEdited) {
          setModalComponentKey(MODAL_DISCARD_CONFIRMATION);
        } else {
          setActionPanelKey('');
          setIsDisabledCreateInboxForm(false);
        }
        return;
      }
      case CreateInboxForm.INTERACTION_INBOX_EDITED: {
        setInboxEdited(data.edited);
        return;
      }
      case CreateInboxForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        createInbox({
          orgID: authenticatedUser.orgID,
          providerRID: data.providerRID,
          name: data.name,
          attributes: data.attributes
        });
        return;
      }
      case EditInboxForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        if (inboxEdited) {
          setModalComponentKey(MODAL_DISCARD_CONFIRMATION);
        } else {
          setActionPanelKey('');
          setEditingInboxID('');
          setIsDisabledCreateInboxForm(false);
        }
        return;
      }
      case EditInboxForm.INTERACTION_INBOX_EDITED: {
        setInboxEdited(data.edited);
        return;
      }
      case EditInboxForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateInbox(data.inboxID, {
          providerRID: data.providerRID,
          name: data.name,
          attributes: data.attributes
        });
        return;
      }
      case InboxList.INTERACTION_ITEM_CLICKED: {
        const { serviceID, serviceType } = decodeRID(data.providerRID);
        if (
          serviceID &&
          serviceType === MessagingServiceType.TWILIO_MESSAGING_SERVICE
        ) {
          fetchTwilioMessagingService(serviceID);
        }

        setActionPanelKey(ACTION_PANEL_EDIT_FORM);
        setEditingInboxID(data.inboxID);
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        setSearchTerm('');
        setShowSearchInput(false);
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        setPageNumber(1);
        setSearchTerm(data.searchTerm);
        return;
      }
    }
  }

  //
  // Render
  //

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_CREATE_FORM:
        return (
          <CreateInboxForm
            isDisabled={isDisabledCreateInboxForm}
            isLoadingServices={isLoadingServices}
            isProcessing={isCreatingInbox}
            message={renderAlert()}
            messagingProviderType={messagingProviderType}
            services={services ?? []}
            onInteraction={handleInteraction}
          />
        );
      case ACTION_PANEL_EDIT_FORM:
        const thisInbox = inboxesKeyedByID[editingInboxID];
        const inboxPhoneNumbers = isEmpty(thisInbox.providerRID)
          ? []
          : phoneNumbers;
        return (
          <EditInboxForm
            countryCode={countryCode}
            inbox={thisInbox}
            isLoadingServices={isLoadingServices}
            isProcessing={
              isActivatingInbox || isDeactivatingInbox || isUpdatingInbox
            }
            message={renderAlert()}
            messagingProviderType={messagingProviderType}
            phoneNumbers={inboxPhoneNumbers ?? []}
            renderHeaderMenu={renderMenu}
            services={services ?? []}
            onInteraction={handleInteraction}
          />
        );

      default:
        return null;
    }
  }

  function renderAlert(): ReactNode {
    if (
      ![
        ERROR_ACTIVATING_INBOX,
        ERROR_CREATING_INBOX_DUPLICATE_PROVIDER_RID,
        ERROR_CREATING_INBOX_UNEXPECTED,
        ERROR_DEACTIVATING_INBOX,
        ERROR_LOADING_MESSAGING_SERVICES,
        ERROR_LOADING_MESSAGING_SERVICE_PHONE_NUMBERS,
        ERROR_UPDATING_INBOX_DUPLICATE_PROVIDER_RID,
        ERROR_UPDATING_INBOX_UNEXPECTED
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton={alertKey !== ERROR_LOADING_MESSAGING_SERVICES}
        title={copyText.ERROR_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderMenu({ inboxID }: { inboxID: string }): ReactElement {
    function handleClickDeactivate(): void {
      setEditingInboxID(inboxID);
      setModalComponentKey(MODAL_DEACTIVATE_CONFIRMATION);
    }

    function handleClickManageUsers(): void {
      navigate(paths.settingsInboxUsers.replace(':inboxID', inboxID));
    }

    const menuOptions = [
      {
        text: copyText.actionMenuItemManageUsers,
        onClick: handleClickManageUsers
      }
    ];

    switch (statusFilter) {
      case InboxStatus.ACTIVE:
        menuOptions.push({
          text: copyText.actionMenuItemDeactivateInbox,
          onClick: handleClickDeactivate
        });
        break;
      default:
        break;
    }

    return (
      <Dropdown data={menuOptions} placement="bottom-end">
        <MinimalButton
          aria-label={copyText.openMenuButtonLabel}
          iconStart={<IconEllipsisVertical color={palette.gray[60]} />}
          size="small"
          type="button"
        />
      </Dropdown>
    );
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_DISCARD_CONFIRMATION: {
        return (
          <ConfirmationDialog
            message={copyText.discardBody}
            title={copyText.discardTitle}
            variant="warning"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        );
      }
      case MODAL_DEACTIVATE_CONFIRMATION: {
        const editingInbox = inboxesKeyedByID[editingInboxID];

        return (
          <ConfirmationDialog
            message={copyText.deactivateConfirmationMessage.replace(
              '%inboxName%',
              editingInbox.name
            )}
            title={copyText.deactivateConfirmationTitle}
            variant="danger"
            onCancel={handleCloseModal}
            onConfirm={() => deactivateInbox(editingInbox)}
          />
        );
      }
      default:
        return null;
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        ERROR_ACTIVATING_INBOX,
        ERROR_DEACTIVATING_INBOX,
        ERROR_LOADING_API_KEY,
        ERROR_LOADING_INBOXES,
        ERROR_LOADING_ORG_SETTINGS,
        SUCCESS_INBOX_ACTIVATED,
        SUCCESS_INBOX_CREATED,
        SUCCESS_INBOX_DEACTIVATED,
        SUCCESS_INBOX_UPDATED
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={get(copyText, `${alertKey}_message`) || ''}
        showCloseButton={alertKey !== ERROR_LOADING_INBOXES}
      />
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: copyText.title, color: palette.gray[100] }
  ];

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={actionPanelKey.length > 0}
        right
        styles={bmStyles}
        width={500}
        onClose={handleActionPanelClose}
        onStateChange={handleChangeActionPanelState}
        customOnKeyDown={handleActionPanelKeyDown}
      >
        {renderActionPanel()}
      </Menu>
      <SettingsScreenLayout
        authenticatedUser={authenticatedUser}
        blur={actionPanelKey.length > 0 ? 1 : undefined}
        currentPath={paths.settingsInboxes}
        homeBaseURL={homeBaseURL}
        signOutPath={signOutPath}
      >
        <SettingsScreenLayout.Header>
          <Breadcrumb items={breadcrumbItems} />
          <FlexItem flex>
            {showSearchInput ? (
              <SearchInput
                aria-label={copyText.searchInputLabel}
                name="searchTerm"
                clearable
                onInteraction={handleInteraction}
              />
            ) : (
              <Button
                aria-label={copyText.searchToggleButtonLabel}
                iconStart={<IconSearch color={palette.gray[60]} />}
                minimal
                onClick={() => setShowSearchInput(true)}
              />
            )}
            <Button
              aria-label={copyText.createButtonLabel}
              iconStart={<IconPlus color={palette.green[60]} />}
              marginLeft={theme.space_inline_xs}
              minimal
              onClick={() => setActionPanelKey(ACTION_PANEL_CREATE_FORM)}
            />
          </FlexItem>
        </SettingsScreenLayout.Header>
        <SettingsScreenLayout.Body>
          {renderScreenAlert()}
          <Flex marginBottom={theme.space_stack_lg} justifyContent="center">
            <ButtonGroup
              aria-label={copyText.inboxFilterLabel}
              defaultChecked={0}
              mode="radio"
              size="medium"
              onClick={handleClickStatusFilter}
            >
              <Button value={InboxStatus.ACTIVE} width="105px">
                {`${copyText.activeInboxesFilterLabel} (${
                  inboxesGroupedByStatus[InboxStatus.ACTIVE].length
                })`}
              </Button>
              <Button value={InboxStatus.DEACTIVATED} width="105px">
                {`${copyText.inactiveInboxesFilterLabel} (${
                  inboxesGroupedByStatus[InboxStatus.DEACTIVATED].length
                })`}
              </Button>
            </ButtonGroup>
          </Flex>
          <InboxList
            isLoading={isLoadingInboxes}
            inboxes={inboxes}
            renderItemMenu={renderMenu}
            onInteraction={handleInteraction}
          />
          {totalInboxes > pageSize ? (
            <Box
              alignSelf="end"
              marginVertical={theme.space_stack_md}
              width="100%"
            >
              <Pagination
                currentPage={pageNumber}
                onPageChange={setPageNumber}
                pageSize={pageSize}
                totalCount={totalInboxes}
              />
            </Box>
          ) : null}
        </SettingsScreenLayout.Body>
      </SettingsScreenLayout>
    </Box>
  );
}

function getMessagingProviderType(
  apiKey?: MessagingAPIKeyEntity
): MessagingProviderType | undefined {
  if (!apiKey) return undefined;

  const messagingProviderType = apiKey.providerRID.split(
    '/'
  )[0] as MessagingProviderType;

  return messagingProviderType;
}

export default InboxManagementScreen;
