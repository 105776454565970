/* eslint-disable @typescript-eslint/no-explicit-any */

import md5 from 'blueimp-md5';

export function toUUID(string: string): string {
  return format(md5(string));
}

export default toUUID;

function format(md5Hash: string): string {
  /*
   The code in this function is fully credited to Carmine M. DiMascio, an open-source developer
   https://github.com/cdimascio
   https://github.com/cdimascio/md5-to-uuid/blob/master/LICENSE
   https://github.com/cdimascio/md5-to-uuid/blob/master/lib/index.js
  */
  return (
    md5Hash.substring(0, 8) +
    '-' +
    md5Hash.substring(8, 12) +
    '-' +
    md5Hash.substring(12, 16) +
    '-' +
    md5Hash.substring(16, 20) +
    '-' +
    md5Hash.substring(20)
  ).toLowerCase();
}
