import { RouteComponentProps, useNavigate } from '@reach/router';
import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import IconChevronDown from '@targetx/tx-web-ui-lib/lib/icons/IconChevronDown';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import IconCog from '@targetx/tx-web-ui-lib/lib/icons/IconCog';
import IconPrinter from '@targetx/tx-web-ui-lib/lib/icons/IconPrinter';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import { noop } from 'lodash';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import { formatE164 } from 'phoneformat.js';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import { CSVLink } from 'react-csv';
import ContactInfoComponent from '../../components/ContactInfoComponent';
import MessageTranscriptComponent from '../../components/MessageTranscriptComponent';
import ScreenLevelAlert from '../../components/ScreenLevelAlert';
import paths from '../../constants/paths';
import { UserInboxPermissionType } from '../../data-api/types';
import useDataAPIGetContactByRID from '../../data-api/useDataAPIGetContactByRID';
import useDataAPIGetContactMappingsByPhoneNumbers from '../../data-api/useDataAPIGetContactMappingsByPhoneNumbers';
import useDataAPIGetInboxesByIDs from '../../data-api/useDataAPIGetInboxesByIDs';
import useDataAPIGetMessagesByConversationID from '../../data-api/useDataAPIGetMessagesByConversationID';
import useDataAPIGetMessageSendersByInboxID from '../../data-api/useDataAPIGetMessageSendersByInboxID';
import useDataAPIGetOrgSettingsByOrgID from '../../data-api/useDataAPIGetOrgSettingsByOrgID';
import useDataAPIGetUserPermissionsByUserID from '../../data-api/useDataAPIGetUserPermissionsByUserID';
import { UserEntity as AuthenticatedUserEntity } from '../../types';
import { getFullName as getContactMappingFullName } from '../../utils/ContactMappingUtils';
import { getFullName as getContactFullName } from '../../utils/ContactRecordUtils';
import toUUID from '../../utils/toUUID';
import { getFullName as getUserFullName } from '../../utils/UserUtils';
import copyText from './copyText';

const ERROR_LOADING_CONTACT = 'ERROR_LOADING_CONTACT';
const ERROR_LOADING_CONTACT_MAPPINGS = 'ERROR_LOADING_CONTACT_MAPPINGS';
const ERROR_LOADING_INBOXES = 'ERROR_LOADING_INBOXES';
const ERROR_LOADING_MESSAGES = 'ERROR_LOADING_MESSAGES';
const ERROR_LOADING_ORG_SETTINGS = 'ERROR_LOADING_ORG_SETTINGS';
const ERROR_LOADING_PERMISSIONS = 'ERROR_LOADING_PERMISSIONS';
const ERROR_LOADING_USERS = 'ERROR_LOADING_USERS';

const SETTING_NAME_DEFAULT_COUNTRY_CODE = 'defaultCountryCode';
const SYSTEM_DEFAULT_COUNTRY_CODE = 'US';

export interface Props extends RouteComponentProps {
  authenticatedUser: AuthenticatedUserEntity;
  homeBaseURL: string;
  phoneNumber?: string;
  signOutPath: string;
}

export function MessageTranscriptScreen({
  authenticatedUser,
  homeBaseURL,
  phoneNumber: _phoneNumber,
  signOutPath
}: Props): ReactElement {
  const navigate = useNavigate();

  //
  // State
  //

  const [alertKey, setAlertKey] = useState('');
  const [selectedInboxID, setSelectedInboxID] = useState('');

  //
  // Data API
  //

  const { data: permissions, error: errorLoadingPermissions } =
    useDataAPIGetUserPermissionsByUserID(authenticatedUser.id);

  const inboxIDs = useMemo(
    () =>
      permissions
        ? permissions.reduce((inboxIDs: string[], permission) => {
            return permission.type === UserInboxPermissionType.CAN_ACCESS
              ? [...inboxIDs, permission.targetID]
              : inboxIDs;
          }, [])
        : undefined,
    [permissions]
  );

  const {
    data: _inboxes,
    isLoading: isLoadingInboxes,
    error: errorLoadingInboxes
  } = useDataAPIGetInboxesByIDs(inboxIDs);

  const inboxes = useMemo(
    () => (_inboxes ? orderBy(_inboxes, 'name') : undefined),
    [_inboxes]
  );

  const { data: orgSettings, error: errorLoadingOrgSettings } =
    useDataAPIGetOrgSettingsByOrgID(authenticatedUser.orgID);

  const countryCode = useMemo(
    () =>
      orgSettings?.find(
        setting => setting.name === SETTING_NAME_DEFAULT_COUNTRY_CODE
      )?.value ?? SYSTEM_DEFAULT_COUNTRY_CODE,
    [orgSettings]
  );

  const phoneNumber = useMemo(
    () => (_phoneNumber ? decodeURIComponent(_phoneNumber) : ''),
    [_phoneNumber]
  );

  const canonicalPhoneNumbers = useMemo(
    () => (orgSettings ? [formatE164(countryCode, phoneNumber)] : undefined),
    [orgSettings, phoneNumber]
  );

  const { data: contactMappings, error: errorLoadingContactMappings } =
    useDataAPIGetContactMappingsByPhoneNumbers(canonicalPhoneNumbers);

  // TODO: Figure out what to do if multiple contact mappings exist.
  const rid = useMemo(
    () =>
      contactMappings && contactMappings.length > 0
        ? contactMappings[0].contactRID
        : undefined,
    [contactMappings]
  );

  const {
    data: contact,
    isLoading: isLoadingContact,
    error: errorLoadingContact
  } = useDataAPIGetContactByRID(rid);

  const conversationID = useMemo(
    () =>
      selectedInboxID
        ? toUUID(`${selectedInboxID}/${formatE164(countryCode, phoneNumber)}`)
        : undefined,
    [selectedInboxID]
  );

  const {
    data: _messages,
    isLoading: isLoadingMessages,
    error: errorLoadingMessages
  } = useDataAPIGetMessagesByConversationID(conversationID);

  const { data: users, error: errorLoadingUsers } =
    useDataAPIGetMessageSendersByInboxID(selectedInboxID);

  //
  // Side Effects
  //

  useEffect(() => {
    if (!errorLoadingContact) return;
    setAlertKey(ERROR_LOADING_CONTACT);
  }, [errorLoadingContact]);

  useEffect(() => {
    if (!errorLoadingContactMappings) return;
    setAlertKey(ERROR_LOADING_CONTACT_MAPPINGS);
  }, [errorLoadingContactMappings]);

  useEffect(() => {
    if (!errorLoadingInboxes) return;
    setAlertKey(ERROR_LOADING_INBOXES);
  }, [errorLoadingInboxes]);

  useEffect(() => {
    if (
      !errorLoadingMessages ||
      errorLoadingMessages.reason === 'CONVERSATION_NOT_FOUND'
    ) {
      return;
    }

    setAlertKey(ERROR_LOADING_MESSAGES);
  }, [errorLoadingMessages]);

  useEffect(() => {
    if (!errorLoadingOrgSettings) return;
    setAlertKey(ERROR_LOADING_ORG_SETTINGS);
  }, [errorLoadingOrgSettings]);

  useEffect(() => {
    if (!errorLoadingPermissions) return;
    setAlertKey(ERROR_LOADING_PERMISSIONS);
  }, [errorLoadingPermissions]);

  useEffect(() => {
    if (!errorLoadingUsers) return;
    setAlertKey(ERROR_LOADING_USERS);
  }, [errorLoadingUsers]);

  //
  // Computed Values
  //

  // TODO: Discuss best way to handle multiple contacts tied to the same phone number.
  const contactMappingsKeyedByPhoneNumber = useMemo(
    () => keyBy(contactMappings, 'canonicalPhoneNumber'),
    [contactMappings]
  );

  const inboxesKeyedByID = useMemo(() => keyBy(inboxes, 'id'), [inboxes]);
  const usersKeyedByID = useMemo(() => keyBy(users, 'id'), [users]);

  const messages = useMemo(
    () =>
      _messages && users
        ? _messages.map(message => {
            const contactMapping =
              contactMappingsKeyedByPhoneNumber[message.canonicalPhoneNumber];

            return {
              ...message,
              ...(contactMapping ? { contactMapping } : {}),
              ...(message.userID
                ? { user: usersKeyedByID[message.userID] }
                : {})
            };
          })
        : undefined,
    [_messages, users]
  );

  const selectedInbox = inboxesKeyedByID[selectedInboxID];

  const csvData = useMemo(
    () =>
      messages
        ? messages.map(message => {
            let Name = message.canonicalPhoneNumber;

            if (message.user) {
              Name = getUserFullName(message.user);
            } else if (message.contactMapping) {
              Name = getContactMappingFullName(message.contactMapping);
            }

            return {
              Time: dateFormat(message.timeCreated, 'mm/dd/yy hh:MM:ss TT'),
              Name,
              Content: message.content
            };
          })
        : undefined,
    [messages]
  );

  //
  // Interaction Handlers
  //

  function handleClickRootContainer(): void {
    if (
      ['', ERROR_LOADING_INBOXES, ERROR_LOADING_PERMISSIONS].includes(alertKey)
    ) {
      return;
    }

    setAlertKey('');
  }

  //
  // Render
  //

  function renderContactInfoComponent(): ReactNode {
    if (isLoadingContact) {
      return (
        <Flex alignItems="center" height="50%" justifyContent="center">
          <SVGSpinner size="2.5em" />
        </Flex>
      );
    }

    const salesforceInstanceURL = get(
      authenticatedUser,
      'salesforceProfile.instance_url'
    );

    return (
      <ContactInfoComponent
        canonicalPhoneNumber={formatE164(countryCode, phoneNumber)}
        contact={contact}
        salesforceInstanceURL={salesforceInstanceURL}
      />
    );
  }

  // TODO: Figoure out how to deal with Messages flicker on load.
  function renderInboxList(): ReactElement {
    if (!inboxes || isLoadingInboxes) {
      return (
        <Flex
          alignItems="center"
          justifyContent="center"
          padding={theme.space_inset_md}
        >
          <SVGSpinner size="2.5em" />
        </Flex>
      );
    }

    if (inboxes.length === 0) {
      return <Text align="center">{copyText.noInboxesLabel}</Text>;
    }

    return (
      <>
        {inboxes.map((inbox, i) => (
          <Box key={inbox.id}>
            <Flex
              alignItems="center"
              backgroundColor={palette.white}
              borderBottom={
                i < inboxes.length - 1 ? `1px solid ${palette.gray[40]}` : null
              }
              borderRadius={getBorderRadius(inboxes.length, i)}
              justifyContent="between"
              padding={theme.space_inset_md}
            >
              <Flex
                alignItems="center"
                cursor="pointer"
                width="100%"
                onClick={() =>
                  setSelectedInboxID(
                    inbox.id === selectedInboxID ? '' : inbox.id
                  )
                }
              >
                {selectedInboxID === inbox.id ? (
                  <IconChevronDown color={palette.gray[60]} />
                ) : (
                  <IconChevronRight color={palette.gray[60]} />
                )}
                <Text
                  color={palette.gray[80]}
                  fontSize={theme.fontSize_prose}
                  fontWeight={theme.fontWeight_semiBold}
                  marginLeft={theme.space_inline_sm}
                >
                  {inbox.name}
                </Text>
              </Flex>
              <CSVLink
                data={csvData ?? []}
                filename={`tx-sms-${phoneNumber}-${
                  selectedInbox?.name
                }-${dateFormat(new Date(), 'fullDate')}.csv`}
              >
                <Button
                  aria-label={copyText.downloadButtonLabel}
                  disabled={selectedInboxID !== inbox.id}
                  iconStart={<IconPrinter color={palette.green[60]} />}
                  marginLeft={theme.space_inline_xs}
                  minimal
                  size="small"
                />
              </CSVLink>
            </Flex>
            {selectedInboxID === inbox.id ? (
              <Box
                borderBottom={
                  i === inboxes.length - 1
                    ? null
                    : `1px solid ${palette.gray[40]}`
                }
                borderTop={
                  i === inboxes.length - 1
                    ? `1px solid ${palette.gray[40]}`
                    : null
                }
                maxHeight={1000}
                padding={theme.space_inset_md}
                scrollable
              >
                <MessageTranscriptComponent
                  isLoadingMessages={isLoadingMessages}
                  messages={messages ?? []}
                />
              </Box>
            ) : null}
          </Box>
        ))}
      </>
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: copyText.title },
    {
      label: contact ? getContactFullName(contact) : '...',
      color: palette.gray[100]
    }
  ];

  return (
    <Box onClick={handleClickRootContainer}>
      {alertKey ? (
        <ScreenLevelAlert
          alertKey={alertKey}
          message={get(copyText, `${alertKey}_message`) || ''}
          showCloseButton={![ERROR_LOADING_INBOXES].includes(alertKey)}
        />
      ) : null}
      <Layout height="100vh">
        <Layout.Body flex>
          <SideNav
            authenticatedUser={authenticatedUser}
            homeBaseURL={homeBaseURL}
            justifyContent="end"
            signOutPath={signOutPath}
          >
            <SideNav.Item
              href={paths.settings}
              label={copyText.settingsLinkLabel}
              onClick={(path: string) => (navigate ? navigate(path) : noop)}
            >
              <IconCog color={palette.gray[40]} size={24} />
            </SideNav.Item>
          </SideNav>
          <Layout height="100vh" width="100%">
            <Layout.Header
              alignItems="center"
              flex
              minHeight={theme.space_stack_xxl}
              paddingLeft={theme.space_inline_lg}
              paddingVertical={theme.space_stack_sm}
            >
              <Breadcrumb items={breadcrumbItems} />
            </Layout.Header>
            <Layout.Body
              backgroundColor={palette.gray[20]}
              flex
              padding={theme.space_inset_lg}
              scrollable
            >
              <Box
                backgroundColor={palette.white}
                borderRadius="8px"
                boxShadow="0 1px 8px 0 rgba(0,0,0,0.10)"
                height="fit-content"
                width="100%"
              >
                {renderInboxList()}
              </Box>
              <Box
                backgroundColor={palette.white}
                borderRadius="8px"
                boxShadow="0 1px 8px 0 rgba(0,0,0,0.10)"
                marginLeft={theme.space_inline_lg}
                minWidth={350}
                paddingHorizontal={theme.space_inline_md}
                paddingVertical={theme.space_stack_lg}
                width={350}
              >
                {renderContactInfoComponent()}
              </Box>
            </Layout.Body>
          </Layout>
        </Layout.Body>
      </Layout>
    </Box>
  );
}

function getBorderRadius(length: number, index: number): string {
  if (index === 0) return '8px 8px 0 0';

  if (index === length - 1) return '0 0 8px 8px';

  return '';
}

export default MessageTranscriptScreen;
