/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  InboxStatus,
  MessagingProviderType,
  MessagingServiceType
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import { decodeRID } from '@targetx/tx-sms-api-lib/lib/utils/MessagingProviderUtils';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import LoadingComponent from '@targetx/tx-web-ui-lib/lib/components/LoadingComponent';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import IconUsers from '@targetx/tx-web-ui-lib/lib/icons/IconUsers';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import noop from 'lodash.noop';
import { formatLocal } from 'phoneformat.js';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import Switch from 'react-switch';
import DispatcherContext from '../DispatcherContext';
import { ValueType } from 'react-select/src/types';
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import isNil from 'lodash.isnil';
import styleProps from '../styles/props';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './EditInboxForm.copyText';
import EmailRecipientsForm from './EmailRecipientsForm';
import {
  InboxUsersQueryByInboxID,
  InboxUsersQueryFailure,
  InboxUsersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxUsersQuery';
import { PartialState } from '../types/PartialState';
import { BaseUserEntity } from '../types';
import { getFullName } from '../utils/UserUtils';

const UNICODE_BULLET = '\u2022';
export namespace EditInboxForm {
  export interface InboxEntity {
    id: string;
    providerRID: string;
    name: string;
    status?: string;
    attributes?: { [key: string]: any };
  }

  export interface ServiceEntity {
    providerRID: string;
    name: string;
  }

  export interface Props {
    countryCode: string;
    inbox: InboxEntity;
    isLoadingServices?: boolean;
    isProcessing?: boolean;
    message?: ReactNode;
    messagingProviderType?: MessagingProviderType;
    phoneNumbers: string[];
    renderHeaderMenu?: ({ inboxID }: { inboxID: string }) => ReactNode;
    services: ServiceEntity[];
    onInteraction?: InteractionDelegate;
  }
}

interface Option {
  label: string;
  value: string;
}

interface InputState {
  additionalRecipientsInput: {
    value: string;
    isValid: boolean;
    hasChanged: boolean;
  };
  inboxRecipientsInput: {
    value: BaseUserEntity['id'][];
    isValid: boolean;
    hasChanged: boolean;
  };
  emailNotificationsInput: {
    value: boolean;
    isValid: boolean;
    hasChanged: boolean;
  };
  nameInput: { value: string; isValid: boolean; hasChanged: boolean };
  notificationTimerInput: {
    value: string;
    isValid: boolean;
    hasChanged: boolean;
  };
  providerRIDInput: { value: string; isValid: boolean; hasChanged: boolean };
}

interface State {
  isLoadingUsers: boolean;
  users: BaseUserEntity[];
  viewingRecipientsList: boolean;
}

export function EditInboxForm({
  countryCode,
  inbox,
  isLoadingServices,
  isProcessing,
  message,
  messagingProviderType,
  phoneNumbers,
  renderHeaderMenu,
  services: _services,
  onInteraction = noop
}: EditInboxForm.Props): ReactElement {
  const initialInputState: InputState = {
    additionalRecipientsInput: {
      value: inbox.attributes?.additionalRecipients || '',
      isValid: true,
      hasChanged: false
    },
    inboxRecipientsInput: {
      value: inbox.attributes?.inboxRecipients || [],
      isValid: true,
      hasChanged: false
    },
    emailNotificationsInput: {
      value: !!inbox.attributes?.emailNotifications,
      isValid: true,
      hasChanged: false
    },
    nameInput: { value: inbox.name, isValid: true, hasChanged: false },
    notificationTimerInput: {
      value: inbox.attributes?.timeNotifications,
      isValid: true,
      hasChanged: false
    },
    providerRIDInput: {
      value: inbox.providerRID,
      isValid: true,
      hasChanged: false
    }
  };

  const initialState: State = {
    isLoadingUsers: true,
    users: [],
    viewingRecipientsList: false
  };

  const [inputState, setInputState] = useState<InputState>(initialInputState);
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    additionalRecipientsInput,
    inboxRecipientsInput,
    emailNotificationsInput,
    nameInput,
    providerRIDInput,
    notificationTimerInput
  } = inputState;

  const { isLoadingUsers, users, viewingRecipientsList } = state;

  const services = [
    {
      providerRID: inbox.providerRID,
      name: get(inbox, 'attributes.friendlyName', '')
    },
    ..._services
  ];

  const dispatcher = useContext(DispatcherContext);

  const servicesKeyedByProviderRID = keyBy(services, 'providerRID');

  const canSubmit =
    Object.values(inputState).every((input): boolean => input.isValid) &&
    Object.values(inputState).some((input): boolean => input.hasChanged);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    let value: boolean | string | string[] = event.target.value;

    let isValid = false;

    switch (name) {
      case 'emailNotifications':
        isValid = true;
        value = value === 'true';
        break;
      case 'name':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case 'additionalRecipients':
        isValid = true;

        if (value.length > 255) {
          isValid = false;
          break;
        }

        const emails = value.split(/[ ,;]+/);
        for (const email of emails) {
          if (email && !isEmail(email)) {
            isValid = false;
            break;
          }
        }
        break;
      case 'inboxRecipients':
        isValid = true;
        let inboxRecipientsClone = [...inboxRecipientsInput.value];
        const checked = event.target.checked;
        if (inboxRecipientsInput.value.includes(value) && !checked) {
          inboxRecipientsClone = [
            ...inboxRecipientsInput.value.filter(email => email !== value)
          ];
        } else if (!inboxRecipientsInput.value.includes(value) && checked) {
          inboxRecipientsClone.push(value);
        }
        value = [...inboxRecipientsClone];
        break;
      default:
        break;
    }

    const hasChanged =
      initialInputState[`${name}Input` as keyof InputState].value !== value;

    setInputState(currentState => ({
      ...currentState,
      [`${name}Input`]: { value, isValid, hasChanged }
    }));
  }

  useEffect(() => {
    if (!isLoadingUsers) {
      validateEmailNotifications();
    }
  }, [
    additionalRecipientsInput.value,
    emailNotificationsInput.value,
    inboxRecipientsInput.value
  ]);

  useEffect(() => {
    onInteraction({
      type: EditInboxForm.INTERACTION_INBOX_EDITED,
      edited: Object.values(inputState).some(
        (input): boolean => input.hasChanged
      )
    });
  }, [inputState]);

  function validateEmailNotifications() {
    let isValid = true;

    if (emailNotificationsInput.value) {
      if (additionalRecipientsInput.value.length > 255) {
        isValid = false;
      } else {
        const emails = additionalRecipientsInput.value.split(/[ ,;]+/);
        for (const email of emails) {
          if (email && !isEmail(email)) {
            isValid = false;
            break;
          }
        }
      }
    } else {
      isValid = true;
    }

    setInputState(currentState => ({
      ...currentState,
      additionalRecipientsInput: {
        ...currentState.additionalRecipientsInput,
        isValid
      },
      emailNotificationsInput: {
        ...currentState.emailNotificationsInput,
        isValid:
          isValid &&
          (totalEmailRecipients() > 0 ||
            !currentState.emailNotificationsInput.value)
      }
    }));
  }

  useEffect(() => {
    loadInboxUsers(inbox.id);
  }, [inbox.id]);

  async function loadInboxUsers(inboxID: string): Promise<void> {
    const usersQueryResult = await dispatcher.dispatch<
      InboxUsersQueryResult | InboxUsersQueryFailure
    >(new InboxUsersQueryByInboxID({ inboxID }));

    if (usersQueryResult instanceof InboxUsersQueryFailure) {
      return;
    }

    usersQueryResult.users.sort((a, b) =>
      getFullName(a).localeCompare(getFullName(b))
    );

    if (
      !inboxRecipientsInput.value.length &&
      !additionalRecipientsInput.value
    ) {
      inboxRecipientsInput.value = usersQueryResult.users.map(u => u.id);
    }

    changeState({ users: usersQueryResult.users, isLoadingUsers: false });
  }

  function handleChangeOption(
    name: string,
    option: ValueType<Option, false>
  ): void {
    const value = option ? option.value : '';

    setInputState(currentState => ({
      ...currentState,
      [`${name}Input`]: {
        value,
        isValid: true,
        hasChanged:
          initialInputState[`${name}Input` as keyof InputState].value !== value
      }
    }));
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    const service = servicesKeyedByProviderRID[providerRIDInput.value];

    const { serviceType } = decodeRID(providerRIDInput.value);

    let attributes;

    switch (serviceType) {
      case MessagingServiceType.TWILIO_MESSAGING_SERVICE: {
        attributes = {
          friendlyName: service.name
        };
        break;
      }
    }

    attributes = {
      ...inbox.attributes,
      ...attributes,
      emailNotifications: emailNotificationsInput.value,
      timeNotifications: notificationTimerInput.value,
      inboxRecipients: inboxRecipientsInput.value,
      additionalRecipients: additionalRecipientsInput.value
    };

    onInteraction({
      type: EditInboxForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      inboxID: inbox.id,
      ...(nameInput.hasChanged ? { name: nameInput.value.trim() } : {}),
      ...(providerRIDInput.hasChanged
        ? { providerRID: providerRIDInput.value }
        : {}),
      ...(emailNotificationsInput.hasChanged ||
      providerRIDInput.hasChanged ||
      notificationTimerInput.hasChanged ||
      inboxRecipientsInput.hasChanged ||
      additionalRecipientsInput.hasChanged
        ? { attributes }
        : {})
    });
  }

  function handleReset(): void {
    onInteraction({ type: EditInboxForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  }

  function handleViewRecipientsList() {
    changeState({ viewingRecipientsList: true });
  }

  let defaultProviderValue, defaultNotificationValue;

  const options = services.map(service => {
    if (service.providerRID) {
      const option = {
        label: formatOptionLabel(service),
        value: service.providerRID
      };

      if (
        service.providerRID === providerRIDInput.value &&
        inbox.status === InboxStatus.ACTIVE
      ) {
        defaultProviderValue = option;
      }

      return option;
    } else {
      return {
        label: '',
        value: ''
      };
    }
  });

  const minuteOptions = Array.from(Array(12).keys()).map((_el, i) => {
    const option = {
      label: 5 + i * 5 + '',
      value: 5 + i * 5 + ''
    };

    if (
      option.value === notificationTimerInput.value ||
      (!notificationTimerInput.value && option.value === '5')
    ) {
      defaultNotificationValue = option;
      notificationTimerInput.value = option.value;
    }

    return option;
  });

  const totalEmailRecipients = () => {
    return inboxRecipientsInput.value
      ? inboxRecipientsInput.value.length +
          additionalRecipientsInput.value
            .split(/[ ,;]+/)
            .filter(email => !!email.trim()).length
      : 0;
  };

  function renderPhoneNumberList(phoneNumbers: string[]): ReactElement {
    return (
      <Box marginTop={theme.space_stack_md}>
        <Text
          fontSize={theme.fontSize_ui}
          marginBottom={theme.space_stack_sm}
          marginTop={theme.space_stack_md}
        >
          {copyText.phoneNumbersDescription}
        </Text>
        <Box paddingLeft={theme.space_inline_sm}>
          {phoneNumbers.map(phoneNumber => (
            <Flex alignItems="center" key={phoneNumber}>
              <Box marginRight={theme.space_inline_sm}>{UNICODE_BULLET}</Box>
              <Text fontFamily="monospace" fontSize={theme.fontSize_mouse}>
                {formatLocal(countryCode, phoneNumber)}
              </Text>
            </Flex>
          ))}
        </Box>
      </Box>
    );
  }

  return (
    <>
      {!viewingRecipientsList && (
        <Layout
          as={Form}
          backgroundColor={palette.white}
          onSubmit={handleSubmit}
        >
          <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
            <Text appearance="h3" as="h1" bold>
              {copyText.title}
            </Text>
            <Box>
              {renderHeaderMenu
                ? renderHeaderMenu({ inboxID: inbox.id })
                : null}
              <MinimalButton
                aria-label={copyText.cancelButtonLabel}
                iconStart={<IconTimes color={palette.gray[60]} />}
                size="small"
                type="button"
                onClick={handleReset}
              />
            </Box>
          </Layout.Header>
          <Layout.Body {...styleProps.ActionPanelLayoutBody}>
            {message}
            <Flex
              borderBottom={`1px solid ${palette.gray[50]}`}
              direction="column"
              paddingVertical={theme.space_stack_md}
            >
              <Text
                as="h2"
                bold
                color={palette.gray[90]}
                fontSize="1.5rem"
                lineHeight="1.25"
              >
                {inbox.name}
              </Text>
              <Text color={palette.gray[80]} fontSize=".75rem" lineHeight="1">
                {`${copyText.idLabel}: ${inbox.id}`}
              </Text>
            </Flex>
            <FormField
              name="name"
              input={TextInput}
              label={
                <Text
                  color={isValidInput(nameInput) ? undefined : palette.red[60]}
                >
                  {copyText.nameInputLabel}
                  <Asterisk color={palette.red[60]} />
                </Text>
              }
              marginTop={theme.space_stack_md}
              required
              value={nameInput.value}
              variant={isValidInput(nameInput) ? undefined : 'danger'}
              onChange={handleChange}
            />
            <FormField
              name="providerRID"
              caption={get(
                copyText,
                `providerRIDInputCaption_${messagingProviderType}`
              )}
              disabled={services.length === 0}
              label={
                <Text
                  color={
                    isValidInput(providerRIDInput) ? undefined : palette.red[60]
                  }
                >
                  {copyText.providerRIDInputLabel}
                  <Asterisk color={palette.red[60]} />
                </Text>
              }
              marginTop={theme.space_stack_md}
            >
              <Select
                isLoading={isLoadingServices}
                options={options}
                placeholder=""
                defaultValue={defaultProviderValue}
                onChange={handleChangeOption.bind({}, 'providerRID')}
              />
            </FormField>
            {phoneNumbers.length > 0
              ? renderPhoneNumberList(phoneNumbers)
              : null}
            {inbox.status === 'ACTIVE' && (
              <Flex
                alignItems="center"
                justifyContent="between"
                style={{ marginTop: 25 }}
              >
                <FormField
                  labelFor="emailNotifications"
                  label={
                    <Text marginTop={theme.space_stack_sm}>
                      {copyText.emailNotificationsLabel}
                    </Text>
                  }
                  style={{ marginBottom: 0 }}
                />
                <Switch
                  id="emailNotifications"
                  checked={emailNotificationsInput.value}
                  checkedIcon={false}
                  offColor={palette.gray[60]}
                  onColor={palette.green[60]}
                  uncheckedIcon={false}
                  onChange={(checked): void =>
                    handleChange({
                      target: {
                        name: 'emailNotifications',
                        value: String(checked)
                      }
                    } as ChangeEvent<HTMLInputElement>)
                  }
                />
              </Flex>
            )}
            {emailNotificationsInput.value && (
              <>
                <Box
                  backgroundColor={palette.white}
                  borderRadius={theme.borderRadius_2}
                  boxShadow={`0 0 4px 0 ${palette.gray[30]}`}
                  border={`0.5px solid ${
                    additionalRecipientsInput.isValid &&
                    emailNotificationsInput.isValid
                      ? palette.gray[10]
                      : palette.red[60]
                  }`}
                  onClick={isLoadingUsers ? null : handleViewRecipientsList}
                  cursor={isLoadingUsers ? '' : 'pointer'}
                  marginTop="1rem"
                  marginBottom="1rem"
                  style={{
                    opacity: isLoadingUsers ? 0.5 : null
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="between"
                    padding={theme.space_inset_md}
                    paddingRight={theme.space_inset_sm}
                  >
                    <Flex alignItems="center">
                      <IconUsers color={palette.gray[60]} size={24} />
                      <FlexItem
                        margin="0"
                        paddingLeft={theme.space_inset_md}
                        width="100%"
                      >
                        <Text fontWeight={theme.fontWeight_semiBold}>
                          {copyText.viewRecipientsListTitle}
                        </Text>
                        {!isLoadingUsers && (
                          <Text>
                            This Email Notification will be delivered to{' '}
                            {totalEmailRecipients()}{' '}
                            {totalEmailRecipients() === 1 ? 'person' : 'people'}
                          </Text>
                        )}
                      </FlexItem>
                    </Flex>
                    {isLoadingUsers ? (
                      <Box marginRight="1em">
                        <LoadingComponent size={'1.5em'} />
                      </Box>
                    ) : (
                      <MinimalButton
                        iconStart={
                          <IconChevronRight color={palette.gray[60]} />
                        }
                        title={copyText.viewRecipientsListTitle}
                        size="small"
                      />
                    )}
                  </Flex>
                </Box>
                {!isLoadingUsers && totalEmailRecipients() === 0 && (
                  <Text color={palette.red[60]} marginTop="-1em">
                    Email notifications require at least 1 recipient
                  </Text>
                )}
                <Box style={{ maxWidth: '100px' }}>
                  <FormField
                    name="notificationTimer"
                    label={
                      <>
                        <Text style={{ whiteSpace: 'nowrap' }}>
                          {copyText.notificationTimerLabel}
                          <Asterisk color={palette.red[60]} />
                        </Text>
                        <Text
                          appearance="mouse"
                          fontWeight={400}
                          marginBottom="-0.5rem"
                        >
                          Minutes
                        </Text>
                      </>
                    }
                    marginTop={theme.space_stack_md}
                  >
                    <Select
                      options={minuteOptions}
                      placeholder=""
                      defaultValue={defaultNotificationValue}
                      menuPlacement="auto"
                      menuPosition="fixed"
                      onChange={handleChangeOption.bind(
                        {},
                        'notificationTimer'
                      )}
                    />
                  </FormField>
                </Box>
              </>
            )}
          </Layout.Body>
          <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
            <Button
              width="48%"
              type="reset"
              variant="grayscale"
              onClick={handleReset}
            >
              {copyText.cancelButtonLabel}
            </Button>
            <Button
              disabled={!canSubmit || isProcessing}
              width="48%"
              primary
              type="submit"
            >
              {inbox.providerRID || isEmpty(providerRIDInput.value)
                ? copyText.submitButtonLabel
                : copyText.activateInbox}
            </Button>
          </Layout.Footer>
        </Layout>
      )}
      {viewingRecipientsList && (
        <EmailRecipientsForm
          handleClickBack={() => changeState({ viewingRecipientsList: false })}
          users={users}
          additionalRecipients={additionalRecipientsInput}
          inboxRecipients={inboxRecipientsInput.value}
          handleChange={handleChange}
        />
      )}
    </>
  );
}

function isValidInput({
  value,
  isValid
}: {
  value: string;
  isValid: boolean;
}): boolean {
  return isNil(value) || isValid;
}

function formatOptionLabel(service: EditInboxForm.ServiceEntity): string {
  let label = service.name;

  const { serviceID, serviceType } = decodeRID(service.providerRID);

  if (serviceType === MessagingServiceType.TWILIO_MESSAGING_SERVICE) {
    label = `${label} (${serviceID})`;
  }

  return label;
}

EditInboxForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${EditInboxForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
EditInboxForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditInboxForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;
EditInboxForm.INTERACTION_INBOX_EDITED = `${EditInboxForm.name}.INTERACTION_INBOX_EDITED`;

export default EditInboxForm;
