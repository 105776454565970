export default {
  additionalRecipientsCaption:
    'Add recipients that are outside of the inbox recipients list.',
  additionalRecipientsLabel: 'Additional Recipients',
  inboxRecipientsLabel: 'Inbox Recipients',
  invalidEmailsCaption:
    'Recipients must be a comma separated list of valid emails.',
  invalidEmailsLength: 'Recipients list must be less than 256 characters.',
  noUsersMessage: 'No users have been granted permission to access this inbox.',
  title: 'Inbox User List'
};
