/* eslint-disable @typescript-eslint/no-explicit-any */

import { navigate } from '@reach/router';
import { ThemeProvider } from '@targetx/mineral-ui/themes';
import HTTPMessageSender from '@targetx/tx-cq-lib/lib/HTTPMessageSender';
import ActivateInboxCommand, {
  ActivateInboxCommandAck,
  ActivateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/ActivateInboxCommand';
import CreateBroadcastCommand, {
  CreateBroadcastCommandAck,
  CreateBroadcastCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateBroadcastCommand';
import CreateCampaignCommand, {
  CreateCampaignCommandAck,
  CreateCampaignCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateCampaignCommand';
import CreateContactMappingAttributesCommand, {
  CreateContactMappingAttributesCommandAck,
  CreateContactMappingAttributesCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateContactMappingAttributesCommand';
import CreateInboxCommand, {
  CreateInboxCommandAck,
  CreateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateInboxCommand';
import {
  CreateMessageTemplateCommandAck,
  CreateMessageTemplateCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateMessageTemplateCommand';
import CreateReportCommand, {
  CreateReportCommandAck,
  CreateReportCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateReportCommand';
import DeactivateInboxCommand, {
  DeactivateInboxCommandAck,
  DeactivateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/DeactivateInboxCommand';
import DeactivateReportCommand, {
  DeactivateReportCommandAck,
  DeactivateReportCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/DeactivateReportCommand';
import {
  DeleteMessageTemplateCommandAck,
  DeleteMessageTemplateCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/DeleteMessageTemplateCommand';
import GrantUserInboxPermissionCommand, {
  GrantUserInboxPermissionCommandAck,
  GrantUserInboxPermissionCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/GrantUserInboxPermissionCommand';
import GrantUserPermissionsToInboxCommand, {
  GrantUserPermissionsToInboxCommandAck,
  GrantUserPermissionsToInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/GrantUserPermissionsToInboxCommand';
import MarkConversationAsReadCommand, {
  MarkConversationAsReadCommandAck,
  MarkConversationAsReadCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/MarkConversationAsReadCommand';
import RevokeUserInboxPermissionCommand, {
  RevokeUserInboxPermissionCommandAck,
  RevokeUserInboxPermissionCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/RevokeUserInboxPermissionCommand';
import SendMessageCommand, {
  SendMessageCommandAck,
  SendMessageCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/SendMessageCommand';
import UpdateCampaignCommand, {
  UpdateCampaignCommandAck,
  UpdateCampaignCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateCampaignCommand';
import UpdateContactMappingAttributesCommand, {
  UpdateContactMappingAttributesCommandAck,
  UpdateContactMappingAttributesCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateContactMappingAttributesCommand';
import UpdateInboxCommand, {
  UpdateInboxCommandAck,
  UpdateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateInboxCommand';
import UpdateMergeMessageCommand, {
  UpdateMergeMessageCommandAck,
  UpdateMergeMessageCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateMergeMessageCommand';
import {
  UpdateMessageTemplateCommandAck,
  UpdateMessageTemplateCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateMessageTemplateCommand';
import UpdateMessagingAPIKeyCommand, {
  UpdateMessagingAPIKeyCommandAck,
  UpdateMessagingAPIKeyCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateMessagingAPIKeyCommand';
import {
  UpdateOrgSettingCommandAck,
  UpdateOrgSettingCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateOrgSettingCommand';
import UpdateReportCommand, {
  UpdateReportCommandAck,
  UpdateReportCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateReportCommand';
import BroadcastsQuery, {
  BroadcastsQueryFailure,
  BroadcastsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/BroadcastsQuery';
import CampaignsQuery, {
  CampaignsQueryFailure,
  CampaignsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/CampaignsQuery';
import ContactMappingsQuery, {
  ContactMappingsQueryFailure,
  ContactMappingsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactMappingsQuery';
import ContactRecordQuery, {
  ContactRecordQueryFailure,
  ContactRecordQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactRecordQuery';
import ContactRecordsQuery, {
  ContactRecordsQueryFailure,
  ContactRecordsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactRecordsQuery';
import {
  ConversationsQueryByInboxID,
  ConversationsQueryFailure,
  ConversationsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ConversationsQuery';
import {
  ConversationsUnreadQueryByInboxID,
  ConversationsUnreadQueryFailure,
  ConversationsUnreadQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ConversationsUnreadQuery';
import InboxesQuery, {
  InboxesQueryFailure,
  InboxesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxesQuery';
import {
  InboxQueryByID,
  InboxQueryFailure,
  InboxQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxQuery';
import InboxUsersQuery, {
  InboxUsersQueryFailure,
  InboxUsersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxUsersQuery';
import MessageSendersQuery, {
  MessageSendersQueryFailure,
  MessageSendersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageSendersQuery';
import MessagesQuery, {
  MessagesQueryFailure,
  MessagesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagesQuery';
import {
  MessageTemplatesQueryByOwnerID,
  MessageTemplatesQueryFailure,
  MessageTemplatesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageTemplatesQuery';
import {
  MessagingAPIKeyQueryByOrgID,
  MessagingAPIKeyQueryFailure,
  MessagingAPIKeyQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagingAPIKeyQuery';
import {
  MessagingServicesQueryByOrgID,
  MessagingServicesQueryFailure,
  MessagingServicesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagingServicesQuery';
import {
  OrgSettingsQueryFailure,
  OrgSettingsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/OrgSettingsQuery';
import ReportColumnNamesQuery, {
  ReportColumnNamesQueryFailure,
  ReportColumnNamesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportColumnNamesQuery';
import ReportQuery, {
  ReportQueryFailure,
  ReportQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportQuery';
import ReportRecordCountQuery, {
  ReportRecordCountQueryFailure,
  ReportRecordCountQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportRecordCountQuery';
import ReportRecordsQuery, {
  ReportRecordsQueryFailure,
  ReportRecordsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportRecordsQuery';
import ReportsQuery, {
  ReportsQueryFailure,
  ReportsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportsQuery';
import SalesforceReportColumnNamesQuery, {
  SalesforceReportColumnNamesQueryFailure,
  SalesforceReportColumnNamesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportColumnNamesQuery';
import SalesforceReportFoldersQuery, {
  SalesforceReportFoldersQueryFailure,
  SalesforceReportFoldersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportFoldersQuery';
import SalesforceReportQuery, {
  SalesforceReportQueryFailure,
  SalesforceReportQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportQuery';
import SalesforceReportsQuery, {
  SalesforceReportsQueryFailure,
  SalesforceReportsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportsQuery';
import TwilioMessagingServiceQuery, {
  TwilioMessagingServiceQueryFailure,
  TwilioMessagingServiceQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/TwilioMessagingServiceQuery';
import UserPermissionsQuery, {
  UserPermissionsQueryFailure,
  UserPermissionsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/UserPermissionsQuery';
import UserQuery, {
  UserQueryFailure,
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import UsersQuery, {
  UsersQueryByOrgID,
  UsersQueryFailure,
  UsersQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UsersQuery';
import React, { ReactElement } from 'react';
import UDispatcher from 'unilib-dispatcher';
import registry from 'unilib-registry/instance';
import App from './App';
import NavigateCommand from './commands/NavigateCommand';
import RedirectCommand from './commands/RedirectCommand';
import config from './config';
import DataAPIClient from './data-api/DataAPIClient';
import DataAPIClientImpl from './data-api/DataAPIClientImpl';
import DataAPIClientProvider from './data-api/DataAPIClientProvider';
import DispatcherContext, { Dispatcher } from './DispatcherContext';
import theme from './theme';
import redirectOnAuthError from './utils/redirectOnAuthError';

//
// Message Senders
//

registry.register(
  'SMSSender',
  (): HTTPMessageSender =>
    new HTTPMessageSender({
      constructorRegistry: {
        [BroadcastsQueryFailure.name]: BroadcastsQueryFailure,
        [BroadcastsQueryResult.name]: BroadcastsQueryResult,
        [CampaignsQueryFailure.name]: CampaignsQueryFailure,
        [CampaignsQueryResult.name]: CampaignsQueryResult,
        [ContactMappingsQueryFailure.name]: ContactMappingsQueryFailure,
        [ContactMappingsQueryResult.name]: ContactMappingsQueryResult,
        [ContactRecordQueryFailure.name]: ContactRecordQueryFailure,
        [ContactRecordQueryResult.name]: ContactRecordQueryResult,
        [ContactRecordsQueryFailure.name]: ContactRecordsQueryFailure,
        [ContactRecordsQueryResult.name]: ContactRecordsQueryResult,
        [ConversationsQueryFailure.name]: ConversationsQueryFailure,
        [ConversationsQueryResult.name]: ConversationsQueryResult,
        [ConversationsUnreadQueryFailure.name]: ConversationsUnreadQueryFailure,
        [ConversationsUnreadQueryResult.name]: ConversationsUnreadQueryResult,
        [InboxesQueryFailure.name]: InboxesQueryFailure,
        [InboxesQueryResult.name]: InboxesQueryResult,
        [InboxQueryFailure.name]: InboxQueryFailure,
        [InboxQueryResult.name]: InboxQueryResult,
        [InboxUsersQueryFailure.name]: InboxUsersQueryFailure,
        [InboxUsersQueryResult.name]: InboxUsersQueryResult,
        [MessageSendersQueryFailure.name]: MessageSendersQueryFailure,
        [MessageSendersQueryResult.name]: MessageSendersQueryResult,
        [MessagesQueryFailure.name]: MessagesQueryFailure,
        [MessagesQueryResult.name]: MessagesQueryResult,
        [MessageTemplatesQueryFailure.name]: MessageTemplatesQueryFailure,
        [MessageTemplatesQueryResult.name]: MessageTemplatesQueryResult,
        [MessagingAPIKeyQueryFailure.name]: MessagingAPIKeyQueryFailure,
        [MessagingAPIKeyQueryResult.name]: MessagingAPIKeyQueryResult,
        [MessagingServicesQueryFailure.name]: MessagingServicesQueryFailure,
        [MessagingServicesQueryResult.name]: MessagingServicesQueryResult,
        [OrgSettingsQueryFailure.name]: OrgSettingsQueryFailure,
        [OrgSettingsQueryResult.name]: OrgSettingsQueryResult,
        [ReportColumnNamesQueryFailure.name]: ReportColumnNamesQueryFailure,
        [ReportColumnNamesQueryResult.name]: ReportColumnNamesQueryResult,
        [ReportQueryFailure.name]: ReportQueryFailure,
        [ReportQueryResult.name]: ReportQueryResult,
        [ReportRecordsQueryFailure.name]: ReportRecordsQueryFailure,
        [ReportRecordsQueryResult.name]: ReportRecordsQueryResult,
        [ReportRecordCountQueryFailure.name]: ReportRecordCountQueryFailure,
        [ReportRecordCountQueryResult.name]: ReportRecordCountQueryResult,
        [ReportsQueryFailure.name]: ReportsQueryFailure,
        [ReportsQueryResult.name]: ReportsQueryResult,
        [SalesforceReportColumnNamesQueryFailure.name]:
          SalesforceReportColumnNamesQueryFailure,
        [SalesforceReportColumnNamesQueryResult.name]:
          SalesforceReportColumnNamesQueryResult,
        [SalesforceReportFoldersQueryFailure.name]:
          SalesforceReportFoldersQueryFailure,
        [SalesforceReportFoldersQueryResult.name]:
          SalesforceReportFoldersQueryResult,
        [SalesforceReportQueryFailure.name]: SalesforceReportQueryFailure,
        [SalesforceReportQueryResult.name]: SalesforceReportQueryResult,
        [SalesforceReportsQueryFailure.name]: SalesforceReportsQueryFailure,
        [SalesforceReportsQueryResult.name]: SalesforceReportsQueryResult,
        [TwilioMessagingServiceQueryResult.name]:
          TwilioMessagingServiceQueryResult,
        [TwilioMessagingServiceQueryFailure.name]:
          TwilioMessagingServiceQueryFailure,
        [SalesforceReportsQueryResult.name]: SalesforceReportsQueryResult,
        [UserQueryFailure.name]: UserQueryFailure,
        [UserQueryResult.name]: UserQueryResult,
        [UserPermissionsQueryFailure.name]: UserPermissionsQueryFailure,
        [UserPermissionsQueryResult.name]: UserPermissionsQueryResult,
        [ActivateInboxCommandFailure.name]: ActivateInboxCommandFailure,
        [ActivateInboxCommandAck.name]: ActivateInboxCommandAck,
        [CreateBroadcastCommandFailure.name]: CreateBroadcastCommandFailure,
        [CreateBroadcastCommandAck.name]: CreateBroadcastCommandAck,
        [CreateCampaignCommandFailure.name]: CreateCampaignCommandFailure,
        [CreateCampaignCommandAck.name]: CreateCampaignCommandAck,
        [CreateContactMappingAttributesCommandFailure.name]:
          CreateContactMappingAttributesCommandFailure,
        [CreateContactMappingAttributesCommandAck.name]:
          CreateContactMappingAttributesCommandAck,
        [CreateInboxCommandFailure.name]: CreateInboxCommandFailure,
        [CreateInboxCommandAck.name]: CreateInboxCommandAck,
        [CreateMessageTemplateCommandFailure.name]:
          CreateMessageTemplateCommandFailure,
        [CreateMessageTemplateCommandAck.name]: CreateMessageTemplateCommandAck,
        [CreateReportCommandFailure.name]: CreateReportCommandFailure,
        [CreateReportCommandAck.name]: CreateReportCommandAck,
        [DeactivateInboxCommandFailure.name]: DeactivateInboxCommandFailure,
        [DeactivateInboxCommandAck.name]: DeactivateInboxCommandAck,
        [DeactivateReportCommandFailure.name]: DeactivateReportCommandFailure,
        [DeactivateReportCommandAck.name]: DeactivateReportCommandAck,
        [DeleteMessageTemplateCommandFailure.name]:
          DeleteMessageTemplateCommandFailure,
        [DeleteMessageTemplateCommandAck.name]: DeleteMessageTemplateCommandAck,
        [GrantUserInboxPermissionCommandFailure.name]:
          GrantUserInboxPermissionCommandFailure,
        [GrantUserInboxPermissionCommandAck.name]:
          GrantUserInboxPermissionCommandAck,
        [GrantUserPermissionsToInboxCommandFailure.name]:
          GrantUserPermissionsToInboxCommandFailure,
        [GrantUserPermissionsToInboxCommandAck.name]:
          GrantUserPermissionsToInboxCommandAck,
        [MarkConversationAsReadCommandFailure.name]:
          MarkConversationAsReadCommandFailure,
        [MarkConversationAsReadCommandAck.name]:
          MarkConversationAsReadCommandAck,
        [RevokeUserInboxPermissionCommandFailure.name]:
          RevokeUserInboxPermissionCommandFailure,
        [RevokeUserInboxPermissionCommandAck.name]:
          RevokeUserInboxPermissionCommandAck,
        [SendMessageCommandFailure.name]: SendMessageCommandFailure,
        [SendMessageCommandAck.name]: SendMessageCommandAck,
        [UpdateCampaignCommandFailure.name]: UpdateCampaignCommandFailure,
        [UpdateCampaignCommandAck.name]: UpdateCampaignCommandAck,
        [UpdateContactMappingAttributesCommandFailure.name]:
          UpdateContactMappingAttributesCommandFailure,
        [UpdateContactMappingAttributesCommandAck.name]:
          UpdateContactMappingAttributesCommandAck,
        [UpdateInboxCommandFailure.name]: UpdateInboxCommandFailure,
        [UpdateInboxCommandAck.name]: UpdateInboxCommandAck,
        [UpdateMergeMessageCommandFailure.name]:
          UpdateMergeMessageCommandFailure,
        [UpdateMergeMessageCommandAck.name]: UpdateMergeMessageCommandAck,
        [UpdateMessageTemplateCommandFailure.name]:
          UpdateMessageTemplateCommandFailure,
        [UpdateMessageTemplateCommandAck.name]: UpdateMessageTemplateCommandAck,
        [UpdateMessagingAPIKeyCommandFailure.name]:
          UpdateMessagingAPIKeyCommandFailure,
        [UpdateMessagingAPIKeyCommandAck.name]: UpdateMessagingAPIKeyCommandAck,
        [UpdateOrgSettingCommandFailure.name]: UpdateOrgSettingCommandFailure,
        [UpdateOrgSettingCommandAck.name]: UpdateOrgSettingCommandAck,
        [UpdateReportCommandFailure.name]: UpdateReportCommandFailure,
        [UpdateReportCommandAck.name]: UpdateReportCommandAck
      },
      endpointURL: config.SMS_API_BASE_URL
    })
);

registry.register(
  'UserManagementSender',
  (): HTTPMessageSender =>
    new HTTPMessageSender({
      constructorRegistry: {
        [UserQueryFailure.name]: UserQueryFailure,
        [UserQueryResult.name]: UserQueryResult,
        [UsersQueryFailure.name]: UsersQueryFailure,
        [UsersQueryResult.name]: UsersQueryResult
      },
      endpointURL: config.USERMGMT_API_BASE_URL
    })
);

//
// Dispatcher
//

registry.register('dispatcher', (): UDispatcher => {
  const dispatcher = new UDispatcher();

  const smsSender = registry.get('SMSSender');
  const userManagementSender = registry.get('UserManagementSender');

  //
  // Queries
  //

  dispatcher.register(
    `${BroadcastsQuery.name}By*`,
    async (
      query: BroadcastsQuery
    ): Promise<BroadcastsQueryResult | BroadcastsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        return new BroadcastsQueryFailure({
          correlationUUID: query.uuid,
          reason: BroadcastsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${CampaignsQuery.name}By*`,
    async (
      query: CampaignsQuery
    ): Promise<CampaignsQueryResult | CampaignsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        return new CampaignsQueryFailure({
          correlationUUID: query.uuid,
          reason: CampaignsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ContactMappingsQuery.name}By*`,
    async (
      query: ContactMappingsQuery
    ): Promise<ContactMappingsQueryResult | ContactMappingsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new ContactMappingsQueryFailure({
          correlationUUID: query.uuid,
          reason: ContactMappingsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ContactRecordQuery.name}By*`,
    async (
      query: ContactRecordQuery
    ): Promise<ContactRecordQueryResult | ContactRecordQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });

        return new ContactRecordQueryFailure({
          correlationUUID: query.uuid,
          reason: ContactRecordQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ContactRecordsQuery.name}By*`,
    async (
      query: ContactRecordsQuery
    ): Promise<ContactRecordsQueryResult | ContactRecordsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new ContactRecordsQueryFailure({
          correlationUUID: query.uuid,
          reason: ContactRecordsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    ConversationsQueryByInboxID.name,
    async (
      query: ConversationsQueryByInboxID
    ): Promise<ConversationsQueryResult | ConversationsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new ConversationsQueryFailure({
          correlationUUID: query.uuid,
          reason: ConversationsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    ConversationsUnreadQueryByInboxID.name,
    async (
      query: ConversationsUnreadQueryByInboxID
    ): Promise<
      ConversationsUnreadQueryResult | ConversationsUnreadQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log({ error });
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new ConversationsUnreadQueryFailure({
          correlationUUID: query.uuid,
          reason: ConversationsUnreadQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${InboxesQuery.name}By*`,
    async (
      query: InboxesQuery
    ): Promise<InboxesQueryResult | InboxesQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new InboxesQueryFailure({
          correlationUUID: query.uuid,
          reason: InboxesQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    InboxQueryByID.name,
    async (
      query: InboxQueryByID
    ): Promise<InboxQueryResult | InboxQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new InboxQueryFailure({
          correlationUUID: query.uuid,
          reason: InboxQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${InboxUsersQuery.name}By*`,
    async (
      query: InboxUsersQuery
    ): Promise<InboxUsersQueryResult | InboxUsersQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new InboxUsersQueryFailure({
          correlationUUID: query.uuid,
          reason: InboxUsersQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${MessageSendersQuery.name}By*`,
    async (
      query: MessageSendersQuery
    ): Promise<MessageSendersQueryResult | MessageSendersQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new MessageSendersQueryFailure({
          correlationUUID: query.uuid,
          reason: MessageSendersQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${MessagesQuery.name}By*`,
    async (
      query: MessagesQuery
    ): Promise<MessagesQueryResult | MessagesQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new MessagesQueryFailure({
          correlationUUID: query.uuid,
          reason: MessagesQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    MessageTemplatesQueryByOwnerID.name,
    async (
      query: MessageTemplatesQueryByOwnerID
    ): Promise<MessageTemplatesQueryResult | MessageTemplatesQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new MessageTemplatesQueryFailure({
          correlationUUID: query.uuid,
          reason: MessageTemplatesQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    MessagingAPIKeyQueryByOrgID.name,
    async (
      query: MessagingAPIKeyQueryByOrgID
    ): Promise<MessagingAPIKeyQueryResult | MessagingAPIKeyQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new MessagingAPIKeyQueryFailure({
          correlationUUID: query.uuid,
          reason: MessagingAPIKeyQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    MessagingServicesQueryByOrgID.name,
    async (
      query: MessagingServicesQueryByOrgID
    ): Promise<
      MessagingServicesQueryResult | MessagingServicesQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.log(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new MessagingServicesQueryFailure({
          correlationUUID: query.uuid,
          reason: MessagingServicesQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ReportColumnNamesQuery.name}By*`,
    async (
      query: ReportColumnNamesQuery
    ): Promise<
      ReportColumnNamesQueryResult | ReportColumnNamesQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        return new ReportColumnNamesQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportColumnNamesQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ReportQuery.name}By*`,
    async (
      query: ReportQuery
    ): Promise<ReportQueryResult | ReportQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        return new ReportQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ReportRecordCountQuery.name}By*`,
    async (
      query: ReportRecordCountQuery
    ): Promise<
      ReportRecordCountQueryResult | ReportRecordCountQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        return new ReportRecordCountQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportRecordCountQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ReportRecordsQuery.name}By*`,
    async (
      query: ReportRecordsQuery
    ): Promise<ReportRecordsQueryResult | ReportRecordsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        return new ReportRecordsQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportRecordsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${ReportsQuery.name}By*`,
    async (
      query: ReportsQuery
    ): Promise<ReportsQueryResult | ReportsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        return new ReportsQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${SalesforceReportColumnNamesQuery.name}By*`,
    async (
      query: SalesforceReportColumnNamesQuery
    ): Promise<
      | SalesforceReportColumnNamesQueryResult
      | SalesforceReportColumnNamesQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        return new SalesforceReportColumnNamesQueryFailure({
          correlationUUID: query.uuid,
          reason: SalesforceReportColumnNamesQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${SalesforceReportFoldersQuery.name}By*`,
    async (
      query: SalesforceReportFoldersQuery
    ): Promise<
      SalesforceReportFoldersQueryResult | SalesforceReportFoldersQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);

        return new SalesforceReportFoldersQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );
  dispatcher.register(
    `${SalesforceReportQuery.name}By`,
    async (
      query: SalesforceReportQuery
    ): Promise<SalesforceReportQueryResult | SalesforceReportQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);

        return new SalesforceReportQueryFailure({
          correlationUUID: query.uuid,
          reason: SalesforceReportQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${SalesforceReportsQuery.name}By*`,
    async (
      query: SalesforceReportsQuery
    ): Promise<
      SalesforceReportsQueryResult | SalesforceReportsQueryFailure
    > => {
      console.log('query: ', query);
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);

        return new SalesforceReportsQueryFailure({
          correlationUUID: query.uuid,
          reason: ReportsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${TwilioMessagingServiceQuery.name}By*`,
    async (
      query: TwilioMessagingServiceQuery
    ): Promise<
      TwilioMessagingServiceQueryResult | TwilioMessagingServiceQueryFailure
    > => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new TwilioMessagingServiceQueryFailure({
          correlationUUID: query.uuid,
          reason: TwilioMessagingServiceQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${UserPermissionsQuery.name}By*`,
    async (
      query: UserPermissionsQuery
    ): Promise<UserPermissionsQueryResult | UserPermissionsQueryFailure> => {
      try {
        return await smsSender.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UserPermissionsQueryFailure({
          correlationUUID: query.uuid,
          reason: UserPermissionsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UserQuery.name,
    async (query: UserQuery): Promise<UserQueryResult | UserQueryFailure> => {
      try {
        return await userManagementSender.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UserQueryFailure({
          correlationUUID: query.uuid,
          reason: UserQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${UserQuery.name}By*`,
    async (query: UserQuery): Promise<UserQueryResult | UserQueryFailure> => {
      try {
        return await userManagementSender.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UserQueryFailure({
          correlationUUID: query.uuid,
          reason: UserQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UsersQueryByOrgID.name,
    async (
      query: UsersQuery
    ): Promise<UsersQueryResult | UsersQueryFailure> => {
      try {
        return await userManagementSender.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UsersQueryFailure({
          correlationUUID: query.uuid,
          reason: UsersQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  //
  // Commands
  //

  dispatcher.register(
    NavigateCommand.name,
    (command: NavigateCommand): Promise<void> => navigate(command.path)
  );

  dispatcher.register(RedirectCommand.name, (command: RedirectCommand): void =>
    window.location.replace(command.url)
  );

  dispatcher.register(
    ActivateInboxCommand.name,
    async (
      command: ActivateInboxCommand
    ): Promise<ActivateInboxCommandAck | ActivateInboxCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new ActivateInboxCommandFailure({
          correlationUUID: command.uuid,
          reason: ActivateInboxCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateBroadcastCommand.name,
    async (
      command: CreateBroadcastCommand
    ): Promise<CreateBroadcastCommandAck | CreateBroadcastCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        return new CreateBroadcastCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateBroadcastCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateCampaignCommand.name,
    async (
      command: CreateCampaignCommand
    ): Promise<CreateCampaignCommandAck | CreateCampaignCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        return new CreateCampaignCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateCampaignCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateCampaignCommand.name,
    async (
      command: UpdateCampaignCommand
    ): Promise<UpdateCampaignCommandAck | UpdateCampaignCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        return new UpdateCampaignCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateCampaignCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateInboxCommand.name,
    async (
      command: CreateInboxCommand
    ): Promise<CreateInboxCommandAck | CreateInboxCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new CreateInboxCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateInboxCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateReportCommand.name,
    async (
      command: CreateReportCommand
    ): Promise<CreateReportCommandAck | CreateReportCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);

        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });

        return new CreateReportCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateReportCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    DeactivateInboxCommand.name,
    async (
      command: DeactivateInboxCommand
    ): Promise<DeactivateInboxCommandAck | DeactivateInboxCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new DeactivateInboxCommandFailure({
          correlationUUID: command.uuid,
          reason: DeactivateInboxCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    DeactivateReportCommand.name,
    async (
      command: DeactivateReportCommand
    ): Promise<DeactivateReportCommandAck | DeactivateReportCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new DeactivateReportCommandFailure({
          correlationUUID: command.uuid,
          reason: DeactivateReportCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    GrantUserInboxPermissionCommand.name,
    async (
      command: GrantUserInboxPermissionCommand
    ): Promise<
      | GrantUserInboxPermissionCommandAck
      | GrantUserInboxPermissionCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new GrantUserInboxPermissionCommandFailure({
          correlationUUID: command.uuid,
          reason: GrantUserInboxPermissionCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    GrantUserPermissionsToInboxCommand.name,
    async (
      command: GrantUserPermissionsToInboxCommand
    ): Promise<
      | GrantUserPermissionsToInboxCommandAck
      | GrantUserPermissionsToInboxCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new GrantUserPermissionsToInboxCommandFailure({
          correlationUUID: command.uuid,
          reason: GrantUserPermissionsToInboxCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    MarkConversationAsReadCommand.name,
    async (
      command: MarkConversationAsReadCommand
    ): Promise<
      MarkConversationAsReadCommandAck | MarkConversationAsReadCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new MarkConversationAsReadCommandFailure({
          correlationUUID: command.uuid,
          reason: MarkConversationAsReadCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    RevokeUserInboxPermissionCommand.name,
    async (
      command: RevokeUserInboxPermissionCommand
    ): Promise<
      | RevokeUserInboxPermissionCommandAck
      | RevokeUserInboxPermissionCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new RevokeUserInboxPermissionCommandFailure({
          correlationUUID: command.uuid,
          reason: RevokeUserInboxPermissionCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateContactMappingAttributesCommand.name,
    async (
      command: CreateContactMappingAttributesCommand
    ): Promise<
      | CreateContactMappingAttributesCommandAck
      | CreateContactMappingAttributesCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new CreateContactMappingAttributesCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateContactMappingAttributesCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    SendMessageCommand.name,
    async (
      command: SendMessageCommand
    ): Promise<SendMessageCommandAck | SendMessageCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new SendMessageCommandFailure({
          correlationUUID: command.uuid,
          reason: SendMessageCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateContactMappingAttributesCommand.name,
    async (
      command: UpdateContactMappingAttributesCommand
    ): Promise<
      | UpdateContactMappingAttributesCommandAck
      | UpdateContactMappingAttributesCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UpdateContactMappingAttributesCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateContactMappingAttributesCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateInboxCommand.name,
    async (
      command: UpdateInboxCommand
    ): Promise<UpdateInboxCommandAck | UpdateInboxCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UpdateInboxCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateInboxCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateMergeMessageCommand.name,
    async (
      command: UpdateMergeMessageCommand
    ): Promise<
      UpdateMergeMessageCommandAck | UpdateMergeMessageCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UpdateMergeMessageCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateMergeMessageCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateMessagingAPIKeyCommand.name,
    async (
      command: UpdateMessagingAPIKeyCommand
    ): Promise<
      UpdateMessagingAPIKeyCommandAck | UpdateMessagingAPIKeyCommandFailure
    > => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UpdateMessagingAPIKeyCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateMessagingAPIKeyCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateReportCommand.name,
    async (
      command: UpdateReportCommand
    ): Promise<UpdateReportCommandAck | UpdateReportCommandFailure> => {
      try {
        return await smsSender.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        });
        return new UpdateReportCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateReportCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  return dispatcher;
});

//
// Client
//

registry.register(
  'DataAPIClient',
  (): DataAPIClient =>
    new DataAPIClientImpl({
      redirectOnAuthError: error =>
        redirectOnAuthError(error, {
          authBaseURL: config.AUTH_BASE_URL,
          homeBaseURL: config.HOME_BASE_URL
        }),
      sender: registry.get('SMSSender')
    })
);

registry.set(
  'Root',
  (): ReactElement =>
    React.createElement(
      ThemeProvider,
      { theme },
      React.createElement(
        DispatcherContext.Provider,
        {
          value: {
            async dispatch(payload: Dispatcher.Payload): Promise<any> {
              try {
                const dispatcher: UDispatcher = registry.get('dispatcher');
                return await dispatcher.dispatch(
                  payload.constructor.name,
                  payload
                );
              } catch (error: any) {
                console.error(error);
              }
            }
          }
        },
        React.createElement(
          DataAPIClientProvider,
          {
            client: registry.get<DataAPIClient>('DataAPIClient')
          },
          React.createElement(App, {
            authBaseURL: config.AUTH_BASE_URL,
            homeBaseURL: config.HOME_BASE_URL
          })
        )
      )
    )
);
