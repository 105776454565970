/* eslint-disable @typescript-eslint/no-explicit-any */

import HTTPMessageSender from '@targetx/tx-cq-lib/lib/HTTPMessageSender';
import ActivateInboxCommand, {
  ActivateInboxCommandAck,
  ActivateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/ActivateInboxCommand';
import CreateInboxCommand, {
  CreateInboxCommandAck,
  CreateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateInboxCommand';
import CreateMessageTemplateCommand, {
  CreateMessageTemplateCommandAck,
  CreateMessageTemplateCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateMessageTemplateCommand';
import CreateReportCommand, {
  CreateReportCommandAck,
  CreateReportCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateReportCommand';
import DeactivateInboxCommand, {
  DeactivateInboxCommandAck,
  DeactivateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/DeactivateInboxCommand';
import DeactivateReportCommand, {
  DeactivateReportCommandAck,
  DeactivateReportCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/DeactivateReportCommand';
import DeleteMessageTemplateCommand, {
  DeleteMessageTemplateCommandAck,
  DeleteMessageTemplateCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/DeleteMessageTemplateCommand';
import UpdateInboxCommand, {
  UpdateInboxCommandAck,
  UpdateInboxCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateInboxCommand';
import UpdateMessageTemplateCommand, {
  UpdateMessageTemplateCommandAck,
  UpdateMessageTemplateCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateMessageTemplateCommand';
import UpdateOrgSettingCommand, {
  UpdateOrgSettingCommandAck,
  UpdateOrgSettingCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateOrgSettingCommand';
import UpdateReportCommand, {
  UpdateReportCommandAck,
  UpdateReportCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateReportCommand';
import {
  ContactMappingsQueryByPhoneNumbers,
  ContactMappingsQueryFailure,
  ContactMappingsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactMappingsQuery';
import {
  ContactRecordQueryByRID,
  ContactRecordQueryFailure,
  ContactRecordQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactRecordQuery';
import {
  InboxesQueryByIDs,
  InboxesQueryByOrgID,
  InboxesQueryFailure,
  InboxesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxesQuery';
import {
  MessageSendersQueryByInboxID,
  MessageSendersQueryFailure,
  MessageSendersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageSendersQuery';
import {
  MessagesQueryByConversationID,
  MessagesQueryFailure,
  MessagesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagesQuery';
import {
  MessageTemplatesQueryByOwnerID,
  MessageTemplatesQueryFailure,
  MessageTemplatesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageTemplatesQuery';
import {
  MessagingAPIKeyQueryByOrgID,
  MessagingAPIKeyQueryFailure,
  MessagingAPIKeyQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagingAPIKeyQuery';
import {
  MessagingServicesQueryByOrgID,
  MessagingServicesQueryFailure,
  MessagingServicesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagingServicesQuery';
import {
  OrgSettingsQueryByOrgID,
  OrgSettingsQueryFailure,
  OrgSettingsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/OrgSettingsQuery';
import {
  ReportColumnNamesQueryByReportID,
  ReportColumnNamesQueryFailure,
  ReportColumnNamesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportColumnNamesQuery';
import {
  ReportQueryByID,
  ReportQueryFailure,
  ReportQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportQuery';
import {
  ReportsQueryByOrgID,
  ReportsQueryFailure,
  ReportsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ReportsQuery';
import {
  SalesforceReportColumnNamesQueryByReportID,
  SalesforceReportColumnNamesQueryFailure,
  SalesforceReportColumnNamesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportColumnNamesQuery';
import {
  SalesforceReportFoldersQueryByOrgID,
  SalesforceReportFoldersQueryFailure,
  SalesforceReportFoldersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportFoldersQuery';
import {
  SalesforceReportQueryByOrgIDAndRID,
  SalesforceReportQueryFailure,
  SalesforceReportQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportQuery';
import {
  SalesforceReportsQueryByOrgIDAndFolderID,
  SalesforceReportsQueryFailure,
  SalesforceReportsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/SalesforceReportsQuery';
import {
  TwilioMessagingServiceQueryBySID,
  TwilioMessagingServiceQueryFailure,
  TwilioMessagingServiceQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/TwilioMessagingServiceQuery';
import {
  UserPermissionsQueryByUserID,
  UserPermissionsQueryFailure,
  UserPermissionsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/UserPermissionsQuery';
import isNil from 'lodash.isnil';
import DataAPIClient, {
  CreateInboxParameters,
  CreateMessageTemplateParameters,
  CreateReportParameters,
  DataAPIClientError,
  UpdateInboxParameters,
  UpdateMessageTemplateParameters,
  UpdateReportParameters
} from './DataAPIClient';
import {
  BaseReportEntity,
  ContactMappingEntity,
  ContactRecord,
  InboxEntity,
  MessageEntity,
  MessageTemplateEntity,
  MessagingAPIKeyEntity,
  MessagingServiceEntity,
  OrgSettingEntity,
  ReportEntity,
  SalesforceReportFolderObject,
  SalesforceReportObject,
  TwilioServiceObject,
  UserEntity,
  UserPermissionEntity
} from './types';

export interface Config {
  redirectOnAuthError: (error: Error) => Promise<void>;
  sender: HTTPMessageSender;
}

export class DataAPIClientImpl implements DataAPIClient {
  private readonly _redirectOnAuthError: (error: Error) => Promise<void>;
  private readonly _sender: HTTPMessageSender;

  constructor({ redirectOnAuthError, sender }: Config) {
    this._redirectOnAuthError = redirectOnAuthError;
    this._sender = sender;
  }

  public async activateInbox(inboxID: string): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new ActivateInboxCommand({ inboxID })
      )) as ActivateInboxCommandAck | ActivateInboxCommandFailure;

      if (ack instanceof ActivateInboxCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.inboxID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_ACTIVATING_INBOX);
    }
  }

  public async createInbox(parameters: CreateInboxParameters): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new CreateInboxCommand(parameters)
      )) as CreateInboxCommandAck | CreateInboxCommandFailure;

      if (ack instanceof CreateInboxCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.inboxID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      let reason;

      if (
        error.message ===
        CreateInboxCommandFailure.REASON_DUPLICATE_PROVIDER_RID
      ) {
        reason = 'DUPLICATE_PROVIDER_RID';
      }

      throw new DataAPIClientError(DataAPIClientError.FAILED_CREATING_INBOX, {
        reason
      });
    }
  }

  public async createMessageTemplate(
    parameters: CreateMessageTemplateParameters
  ): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new CreateMessageTemplateCommand(parameters)
      )) as
        | CreateMessageTemplateCommandAck
        | CreateMessageTemplateCommandFailure;

      if (ack instanceof CreateMessageTemplateCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.templateID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_CREATING_MESSAGE_TEMPLATE
      );
    }
  }

  public async createReport(
    parameters: CreateReportParameters
  ): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new CreateReportCommand(parameters)
      )) as CreateReportCommandAck | CreateReportCommandFailure;

      if (ack instanceof CreateReportCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.reportID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_CREATING_REPORT);
    }
  }

  public async deactivateInbox(inbox: InboxEntity): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new DeactivateInboxCommand({ inboxID: inbox.id })
      )) as DeactivateInboxCommandAck | DeactivateInboxCommandFailure;

      if (ack instanceof DeactivateInboxCommandFailure) {
        throw new Error(ack.reason);
      }

      const inboxAck = (await this._sender.send(
        new UpdateInboxCommand({
          inboxID: inbox.id,
          attributes: { ...inbox.attributes, emailNotifications: false }
        })
      )) as UpdateInboxCommandAck | UpdateInboxCommandFailure;

      if (inboxAck instanceof UpdateInboxCommandFailure) {
        throw new Error(inboxAck.reason);
      }

      return ack.inboxID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_DEACTIVATING_INBOX
      );
    }
  }

  public async deactivateReport(reportID: string): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new DeactivateReportCommand({ reportID })
      )) as DeactivateReportCommandAck | DeactivateReportCommandFailure;

      if (ack instanceof DeactivateReportCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.reportID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_DEACTIVATING_REPORT
      );
    }
  }

  public async deleteMessageTemplate(templateID: string): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new DeleteMessageTemplateCommand({ templateID })
      )) as
        | DeleteMessageTemplateCommandAck
        | DeleteMessageTemplateCommandFailure;

      if (ack instanceof DeleteMessageTemplateCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.templateID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_DELETING_MESSAGE_TEMPLATE
      );
    }
  }

  public async getContactByRID(rid: string): Promise<ContactRecord> {
    try {
      const result = (await this._sender.send(
        new ContactRecordQueryByRID({ rid })
      )) as ContactRecordQueryResult | ContactRecordQueryFailure;

      if (result instanceof ContactRecordQueryFailure) {
        throw new Error(result.reason);
      }

      return {
        rid: result.contact.rid,
        firstName: result.contact.firstName,
        lastName: result.contact.lastName,
        mobilePhone: result.contact.mobilePhone,
        emailAddress: result.contact.emailAddress,
        assignedTo: result.contact.assignedTo
      };
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_LOADING_CONTACT);
    }
  }

  public async getContactMappingsByPhoneNumbers(
    phoneNumbers: string[]
  ): Promise<ContactMappingEntity[]> {
    try {
      const result = (await this._sender.send(
        new ContactMappingsQueryByPhoneNumbers({ phoneNumbers })
      )) as ContactMappingsQueryResult | ContactMappingsQueryFailure;

      if (result instanceof ContactMappingsQueryFailure) {
        throw new Error(result.reason);
      }

      return result.mappings.map(mapping => ({
        id: mapping.id,
        canonicalPhoneNumber: mapping.canonicalPhoneNumber,
        contactRID: mapping.contactRID,
        attributes: mapping.attributes
      }));
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_CONTACT_MAPPINGS
      );
    }
  }

  public async getInboxesByIDs(inboxIDs: string[]): Promise<InboxEntity[]> {
    try {
      const result = (await this._sender.send(
        new InboxesQueryByIDs({ inboxIDs })
      )) as InboxesQueryResult | InboxesQueryFailure;

      if (result instanceof InboxesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.inboxes.map(this._toInboxEntity);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_LOADING_INBOXES);
    }
  }

  public async getInboxesByOrgID(orgID: string): Promise<InboxEntity[]> {
    try {
      const result = (await this._sender.send(
        new InboxesQueryByOrgID({ orgID })
      )) as InboxesQueryResult | InboxesQueryFailure;

      if (result instanceof InboxesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.inboxes.map(this._toInboxEntity);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_LOADING_INBOXES);
    }
  }

  public async getMessagesByConversationID(
    conversationID: string
  ): Promise<MessageEntity[]> {
    try {
      const result = (await this._sender.send(
        new MessagesQueryByConversationID({ conversationID })
      )) as MessagesQueryResult | MessagesQueryFailure;

      if (result instanceof MessagesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.messages.map(message => ({
        id: message.id,
        inboxID: message.inboxID,
        rid: message.rid,
        canonicalPhoneNumber: message.canonicalPhoneNumber,
        content: message.content,
        status: message.status,
        broadcastID: message.broadcastID,
        contactMappingID: message.contactMappingID,
        userID: message.userID,
        timeCreated: message.timeCreated
      }));
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      let reason;

      if (
        error.message === MessagesQueryFailure.REASON_CONVERSATION_NOT_FOUND
      ) {
        reason = 'CONVERSATION_NOT_FOUND';
      }

      throw new DataAPIClientError(DataAPIClientError.FAILED_LOADING_MESSAGES, {
        reason
      });
    }
  }

  public async getMessageSendersByInboxID(
    inboxID: string
  ): Promise<UserEntity[]> {
    try {
      const result = (await this._sender.send(
        new MessageSendersQueryByInboxID({ inboxID })
      )) as MessageSendersQueryResult | MessageSendersQueryFailure;

      if (result instanceof MessageSendersQueryFailure) {
        throw new Error(result.reason);
      }

      return result.users.map(user => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username
      }));
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_MESSAGE_SENDERS
      );
    }
  }

  public async getMessageTemplatesByUserID(
    ownerID: string
  ): Promise<MessageTemplateEntity[]> {
    try {
      const result = (await this._sender.send(
        new MessageTemplatesQueryByOwnerID({ ownerID })
      )) as MessageTemplatesQueryResult | MessageTemplatesQueryFailure;

      if (result instanceof MessageTemplatesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.templates.map(template => ({
        id: template.id,
        orgID: template.orgID,
        ownerID: template.ownerID,
        name: template.name,
        content: template.content
      }));
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_MESSAGE_TEMPLATES
      );
    }
  }

  public async getMessagingAPIKeyByOrgID(
    orgID: string
  ): Promise<MessagingAPIKeyEntity> {
    try {
      const result = (await this._sender.send(
        new MessagingAPIKeyQueryByOrgID({ orgID })
      )) as MessagingAPIKeyQueryResult | MessagingAPIKeyQueryFailure;

      if (result instanceof MessagingAPIKeyQueryFailure) {
        throw new Error(result.reason);
      }

      return this._toAPIKeyEntity(result.apiKey);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_MESSAGING_API_KEY
      );
    }
  }

  public async getMessagingServicesByOrgID(
    orgID: string,
    options?: { isConnectedToInbox?: boolean }
  ): Promise<MessagingServiceEntity[]> {
    try {
      const result = (await this._sender.send(
        new MessagingServicesQueryByOrgID({
          orgID,
          ...(options && !isNil(options.isConnectedToInbox)
            ? { isConnectedToInbox: options.isConnectedToInbox }
            : {})
        })
      )) as MessagingServicesQueryResult | MessagingServicesQueryFailure;

      if (result instanceof MessagingServicesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.services.map(this._toMessagingServiceEntity);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_MESSAGING_SERVICES
      );
    }
  }

  public async getOrgSettingsByOrgID(
    orgID: string
  ): Promise<OrgSettingEntity[]> {
    try {
      const result = (await this._sender.send(
        new OrgSettingsQueryByOrgID({ orgID })
      )) as OrgSettingsQueryResult | OrgSettingsQueryFailure;

      if (result instanceof OrgSettingsQueryFailure) {
        throw new Error(result.reason);
      }

      return result.settings.map(setting => ({
        id: setting.id,
        name: setting.name,
        value: setting.value
      }));
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_ORG_SETTINGS
      );
    }
  }

  public async getReportByID(reportID: string): Promise<ReportEntity> {
    try {
      const result = (await this._sender.send(
        new ReportQueryByID({ reportID })
      )) as ReportQueryResult | ReportQueryFailure;

      if (result instanceof ReportQueryFailure) {
        throw new Error(result.reason);
      }

      return this._toReportEntity(result.report);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_LOADING_REPORT);
    }
  }

  public async getReportColumnNamesByReportID(
    reportID: string
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(
        new ReportColumnNamesQueryByReportID({ reportID })
      )) as ReportColumnNamesQueryResult | ReportColumnNamesQueryFailure;

      if (result instanceof ReportColumnNamesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.columnNames;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_REPORT_COLUMN_NAMES
      );
    }
  }

  public async getReportsByOrgID(orgID: string): Promise<BaseReportEntity[]> {
    try {
      const result = (await this._sender.send(
        new ReportsQueryByOrgID({ orgID })
      )) as ReportsQueryResult | ReportsQueryFailure;

      if (result instanceof ReportsQueryFailure) {
        throw new Error(result.reason);
      }

      return result.reports.map(this._toBaseReportEntity);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_LOADING_REPORTS);
    }
  }

  public async getSalesforceReportColumnNamesByReportID(
    reportID: string
  ): Promise<string[]> {
    try {
      const result = (await this._sender.send(
        new SalesforceReportColumnNamesQueryByReportID({ reportID })
      )) as
        | SalesforceReportColumnNamesQueryResult
        | SalesforceReportColumnNamesQueryFailure;

      if (result instanceof SalesforceReportColumnNamesQueryFailure) {
        throw new Error(result.reason);
      }

      return result.columnNames;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_SALESFORCE_REPORT_COLUMN_NAMES
      );
    }
  }

  public async getSalesforceReportFoldersByOrgID(
    orgID: string
  ): Promise<SalesforceReportFolderObject[]> {
    try {
      const result = (await this._sender.send(
        new SalesforceReportFoldersQueryByOrgID({ orgID })
      )) as
        | SalesforceReportFoldersQueryResult
        | SalesforceReportFoldersQueryFailure;

      if (result instanceof SalesforceReportFoldersQueryFailure) {
        throw new Error(result.reason);
      }

      return result.folders.map(this._toSalesforceReportFolderObject);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_SALESFORCE_REPORTS
      );
    }
  }

  public async getSalesforceReportByOrgIDAndRID(
    rid: string,
    orgID: string
  ): Promise<SalesforceReportObject> {
    try {
      const result = (await this._sender.send(
        new SalesforceReportQueryByOrgIDAndRID({ rid, orgID })
      )) as SalesforceReportQueryResult | SalesforceReportsQueryFailure;

      if (result instanceof SalesforceReportQueryFailure) {
        throw new Error(result.reason);
      }

      return this._toSalesforceReportObject(result.report);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);
      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_SALESFORCE_REPORT
      );
    }
  }

  public async getSalesforceReportsByOrgIDAndFolderID(
    folderID: string,
    orgID: string
  ): Promise<SalesforceReportObject[]> {
    try {
      const result = (await this._sender.send(
        new SalesforceReportsQueryByOrgIDAndFolderID({ folderID, orgID })
      )) as SalesforceReportsQueryResult | SalesforceReportsQueryFailure;

      if (result instanceof SalesforceReportsQueryFailure) {
        throw new Error(result.reason);
      }

      return result.reports.map(this._toSalesforceReportObject);
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_SALESFORCE_REPORTS
      );
    }
  }

  public async getTwilioMessagingServiceBySID(
    serviceSID: string
  ): Promise<TwilioServiceObject> {
    try {
      const result = (await this._sender.send(
        new TwilioMessagingServiceQueryBySID({ serviceSID })
      )) as
        | TwilioMessagingServiceQueryResult
        | TwilioMessagingServiceQueryFailure;

      if (result instanceof TwilioMessagingServiceQueryFailure) {
        throw new Error(result.reason);
      }

      return result.service;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_TWILIO_MESSAGING_SERVICE
      );
    }
  }

  public async getUserPermissionsByUserID(
    userID: string
  ): Promise<UserPermissionEntity[]> {
    try {
      const result = (await this._sender.send(
        new UserPermissionsQueryByUserID({ userID })
      )) as UserPermissionsQueryResult | UserPermissionsQueryFailure;

      if (result instanceof UserPermissionsQueryFailure) {
        throw new Error(result.reason);
      }

      return result.permissions.map(permission => ({
        id: permission.id,
        userID: permission.userID,
        targetID: permission.targetID,
        type: permission.type
      }));
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_LOADING_USER_PERMISSIONS
      );
    }
  }

  public async updateInbox(
    inboxID: string,
    parameters: UpdateInboxParameters
  ): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new UpdateInboxCommand({ inboxID, ...parameters })
      )) as UpdateInboxCommandAck | UpdateInboxCommandFailure;

      if (ack instanceof UpdateInboxCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.inboxID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      let reason;

      if (
        error.message ===
        UpdateInboxCommandFailure.REASON_DUPLICATE_PROVIDER_RID
      ) {
        reason = 'DUPLICATE_PROVIDER_RID';
      }

      throw new DataAPIClientError(DataAPIClientError.FAILED_UPDATING_INBOX, {
        reason
      });
    }
  }

  public async updateMessageTemplate(
    templateID: string,
    parameters: UpdateMessageTemplateParameters
  ): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new UpdateMessageTemplateCommand({ templateID, ...parameters })
      )) as
        | UpdateMessageTemplateCommandAck
        | UpdateMessageTemplateCommandFailure;

      if (ack instanceof UpdateMessageTemplateCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.templateID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      let reason;

      if (error.message === UpdateReportCommandFailure.REASON_DUPLICATE_NAME) {
        reason = 'DUPLICATE_NAME';
      }

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_UPDATING_MESSAGE_TEMPLATE,
        { reason }
      );
    }
  }

  public async updateReport(
    reportID: string,
    parameters: UpdateReportParameters
  ): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new UpdateReportCommand({ reportID, ...parameters })
      )) as UpdateReportCommandAck | UpdateReportCommandFailure;

      if (ack instanceof UpdateReportCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.reportID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(DataAPIClientError.FAILED_UPDATING_REPORT);
    }
  }

  public async updateOrgSetting(
    orgID: string,
    name: string,
    value: string
  ): Promise<string> {
    try {
      const ack = (await this._sender.send(
        new UpdateOrgSettingCommand({ orgID, name, value })
      )) as UpdateOrgSettingCommandAck | UpdateOrgSettingCommandFailure;

      if (ack instanceof UpdateOrgSettingCommandFailure) {
        throw new Error(ack.reason);
      }

      return ack.settingID;
    } catch (error: any) {
      console.error(error);

      await this._redirectOnAuthError(error);

      throw new DataAPIClientError(
        DataAPIClientError.FAILED_UPDATING_ORG_SETTING
      );
    }
  }

  private _toAPIKeyEntity(
    apiKey: MessagingAPIKeyQueryResult.MessagingAPIKeyEntity
  ): MessagingAPIKeyEntity {
    return {
      id: apiKey.id,
      providerRID: apiKey.providerRID,
      key: apiKey.key,
      secretPreview: apiKey.secretPreview,
      isValid: apiKey.isValid
    };
  }

  private _toInboxEntity(inbox: InboxesQueryResult.InboxEntity): InboxEntity {
    return {
      id: inbox.id,
      providerRID: inbox.providerRID,
      name: inbox.name,
      status: inbox.status,
      attributes: inbox.attributes
    };
  }

  private _toMessagingServiceEntity(
    service: MessagingServicesQueryResult.ServiceEntity
  ): MessagingServiceEntity {
    return {
      providerRID: service.providerRID,
      name: service.name,
      inboxID: service.inboxID
    };
  }

  private _toBaseReportEntity(
    report: ReportsQueryResult.ReportEntity
  ): BaseReportEntity {
    return {
      id: report.id,
      status: report.status,
      timeCreated: report.timeCreated,
      timeModified: report.timeModified,
      name: report.name,
      type: report.type
    };
  }

  private _toReportEntity(
    report: ReportQueryResult.ReportEntity
  ): ReportEntity {
    return {
      id: report.id,
      status: report.status,
      timeCreated: report.timeCreated,
      name: report.name,
      type: report.type,
      rid: report.rid,
      columnMapping: report.columnMapping
    };
  }

  private _toSalesforceReportObject(
    report: SalesforceReportsQueryResult.ReportObject
  ): SalesforceReportObject {
    return {
      id: report.Id,
      name: report.Name
    };
  }

  private _toSalesforceReportFolderObject(
    folder: SalesforceReportFoldersQueryResult.FolderObject
  ): SalesforceReportFolderObject {
    return {
      id: folder.Id,
      name: folder.Name,
      developerName: folder.DeveloperName
    };
  }
}

export default DataAPIClientImpl;
