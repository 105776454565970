import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import ButtonGroup from '@targetx/mineral-ui/ButtonGroup';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import IconSchedule from '@targetx/tx-web-ui-lib/lib/icons/IconSchedule';
import IconCheckCircle from '@targetx/tx-web-ui-lib/lib/icons/IconCheckCircle';
import IconTimesCircle from '@targetx/tx-web-ui-lib/lib/icons/IconTimesCircle';
import Image from '@targetx/tx-web-ui-lib/lib/components/Image';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import noop from 'lodash.noop';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './BroadcastList.copyText';
import { CampaignScheduleStatus } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import { CampaignEntity } from '../types';

export namespace BroadcastList {
  export interface BroadcastEntity {
    id: string;
    name: string;
    campaignID?: string;
    content: string;
    timeCreated: string;
  }

  export interface Props {
    broadcasts: BroadcastEntity[];
    campaigns: CampaignEntity[];
    isLoadingBroadcasts?: boolean;
    selectedID: string;
    onInteraction?: InteractionDelegate;
  }
}

export function BroadcastList({
  broadcasts,
  campaigns,
  isLoadingBroadcasts,
  selectedID,
  onInteraction = noop
}: BroadcastList.Props): ReactElement {
  const [showScheduled, setShowScheduledState] = useState<boolean>(false);

  const handleActionKeyPress =
    (id: string, actionType: string) =>
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      e = e || window.event;
      e.preventDefault();
      if (e.key === 'Enter' || e.key === ' ') {
        if (actionType == 'broadcast') handleClickBroadcast(id);
        if (actionType == 'campaign') handleClickCampaign(id);
      }
    };

  function handleClickBroadcast(broadcastID: string): void {
    onInteraction({
      type: BroadcastList.INTERACTION_ITEM_SELECTED,
      broadcastID
    });
  }

  function handleClickCampaign(campaignID: string): void {
    onInteraction({
      type: BroadcastList.INTERACTION_CAMPAIGN_ITEM_SELECTED,
      campaignID
    });
  }

  function handleClickScheduledFilter(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    setShowScheduledState(Boolean(parseInt(event.target.value)));
  }

  if (isLoadingBroadcasts) {
    return (
      <Flex justifyContent="center" padding={theme.space_inset_md}>
        <SVGSpinner size="2.5em" />
      </Flex>
    );
  }

  return (
    <>
      <Box
        borderBottom={`1px solid ${palette.gray[80]}`}
        borderTop={`1px solid ${palette.gray[80]}`}
        paddingHorizontal={theme.space_inline_md}
        paddingVertical={theme.space_stack_lg}
      >
        <ButtonGroup
          aria-label={copyText.broadcastsFilterAriaLabel}
          defaultChecked={showScheduled ? 1 : 0}
          mode="radio"
          size="small"
          onClick={handleClickScheduledFilter}
          role="tablist"
        >
          <Button
            value={0}
            width="50%"
            aria-label={copyText.broadcastsFilterLabelAll}
            role="tab"
          >
            {copyText.broadcastsFilterLabelAll}
          </Button>
          <Button
            value={1}
            width="50%"
            aria-label={copyText.broadcastsFilterLabelScheduled}
            role="tab"
          >
            {copyText.broadcastsFilterLabelScheduled}
          </Button>
        </ButtonGroup>
      </Box>
      <Box scrollable>
        {!showScheduled && broadcasts.length === 0 && (
          <Text
            align="center"
            color={palette.white}
            marginTop={theme.space_stack_sm}
          >
            {copyText.noBroadcastsMessage}
          </Text>
        )}
        {!showScheduled &&
          broadcasts.map(broadcast => {
            const { id, name, content, timeCreated } = broadcast;
            const isSelected = selectedID === id;

            const campaign = campaigns.find(c => c.id == broadcast.campaignID);

            return (
              <Flex
                key={id}
                alignItems="center"
                backgroundColor={isSelected ? theme.color_mirage : null}
                backgroundColorOnHover={theme.color_mirage}
                borderBottom={`1px solid ${palette.gray[90]}`}
                borderRight={
                  isSelected ? `10px solid ${theme.color_jungle_green}` : null
                }
                cursor="pointer"
                height={75}
                justifyContent="between"
                padding={theme.space_inset_md}
                onClick={() => handleClickBroadcast(id)}
                onKeyPress={handleActionKeyPress(id, 'broadcast')}
                tabIndex="0"
                aria-label={name}
                css={{
                  '&:focus': {
                    outline: `1px solid ${palette.blue[60]}`,
                    outlineOffset: `-1px`
                  }
                }}
              >
                <Flex alignItems="center" width="85%">
                  <Image
                    height={32}
                    src="tx-sms-app-logo.3f630761.svg"
                    alt={copyText.bulkMessagesLabel}
                  />
                  <Flex
                    direction="column"
                    marginLeft={theme.space_inline_sm}
                    overflow="hidden"
                  >
                    <Text
                      color={palette.white}
                      fontSize={theme.fontSize_base}
                      fontWeight={theme.fontWeight_semiBold}
                      truncate={250}
                    >
                      {name}
                    </Text>
                    <Text
                      color={palette.white}
                      fontSize={theme.fontSize_ui_sm}
                      truncate={250}
                    >
                      {content}
                    </Text>
                    <Text color={palette.white} fontSize={theme.fontSize_mouse}>
                      {dateFormat(timeCreated, 'mm/dd/yyyy hh:MMTT')}
                    </Text>
                  </Flex>
                </Flex>
                {campaign?.schedule && (
                  <Flex>
                    <IconSchedule color={palette.gray[50]} size={18} />
                  </Flex>
                )}
              </Flex>
            );
          })}
        {showScheduled && campaigns.length === 0 && (
          <Text
            align="center"
            color={palette.white}
            marginTop={theme.space_stack_sm}
          >
            {copyText.noScheduledMessage}
          </Text>
        )}
        {showScheduled &&
          campaigns
            .filter(c => c.schedule)
            .map(campaign => {
              const { id, name, content } = campaign;

              const isSelected = selectedID === id;

              return (
                <Flex
                  key={id}
                  alignItems="center"
                  backgroundColor={isSelected ? theme.color_mirage : null}
                  backgroundColorOnHover={theme.color_mirage}
                  borderBottom={`1px solid ${palette.gray[90]}`}
                  borderRight={
                    isSelected ? `10px solid ${theme.color_jungle_green}` : null
                  }
                  cursor="pointer"
                  height={75}
                  justifyContent="between"
                  padding={theme.space_inset_md}
                  onClick={() => handleClickCampaign(id)}
                  onKeyPress={handleActionKeyPress(id, 'campaign')}
                  tabIndex="0"
                  aria-label={name}
                  css={{
                    '&:focus': {
                      outline: `1px solid ${palette.blue[60]}`,
                      outlineOffset: `-1px`
                    }
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="between"
                    width="100%"
                  >
                    <Image
                      height={32}
                      src="tx-sms-app-logo.3f630761.svg"
                      alt={copyText.bulkMessagesLabel}
                    />
                    <Flex
                      direction="column"
                      marginLeft={theme.space_inline_sm}
                      overflow="hidden"
                      width="70%"
                    >
                      <Text
                        color={palette.white}
                        fontSize={theme.fontSize_base}
                        fontWeight={theme.fontWeight_semiBold}
                        truncate={250}
                      >
                        {name}
                      </Text>
                      <Text
                        color={palette.white}
                        fontSize={theme.fontSize_ui_sm}
                        truncate={250}
                      >
                        {content}
                      </Text>
                    </Flex>
                    <Flex
                      direction="column"
                      alignItems="end"
                      width="30%"
                      marginTop="0.5em"
                    >
                      <IconSchedule color={palette.gray[50]} size={18} />
                      <Flex
                        marginTop="0.6em"
                        direction="column"
                        alignItems="end"
                      >
                        {campaign.scheduleStatus ===
                        CampaignScheduleStatus.DISABLED ? (
                          <FlexItem flex alignItems="center">
                            <IconTimesCircle
                              color={theme.color_danger}
                              size={16}
                              solid
                              css={{
                                background: palette.white,
                                clipPath: 'circle(7px)'
                              }}
                            />
                            &nbsp;
                            <Text color={palette.white} fontSize="1em">
                              Inactive
                            </Text>
                          </FlexItem>
                        ) : (
                          <FlexItem flex alignItems="center">
                            <IconCheckCircle
                              color={palette.green[70]}
                              size={16}
                              solid
                              css={{
                                background: palette.white,
                                clipPath: 'circle(7px)'
                              }}
                            />
                            &nbsp;
                            <Text color={palette.white} fontSize="1em">
                              Active
                            </Text>
                          </FlexItem>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
      </Box>
    </>
  );
}

BroadcastList.INTERACTION_ITEM_SELECTED = `${BroadcastList.name}.INTERACTION_ITEM_SELECTED`;
BroadcastList.INTERACTION_CAMPAIGN_ITEM_SELECTED = `${BroadcastList.name}.INTERACTION_CAMPAIGN_ITEM_SELECTED`;
BroadcastList.INTERACTION_FILTER_BROADCAST = `${BroadcastList.name}.INTERACTION_FILTER_BROADCAST`;

export default BroadcastList;
