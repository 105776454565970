import palette from '@targetx/mineral-ui/themes/generated/palette';
import theme from '../theme';

export const ActionPanelLayoutBody = {
  backgroundColor: palette.gray[10],
  padding: theme.space_inset_md,
  scrollable: true
};

export const ActionPanelLayoutFooter = {
  flex: true,
  justifyContent: 'between',
  padding: theme.space_inset_md
};

export const ActionPanelLayoutHeader = {
  alignItems: 'center',
  flex: true,
  justifyContent: 'between',
  minHeight: theme.space_stack_xxl,
  paddingHorizontal: theme.space_inline_md,
  paddingVertical: theme.space_stack_sm
};

export default {
  ActionPanelLayoutBody,
  ActionPanelLayoutFooter,
  ActionPanelLayoutHeader
};
