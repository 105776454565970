/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';
import { UserPermissionEntity } from './types';

interface State {
  readonly data?: UserPermissionEntity[];
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (userID: string) => Promise<void>;
}

export default function useDataAPIGetUserPermissionsByUserID(
  userID?: string
): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(userID: string): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getUserPermissionsByUserID(userID);

      setState({ isLoading: false, data });
    } catch (error: any) {
      setState({ isLoading: false, error });
    }
  }

  useEffect(() => {
    if (!userID) return;

    fetch(userID);
  }, []);

  return { ...state, refetch: fetch };
}
