export default {
  cancelButtonLabel: 'CANCEL',
  contentInputCaption: '%count% characters left.',
  contentInputLabel: 'Content',
  isGlobalInputDescription:
    'Make this template visible to all TX SMS users in your organization.',
  isGlobalInputLabel: 'Global',
  nameInputLabel: 'Name',
  submitButtonLabel: 'CREATE',
  title: 'Create Message Template'
};
