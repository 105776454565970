export default {
  contentInputCaptionCount: '%count% characters left.',
  contentInputCaptionWarning:
    "Sorry, you can't use merge fields when sending a 1:1 message.",
  contentInputLabel: 'Content',
  contentInputPlaceholder: 'Type your message here',
  submitButtonLabel: 'SEND',
  templateInputLabel: 'Message Template (Optional)',
  templateInputPlaceholder: 'Message Template (Optional)',
  templateOptionGroupLabel_GLOBAL: 'GLOBAL',
  templateOptionGroupLabel_PERSONAL: 'PERSONAL'
};
