export default {
  apiKeyInputLabel: 'Account SID',
  apiSecretInputCaption:
    "Please enter the Twilio Account SID and Auth Token that you'd like our system to use. Rest assured, our system securely stores your Auth Token using an industry-standard AES-256 encryption algorithm.",
  apiSecretInputLabel: 'Auth Token',
  header:
    "Our system uses the Twilio API for sending and receiving SMS messages on your organization's behalf.",
  resetButtonLabel: 'RESET',
  submitButtonLabel: 'UPDATE'
};
