/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField, FormFieldDivider } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { ReportType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import { InteractionDelegate } from '@targetx/tx-web-ui-lib/lib/types/Interaction';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import { ValueType } from 'react-select';
import isEmpty from 'validator/lib/isEmpty';
import theme from '../theme';
import { Input, Option } from '../types/Form';
import { PartialState } from '../types/PartialState';
import copyText from './EditReportForm.copyText';
import MoreInfoPopover from './MoreInfoPopover';

export namespace EditReportForm {
  interface ReportEntity {
    id: string;
    name: string;
    type: ReportType;
    columnMapping?: { [key: string]: string };
  }

  export interface Props {
    isLoadingReportColumnNames?: boolean;
    isLoadingSalesforceReportName?: boolean;
    isProcessing: boolean;
    message?: ReactNode;
    report?: ReportEntity;
    reportColumnNames?: string[];
    salesforceReportName?: string;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  contactIDColumnNameInput: Input;
  firstNameColumnNameInput: Input;
  lastNameColumnNameInput: Input;
  mobilePhoneNumberColumnNameInput: Input;
  nameInput: Input;
}

export function EditReportForm({
  isLoadingReportColumnNames,
  isLoadingSalesforceReportName,
  isProcessing,
  message,
  report,
  reportColumnNames,
  salesforceReportName,
  onInteraction = noop
}: EditReportForm.Props): ReactElement {
  if (!report) {
    return (
      <Flex alignItems="center" height="100%" justifyContent="center">
        <SVGSpinner size="2.5em" />
      </Flex>
    );
  }

  //
  // State
  //

  const initialState = {
    contactIDColumnNameInput: {
      value: report.columnMapping?.contactID ?? '',
      hasChanged: false,
      isValid: true
    },
    firstNameColumnNameInput: {
      value: report.columnMapping?.firstName ?? '',
      hasChanged: false,
      isValid: true
    },
    lastNameColumnNameInput: {
      value: report.columnMapping?.lastName ?? '',
      hasChanged: false,
      isValid: true
    },
    mobilePhoneNumberColumnNameInput: {
      value: report.columnMapping?.mobilePhoneNumber ?? '',
      hasChanged: false,
      isValid: true
    },
    nameInput: { value: report.name ?? '', hasChanged: false, isValid: true }
  };

  const [state, setState] = useState<State>(initialState);

  const {
    contactIDColumnNameInput,
    firstNameColumnNameInput,
    lastNameColumnNameInput,
    mobilePhoneNumberColumnNameInput,
    nameInput
  } = state;

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  //
  // Interaction Handlers
  //

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    let isValid = true;

    const hasChanged =
      initialState[`${name}Input` as keyof State].value !== value;

    switch (name) {
      case 'name':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      default:
        break;
    }

    changeState({ [`${name}Input`]: { value, hasChanged, isValid } });
  };

  function handleReset(): void {
    onInteraction({
      type: EditReportForm.INTERACTION_CANCEL_BUTTON_CLICKED
    });
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      reportID: report?.id,
      type: EditReportForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      ...(nameInput.hasChanged ? { name: nameInput.value.trim() } : {}),
      contactIDColumnName: contactIDColumnNameInput.value,
      firstNameColumnName: firstNameColumnNameInput.value,
      lastNameColumnName: lastNameColumnNameInput.value,
      mobilePhoneNumberColumnName: mobilePhoneNumberColumnNameInput.value
    });
  }

  //
  // Render
  //

  const reportColumnNameOptions = reportColumnNames?.map(reportColumnName => ({
    label: reportColumnName,
    value: reportColumnName
  }));

  const contactIDColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === contactIDColumnNameInput.value
    ) || null;

  const firstNameColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === firstNameColumnNameInput.value
    ) || null;

  const lastNameColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === lastNameColumnNameInput.value
    ) || null;

  const mobilePhoneNumberColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === mobilePhoneNumberColumnNameInput.value
    ) || null;

  const canSubmit =
    !isProcessing &&
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  function renderColumnMappingInputs(): ReactElement {
    return (
      <Box marginTop={theme.space_stack_md}>
        <FormFieldDivider color={palette.gray[30]} marginVertical={20} />
        <Text fontSize={theme.fontSize_ui_sm}>
          {copyText.columnMappingDescription}
        </Text>
        <FormField
          label={<Text>{copyText.contactIDColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.contactIDColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            name="contactIDColumnName"
            isClearable
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={contactIDColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'contactIDColumnName',
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={<Text>{copyText.firstNameColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.firstNameColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            name="firstNameColumnName"
            isClearable
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={firstNameColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'firstNameColumnName',
                  value: (option as Option)?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={<Text>{copyText.lastNameColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.lastNameColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            name="lastNameColumnName"
            isClearable
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={lastNameColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'lastNameColumnName',
                  value: (option as Option)?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={<Text>{copyText.mobilePhoneNumberColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.mobilePhoneNumberColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            name="mobilePhoneNumberColumnName"
            isClearable
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={mobilePhoneNumberColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'mobilePhoneNumberColumnName',
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
      </Box>
    );
  }

  function renderSalesforceReportInputs(): ReactElement {
    return (
      <>
        <Text
          fontWeight={theme.fontWeight_semiBold}
          marginTop={theme.space_stack_md}
        >
          {copyText.salesforceReportLabel}
        </Text>
        {isLoadingSalesforceReportName ? (
          <Flex alignItems="center" justifyContent="center" width="100%">
            <SVGSpinner size="2.5em" />
          </Flex>
        ) : (
          salesforceReportName && (
            <Text marginTop={theme.space_stack_sm}>{salesforceReportName}</Text>
          )
        )}
      </>
    );
  }

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header
        flex
        alignItems="center"
        minHeight={theme.space_stack_xxl}
        padding={theme.space_stack_md}
      >
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
      </Layout.Header>
      <Layout.Body
        backgroundColor={palette.gray[10]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}
        <FormField
          name="name"
          autoFocus
          input={TextInput}
          label={
            <Text color={isValidInput(nameInput) ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={nameInput.value}
          variant={nameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        {renderSalesforceReportInputs()}
        {renderColumnMappingInputs()}
      </Layout.Body>
      <Layout.Footer
        flex
        justifyContent="between"
        padding={theme.space_stack_md}
      >
        <Button
          type="reset"
          variant="grayscale"
          width="48%"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          primary
          type="submit"
          width="48%"
        >
          {isProcessing ? (
            <Flex alignItems="center" justifyContent="center">
              <SVGSpinner fill={palette.white} size="2em" />
            </Flex>
          ) : (
            copyText.submitButtonLabel
          )}
        </Button>
      </Layout.Footer>
      /
    </Layout>
  );
}

function isValidInput({ value, isValid }: Input): boolean {
  return isEmpty(value) || isValid;
}

EditReportForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${EditReportForm.name}/INTERACTION_CANCEL_BUTTON_CLICKED`;
EditReportForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditReportForm.name}/INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default EditReportForm;
