export default {
  backButtonLabel: 'BACK',
  bulkMessageDetails: 'Bulk Message Details',
  createBulkMessage: 'Create Bulk Messages',
  createButtonLabel: 'CREATE',
  scheduleButtonLabel: 'SCHEDULE',
  discardBroadcastConfirmationMessage:
    'Changes to this bulk message will be discarded. Are you sure you want to proceed?',
  discardBroadcastConfirmationTitle: 'DISCARD BULK MESSAGE CHANGES',
  FAILURE_CREATING_CAMPAIGN_message:
    'An error occurred while creating the campaign. Please try again.',
  FAILURE_UPDATING_CAMPAIGN_message:
    'An error occurred while updating the campaign. Please try again.',
  FAILURE_INBOX_NOT_FOUND_message:
    'The specified inbox does not exist. Please verify you have the correct inbox ID and try again.',
  FAILURE_INBOX_PERMISSION_DENIED_message:
    'You do not have permission to access the specified inbox. Please verify access has been granted by your administrator and try again.',
  FAILURE_INITIALIZING_message:
    'An error occurred while initializing this screen. Please try again.',
  FAILURE_LOADING_BROADCASTS_message:
    'An error occurred while loading broadcasts. Please try again.',
  FAILURE_LOADING_MESSAGES_message:
    'Sorry, the system failed to load the outgoing messages and recipients for this broadcast. Please refresh the page and try again.',
  ERROR_LOADING_ORG_SETTINGS_message:
    'An unexpected error occurred while attempting to load settings for your org. Please reload the current browser window and try again.',
  FAILURE_LOADING_RECIPIENTS_message:
    'Sorry, the system failed to load the recipients for this broadcast. Please refresh the page and try again.',
  FAILURE_LOADING_REPORT_message:
    "The system failed to load the selected report's column mapping configuration. Please change the report and try again.",
  FAILURE_LOADING_REPORT_COLUMN_NAMES_message:
    'Operation Failed. Sorry, the system failed to load the specified report.',
  FAILURE_LOADING_REPORT_RECORD_COUNT_message:
    'Sorry, the system failed to load the recipient count for this report. Please try again.',
  FAILURE_title: 'Operation Failed.',
  recipient_count_button:
    '' +
    'This report contains %count% recipients based on the Bulk Message Owner.',
  report_recipient_button: 'Report Recipient Count',
  settingsLinkLabel: 'Navigate To Settings',
  searchInputPlaceholder: 'Search Bulk Messages',
  sendBroadcastConfirmationMessage:
    'You are about to send a bulk message to %count% recipients. Are you sure you want to do this?',
  sendBroadcastConfirmationTitle: 'SEND BULK MESSAGE',
  sendCampaignConfirmationMessage:
    'You are scheduling a bulk message to be sent %schedule%. Do you wish to continue?',
  sendCampaignConfirmationTitle: 'CONFIRM BULK MESSAGE SCHEDULE',
  SUCCESS_CAMPAIGN_CREATED_message: 'The broadcast %name% has been initiated.',
  SUCCESS_CAMPAIGN_UPDATED_message:
    'The broadcast %name% has been successfully updated.',
  title: 'Bulk Messages',
  broadcastsFilterLabelAll: '0',
  broadcastsFilterLabelScheduled: '1'
};
