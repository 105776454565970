import Flex from '@targetx/mineral-ui/Flex';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { ReportType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import dateFormat from 'dateformat';
import get from 'lodash.get';
import React, { ReactElement, ReactNode } from 'react';
import theme from '../theme';
import copyText from './ReportList.copyText';

const DATE_COLUMN_MIN_WIDTH = 145; // TODO: If we use this in other tables, move to constants/index

export namespace ReportList {
  export interface ReportEntity {
    id: string;
    name: string;
    timeCreated: string;
    timeModified?: string;
    type: ReportType;
  }

  export interface Props {
    isLoading?: boolean;
    renderItemMenu?: ({ reportID }: { reportID: string }) => ReactNode;
    reports: ReportEntity[];
  }
}

interface RowData {
  id: string;
  row: () => ReactElement;
}

const columns = [
  {
    key: copyText.headerName,
    borderless: true,
    content: copyText.headerName,
    minWidth: 300
  },
  {
    key: copyText.headerType,
    borderless: true,
    content: copyText.headerType,
    minWidth: 145
  },
  {
    key: copyText.headerTimeModified,
    borderless: true,
    content: copyText.headerTimeModified,
    minWidth: DATE_COLUMN_MIN_WIDTH
  },
  {
    key: copyText.headerTimeCreated,
    borderless: true,
    content: copyText.headerTimeCreated,
    minWidth: DATE_COLUMN_MIN_WIDTH
  },
  {
    key: copyText.headerAction,
    borderless: true,
    content: <Text hidden>{copyText.headerAction}</Text>,
    label: copyText.headerAction,
    sortable: false,
    width: '20px'
  }
];

export function ReportList({
  isLoading,
  renderItemMenu,
  reports
}: ReportList.Props): ReactElement {
  let data: RowData[];

  if (isLoading || reports.length === 0) {
    const render = (): ReactElement => (
      <TableRow>
        <TableCell colSpan={5}>
          <Flex alignItems="center" height="75px" justifyContent="center">
            {isLoading ? (
              <SVGSpinner size="2.5em" />
            ) : (
              <Text color={palette.gray[70]}>{copyText.noReportsMessage}</Text>
            )}
          </Flex>
        </TableCell>
      </TableRow>
    );

    data = [{ id: '_', row: render }];
  } else {
    data = reports.map(report => {
      const render = (): ReactElement => (
        <ReportListItem renderItemMenu={renderItemMenu} report={report} />
      );

      return { id: report.id, row: render };
    });
  }

  return (
    <Table
      rowKey="id"
      border={`1px solid ${palette.gray[50]}`}
      borderRadius={theme.borderRadius_2}
      columns={columns}
      data={data}
      striped
      title={copyText.title}
      titleAs="h2"
      width="100%"
    />
  );
}

function ReportListItem({
  renderItemMenu,
  report
}: {
  renderItemMenu?: ({ reportID }: { reportID: string }) => ReactNode;
  report: ReportList.ReportEntity;
}): ReactElement {
  const { id, name, timeCreated, timeModified, type } = report;

  const typeCopyText = get(copyText, `reportType_${type}`, '');

  return (
    <TableRow>
      <TableCell verticalAlign="middle">
        <Text fontWeight={theme.fontWeight_semiBold}>{name}</Text>
      </TableCell>
      <TableCell verticalAlign="middle">
        <Text>{typeCopyText}</Text>
      </TableCell>
      <TableCell verticalAlign="middle">
        {timeModified ? dateFormat(timeModified, 'dd mmmm yyyy') : copyText.NA}
      </TableCell>
      <TableCell verticalAlign="middle">
        {dateFormat(timeCreated, 'dd mmmm yyyy')}
      </TableCell>
      <TableCell textAlign="right" verticalAlign="middle">
        {renderItemMenu ? renderItemMenu({ reportID: id }) : null}
      </TableCell>
    </TableRow>
  );
}

export default ReportList;
