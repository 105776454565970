import { BaseUserEntity } from '../types';

export function getFullName(user: BaseUserEntity): string {
  if (!user) return '';

  return `${user.firstName} ${user.lastName}`;
}

export function getInitials(user: BaseUserEntity): string {
  if (!user) return '';

  const firstNameInitial = user.firstName ? user.firstName[0] : '';
  const lastNameInitial = user.lastName ? user.lastName[0] : '';
  return `${firstNameInitial}${lastNameInitial}`;
}

export default {
  getFullName,
  getInitials
};
