/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Avatar from '@targetx/mineral-ui/Avatar';
import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import ButtonGroup from '@targetx/mineral-ui/ButtonGroup';
import Dropdown from '@targetx/mineral-ui/Dropdown';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import isNil from 'lodash.isnil';
import MarkConversationAsReadCommand, {
  MarkConversationAsReadCommandAck,
  MarkConversationAsReadCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/MarkConversationAsReadCommand';
import SendMessageCommand, {
  SendMessageCommandAck,
  SendMessageCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/SendMessageCommand';
import {
  ContactType,
  InboxStatus,
  UserInboxPermissionType
} from '@targetx/tx-sms-api-lib/lib/constants/enums';
import {
  ContactMappingsQueryByPhoneNumbers,
  ContactMappingsQueryFailure,
  ContactMappingsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactMappingsQuery';
import {
  ContactRecordQueryByRID,
  ContactRecordQueryFailure,
  ContactRecordQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactRecordQuery';
import {
  ContactRecordsQueryByName,
  ContactRecordsQueryFailure,
  ContactRecordsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ContactRecordsQuery';
import {
  ConversationsQueryByInboxID,
  ConversationsQueryFailure,
  ConversationsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ConversationsQuery';
import {
  ConversationsUnreadQueryByInboxID,
  ConversationsUnreadQueryFailure,
  ConversationsUnreadQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/ConversationsUnreadQuery';
import CreateContactMappingAttributesCommand, {
  CreateContactMappingAttributesCommandAck,
  CreateContactMappingAttributesCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/CreateContactMappingAttributesCommand';
import {
  InboxesQueryByIDs,
  InboxesQueryFailure,
  InboxesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/InboxesQuery';
import {
  MessageSendersQueryByInboxID,
  MessageSendersQueryFailure,
  MessageSendersQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageSendersQuery';
import {
  MessagesQueryByConversationID,
  MessagesQueryFailure,
  MessagesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagesQuery';
import {
  MessageTemplatesQueryByOwnerID,
  MessageTemplatesQueryFailure,
  MessageTemplatesQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessageTemplatesQuery';
import {
  UserPermissionsQueryByUserID,
  UserPermissionsQueryFailure,
  UserPermissionsQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/UserPermissionsQuery';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import Image from '@targetx/tx-web-ui-lib/lib/components/Image';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import IconChevronCircleDown from '@targetx/tx-web-ui-lib/lib/icons/IconChevronCircleDown';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import IconCog from '@targetx/tx-web-ui-lib/lib/icons/IconCog';
import IconComments from '@targetx/tx-web-ui-lib/lib/icons/IconComments';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconQuestionCircle from '@targetx/tx-web-ui-lib/lib/icons/IconQuestionCircle';
import IconTimesCircle from '@targetx/tx-web-ui-lib/lib/icons/IconTimesCircle';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import get from 'lodash.get';
import keyBy from 'lodash.keyby';
import noop from 'lodash.noop';
import { formatE164, formatLocal } from 'phoneformat.js';
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import NavigateCommand from '../commands/NavigateCommand';
import ContactInfoComponent from '../components/ContactInfoComponent';
import ConversationList from '../components/ConversationList';
import MessageList from '../components/MessageList';
import ScreenLevelAlert from '../components/ScreenLevelAlert';
import SearchContactsForm from '../components/SearchContactsForm';
import SendMessageForm from '../components/SendMessageForm';
import paths from '../constants/paths';
import useDataAPIGetOrgSettingsByOrgID from '../data-api/useDataAPIGetOrgSettingsByOrgID';
import DispatcherContext from '../DispatcherContext';
import { bmStyles, modalStyles } from '../styles';
import {
  BaseUserEntity,
  ContactMappingEntity,
  ContactRecord,
  InboxEntity,
  MessageEntity,
  MessageTemplateEntity,
  UserEntity,
  UserPermissionEntity,
  UpdateContactRecord
} from '../types';
import Interaction from '../types/Interaction';
import LogMeasureCount from '../utils/LogMeasureUtils';
import { PartialState } from '../types/PartialState';
import { getFullName, getInitials } from '../utils/ContactMappingUtils';
import toUUID from '../utils/toUUID';
import copyText from './MessagingScreen.copyText';
import UpdateContactMappingAttributesCommand, {
  UpdateContactMappingAttributesCommandAck,
  UpdateContactMappingAttributesCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateContactMappingAttributesCommand';
import UpdateMergeMessageCommand, {
  UpdateMergeMessageCommandAck,
  UpdateMergeMessageCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateMergeMessageCommand';

const ACTION_PANEL_SEARCH_CONTACTS_FORM = 'SEARCH_CONTACTS_FORM';

const ERROR_LOADING_ORG_SETTINGS = 'ERROR_LOADING_ORG_SETTINGS';

const FAILURE_INITIALIZING = 'FAILURE_INITIALIZING';
const FAILURE_LOADING_CONTACT = 'FAILURE_LOADING_CONTACT';
const FAILURE_LOADING_CONTACTS = 'FAILURE_LOADING_CONTACTS';
const FAILURE_LOADING_MESSAGES = 'FAILURE_LOADING_MESSAGES';
const FAILURE_LOADING_MESSAGES_AFTER_SEND =
  'FAILURE_LOADING_MESSAGES_AFTER_SEND';
const FAILURE_MERGING_CONTACT = 'FAILURE_MERGING_CONTACT';
const FAILURE_SENDING_MESSAGE = 'FAILURE_SENDING_MESSAGE';
const FAILURE_UPDATING_CONTACT_MAPPING_ATTRIBUTES =
  'FAILURE_UPDATING_CONTACT_MAPPING_ATTRIBUTES';

//The limit clause to limit the number of rows returned from a query.
const LIMIT_RECORDS = 100;
const MODAL_DISCARD_CONFIRMATION = 'DISCARD_CONFIRMATION';
const POLL_INTERVAL = 7000;

const SETTING_NAME_DEFAULT_COUNTRY_CODE = 'defaultCountryCode';
const SUCCESS_UPDATING_CONTACT_MAPPING_ATTRIBUTES =
  'FAILURE_UPDATING_CONTACT_MAPPING_ATTRIBUTES';
const SYSTEM_DEFAULT_COUNTRY_CODE = 'US';

const WARNING_LOADING_TEMPLATES = 'WARNING_LOADING_TEMPLATES';

export namespace MessagingScreen {
  export interface Props {
    authenticatedUser: UserEntity;
    homeBaseURL: string;
    inboxID?: string;
    messageTranscriptsBasePath?: string;
    path?: string;
    signOutPath: string;
  }
}

interface ConversationEntity {
  id: string;
  inboxID: string;
  canonicalPhoneNumber: string;
  lastMarkedAsReadByUserID?: string;
  timeLastMarkedAsRead?: string;
  lastMessage: {
    timeCreated: string;
    content: string;
  };
  lastIncomingMessage?: {
    timeCreated: string;
  };
  contact?: {
    firstName: string;
    lastName: string;
  };
}

interface InsertContactParameters {
  orgID: string;
  inboxID: string;
  userID: string;
  phoneNumber: string;
  contactRID?: string;
  contactFirstName?: string;
  contactLastName?: string;
}

interface updateMergeParameters {
  orgID: string;
  inboxID: string;
  userID: string;
  conversationID: string;
  phoneNumber: string;
  contactMappingID: string;
  contactToPurge: string;
}

interface SendMessageParameters {
  orgID: string;
  inboxID: string;
  userID: string;
  phoneNumber: string;
  content: string;
  contactRID?: string;
  contactFirstName?: string;
  contactLastName?: string;
}

interface State {
  actionPanelKey: string;
  alertKey: string;
  bodyMessage: string;
  contact: ContactRecord | undefined;
  contactSelected: ContactMappingEntity | undefined;
  contactMappings: ContactMappingEntity[];
  conversations: ConversationEntity[];
  inboxes: InboxEntity[];
  isCreatingMessage: boolean;
  isInitializing: boolean;
  isLoadingContact: boolean;
  isLoadingMessages: boolean;
  isLoadingSearchResultContacts: boolean;
  isLoadingTemplates: boolean;
  isUpdatingContactMappingAttributes: boolean;
  messages: MessageEntity[];
  modalComponentKey: string;
  permissions: UserPermissionEntity[];
  mergeParameters: updateMergeParameters | undefined;
  searchResultContacts: ContactRecord[];
  searchTerm: string;
  selectedContactRID: string;
  selectedConversationID: string;
  selectedConversationLast: object;
  showUnreadConversationsOnly: boolean;
  templates: MessageTemplateEntity[];
  users: BaseUserEntity[];
  isNextPageLoading: boolean;
  insertParameters: InsertContactParameters | undefined;
  hasNextPage: boolean;
  currentPage: number;
}

const initialState: State = {
  actionPanelKey: '',
  alertKey: '',
  bodyMessage: '',
  contact: undefined,
  contactSelected: undefined,
  contactMappings: [],
  conversations: [],
  inboxes: [],
  isCreatingMessage: false,
  isInitializing: true,
  isLoadingContact: false,
  isLoadingMessages: false,
  isLoadingSearchResultContacts: false,
  isLoadingTemplates: false,
  isUpdatingContactMappingAttributes: false,
  messages: [],
  modalComponentKey: '',
  permissions: [],
  mergeParameters: undefined,
  searchResultContacts: [],
  searchTerm: '',
  selectedContactRID: '',
  selectedConversationID: '',
  selectedConversationLast: {},
  showUnreadConversationsOnly: false,
  templates: [],
  users: [],
  isNextPageLoading: false,
  insertParameters: undefined,
  hasNextPage: true,
  currentPage: 0
};

export function MessagingScreen({
  authenticatedUser,
  homeBaseURL,
  inboxID = '',
  messageTranscriptsBasePath,
  signOutPath
}: MessagingScreen.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    actionPanelKey,
    alertKey,
    bodyMessage,
    contact,
    contactSelected,
    contactMappings,
    conversations: _conversations,
    isCreatingMessage,
    isInitializing,
    isLoadingContact,
    isLoadingMessages,
    isLoadingSearchResultContacts,
    isLoadingTemplates,
    messages: _messages,
    mergeParameters,
    modalComponentKey,
    permissions,
    searchResultContacts,
    searchTerm,
    selectedContactRID,
    selectedConversationID,
    selectedConversationLast,
    showUnreadConversationsOnly,
    templates,
    users,
    isNextPageLoading,
    insertParameters,
    hasNextPage
  } = state;

  let inboxes = state.inboxes;

  //
  // Hooks
  //

  const { data: orgSettings, error: errorLoadingOrgSettings } =
    useDataAPIGetOrgSettingsByOrgID(authenticatedUser.orgID);

  //
  // Side Effects
  //

  useEffect(() => {
    if (!errorLoadingOrgSettings) return;
    changeState({ alertKey: ERROR_LOADING_ORG_SETTINGS });
  }, [errorLoadingOrgSettings]);

  // TODO: Discuss best way to handle multiple contacts tied to the same phone number.
  const contactMappingsKeyedByPhoneNumber = useMemo(
    () => keyBy(contactMappings, 'canonicalPhoneNumber'),
    [contactMappings]
  );

  const searchResultContactsKeyedByRID = useMemo(
    () => keyBy(searchResultContacts, 'rid'),
    [searchResultContacts]
  );

  const defaultCountryCodeSetting = useMemo(
    () =>
      orgSettings?.find(
        setting => setting.name === SETTING_NAME_DEFAULT_COUNTRY_CODE
      ),
    [orgSettings]
  );

  const countryCode = defaultCountryCodeSetting
    ? defaultCountryCodeSetting.value
    : SYSTEM_DEFAULT_COUNTRY_CODE;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  function getPhoneNumbers(arrConversation: ConversationEntity[]): any {
    return arrConversation.map(item => item.canonicalPhoneNumber);
  }

  function getFilteredContacts(
    arrContacts: ContactMappingEntity[],
    search: string
  ): any {
    return arrContacts.filter(contact => {
      const fullName = getFullName(contact);
      const phone = contact.canonicalPhoneNumber;
      if (
        fullName.toLowerCase().match(search.toLowerCase()) ||
        phone.match(search)
      ) {
        return contact;
      }
    });
  }

  async function initialize(
    inboxID: string,
    page: number,
    search: string,
    isUnRead = false
  ): Promise<void> {
    changeState({ isInitializing: true });

    const marks = new LogMeasureCount(
      [
        'Initialize',
        'User Permissions Query',
        'Inboxes Query',
        'Messages Query',
        'Contacts Query',
        'Users Query'
      ],
      inboxID,
      authenticatedUser.orgID,
      authenticatedUser.org.name,
      authenticatedUser.salesforceID
    );

    marks.setPerformance(0);
    const userPermissionsQueryResult = await dispatcher.dispatch<
      UserPermissionsQueryResult | UserPermissionsQueryFailure
    >(new UserPermissionsQueryByUserID({ userID: authenticatedUser.id }));

    if (userPermissionsQueryResult instanceof UserPermissionsQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const permissions = userPermissionsQueryResult.permissions;
    const inboxIDs = permissions.reduce((inboxIDs: string[], permission) => {
      return permission.type === UserInboxPermissionType.CAN_ACCESS
        ? [...inboxIDs, permission.targetID]
        : inboxIDs;
    }, []);

    marks.setPerformance(1);

    const inboxesQueryResult = await dispatcher.dispatch<
      InboxesQueryResult | InboxesQueryFailure
    >(new InboxesQueryByIDs({ inboxIDs }));

    if (inboxesQueryResult instanceof InboxesQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const inboxes = inboxesQueryResult.inboxes.filter(
      inbox => inbox.status !== InboxStatus.DEACTIVATED
    );

    if (!inboxID) {
      changeState({ inboxes, isInitializing: false, permissions });
      return;
    }

    marks.setPerformance(2);

    let query;
    let conversationQueryArr: ConversationEntity[] = [];
    const currentPage = page + 1;

    //The offset clause skips the offset rows before beginning to return the rows.
    const offset = currentPage === 1 ? 0 : LIMIT_RECORDS * (currentPage - 1);

    if (isUnRead) {
      try {
        query = new ConversationsUnreadQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset,
          search
        });
      } catch {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }

      const conversationsUnreadQueryResult = await dispatcher.dispatch<
        ConversationsUnreadQueryResult | ConversationsUnreadQueryFailure
      >(query);

      if (
        conversationsUnreadQueryResult instanceof
        ConversationsUnreadQueryFailure
      ) {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }
      conversationQueryArr = conversationsUnreadQueryResult.conversations;
    } else {
      try {
        query = new ConversationsQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset,
          search
        });
      } catch {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }

      const conversationsQueryResult = await dispatcher.dispatch<
        ConversationsQueryResult | ConversationsQueryFailure
      >(query);

      if (conversationsQueryResult instanceof ConversationsQueryFailure) {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }
      conversationQueryArr = conversationsQueryResult.conversations;
    }

    const usersQueryResult = await dispatcher.dispatch<
      MessageSendersQueryResult | MessageSendersQueryFailure
    >(new MessageSendersQueryByInboxID({ inboxID }));

    if (usersQueryResult instanceof MessageSendersQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    marks.setPerformance(3);

    const phoneNumbers = getPhoneNumbers(conversationQueryArr);

    const contactMappingsQueryResult = await dispatcher.dispatch<
      ContactMappingsQueryResult | ContactMappingsQueryFailure
    >(new ContactMappingsQueryByPhoneNumbers({ phoneNumbers }));

    if (contactMappingsQueryResult instanceof ContactMappingsQueryFailure) {
      changeState({
        alertKey: FAILURE_INITIALIZING,
        isInitializing: false
      });
      return;
    }

    let realContacts = contactMappingsQueryResult.mappings;
    if (search.length > 2) {
      realContacts = getFilteredContacts(
        contactMappingsQueryResult.mappings,
        search
      );
    }

    marks.setPerformance(4);
    const ids = new Set(contactMappings.map(d => d.id));
    realContacts = [
      ...contactMappings,
      ...realContacts.filter(d => !ids.has(d.id))
    ];

    changeState({
      inboxes,
      conversations: conversationQueryArr,
      isInitializing: false,
      permissions,
      users: usersQueryResult.users,
      currentPage: currentPage,
      hasNextPage: conversationQueryArr.length === LIMIT_RECORDS,
      isNextPageLoading: false,
      contactMappings: realContacts
    });

    // Create a variety of measurements.
    marks.calcPerformance();
  }

  //Todo refactor initialize and loadMoreData it may need to be combined and added it to a helper library
  async function loadMoreData(inboxID: string, page: number): Promise<void> {
    changeState({ isNextPageLoading: true });

    const marks = new LogMeasureCount(
      ['Load More Data', 'Messages Query', 'Contacts Query'],
      inboxID,
      authenticatedUser.orgID,
      authenticatedUser.org.name,
      authenticatedUser.salesforceID
    );
    marks.setPerformance(0);
    let query;
    let conversationQueryArr: ConversationEntity[] = [];
    const currentPage = page + 1;

    //The offset clause skips the offset rows before beginning to return the rows.
    const offset = currentPage === 1 ? 0 : LIMIT_RECORDS * (currentPage - 1);

    if (showUnreadConversationsOnly) {
      try {
        query = new ConversationsUnreadQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset,
          search: searchTerm
        });
      } catch {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }

      const conversationsUnreadQueryResult = await dispatcher.dispatch<
        ConversationsUnreadQueryResult | ConversationsUnreadQueryFailure
      >(query);

      if (
        conversationsUnreadQueryResult instanceof
        ConversationsUnreadQueryFailure
      ) {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }
      conversationQueryArr = conversationsUnreadQueryResult.conversations;
    } else {
      try {
        query = new ConversationsQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset,
          search: searchTerm
        });
      } catch {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }

      const conversationsQueryResult = await dispatcher.dispatch<
        ConversationsQueryResult | ConversationsQueryFailure
      >(query);

      if (conversationsQueryResult instanceof ConversationsQueryFailure) {
        changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
        return;
      }
      conversationQueryArr = conversationsQueryResult.conversations;
    }

    marks.setPerformance(1);
    const phoneNumbers = getPhoneNumbers(conversationQueryArr);

    const contactMappingsQueryResult = await dispatcher.dispatch<
      ContactMappingsQueryResult | ContactMappingsQueryFailure
    >(new ContactMappingsQueryByPhoneNumbers({ phoneNumbers }));

    if (contactMappingsQueryResult instanceof ContactMappingsQueryFailure) {
      changeState({
        alertKey: FAILURE_INITIALIZING,
        isInitializing: false
      });
      return;
    }

    let realContacts = contactMappingsQueryResult.mappings;
    if (searchTerm.length > 2) {
      realContacts = getFilteredContacts(
        contactMappingsQueryResult.mappings,
        searchTerm
      );
    }

    const conversationArr = [...conversations].concat(
      conversationQueryArr.filter(item => item != undefined)
    );
    const ids = new Set(contactMappings.map(d => d.id));
    realContacts = [
      ...contactMappings,
      ...realContacts.filter(d => !ids.has(d.id))
    ];

    marks.setPerformance(2);

    changeState({
      currentPage: currentPage,
      conversations: conversationArr,
      hasNextPage: conversationQueryArr.length === LIMIT_RECORDS,
      isNextPageLoading: false,
      contactMappings: realContacts
    });

    // Create a variety of measurements.
    marks.calcPerformance();
  }

  async function validateContactRecordByRID(
    rid: string | undefined
  ): Promise<ContactRecord | undefined> {
    if (!rid) {
      return;
    }
    const result = await dispatcher.dispatch<
      ContactRecordQueryResult | ContactRecordQueryFailure
    >(new ContactRecordQueryByRID({ rid }));

    if (result instanceof ContactRecordQueryFailure) {
      return;
    }
    return result.contact;
  }

  async function loadContactRecordByRID(
    rid: string
  ): Promise<ContactRecord | undefined> {
    const result = await dispatcher.dispatch<
      ContactRecordQueryResult | ContactRecordQueryFailure
    >(new ContactRecordQueryByRID({ rid }));

    if (result instanceof ContactRecordQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_CONTACT,
        isLoadingContact: false
      });
      return;
    }

    changeState({ contact: result.contact, isLoadingContact: false });
    return result.contact;
  }

  async function loadMessagesByConversationID(
    conversationID: string
  ): Promise<void> {
    changeState({
      actionPanelKey: '',
      isLoadingMessages: true,
      messages: [],
      searchResultContacts: [],
      selectedConversationID: conversationID,
      selectedConversationLast: conversationsKeyedByID[conversationID],
      isNextPageLoading: true
    });

    const result = await dispatcher.dispatch<
      MessagesQueryResult | MessagesQueryFailure
    >(new MessagesQueryByConversationID({ conversationID }));

    if (result instanceof MessagesQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_MESSAGES,
        isLoadingMessages: false
      });
      return;
    }

    const conversation = conversationsKeyedByID[conversationID];

    const shouldBeMarkedAsRead =
      conversation?.lastIncomingMessage &&
      (conversation.timeLastMarkedAsRead === undefined ||
        isNil(conversation.timeLastMarkedAsRead) ||
        conversation.lastIncomingMessage.timeCreated >
          conversation.timeLastMarkedAsRead);

    if (shouldBeMarkedAsRead || showUnreadConversationsOnly) {
      await dispatcher.dispatch<
        MarkConversationAsReadCommandAck | MarkConversationAsReadCommandFailure
      >(new MarkConversationAsReadCommand({ conversationID }));
    }

    const usersQueryResult = await dispatcher.dispatch<
      MessageSendersQueryResult | MessageSendersQueryFailure
    >(new MessageSendersQueryByInboxID({ inboxID }));

    // TODO: What failure should this be? fail silently for now
    if (usersQueryResult instanceof MessageSendersQueryFailure) {
      changeState({
        isLoadingMessages: false,
        messages: result.messages
      });
      return;
    }

    changeState({
      isLoadingMessages: false,
      messages: result.messages,
      isNextPageLoading: false,
      users: usersQueryResult.users
      //hasNextPage: conversationQueryArr.length === LIMIT_RECORDS
    });
  }

  async function loadMessageTemplates(): Promise<void> {
    changeState({ isLoadingTemplates: true });

    const result = await dispatcher.dispatch<
      MessageTemplatesQueryResult | MessageTemplatesQueryFailure
    >(new MessageTemplatesQueryByOwnerID({ ownerID: authenticatedUser.id }));

    if (result instanceof MessageTemplatesQueryFailure) {
      changeState({
        alertKey: WARNING_LOADING_TEMPLATES,
        isLoadingTemplates: false
      });
      return;
    }

    changeState({ isLoadingTemplates: false, templates: result.templates });
  }

  async function markConversationAsRead(conversationID: string): Promise<void> {
    const command = new MarkConversationAsReadCommand({ conversationID });

    const ack = await dispatcher.dispatch<
      MarkConversationAsReadCommandAck | MarkConversationAsReadCommandFailure
    >(command);

    if (ack instanceof MarkConversationAsReadCommandFailure) return;

    setState(currentState => ({
      ...currentState,
      conversations: currentState.conversations.map(conversation =>
        conversation.id === conversationID
          ? { ...conversation, timeLastMarkedAsRead: command.timestamp }
          : conversation
      )
    }));
  }

  function navigate(path: string): void {
    dispatcher.dispatch(new NavigateCommand({ path }));
  }

  async function searchContactsByName(name: string): Promise<void> {
    changeState({ isLoadingSearchResultContacts: true });

    const result = await dispatcher.dispatch<
      ContactRecordsQueryResult | ContactRecordsQueryFailure
    >(
      new ContactRecordsQueryByName({
        name,
        type: ContactType.SALESFORCE
      })
    );

    if (result instanceof ContactRecordsQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_CONTACTS,
        isLoadingSearchResultContacts: false
      });
      return;
    }

    changeState({
      isLoadingSearchResultContacts: false,
      searchResultContacts: result.contacts
    });
  }

  async function updateContactMappingAttributes(
    properties: UpdateContactMappingAttributesCommand.Properties
  ): Promise<void> {
    changeState({
      alertKey: '',
      isUpdatingContactMappingAttributes: true,
      isLoadingMessages: true,
      contactMappings: contactMappings.map(contactMapping =>
        contactMapping.id === properties.mappingID
          ? { ...contactMapping, attributes: properties.attributes }
          : contactMapping
      )
    });

    const result = await dispatcher.dispatch<
      | UpdateContactMappingAttributesCommandAck
      | UpdateContactMappingAttributesCommandFailure
    >(new UpdateContactMappingAttributesCommand(properties));

    if (result instanceof UpdateContactMappingAttributesCommandFailure) {
      changeState({
        alertKey: FAILURE_UPDATING_CONTACT_MAPPING_ATTRIBUTES,
        isUpdatingContactMappingAttributes: false,
        isLoadingMessages: false
      });
      return;
    }

    changeState({
      alertKey: SUCCESS_UPDATING_CONTACT_MAPPING_ATTRIBUTES,
      isUpdatingContactMappingAttributes: false,
      isLoadingMessages: false
    });
  }

  async function createContactMapping(
    parameters: InsertContactParameters
  ): Promise<UpdateContactRecord | undefined> {
    const ack = await dispatcher.dispatch<
      | CreateContactMappingAttributesCommandAck
      | CreateContactMappingAttributesCommandFailure
    >(new CreateContactMappingAttributesCommand(parameters));

    if (ack instanceof CreateContactMappingAttributesCommandFailure) {
      changeState({
        alertKey: FAILURE_SENDING_MESSAGE,
        isCreatingMessage: false
      });
      return;
    }
    return ack;
  }

  async function mergeContactMapping(
    mergeParameters: updateMergeParameters | undefined
  ): Promise<void> {
    if (!mergeParameters) return;
    changeState({ actionPanelKey: '' });

    const ack = await dispatcher.dispatch<
      UpdateMergeMessageCommandAck | UpdateMergeMessageCommandFailure
    >(new UpdateMergeMessageCommand(mergeParameters));

    if (ack instanceof UpdateMergeMessageCommandFailure) {
      changeState({
        alertKey: FAILURE_MERGING_CONTACT,
        isCreatingMessage: false
      });
      return;
    }
    await initialize(inboxID, 0, searchTerm, showUnreadConversationsOnly);
  }

  async function sendMessage(parameters: SendMessageParameters): Promise<void> {
    changeState({
      alertKey: '',
      isCreatingMessage: true,
      isNextPageLoading: true
    });

    const marks = new LogMeasureCount(
      ['Send Message', 'Message Sending Function'],
      inboxID,
      authenticatedUser.orgID,
      authenticatedUser.org.name,
      authenticatedUser.salesforceID
    );

    marks.setPerformance(0);

    const ack = await dispatcher.dispatch<
      SendMessageCommandAck | SendMessageCommandFailure
    >(new SendMessageCommand(parameters));

    if (ack instanceof SendMessageCommandFailure) {
      changeState({
        alertKey: FAILURE_SENDING_MESSAGE,
        isCreatingMessage: false
      });
      return;
    }

    const messagesQueryResult = await dispatcher.dispatch<
      MessagesQueryResult | MessagesQueryFailure
    >(
      new MessagesQueryByConversationID({
        conversationID: selectedConversationID
      })
    );

    if (messagesQueryResult instanceof MessagesQueryFailure) {
      changeState({
        alertKey: FAILURE_LOADING_MESSAGES_AFTER_SEND,
        isCreatingMessage: false
      });
      return;
    }

    let conversationQueryArr: ConversationEntity[] = [];
    if (showUnreadConversationsOnly) {
      const conversationsUnreadQueryResult = await dispatcher.dispatch<
        ConversationsUnreadQueryResult | ConversationsUnreadQueryFailure
      >(
        new ConversationsUnreadQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset: 0,
          search: searchTerm
        })
      );

      if (
        conversationsUnreadQueryResult instanceof
        ConversationsUnreadQueryFailure
      ) {
        changeState({
          alertKey: FAILURE_LOADING_MESSAGES_AFTER_SEND,
          isCreatingMessage: false
        });
        return;
      }
      conversationQueryArr = conversationsUnreadQueryResult.conversations;
    } else {
      const conversationsQueryResult = await dispatcher.dispatch<
        ConversationsQueryResult | ConversationsQueryFailure
      >(
        new ConversationsQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset: 0,
          search: searchTerm
        })
      );

      if (conversationsQueryResult instanceof ConversationsQueryFailure) {
        changeState({
          alertKey: FAILURE_LOADING_MESSAGES_AFTER_SEND,
          isCreatingMessage: false
        });
        return;
      }
      conversationQueryArr = conversationsQueryResult.conversations;
    }

    // TODO: What failure should this be? fail silently for now
    const usersQueryResult = await dispatcher.dispatch<
      MessageSendersQueryResult | MessageSendersQueryFailure
    >(new MessageSendersQueryByInboxID({ inboxID }));

    if (usersQueryResult instanceof MessageSendersQueryFailure) {
      changeState({
        messages: messagesQueryResult.messages,
        conversations: conversationQueryArr,
        isCreatingMessage: false
      });
      return;
    }

    marks.setPerformance(1);

    changeState({
      isCreatingMessage: false,
      conversations: conversationQueryArr,
      messages: messagesQueryResult.messages,
      users: usersQueryResult.users,
      isNextPageLoading: false
    });

    // Create a variety of measurements.
    marks.calcPerformance();
  }

  async function refreshMessagesByConversationID(
    conversationID: string,
    showUnreadConversationsOnly: boolean
  ) {
    const result = await dispatcher.dispatch<
      MessagesQueryResult | MessagesQueryFailure
    >(new MessagesQueryByConversationID({ conversationID }));

    // TODO: does an error message need to be displayed to the user?
    if (result instanceof MessagesQueryFailure) return;
    // refresh conversation list
    let conversationsQueryResult;
    if (showUnreadConversationsOnly) {
      conversationsQueryResult = await dispatcher.dispatch<
        ConversationsUnreadQueryResult | ConversationsUnreadQueryFailure
      >(
        new ConversationsUnreadQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset: 0,
          search: searchTerm
        })
      );
    } else {
      conversationsQueryResult = await dispatcher.dispatch<
        ConversationsQueryResult | ConversationsQueryFailure
      >(
        new ConversationsQueryByInboxID({
          inboxID,
          limit: LIMIT_RECORDS,
          offset: 0,
          search: searchTerm
        })
      );
    }

    // TODO: does an error message need to be displayed to the user?
    if (conversationsQueryResult instanceof ConversationsQueryFailure) {
      changeState({ messages: result.messages });
      return;
    }

    changeState({
      conversations: conversationsQueryResult.conversations,
      messages: result.messages
    });
  }

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;
    changeState({ actionPanelKey: '' });
  }

  function handleClickBroadcastTab(): void {
    navigate(paths.broadcasts.replace(':inboxID', inboxID));
  }

  function handleClickClearSelectedConversation(): void {
    if (selectedConversationID === '') return;

    changeState({ selectedConversationID: '', selectedConversationLast: {} });
  }

  function handleClickOpenSearchContactsForm(): void {
    changeState({ actionPanelKey: ACTION_PANEL_SEARCH_CONTACTS_FORM });
  }

  function handleClickSideNavItem(path: string): void {
    navigate(path);
  }

  function handleClickRootContainer(): void {
    if (['', FAILURE_INITIALIZING].includes(alertKey)) return;

    changeState({ alertKey: '' });
  }

  function handleClickUnreadFilter(event: ChangeEvent<HTMLInputElement>): void {
    const isUnread = parseInt(event.target.value) === 1 ? true : false;
    changeState({
      showUnreadConversationsOnly: isUnread
    });
    initialize(inboxID, 0, searchTerm, isUnread);
  }

  async function handleInteraction({
    type,
    ...data
  }: Interaction): Promise<void> {
    switch (type) {
      case ConversationList.INTERACTION_ITEM_SELECTED: {
        changeState({ contact: undefined, isLoadingContact: true });
        await loadMessagesByConversationID(data.conversationID);
        await loadMessageTemplates();

        const conversation = conversationsKeyedByID[data.conversationID];
        const contactMapping =
          contactMappingsKeyedByPhoneNumber[conversation.canonicalPhoneNumber];

        if (conversation.contactMapping) {
          const contactSelectedRID = await loadContactRecordByRID(
            conversation.contactMapping.contactRID
          );
          const conversationAttributes = conversation.contactMapping.attributes;
          if (
            contactSelectedRID &&
            conversationAttributes &&
            (conversationAttributes?.firstName !==
              contactSelectedRID?.firstName ||
              conversationAttributes?.lastName !== contactSelectedRID?.lastName)
          ) {
            await updateContactMappingAttributes({
              mappingID: conversation.contactMapping.id,
              attributes: {
                firstName: contactSelectedRID?.firstName,
                lastName: contactSelectedRID?.lastName
              }
            });
          }
          changeState({ contactSelected: contactMapping });
        } else {
          //No contact was found possible because the contact changed phone numbers
          //then the contact sent a text but the phone was never link to tx sms
          changeState({ contact: undefined, isLoadingContact: false });
        }

        return;
      }
      case SearchContactsForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ actionPanelKey: '' });
        return;
      }
      case SearchContactsForm.INTERACTION_CONTINUE_BUTTON_CLICKED: {
        await loadMessageTemplates();

        const contact = searchResultContactsKeyedByRID[data.contactID];

        const canonicalPhoneNumber = formatE164(
          countryCode,
          contact.mobilePhone
        );

        const existingConversation = conversations.find(
          conversation =>
            conversation.canonicalPhoneNumber === canonicalPhoneNumber
        );

        const contactRID = `${ContactType.SALESFORCE}/${contact.rid}`;

        //The contact may not exist in the sms DB, but it does in Salesforce so add contact to the DB if there is
        //and existing message in the db

        const contactExist = await validateContactRecordByRID(
          existingConversation?.contactMapping?.contactRID
        );
        if (
          existingConversation &&
          (!existingConversation.contactMapping ||
            (contactSelected === undefined && contactExist === undefined))
        ) {
          const parameters = {
            orgID: authenticatedUser.orgID,
            inboxID,
            userID: authenticatedUser.id,
            phoneNumber: canonicalPhoneNumber,
            contactRID,
            contactFirstName: contact.firstName,
            contactLastName: contact.lastName
          };
          await createContactMapping(parameters);
        }

        if (contactSelected) {
          const canonicalPhoneNumberSelected = formatE164(
            countryCode,
            contactSelected.canonicalPhoneNumber
          );

          if (
            existingConversation &&
            contactSelected?.contactRID !== contactRID &&
            canonicalPhoneNumber === canonicalPhoneNumberSelected
          ) {
            const contactMappingFound = contactMappings.filter(
              contact =>
                contact.contactRID === contactRID &&
                contact.canonicalPhoneNumber === canonicalPhoneNumber
            );

            let newContactMappingID = '';
            let insertParameters;
            if (contactMappingFound.length === 0) {
              insertParameters = {
                orgID: authenticatedUser.orgID,
                inboxID,
                userID: authenticatedUser.id,
                phoneNumber: canonicalPhoneNumber,
                contactRID,
                contactFirstName: contact.firstName,
                contactLastName: contact.lastName
              };
            } else {
              newContactMappingID = contactMappingFound[0].id;
            }

            const mergeParameters = {
              orgID: authenticatedUser.orgID,
              inboxID,
              userID: authenticatedUser.id,
              conversationID: existingConversation.id,
              phoneNumber: canonicalPhoneNumber,
              contactMappingID: newContactMappingID,
              contactToPurge: contactSelected.id
            };

            changeState({
              modalComponentKey: MODAL_DISCARD_CONFIRMATION,
              mergeParameters: mergeParameters,
              insertParameters: insertParameters,
              bodyMessage: `${contactSelected.attributes?.firstName} ${contactSelected.attributes?.lastName} will be removed from the conversation list and all messages will be added to the <br>${contact.firstName} ${contact.lastName} conversation`
            });
            return;
          }
        }

        const contactMapping = {
          id: toUUID(`${contact.rid}/${canonicalPhoneNumber}`),
          canonicalPhoneNumber,
          contactRID,
          attributes: {
            firstName: contact.firstName,
            lastName: contact.lastName
          }
        };

        if (existingConversation) {
          if (
            !existingConversation.contactMapping ||
            contactExist === undefined
          ) {
            setState(currentState => ({
              ...currentState,
              contact,
              contactMappings: [
                ...currentState.contactMappings,
                contactMapping
              ],
              selectedContactRID: contactRID
            }));
          }

          changeState({ contact });
          await loadMessagesByConversationID(existingConversation.id);
          return;
        }

        const currentTime = new Date().toISOString();

        const conversationID = toUUID(`${inboxID}/${canonicalPhoneNumber}`);

        const conversation = {
          id: conversationID,
          inboxID,
          canonicalPhoneNumber,
          lastMarkedAsReadByUserID: authenticatedUser.id,
          timeLastMarkedAsRead: currentTime,
          lastMessage: {
            timeCreated: currentTime,
            content: copyText.newConversationPreview
          }
        };

        //This is when a new contact is added then it has to be added to the top of the list
        setState(currentState => ({
          ...currentState,
          actionPanelKey: '',
          contact,
          contactMappings: [contactMapping, ...currentState.contactMappings],
          conversations: [conversation, ...currentState.conversations],
          messages: [],
          searchResultContacts: [],
          selectedContactRID: contactRID,
          selectedConversationID: conversation.id,
          selectedConversationLast:
            conversationsKeyedByID[conversation.id] ?? {}
        }));

        return;
      }
      case SearchContactsForm.INTERACTION_SEARCH_BUTTON_CLICKED: {
        await searchContactsByName(data.searchTerm);
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        if (data.searchTerm.length > 2) {
          await initialize(
            inboxID,
            0,
            data.searchTerm,
            showUnreadConversationsOnly
          );
        }
        if (data.searchTerm.length === 0 && searchTerm.length > 0) {
          await initialize(inboxID, 0, '', showUnreadConversationsOnly);
        }
        changeState({ searchTerm: data.searchTerm });
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        changeState({ searchTerm: '' });
        await initialize(inboxID, 0, '', showUnreadConversationsOnly);
        return;
      }
      case SendMessageForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        let canonicalPhoneNumber: any;
        try {
          canonicalPhoneNumber =
            conversationsKeyedByID[selectedConversationID].canonicalPhoneNumber;
        } catch (e: any) {
          const phoneNumber = Object.entries(selectedConversationLast).find(
            i => i[0] === 'canonicalPhoneNumber'
          );
          canonicalPhoneNumber = phoneNumber ? phoneNumber[1] : '';
        }

        const contactMapping =
          contactMappingsKeyedByPhoneNumber[canonicalPhoneNumber];

        let contactRID, contactFirstName, contactLastName;

        if (contactMapping) {
          contactRID = contactMapping.contactRID;

          if (selectedContactRID) {
            contactFirstName = contactMapping.attributes?.firstName;
            contactLastName = contactMapping.attributes?.lastName;
          }
        }

        await sendMessage({
          orgID: authenticatedUser.orgID,
          inboxID,
          userID: authenticatedUser.id,
          phoneNumber: canonicalPhoneNumber,
          content: data.content,
          contactRID,
          contactFirstName,
          contactLastName
        });

        changeState({ selectedContactRID: '' });

        return;
      }
    }
  }

  function handleSelectInbox(inboxID: string): void {
    changeState({ selectedConversationID: '', selectedConversationLast: {} });
    navigate(paths.inbox.replace(':inboxID', inboxID));
  }

  //
  // Side Effects
  //

  useEffect(() => {
    changeState({
      showUnreadConversationsOnly: false
    });
    initialize(inboxID, 0, searchTerm, false);
  }, [inboxID]);

  useEffect(() => {
    const conversation = conversationsKeyedByID[selectedConversationID];

    const shouldBeMarkedAsRead =
      conversation &&
      conversation.lastIncomingMessage &&
      (conversation.timeLastMarkedAsRead === undefined ||
        conversation.lastIncomingMessage.timeCreated >
          conversation.timeLastMarkedAsRead);

    if (shouldBeMarkedAsRead) {
      markConversationAsRead(selectedConversationID);
    }
  }, [_messages]);

  //Todo Refactor this code since is not working properly
  useEffect(() => {
    if (!selectedConversationID) return;
    const interval = setInterval(() => {
      setState(currentState => {
        refreshMessagesByConversationID(
          selectedConversationID,
          currentState.showUnreadConversationsOnly
        );
        return currentState;
      });
    }, POLL_INTERVAL);

    return () => clearTimeout(interval);
  }, [selectedConversationID, searchTerm]);

  //
  // Render
  //

  const inboxesKeyedByID = useMemo(() => keyBy(inboxes, 'id'), [inboxes]);
  const usersKeyedByID = useMemo(() => keyBy(users, 'id'), [users]);

  inboxes = inboxes.filter(inbox => inbox.id !== inboxID);

  let dropdownProps;

  if (inboxes.length === 0) {
    dropdownProps = {
      data: [{ id: '_' }],
      item: (): ReactElement => (
        <Box padding={theme.space_inset_md}>
          <Text align="center" color={palette.gray[60]}>
            {copyText.noInboxesMessage}
          </Text>
        </Box>
      )
    };
  } else {
    dropdownProps = {
      data: inboxes.map(inbox => ({
        id: inbox.id,
        text: inbox.name,
        onClick: handleSelectInbox.bind({}, inbox.id)
      }))
    };
  }

  const selectedInbox = inboxesKeyedByID[inboxID];

  let canSendBroadcast = false;

  if (selectedInbox) {
    canSendBroadcast = permissions.some(
      permission =>
        permission.targetID === selectedInbox.id &&
        permission.type === UserInboxPermissionType.CAN_SEND_BROADCAST
    );
  }

  const messages = useMemo(() => {
    return _messages.map(message => {
      const contactMapping =
        contactMappingsKeyedByPhoneNumber[message.canonicalPhoneNumber];

      return {
        ...message,
        ...(contactMapping ? { contactMapping } : {}),
        ...(message.userID ? { user: usersKeyedByID[message.userID] } : {})
      };
    });
  }, [_messages, contactMappingsKeyedByPhoneNumber]);

  const conversationsMemo = useMemo(() => {
    const conversations = _conversations.map(conversation => {
      const contactMapping =
        contactMappingsKeyedByPhoneNumber[conversation.canonicalPhoneNumber];

      return {
        ...conversation,
        ...(contactMapping ? { contactMapping } : {})
      };
    });

    const conversationsKeyedByID = keyBy(conversations, 'id');

    return {
      conversations: conversations,
      conversationsKeyedByID
    };
  }, [_conversations, contactMappings]);

  const { conversations } = conversationsMemo;
  const { conversationsKeyedByID } = conversationsMemo;
  const selectedConversation = conversationsKeyedByID[selectedConversationID];

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_SEARCH_CONTACTS_FORM:
        return (
          <SearchContactsForm
            contacts={searchResultContacts}
            countryCode={countryCode}
            isSearching={isLoadingSearchResultContacts}
            message={renderAlert()}
            onInteraction={handleInteraction}
          />
        );
    }
  }

  function renderAlert(): ReactNode {
    if (![FAILURE_LOADING_CONTACTS].includes(alertKey)) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton
        title={copyText.FAILURE_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        FAILURE_INITIALIZING,
        FAILURE_SENDING_MESSAGE,
        FAILURE_LOADING_CONTACT,
        FAILURE_LOADING_MESSAGES,
        FAILURE_LOADING_MESSAGES_AFTER_SEND,
        FAILURE_MERGING_CONTACT,
        ERROR_LOADING_ORG_SETTINGS,
        WARNING_LOADING_TEMPLATES
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={get(copyText, `${alertKey}_message`) || ''}
        showCloseButton={![FAILURE_INITIALIZING].includes(alertKey)}
      />
    );
  }

  function renderContactInfoComponent(): ReactNode {
    const currentSelectedConversation =
      selectedConversation ?? selectedConversationLast;
    if (!currentSelectedConversation) return null;

    if (isLoadingContact) {
      return (
        <Flex alignItems="center" height="50%" justifyContent="center">
          <SVGSpinner size="2.5em" />
        </Flex>
      );
    }

    const salesforceInstanceURL = get(
      authenticatedUser,
      'salesforceProfile.instance_url'
    );

    return (
      <ContactInfoComponent
        canonicalPhoneNumber={currentSelectedConversation.canonicalPhoneNumber}
        contact={contact}
        messageTranscriptsBasePath={messageTranscriptsBasePath}
        salesforceInstanceURL={salesforceInstanceURL}
      />
    );
  }

  function renderInboxStatusMessage(): ReactNode {
    if (isInitializing) return null;

    return (
      <Box maxWidth={350} paddingHorizontal={theme.space_inline_xl}>
        <Alert variant="warning">
          {inboxes.length === 0
            ? copyText.noInboxesCreatedMessage
            : copyText.noInboxSelectedMessage}
        </Alert>
      </Box>
    );
  }

  function renderModalComponent(): ReactNode {
    return (
      <ConfirmationDialog
        message={copyText.discardModalBody + bodyMessage}
        title={copyText.discardModalTitle}
        variant="warning"
        onCancel={handleCloseModal}
        onConfirm={handleConfirmModal}
      />
    );
  }

  function handleCloseModal(): void {
    changeState({ modalComponentKey: '' });
  }

  async function handleConfirmModal(): Promise<void> {
    if (insertParameters) {
      const ack = await createContactMapping(insertParameters);
      if (mergeParameters && ack?.mappingID !== undefined) {
        mergeParameters.contactMappingID = ack.mappingID;
      }
    }
    await mergeContactMapping(mergeParameters);
    changeState({ actionPanelKey: '', modalComponentKey: '' });
  }

  async function loadNextPage(): Promise<void> {
    setTimeout(() => {
      loadMoreData(inboxID, state.currentPage);
    }, 500);
  }

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      {renderScreenAlert()}
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={actionPanelKey.length > 0}
        right
        styles={bmStyles}
        width={500}
        onStateChange={handleChangeActionPanelState}
      >
        {renderActionPanel()}
      </Menu>
      <Layout height="100vh">
        <Layout.Body flex>
          <SideNav
            authenticatedUser={authenticatedUser}
            homeBaseURL={homeBaseURL}
            justifyContent="end"
            signOutPath={signOutPath}
          >
            <SideNav.Item
              href={paths.settings}
              label={copyText.settingsLinkLabel}
              onClick={handleClickSideNavItem}
            >
              <IconCog color={palette.gray[40]} size={24} />
            </SideNav.Item>
          </SideNav>
          <Flex
            backgroundColor={theme.color_ebony_clay}
            direction="column"
            height="100vh"
            minWidth={350}
            position="relative"
          >
            <Box padding={theme.space_inset_md}>
              <Flex marginBottom={theme.space_stack_md}>
                <Text color={palette.white} fontSize={theme.h2_fontSize}>
                  {copyText.title}
                </Text>
              </Flex>
              {inboxID ? (
                <SearchInput
                  aria-label={copyText.searchInputPlaceholder}
                  name="searchTerm"
                  placeholder={copyText.searchInputPlaceholder}
                  variant="dark"
                  clearable
                  onInteraction={handleInteraction}
                />
              ) : null}
            </Box>
            {inboxID ? (
              <Box
                borderBottom={`1px solid ${palette.gray[80]}`}
                borderTop={`1px solid ${palette.gray[80]}`}
                paddingHorizontal={theme.space_inline_md}
                paddingVertical={theme.space_stack_lg}
              >
                {canSendBroadcast ? (
                  <SidePanelTab
                    icon={
                      <Image
                        height={32}
                        src="tx-sms-app-logo.3f630761.svg"
                        alt={copyText.bulkMessagesLabel}
                      />
                    }
                    label={copyText.bulkMessagesLabel}
                    onClick={handleClickBroadcastTab}
                  />
                ) : null}
                <ButtonGroup
                  aria-label={copyText.conversationFilterAriaLabel}
                  defaultChecked={0}
                  mode="radio"
                  size="small"
                  onClick={handleClickUnreadFilter}
                >
                  <Button
                    value={0}
                    width="50%"
                    aria-label={copyText.conversationFilterLabelAll}
                  >
                    {copyText.conversationFilterLabelAll}
                  </Button>
                  <Button
                    value={1}
                    width="50%"
                    aria-label={copyText.conversationFilterLabelUnread}
                  >
                    {copyText.conversationFilterLabelUnread}
                  </Button>
                </ButtonGroup>
              </Box>
            ) : null}
            {inboxID ? (
              <ConversationList
                conversations={conversations}
                countryCode={countryCode}
                hasNextPage={hasNextPage}
                isLoadingConversations={isInitializing}
                isNextPageLoading={isNextPageLoading}
                loadNextPage={loadNextPage}
                onInteraction={handleInteraction}
                selectedConversationID={selectedConversationID}
                showUnreadConversationsOnly={showUnreadConversationsOnly}
              />
            ) : (
              renderInboxStatusMessage()
            )}
            {selectedConversation ? (
              <SelectedConversation
                conversation={selectedConversation}
                countryCode={countryCode}
                onClick={handleClickClearSelectedConversation}
              />
            ) : null}
          </Flex>
          <Layout height="100vh" width="100%">
            <Layout.Header
              alignItems="center"
              flex
              justifyContent="between"
              minHeight={theme.space_stack_xxl}
              paddingLeft={theme.space_inline_lg}
              paddingRight={theme.space_inline_md}
              paddingVertical={theme.space_stack_sm}
            >
              <Flex>
                <Text
                  color={palette.gray[90]}
                  fontSize={theme.fontSize_base}
                  fontWeight={theme.fontWeight_semiBold}
                  marginRight={theme.space_inline_xs}
                >
                  {authenticatedUser.org.name}
                </Text>
                {inboxes.length > 0 || inboxID ? (
                  <Dropdown {...dropdownProps}>
                    <Flex alignItems="center" cursor="pointer">
                      <Text
                        bold
                        color={palette.gray[100]}
                        fontSize={theme.fontSize_base}
                        marginRight={theme.space_inline_sm}
                      >
                        {`/ ${
                          selectedInbox
                            ? selectedInbox.name
                            : copyText.selectInboxLabel
                        }`}
                      </Text>
                      <IconChevronCircleDown color={palette.gray[60]} />
                    </Flex>
                  </Dropdown>
                ) : null}
              </Flex>
              <Button
                aria-label={copyText.createButtonLabel}
                disabled={!inboxID}
                iconStart={<IconPlus color={palette.green[60]} />}
                marginLeft={theme.space_inline_xs}
                minimal
                onClick={handleClickOpenSearchContactsForm}
              />
            </Layout.Header>
            <Layout.Body
              backgroundColor={palette.gray[20]}
              flex
              padding={theme.space_inset_lg}
              scrollable
            >
              {selectedConversationID ? (
                <>
                  <Flex direction="column" minWidth={550} width="100%">
                    <MessageList
                      isLoadingMessages={isLoadingMessages}
                      messages={messages}
                      inboxName={selectedInbox.name}
                    />
                    <SendMessageForm
                      isLoadingTemplates={isLoadingTemplates}
                      isProcessing={isCreatingMessage}
                      templates={templates}
                      onInteraction={handleInteraction}
                    />
                  </Flex>
                  <Box
                    backgroundColor={palette.white}
                    borderRadius="8px"
                    boxShadow="0 1px 8px 0 rgba(0,0,0,0.10)"
                    marginLeft={theme.space_inline_lg}
                    minWidth={350}
                    paddingHorizontal={theme.space_inline_md}
                    paddingVertical={theme.space_stack_lg}
                    width={350}
                  >
                    {renderContactInfoComponent()}
                  </Box>
                </>
              ) : (
                <Flex alignItems="center" justifyContent="center" width="100%">
                  <IconComments color={palette.gray[40]} size={200} />
                </Flex>
              )}
            </Layout.Body>
          </Layout>
        </Layout.Body>
      </Layout>
    </Box>
  );
}

const SELECTED_CONVERSATION_WIDTH = 318;

interface SelectedConversationProps {
  conversation: ConversationEntity & { contactMapping?: ContactMappingEntity };
  countryCode: string;
  onClick?: () => void;
}

function SelectedConversation({
  conversation,
  countryCode,
  onClick = noop
}: SelectedConversationProps): ReactElement {
  const { canonicalPhoneNumber, contactMapping, lastMessage } = conversation;

  let displayName = formatLocal(countryCode, canonicalPhoneNumber);
  let abbr = null;

  if (contactMapping?.attributes) {
    displayName = getFullName(contactMapping);
    abbr = getInitials(contactMapping);
  }

  const handleActionKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e = e || window.event;
    e.preventDefault();
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <Flex
      backgroundColor={palette.white}
      borderRadius={theme.borderRadius_3}
      bottom={16}
      boxShadow="16px 0 16px 0 rgba(36,49,63,0.5), -16px 0 16px 0 rgba(36,49,63,0.5), 0 32px 16px 0 rgba(36,49,63,0.5), 0 -32px 16px 0 rgba(36,49,63,0.5)"
      justifyContent="around"
      marginLeft={theme.space_inline_md}
      padding={theme.space_inset_md}
      position="absolute"
      width={SELECTED_CONVERSATION_WIDTH}
      zIndex={10}
    >
      <FlexItem>
        <Avatar abbr={abbr} size={32}>
          {abbr ? displayName : <IconQuestionCircle />}
        </Avatar>
      </FlexItem>
      <FlexItem grow={1}>
        <Flex direction="column" overflow="hidden">
          <Text
            fontSize={theme.fontSize_base}
            fontWeight={theme.fontWeight_semiBold}
            truncate={210}
          >
            {displayName}
          </Text>
          <Text fontSize={theme.fontSize_ui_sm} truncate={210}>
            {lastMessage.content}
          </Text>
          <Text fontSize={theme.fontSize_mouse}>
            {dateFormat(lastMessage.timeCreated, 'mm/dd/yyyy hh:MMTT')}
          </Text>
        </Flex>
      </FlexItem>
      <FlexItem>
        <Flex
          tabIndex="0"
          aria-label="CLOSE"
          onKeyPress={handleActionKeyPress}
          css={{
            '&:focus': {
              outline: `1px solid ${palette.blue[60]}`,
              outlineOffset: `-1px`,
              height: `min-content`
            }
          }}
        >
          <IconTimesCircle
            clickable
            color={palette.gray[60]}
            onClick={onClick}
          />
        </Flex>
      </FlexItem>
    </Flex>
  );
}

function SidePanelTab({
  icon,
  label,
  onClick = noop
}: {
  icon?: ReactElement;
  label: string;
  onClick?: () => void;
}): ReactElement {
  const handleActionKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e = e || window.event;
    e.preventDefault();
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <Flex
      alignItems="center"
      backgroundColorOnHover={theme.color_mirage}
      border={`1px solid ${palette.gray[70]}`}
      borderRadius={theme.borderRadius_3}
      cursor="pointer"
      justifyContent="between"
      marginBottom={theme.space_stack_lg}
      padding={theme.space_inset_md}
      onClick={onClick}
      tabIndex="0"
      onKeyPress={handleActionKeyPress}
      aria-label={label}
      css={{
        '&:focus': {
          outline: `1px solid ${palette.blue[60]}`,
          outlineOffset: `-1px`
        }
      }}
    >
      <Flex alignItems="center">
        {icon ? <Box marginRight={0}>{icon}</Box> : null}
        <Text
          color={palette.white}
          fontSize={theme.fontSize_base}
          fontWeight={theme.fontWeight_semiBold}
          marginLeft={theme.space_inline_sm}
        >
          {label}
        </Text>
      </Flex>
      <IconChevronRight color={palette.gray[50]} size={16} />
    </Flex>
  );
}

export default MessagingScreen;
