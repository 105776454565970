import { ContactRecord } from '../types';

export function getFullName(contact: ContactRecord): string {
  if (!contact) return '';

  return contact.firstName
    ? `${contact.firstName} ${contact.lastName}`
    : `${contact.lastName}`;
}

export function getInitials(contact: ContactRecord): string {
  if (!contact) return '';

  const firstNameInitial = contact.firstName ? contact.firstName[0] : '';
  const lastNameInitial = contact.lastName ? contact.lastName[0] : '';

  return `${firstNameInitial}${lastNameInitial}`;
}

export default {
  getFullName,
  getInitials
};
