import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Checkbox from '@targetx/mineral-ui/Checkbox';
import Flex from '@targetx/mineral-ui/Flex';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserInboxPermissionType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchSelect from '@targetx/tx-web-ui-lib/lib/components/SearchSelect';
import UserIdentityComponent from '@targetx/tx-web-ui-lib/lib/components/UserIdentityComponent';
import IconMinusCircle from '@targetx/tx-web-ui-lib/lib/icons/IconMinusCircle';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import noop from 'lodash.noop';
import orderBy from 'lodash.orderby';
import React, { ReactElement, ReactNode, useState } from 'react';
import { ValueType } from 'react-select';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import styleProps from '../styles/props';
import theme from '../theme';
import Interaction, { InteractionDelegate } from '../types/Interaction';
import { getFullName } from '../utils/UserUtils';
import copyText from './AddInboxUsersComponent.copyText';

export namespace AddInboxUsersComponent {
  export interface UserEntity {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
  }

  export interface Props {
    isLoadingUsers?: boolean;
    isProcessing?: boolean;
    message?: ReactNode;
    searchResultUsers: UserEntity[];
    onInteraction?: InteractionDelegate;
  }
}

type Option = { label: string; value: string };

interface RowData {
  id: string;
  row: () => ReactElement;
}

interface UserPermissionStructure {
  userID: string;
  type: UserInboxPermissionType;
}

interface State {
  permissions: UserPermissionStructure[];
  usersFilter: string;
}

const initialState: State = {
  permissions: [],
  usersFilter: ''
};

const columns = [
  {
    key: copyText.headerUsers,
    content: copyText.headerUsers,
    borderless: true
  },
  {
    key: copyText.headerBroadcast,
    content: copyText.headerBroadcast,
    borderless: true,
    minWidth: 100,
    width: 100
  },
  {
    key: copyText.headerActions,
    content: <Text hidden>{copyText.headerActions}</Text>,
    label: copyText.headerActions,
    borderless: true,
    minWidth: 50,
    width: 50
  }
];

export function AddInboxUsersComponent({
  isLoadingUsers,
  isProcessing,
  message,
  searchResultUsers,
  onInteraction = noop
}: AddInboxUsersComponent.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  const { permissions, usersFilter } = state;

  const permissionsGroupedByUserID = groupBy(permissions, 'userID');
  const searchResultUsersKeyedByID = keyBy(searchResultUsers, 'id');

  function handleChangeUsersFilter(usersFilter: string): void {
    if (usersFilter !== '' && !isAlphanumeric(usersFilter)) return;

    if (usersFilter.length === 3 && searchResultUsers.length === 0) {
      onInteraction({
        type: AddInboxUsersComponent.INTERACTION_USERS_FILTER_CHANGED
      });
    }

    setState(currentState => ({ ...currentState, usersFilter }));
  }

  function handleInteraction({ type, userID, checked }: Interaction): void {
    switch (type) {
      case UserListItem.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED: {
        if (checked) {
          setState(currentState => ({
            ...currentState,
            permissions: [
              ...currentState.permissions,
              { userID, type: UserInboxPermissionType.CAN_SEND_BROADCAST }
            ]
          }));
          return;
        }

        setState(currentState => ({
          ...currentState,
          permissions: currentState.permissions.filter(
            permission =>
              permission.userID !== userID ||
              permission.type !== UserInboxPermissionType.CAN_SEND_BROADCAST
          )
        }));
        return;
      }
      case UserListItem.INTERACTION_REMOVE_USER_BUTTON_CLICKED: {
        setState(currentState => ({
          ...currentState,
          permissions: currentState.permissions.filter(
            permission => permission.userID !== userID
          )
        }));
        return;
      }
    }
  }

  function handleReset(): void {
    onInteraction({
      type: AddInboxUsersComponent.INTERACTION_CANCEL_BUTTON_CLICKED
    });
  }

  function handleSelect(option: ValueType<Option, false>): void {
    const userID = option ? option.value : '';

    setState(currentState => ({
      ...currentState,
      permissions: [
        ...currentState.permissions,
        { userID, type: UserInboxPermissionType.CAN_ACCESS }
      ],
      usersFilter: ''
    }));
  }

  function handleSubmit(): void {
    onInteraction({
      type: AddInboxUsersComponent.INTERACTION_SUBMIT_BUTTON_CLICKED,
      permissions
    });
  }

  const selectedUserIDs = Object.keys(permissionsGroupedByUserID).map(
    userID => userID
  );

  const userOptions = orderBy(
    searchResultUsers.reduce((options: Option[], user) => {
      if (
        !getFullName(user).toLowerCase().match(usersFilter.toLowerCase()) ||
        selectedUserIDs.includes(user.id)
      ) {
        return options;
      }

      return [...options, { label: getFullName(user), value: user.id }];
    }, []),
    'label'
  );

  let data: RowData[];

  if (selectedUserIDs.length === 0) {
    const render = (): ReactElement => (
      <TableRow>
        <TableCell colSpan={3}>
          <Flex justifyContent="center">
            <Text color={palette.gray[70]}>
              {copyText.noUsersSelectedLabel}
            </Text>
          </Flex>
        </TableCell>
      </TableRow>
    );

    data = [{ id: '_', row: render }];
  } else {
    data = selectedUserIDs.map(userID => {
      const render = (): ReactElement => (
        <UserListItem
          permissions={permissionsGroupedByUserID[userID] || []}
          user={searchResultUsersKeyedByID[userID]}
          onInteraction={handleInteraction}
        />
      );

      return { id: userID, row: render };
    });
  }

  return (
    <Layout backgroundColor={palette.white} height="100vh">
      <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleReset}
        />
      </Layout.Header>
      <Layout.Body
        direction="column"
        flex
        {...styleProps.ActionPanelLayoutBody}
      >
        {message}
        <SearchSelect
          className="searchSelect"
          inputValue={usersFilter}
          isLoading={isLoadingUsers}
          options={userOptions}
          placeholder={copyText.placeholderAddUsers}
          value={null}
          onChange={handleSelect}
          onInputChange={handleChangeUsersFilter}
        />
        <Box marginTop={theme.space_stack_md} scrollable>
          <Table
            border="none"
            borderRadius={theme.borderRadius_2}
            columns={columns}
            data={data}
            striped
            title={copyText.title}
          />
        </Box>
      </Layout.Body>
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button variant="grayscale" width="47%" onClick={handleReset}>
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={selectedUserIDs.length === 0 || isProcessing}
          primary
          width="47%"
          onClick={handleSubmit}
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

namespace UserListItem {
  export interface Props {
    permissions: UserPermissionStructure[];
    user: AddInboxUsersComponent.UserEntity;
    onInteraction: InteractionDelegate;
  }
}

function UserListItem({
  permissions,
  user,
  onInteraction
}: UserListItem.Props): ReactElement {
  const allowBroadcast = permissions.some(
    ({ type }) => type === UserInboxPermissionType.CAN_SEND_BROADCAST
  );

  function handleClickCheckbox(): void {
    onInteraction({
      type: UserListItem.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED,
      userID: user.id,
      checked: !allowBroadcast
    });
  }

  function handleClickRemoveButton(): void {
    onInteraction({
      type: UserListItem.INTERACTION_REMOVE_USER_BUTTON_CLICKED,
      userID: user.id
    });
  }

  return (
    <TableRow>
      <TableCell verticalAlign="middle">
        <UserIdentityComponent user={user} />
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex justifyContent="end">
          <Checkbox
            checked={allowBroadcast}
            hideLabel
            label={copyText.headerBroadcast}
            onChange={handleClickCheckbox}
          />
        </Flex>
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex alignItems="center" justifyContent="end">
          <MinimalButton
            aria-label={copyText.removeButtonLabel}
            iconStart={<IconMinusCircle color={palette.gray[60]} />}
            size="small"
            onClick={handleClickRemoveButton}
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
}

AddInboxUsersComponent.INTERACTION_CANCEL_BUTTON_CLICKED = `${AddInboxUsersComponent.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
AddInboxUsersComponent.INTERACTION_SUBMIT_BUTTON_CLICKED = `${AddInboxUsersComponent.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;
AddInboxUsersComponent.INTERACTION_USERS_FILTER_CHANGED = `${AddInboxUsersComponent.name}.INTERACTION_USERS_FILTER_CHANGED`;

UserListItem.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED = `${UserListItem.name}.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED`;
UserListItem.INTERACTION_REMOVE_USER_BUTTON_CLICKED = `${UserListItem.name}.INTERACTION_REMOVE_USER_BUTTON_CLICKED`;

export default AddInboxUsersComponent;
