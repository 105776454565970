import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import UpdateMessagingAPIKeyCommand, {
  UpdateMessagingAPIKeyCommandAck,
  UpdateMessagingAPIKeyCommandFailure
} from '@targetx/tx-sms-api-lib/lib/commands/UpdateMessagingAPIKeyCommand';
import { MessagingProviderType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import {
  MessagingAPIKeyQueryByOrgID,
  MessagingAPIKeyQueryFailure,
  MessagingAPIKeyQueryResult
} from '@targetx/tx-sms-api-lib/lib/queries/MessagingAPIKeyQuery';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import IconCheckCircle from '@targetx/tx-web-ui-lib/lib/icons/IconCheckCircle';
import IconTimesCircle from '@targetx/tx-web-ui-lib/lib/icons/IconTimesCircle';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import dateFormat from 'dateformat';
import get from 'lodash.get';
import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import Modal from 'react-modal';
import ScreenLevelAlert from '../components/ScreenLevelAlert';
import UpdateMessagingAPIKeyForm from '../components/UpdateMessagingAPIKeyForm';
import paths from '../constants/paths';
import DispatcherContext from '../DispatcherContext';
import SettingsScreenLayout from '../layouts/SettingsScreenLayout';
import { modalStyles } from '../styles';
import theme from '../theme';
import { UserEntity } from '../types';
import Interaction from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import copyText from './MessagingProviderConfigScreen.copyText';

const SUCCESS_API_KEY_UPDATED = 'SUCCESS_API_KEY_UPDATED';

const FAILURE_LOADING_API_KEY_UNEXPECTED = 'FAILURE_LOADING_API_KEY_UNEXPECTED';
const FAILURE_UPDATING_API_KEY_INVALID = 'FAILURE_UPDATING_API_KEY_INVALID';
const FAILURE_UPDATING_API_KEY_UNEXPECTED =
  'FAILURE_UPDATING_API_KEY_UNEXPECTED';

const MODAL_UPDATE_CONFIRMATION = 'UPDATE_CONFIRMATION';

export namespace MessagingProviderConfigScreen {
  export interface Props {
    authenticatedUser: UserEntity;
    homeBaseURL: string;
    path?: string;
    signOutPath: string;
  }
}

interface APIKeyEntity {
  id: string;
  providerRID: string;
  key: string;
  secretPreview: string;
  isValid: boolean;
}

interface UpdateMessagingAPIKeyParameters {
  providerType: MessagingProviderType;
  key: string;
  secret: string;
}

interface State {
  alertKey: string;
  apiKey: APIKeyEntity | null;
  isLoadingAPIKey: boolean;
  isUpdatingAPIKey: boolean;
  modalComponentKey: string;
  modifiedAPIKey: { key: string; secret: string };
  timeLastChecked: string;
}

const initialState: State = {
  alertKey: '',
  apiKey: null,
  isLoadingAPIKey: false,
  isUpdatingAPIKey: false,
  modalComponentKey: '',
  modifiedAPIKey: { key: '', secret: '' },
  timeLastChecked: ''
};

export function MessagingProviderConfigScreen({
  authenticatedUser,
  homeBaseURL,
  signOutPath
}: MessagingProviderConfigScreen.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(prevState => ({ ...prevState, ...partialState }));
  }

  const {
    alertKey,
    apiKey,
    isLoadingAPIKey,
    isUpdatingAPIKey,
    modalComponentKey,
    modifiedAPIKey,
    timeLastChecked
  } = state;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function loadMessagingAPIKey(): Promise<void> {
    changeState({ isLoadingAPIKey: true });

    const result = await dispatcher.dispatch<
      MessagingAPIKeyQueryResult | MessagingAPIKeyQueryFailure
    >(new MessagingAPIKeyQueryByOrgID({ orgID: authenticatedUser.orgID }));

    if (result instanceof MessagingAPIKeyQueryFailure) {
      if (result.reason === MessagingAPIKeyQueryFailure.REASON_NOT_FOUND) {
        changeState({ isLoadingAPIKey: false });
        return;
      }

      changeState({
        alertKey: FAILURE_LOADING_API_KEY_UNEXPECTED,
        isLoadingAPIKey: false
      });
      return;
    }

    changeState({
      apiKey: result.apiKey,
      isLoadingAPIKey: false,
      timeLastChecked: result.timestamp
    });
  }

  async function updateMessagingAPIKey(
    orgID: string,
    parameters: UpdateMessagingAPIKeyParameters
  ): Promise<void> {
    const { providerType, key, secret } = parameters;

    changeState({ alertKey: '', isUpdatingAPIKey: true });

    const ack = await dispatcher.dispatch<
      UpdateMessagingAPIKeyCommandAck | UpdateMessagingAPIKeyCommandFailure
    >(new UpdateMessagingAPIKeyCommand({ orgID, providerType, key, secret }));

    if (ack instanceof UpdateMessagingAPIKeyCommandFailure) {
      const alertKey =
        ack.reason ===
        UpdateMessagingAPIKeyCommandFailure.REASON_UNDERLYING_SERVICE_ERROR
          ? FAILURE_UPDATING_API_KEY_INVALID
          : FAILURE_UPDATING_API_KEY_UNEXPECTED;

      changeState({ alertKey, isUpdatingAPIKey: false, modalComponentKey: '' });
      return;
    }

    changeState({
      alertKey: SUCCESS_API_KEY_UPDATED,
      isUpdatingAPIKey: false,
      modalComponentKey: ''
    });

    loadMessagingAPIKey();
  }

  //
  // Interaction Handlers
  //

  function handleClickRootLayout(): void {
    if (alertKey === '') return;
    changeState({ alertKey: '' });
  }

  function handleCloseModal(): void {
    changeState({ modalComponentKey: '' });
  }

  function handleClickCheckCredentialsButton(): void {
    loadMessagingAPIKey();
  }

  function handleConfirmModal(): void {
    updateMessagingAPIKey(authenticatedUser.orgID, {
      providerType: MessagingProviderType.TWILIO,
      key: modifiedAPIKey.key,
      secret: modifiedAPIKey.secret
    });
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case UpdateMessagingAPIKeyForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (apiKey) {
          changeState({
            modifiedAPIKey: { key: data.key, secret: data.secret },
            modalComponentKey: MODAL_UPDATE_CONFIRMATION
          });
          return;
        }

        updateMessagingAPIKey(authenticatedUser.orgID, {
          providerType: MessagingProviderType.TWILIO,
          key: data.key,
          secret: data.secret
        });
        return;
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    loadMessagingAPIKey();
  }, []);

  //
  // Render
  //

  function renderCredentialsStatusComponent(): ReactNode {
    if (!apiKey) return null;

    return (
      <Flex
        backgroundColor={palette.white}
        border={
          apiKey.isValid
            ? `1px solid ${theme.color_success}`
            : `1px solid ${theme.color_danger}`
        }
        borderRadius={theme.borderRadius_2}
        marginBottom={theme.space_stack_md}
        marginRight={0}
        paddingLeft={theme.space_inline_md}
        padding={theme.space_inset_sm_plus}
        justifyContent="between"
        width="100%"
      >
        <Flex alignItems="center">
          <FlexItem flex marginRight={0}>
            {apiKey.isValid ? (
              <IconCheckCircle color={palette.green[60]} size={24} solid />
            ) : (
              <IconTimesCircle color={theme.color_danger} size={24} solid />
            )}
          </FlexItem>
          <FlexItem flex direction="column" marginLeft={theme.space_inline_md}>
            <Text fontSize={theme.fontSize_mouse} lineHeight={1.25}>
              {apiKey.isValid
                ? copyText.timeLastPassedVerificationLabel.replace(
                    '%dateTime%',
                    dateFormat(timeLastChecked, 'mm/dd/yyyy hh:MMTT')
                  )
                : copyText.timeLastFailedVerificationLabel.replace(
                    '%dateTime%',
                    dateFormat(timeLastChecked, 'mm/dd/yyyy hh:MMTT')
                  )}
            </Text>
          </FlexItem>
        </Flex>
        <Button
          variant={apiKey.isValid ? 'success' : 'danger'}
          primary
          size="small"
          onClick={handleClickCheckCredentialsButton}
        >
          {copyText.checkCredentialsButtonLabel}
        </Button>
      </Flex>
    );
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_UPDATE_CONFIRMATION: {
        return (
          <ConfirmationDialog
            message={copyText.confirmationDialogMessage}
            title={copyText.confirmationDialogTitle}
            variant="warning"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal}
          />
        );
      }
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        FAILURE_LOADING_API_KEY_UNEXPECTED,
        FAILURE_UPDATING_API_KEY_INVALID,
        FAILURE_UPDATING_API_KEY_UNEXPECTED,
        SUCCESS_API_KEY_UPDATED
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={get(copyText, `${alertKey}_message`) || ''}
        showCloseButton
      />
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: copyText.title, color: palette.gray[100] }
  ];

  return (
    <>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <SettingsScreenLayout
        authenticatedUser={authenticatedUser}
        currentPath={paths.settingsMessagingProvider}
        homeBaseURL={homeBaseURL}
        signOutPath={signOutPath}
        onClick={handleClickRootLayout}
      >
        <SettingsScreenLayout.Header>
          <Breadcrumb items={breadcrumbItems} />
        </SettingsScreenLayout.Header>
        <SettingsScreenLayout.Body>
          {renderScreenAlert()}
          {renderCredentialsStatusComponent()}
          {isLoadingAPIKey ? (
            <Flex
              alignItems="center"
              backgroundColor={palette.white}
              border={`1px solid ${palette.gray[50]}`}
              borderRadius={theme.borderRadius_2}
              justifyContent="center"
              padding={theme.space_stack_lg}
              width="100%"
            >
              <SVGSpinner size="2.5em" />
            </Flex>
          ) : (
            <UpdateMessagingAPIKeyForm
              apiKey={apiKey?.key}
              apiSecretPreview={apiKey?.secretPreview}
              isProcessing={isUpdatingAPIKey}
              onInteraction={handleInteraction}
            />
          )}
        </SettingsScreenLayout.Body>
      </SettingsScreenLayout>
    </>
  );
}

export default MessagingProviderConfigScreen;
