import { Option } from '../types';

export default function getTimeOptions(
  interval: 1 | 15 | 30 | 60 = 60
): Option[] {
  const times = [];

  if (![1, 15, 30, 60].includes(interval)) interval = 60;

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute = minute + interval) {
      // prettier-ignore
      times.push({
        label:
          [
            `${hour > 12 ? hour - 12 : hour === 0 ? 12 : hour}`.padStart(2, '0'),
            `${minute}`.padStart(2, '0')
          ].join(':') + (hour >= 12 ? ' PM' : ' AM'),
        value: [
          `${hour}`.padStart(2, '0'),
          `${minute}`.padStart(2, '0')
        ].join(':')
      });
    }
  }

  return times;
}
