import Button from '@targetx/mineral-ui/Button';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextArea from '@targetx/mineral-ui/TextArea';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  ReactElement,
  useState
} from 'react';
import { ValueType } from 'react-select/src/types';
import isEmpty from 'validator/lib/isEmpty';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import { hasMergeFields } from '../utils/MergeFieldUtils';
import copyText from './SendMessageForm.copyText';

const KEY_NAME_ENTER = 'Enter';

const TEMPLATE_TYPE_GLOBAL = 'GLOBAL';
const TEMPLATE_TYPE_PERSONAL = 'PERSONAL';

const CONTAINER_MIN_WIDTH = 405;
const MESSAGE_CONTENT_MAX_LENGTH = 1000;

export namespace SendMessageForm {
  export interface MessageTemplateEntity {
    id: string;
    ownerID?: string;
    name: string;
    content: string;
  }

  export interface Props {
    isProcessing?: boolean;
    isLoadingTemplates?: boolean;
    templates: MessageTemplateEntity[];
    onInteraction?: InteractionDelegate;
  }
}

interface Option {
  label: string;
  value: string;
}

interface State {
  contentInput: { value: string; isValid: boolean };
  selectedTemplateID: string;
}

const initialState = {
  contentInput: { value: '', isValid: false },
  selectedTemplateID: ''
};

export function SendMessageForm({
  isLoadingTemplates,
  isProcessing,
  templates,
  onInteraction = noop
}: SendMessageForm.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  const { contentInput, selectedTemplateID } = state;

  const canSubmit = contentInput.isValid;

  const templatesKeyedByID = keyBy(templates, 'id');

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const name = event.target.name;
    const value = event.target.value.slice(0, MESSAGE_CONTENT_MAX_LENGTH);

    let isValid = false;

    switch (name) {
      case 'content':
        isValid =
          !isEmpty(value, { ignore_whitespace: true }) &&
          value.length <= MESSAGE_CONTENT_MAX_LENGTH &&
          !hasMergeFields(value);
        break;
      default:
        break;
    }

    setState(currentState => ({
      ...currentState,
      [`${name}Input`]: { value, isValid }
    }));
  }

  function handleChangeOption(option: ValueType<Option, false>): void {
    const value = option ? option.value : '';

    const template = templatesKeyedByID[value];

    setState(currentState => ({
      ...currentState,
      selectedTemplateID: value,
      contentInput: {
        value: template.content,
        isValid: !hasMergeFields(template.content)
      }
    }));
  }

  function handleKeyPress(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key !== KEY_NAME_ENTER) return;

    handleSubmit(event);
  }

  function handleSubmit(
    event: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLInputElement>
  ): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: SendMessageForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      content: contentInput.value.trim()
    });

    setState(initialState);
  }

  const templatesGroupedByType = groupBy(templates, messageTemplate =>
    messageTemplate.ownerID ? TEMPLATE_TYPE_PERSONAL : TEMPLATE_TYPE_GLOBAL
  );

  let selectedTemplateOption = null;

  const templateOptions = Object.entries(templatesGroupedByType).map(
    ([templateType, templates]) => ({
      label: get(copyText, `templateOptionGroupLabel_${templateType}`),
      options: templates.map(template => {
        const option = {
          label: template.name,
          value: template.id
        };

        if (selectedTemplateID === template.id) {
          selectedTemplateOption = option;
        }

        return option;
      })
    })
  );

  return (
    <Flex
      as={Form}
      borderTop={`1px solid ${palette.gray[40]}`}
      direction="column"
      height="fit-content"
      minWidth={CONTAINER_MIN_WIDTH}
      paddingTop={theme.space_inset_md}
      onSubmit={handleSubmit}
    >
      <FormField
        name="content"
        input={TextArea}
        caption={
          <Flex justifyContent="between">
            <Text color={palette.red[60]} fontSize={theme.fontSize_mouse}>
              {hasMergeFields(contentInput.value)
                ? copyText.contentInputCaptionWarning
                : ''}
            </Text>
            <Text color={palette.gray[80]} fontSize={theme.fontSize_mouse}>
              {copyText.contentInputCaptionCount.replace(
                '%count%',
                `${MESSAGE_CONTENT_MAX_LENGTH - contentInput.value.length}`
              )}
            </Text>
          </Flex>
        }
        label={copyText.contentInputLabel}
        placeholder={copyText.contentInputPlaceholder}
        hideLabel
        required
        resizeable={false}
        rows={2}
        value={contentInput.value}
        variant={hasMergeFields(contentInput.value) ? 'danger' : null}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
      />
      <FlexItem flex justifyContent="between" marginTop={theme.space_stack_xs}>
        <FormField
          name="templateID"
          label={copyText.templateInputLabel}
          hideLabel
          width="20rem"
        >
          <Select
            isLoading={isLoadingTemplates}
            options={templateOptions}
            placeholder={copyText.templateInputPlaceholder}
            menuPlacement="top"
            value={selectedTemplateOption}
            onChange={handleChangeOption}
          />
        </FormField>
        <Button
          disabled={!canSubmit || isProcessing}
          marginLeft={theme.space_inline_md}
          primary
          type="submit"
          width="10rem"
        >
          {isProcessing ? (
            <SVGSpinner fill={palette.white} size="2em" />
          ) : (
            copyText.submitButtonLabel
          )}
        </Button>
      </FlexItem>
    </Flex>
  );
}

SendMessageForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${SendMessageForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default SendMessageForm;
