import { trackQueries } from './trackUser';
const ALERT_LOAD_TIME = 5; // time in seconds

export default class LogMeasureCount {
  private marks: Array<string> = [];
  private names: Array<string>;
  private measures: number;
  private readonly inboxID: string;
  private readonly orgId: string;
  private readonly orgName: string;
  private readonly salesForceId: string;

  constructor(
    names: Array<string>,
    inboxID: string,
    orgId: string,
    orgName: string,
    salesForceId: string | undefined
  ) {
    this.names = names;
    this.inboxID = inboxID;
    this.orgId = orgId;
    this.orgName = orgName;
    this.measures = 0;
    this.salesForceId = salesForceId || '';
    for (let index = 0; index < names.length; index++) {
      this.marks[this.marks.length] = names[index];
    }
  }

  public calcPerformance = (): void => {
    // Create a variety of measurements.
    if (this.measures != this.marks.length) {
      //Error the marks and the times performance mark call does not match
      return;
    }
    try {
      for (let index = 1; index < this.marks.length; index++) {
        performance.measure(
          this.marks[index],
          this.marks[index - 1],
          this.marks[index]
        );
      }
    } catch (error) {}
    // Finally, get and clean up the entries.
    this.getMeasurements();
  };

  public setPerformance = (step: number): void => {
    performance.mark(this.marks[step]);
    this.measures++;
  };

  private getMeasurements = (): void => {
    // Pull out all of the measurements.
    const measure = performance.getEntriesByType('measure');

    for (let index = 0; index < measure.length; index++) {
      const duration = Math.round(measure[index].duration) / 1000;
      if (duration >= ALERT_LOAD_TIME) {
        const results = {
          'Inbox Id': this.inboxID,
          OrgId: this.orgId,
          'Org Name': this.orgName,
          'Salesforce Id': this.salesForceId,
          'Query Name': measure[index].name,
          'Execution Time in seconds': duration,
          Function: this.marks[0]
        };
        //Call function and save to heap
        trackQueries(results);
      }
    }

    performance.clearMarks();
    performance.clearMeasures();
  };
}
