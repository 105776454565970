export default {
  additionalSettingsLabel: 'Additional Settings',
  cancelButtonLabel: 'CANCEL',
  configureColumnMappingButtonLabel: 'CONFIGURE',
  contentInputCaption: '%count% characters left.',
  contentInputLabel: 'Content',
  contentInputPlaceholder: 'Type your bulk message here',
  contentInputWarning:
    'At least one merge field in this message cannot be populated by records in the specified report. Please correct or remove these merge fields and try again.',
  dayOfMonthOptionLabel: 'Day of Month',
  dayOfWeekLabel_0: 'Sunday',
  dayOfWeekLabel_1: 'Monday',
  dayOfWeekLabel_2: 'Tuesday',
  dayOfWeekLabel_3: 'Wednesday',
  dayOfWeekLabel_4: 'Thursday',
  dayOfWeekLabel_5: 'Friday',
  dayOfWeekLabel_6: 'Saturday',
  deactivatedUserWarning:
    'This bulk message is currently inactive. You must select an active user as the owner before reactivating the the message schedule.',
  enableContactActivityLoggingInputLabel: 'Log Contact Activity Record',
  enableDuplicateMessagesInputLabel: 'Send Duplicate Messages to Recipients',
  enableScheduleMessageInputLabel: 'Schedule Message',
  endDateOptionLabel: 'End Date',
  endDateScheduleSummary: ', ending **%endDateText%**',
  endTimeOptionLabel: 'End Time',
  envelopeTemplateOptionLabel: 'Envelope Template',
  executionTimeOptionLabel: 'Run Time',
  executionTimeZoneLabel: 'Time Zone',
  frequencyDaily: 'Daily',
  frequencyDailyScheduleSummary: '**Every day**, at **%executionTimeText%**,',
  frequencyMonthly: 'Monthly',
  frequencyMonthlyScheduleSummary:
    'On the **%dayOfMonth%** of **every month**, at **%executionTimeText%**,',
  frequencyOnce: 'Once',
  frequencyOnceScheduleSummary:
    '**Once** at **%executionTimeText%**, on **%startDateText%**',
  frequencyOptionLabel: 'Frequency',
  frequencyWeekly: 'Weekly',
  frequencyWeeklyScheduleSummary:
    '**Every week**, on **%daysOfWeek%**, at **%executionTimeText%**,',
  mergeFieldPlaceholder: 'Insert Merge Field (Optional)',
  messageLabel: 'Message',
  messageScheduledTitle: 'Message Scheduled',
  nameInputLabel: 'Name',
  optionPlaceholder: 'Choose One',
  reloadButtonLabel: 'RELOAD',
  reportIDInputPlaceholder: 'Select Report',
  reportInputLabel: 'Report',
  salesforceIntegrationOptionsHeading: 'Salesforce Integration Options',
  saveButtonLabel: 'SAVE',
  scheduleMessageDescription: 'Manage and Schedule Message',
  scheduleMessageTitle: 'Schedule Bulk Message',
  scheduleSummaryLabel: 'Summary',
  sendBroadcastWarning:
    'The "Bulk Message" feature is disabled because you have not specified which column in the selected Salesforce report corresponds to: **%missingColumnNames%**. Please click below to configure the column mapping, or select a different report.',
  startDateOptionLabel: 'Start Date',
  startDateScheduleSummary: 'starting **%startDateText%**',
  submitButtonLabel: 'SUBMIT',
  templateSelectPlaceholder: 'Select Message Template (Optional)',
  timeZoneLabel_America_Adak: 'Hawaii Time',
  timeZoneLabel_America_Chicago: 'Central Time',
  timeZoneLabel_America_Juneau: 'Alaska Time',
  timeZoneLabel_America_Los_Angeles: 'Pacific Time',
  timeZoneLabel_America_New_York: 'Eastern Time',
  timeZoneLabel_America_Phoenix: 'Mountain Time',
  title: 'Create Bulk Message',
  titleUpdate: 'Edit Bulk Message',
  userIDInputPlaceholder: 'Select User',
  userInputLabel: 'Owner',
  viewScheduleMessageLabel: 'View Schedule Message Details',
  weekDayLabel: 'On the following days'
} as { [key: string]: string };
