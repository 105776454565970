export default {
  labelAssignedTo: 'Assigned To',
  labelEmailAddress: 'Email Address',
  labelMobilePhone: 'Mobile Phone',
  linkFullProfile: 'View Full Profile',
  linkMessageTranscripts: 'View Message Transcripts',
  noAssignedToPlaceholder: 'Not assigned to a staff member',
  noContactLabel: 'No information available at this time',
  noEmailAddressPlaceholder: 'No email address on record',
  personalInfoSectionHeader: 'Personal Info'
};
