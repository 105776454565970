import uniq from 'lodash.uniq';

const MERGE_FIELDS_REGEX = /{{\w+}}/;
const MERGE_FIELDS_REGEX_GLOBAL = /{{\w+}}/g;

export function extractMergeFields(content: string): string[] {
  const matches = uniq(content.match(MERGE_FIELDS_REGEX_GLOBAL));

  if (!matches) return [];

  return matches.map(match => match.replace(/{{|}}/g, ''));
}

export function hasMergeFields(content: string): boolean {
  return MERGE_FIELDS_REGEX.test(content);
}

export function isMissingMergeFields(
  keys: string[],
  mergeFields: string[]
): boolean {
  return !mergeFields.every(mergeField => keys.includes(mergeField));
}

export default {
  extractMergeFields,
  hasMergeFields,
  isMissingMergeFields
};
