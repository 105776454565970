/* eslint-disable @typescript-eslint/no-unused-vars*/

import { RouteComponentProps, useNavigate } from '@reach/router';
import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Dropdown from '@targetx/mineral-ui/Dropdown';
import { FlexItem } from '@targetx/mineral-ui/Flex';
import Pagination from '@targetx/mineral-ui/Pagination';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { ReportStatus } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import IconEllipsisVertical from '@targetx/tx-web-ui-lib/lib/icons/IconEllipsisVertical';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import get from 'lodash.get';
import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import CreateReportForm from '../../components/CreateReportForm';
import EditReportForm from '../../components/EditReportForm';
import ReportList from '../../components/ReportList';
import ScreenLevelAlert from '../../components/ScreenLevelAlert';
import paths from '../../constants/paths';
import useDataAPICreateReport from '../../data-api/useDataAPICreateReport';
import useDataAPIDeactivateReport from '../../data-api/useDataAPIDeactivateReport';
import useDataAPIGetReportByID from '../../data-api/useDataAPIGetReportByID';
import useDataAPIGetReportColumnNamesByReportID from '../../data-api/useDataAPIGetReportColumnNamesByReportID';
import useDataAPIGetReportsByOrgID from '../../data-api/useDataAPIGetReportsByOrgID';
import useDataAPIGetSalesforceReportByOrgIDAndRID from '../../data-api/useDataAPIGetSalesforceReportByOrgIDAndRID';
import useDataAPIGetSalesforceReportColumnNamesByReportID from '../../data-api/useDataAPIGetSalesforceReportColumnNamesByReportID';
import useDataAPIGetSalesforceReportFoldersByOrgID from '../../data-api/useDataAPIGetSalesforceReportFoldersByOrgID';
import useDataAPIGetSalesforceReportsByOrgIDAndFolderID from '../../data-api/useDataAPIGetSalesforceReportsByOrgIDAndFolderID';
import useDataAPIUpdateReport from '../../data-api/useDataAPIUpdateReport';
import SettingsScreenLayout from '../../layouts/SettingsScreenLayout';
import { bmStyles, modalStyles } from '../../styles';
import theme from '../../theme';
import { UserEntity } from '../../types';
import Interaction from '../../types/Interaction';
import isBlank from '../../utils/isBlank';
import copyText from './copyText';

const ACTION_PANEL_CREATE_FORM = 'CREATE_FORM';
const ACTION_PANEL_EDIT_FORM = 'EDIT_FORM';

const ERROR_CREATING_REPORT = 'ERROR_CREATING_REPORT';
const ERROR_DEACTIVATING_REPORT = 'ERROR_DEACTIVATING_REPORT';
const ERROR_INITIALIZING = 'ERROR_INITIALIZING';
const ERROR_LOADING_REPORT_COLUMN_NAMES = 'ERROR_LOADING_REPORT_COLUMN_NAMES';
const ERROR_LOADING_REPORT = 'ERROR_LOADING_REPORT';
const ERROR_LOADING_REPORTS = 'ERROR_LOADING_REPORTS';
const ERROR_LOADING_SALESFORCE_REPORT_COLUMN_NAMES =
  'ERROR_LOADING_SALESFORCE_REPORT_COLUMN_NAMES';
const ERROR_LOADING_SALESFORCE_REPORT_FOLDERS =
  'ERROR_LOADING_SALESFORCE_REPORT_FOLDERS';
const ERROR_LOADING_SALESFORCE_REPORT = 'ERROR_LOADING_SALESFORCE_REPORT';
const ERROR_LOADING_SALESFORCE_REPORTS = 'ERROR_LOADING_SALESFORCE_REPORTS';
const ERROR_LOADING_SALESFORCE_REPORTS_BY_FOLDER =
  'ERROR_LOADING_SALESFORCE_REPORTS_BY_FOLDER';
const ERROR_UPDATING_REPORT_DUPLICATE_NAME =
  'ERROR_UPDATING_REPORT_DUPLICATE_NAME';
const ERROR_UPDATING_REPORT = 'ERROR_UPDATING_REPORT';

const MODAL_DELETE_CONFIRMATION = 'DELETE_CONFIRMATION';

const SUCCESS_CREATING_REPORT = 'SUCCESS_CREATING_REPORT';
const SUCCESS_DEACTIVATING_REPORT = 'SUCCESS_DEACTIVATING_REPORT';
const SUCCESS_UPDATING_REPORT = 'SUCCESS_UPDATING_REPORT';

const pageSize = 15;

interface Props extends RouteComponentProps {
  authenticatedUser: UserEntity;
  homeBaseURL: string;
  reportID?: string;
  signOutPath: string;
}

interface MenuOption {
  text: string;
  onClick: (event: MouseEvent) => void;
}

export function ReportManagementScreen({
  authenticatedUser,
  homeBaseURL,
  reportID: editingReportID,
  signOutPath
}: Props): ReactElement {
  const navigate = useNavigate();

  //
  // State
  //

  const [actionPanelKey, setActionPanelKey] = useState('');
  const [alertKey, setAlertKey] = useState('');
  const [lastModifiedReportName, setLastModifiedReportName] = useState('');
  const [modalComponentKey, setModalComponentKey] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedReportID, setSelectedReportID] = useState<string | undefined>(
    undefined
  );
  const [selectedReportRID, setSelectedReportRID] = useState<
    string | undefined
  >(undefined);
  const [selectedSalesforceFolderID, setSelectedSalesforceFolderID] = useState<
    string | undefined
  >(undefined);
  const [selectedSalesforceReportID, setSelectedSalesforceReportID] = useState<
    string | undefined
  >(undefined);
  const [showSearchInput, setShowSearchInput] = useState(false);

  //
  // Data API
  //

  const {
    data: _reports,
    error: errorLoadingReports,
    isLoading: isLoadingReports,
    refetch: refetchReports
  } = useDataAPIGetReportsByOrgID(authenticatedUser.orgID);

  const salesforceReportsByOrgIDAndFolderIDResult =
    useDataAPIGetSalesforceReportsByOrgIDAndFolderID(
      selectedSalesforceFolderID,
      actionPanelKey === ACTION_PANEL_CREATE_FORM
        ? authenticatedUser.orgID
        : undefined
    );

  const {
    error: errorLoadingSalesforceReportsByFolder,
    isLoading: isLoadingSalesforceReportsByFolder
  } = salesforceReportsByOrgIDAndFolderIDResult;

  let salesforceReportsByFolder =
    salesforceReportsByOrgIDAndFolderIDResult.data;

  const {
    data: salesforceReportColumnNames,
    error: errorLoadingSalesforceReportColumnNames,
    isLoading: isLoadingSalesforceReportColumnNames
  } = useDataAPIGetSalesforceReportColumnNamesByReportID(
    selectedSalesforceReportID
  );

  const { data: editingReport, error: errorLoadingReport } =
    useDataAPIGetReportByID(selectedReportID);

  const {
    data: reportColumnNames,
    error: errorLoadingReportColumnNames,
    isLoading: isLoadingReportColumnNames
  } = useDataAPIGetReportColumnNamesByReportID(selectedReportID);

  const salesforceReportFoldersByOrgIDResult =
    useDataAPIGetSalesforceReportFoldersByOrgID(
      actionPanelKey === ACTION_PANEL_CREATE_FORM
        ? authenticatedUser.orgID
        : undefined
    );

  const {
    error: errorLoadingSalesforceReportFolders,
    isLoading: isLoadingSalesforceReportFolders
  } = salesforceReportFoldersByOrgIDResult;

  let salesforceReportFolders = salesforceReportFoldersByOrgIDResult.data;

  const {
    data: salesforceReport,
    error: errorLoadingSalesforceReportByRID,
    isLoading: isLoadingSalesforceReport
  } = useDataAPIGetSalesforceReportByOrgIDAndRID(
    selectedReportRID,
    authenticatedUser.orgID
  );

  const {
    data: lastCreatedReportID,
    error: errorCreatingReport,
    isProcessing: isCreatingReport,
    invoke: createReport
  } = useDataAPICreateReport();

  const {
    data: lastDeactivatedReportID,
    error: errorDeactivatingReport,
    isProcessing: isDeactivatingReport,
    invoke: deactivateReport
  } = useDataAPIDeactivateReport();

  const {
    data: lastUpdatedReportID,
    error: errorUpdatingReport,
    isProcessing: isUpdatingReport,
    invoke: updateReport
  } = useDataAPIUpdateReport();

  //
  // Computed Values
  //

  const reportsKeyedByID = useMemo(
    () =>
      keyBy(
        _reports?.filter(
          _report => _report.status !== ReportStatus.DEACTIVATED
        ),
        'id'
      ),
    [_reports]
  );

  let reports =
    _reports?.filter(_report => _report.status !== ReportStatus.DEACTIVATED) ??
    [];

  reports = useMemo(
    () =>
      searchTerm.length > 0
        ? reports.filter((report): boolean =>
            report.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : reports,
    [reports, searchTerm]
  );

  const totalReports = reports.length;

  reports = useMemo(
    () =>
      reports.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize),
    [reports, pageNumber]
  );

  salesforceReportsByFolder = useMemo(
    () =>
      orderBy(
        salesforceReportsByFolder,
        (salesforceReport): string => salesforceReport.name.toLowerCase(),
        'asc'
      ),
    [salesforceReportsByFolder]
  );

  salesforceReportFolders = useMemo(
    () =>
      orderBy(
        salesforceReportFolders,
        (folder): string => folder.name.toLowerCase(),
        'asc'
      ),
    [salesforceReportFolders]
  );

  //
  // Side Effects
  //

  useEffect(() => {
    if (!alertKey.startsWith('SUCCESS_')) return;
    refetchReports(authenticatedUser.orgID);
  }, [alertKey]);

  useEffect(() => {
    if (isNil(editingReportID)) return;
    setActionPanelKey(ACTION_PANEL_EDIT_FORM);
    setSelectedReportID(editingReportID);
  }, [editingReportID]);

  useEffect(() => {
    if (isNil(editingReport)) return;
    setSelectedReportRID(editingReport?.rid);
  }, [editingReport]);

  useEffect(() => {
    if (!errorCreatingReport) return;
    setAlertKey(ERROR_CREATING_REPORT);
  }, [errorCreatingReport]);

  useEffect(() => {
    if (!errorDeactivatingReport) return;
    setAlertKey(ERROR_DEACTIVATING_REPORT);
  }, [errorDeactivatingReport]);

  useEffect(() => {
    if (!lastDeactivatedReportID) return;
    setAlertKey(SUCCESS_DEACTIVATING_REPORT);
    setActionPanelKey('');
    setModalComponentKey('');
  }, [lastDeactivatedReportID]);

  useEffect(() => {
    if (!errorLoadingReport) return;
    setAlertKey(ERROR_LOADING_REPORT);
  }, [errorLoadingReport]);

  useEffect(() => {
    if (!errorLoadingReportColumnNames) return;
    setAlertKey(ERROR_LOADING_REPORT_COLUMN_NAMES);
  }, [errorLoadingReportColumnNames]);

  useEffect(() => {
    if (!errorLoadingReports) return;
    setAlertKey(ERROR_LOADING_REPORTS);
  }, [errorLoadingReports]);

  useEffect(() => {
    if (!errorLoadingSalesforceReportColumnNames) return;
    setAlertKey(ERROR_LOADING_SALESFORCE_REPORT_COLUMN_NAMES);
  }, [errorLoadingSalesforceReportColumnNames]);

  useEffect(() => {
    if (!errorLoadingSalesforceReportFolders) return;
    setAlertKey(ERROR_LOADING_SALESFORCE_REPORT_FOLDERS);
  }, [errorLoadingSalesforceReportFolders]);

  useEffect(() => {
    if (!errorLoadingSalesforceReportByRID) return;
    setAlertKey(ERROR_LOADING_SALESFORCE_REPORTS);
  }, [errorLoadingSalesforceReportByRID]);

  useEffect(() => {
    if (!errorLoadingSalesforceReportsByFolder) return;
    setAlertKey(ERROR_LOADING_SALESFORCE_REPORTS_BY_FOLDER);
  }, [errorLoadingSalesforceReportsByFolder]);

  useEffect(() => {
    if (!errorUpdatingReport) return;
    setAlertKey(
      errorUpdatingReport.reason === 'DUPLICATE_NAME'
        ? ERROR_UPDATING_REPORT_DUPLICATE_NAME
        : ERROR_UPDATING_REPORT
    );
  }, [errorUpdatingReport]);

  useEffect(() => {
    if (!lastCreatedReportID) return;
    setActionPanelKey('');
    setAlertKey(SUCCESS_CREATING_REPORT);
    setSelectedSalesforceReportID(undefined);
    setSelectedSalesforceFolderID(undefined);
  }, [lastCreatedReportID]);

  useEffect(() => {
    if (!lastUpdatedReportID) return;

    if (!isNil(editingReportID)) {
      navigate(paths.settingsReports);
      return;
    }

    setActionPanelKey('');
    setAlertKey(SUCCESS_UPDATING_REPORT);
    setSelectedReportID(undefined);
  }, [lastUpdatedReportID]);

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;

    if (!isNil(editingReportID)) {
      navigate(paths.settingsReports);
      return;
    }

    setActionPanelKey('');
    setSelectedReportID(undefined);
    setSelectedSalesforceReportID(undefined);
  }

  //TODO: will come back to add archive report

  function handleClickDeactivateButton({
    reportID
  }: {
    reportID: string;
  }): void {
    setSelectedReportID(reportID);
    setLastModifiedReportName(reportsKeyedByID[reportID].name);
    setModalComponentKey(MODAL_DELETE_CONFIRMATION);
  }

  function handleClickEditButton({ reportID }: { reportID: string }): void {
    setActionPanelKey(ACTION_PANEL_EDIT_FORM);
    setSelectedReportID(reportID);
  }

  function handleClickRootContainer(): void {
    if (
      [
        '',
        ERROR_INITIALIZING,
        ERROR_LOADING_REPORTS,
        ERROR_LOADING_SALESFORCE_REPORTS_BY_FOLDER
      ].includes(alertKey)
    ) {
      return;
    }

    setAlertKey('');
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case CreateReportForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        setActionPanelKey('');
        setSelectedSalesforceReportID(undefined);
        return;
      }

      case CreateReportForm.INTERACTION_SALESFORCE_REPORT_FOLDER_SELECTED: {
        setSelectedSalesforceFolderID(data.folderID);
        return;
      }

      case CreateReportForm.INTERACTION_SALESFORCE_REPORT_SELECTED: {
        setSelectedSalesforceReportID(data.rid);
        return;
      }
      case CreateReportForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        createReport({
          orgID: authenticatedUser.orgID,
          name: data.name,
          type: data.reportType,
          rid: data.rid,
          columnMapping: {
            ...(!isBlank(data.contactIDColumnName)
              ? { contactID: data.contactIDColumnName }
              : {}),
            ...(!isBlank(data.firstNameColumnName)
              ? { firstName: data.firstNameColumnName }
              : {}),
            ...(!isBlank(data.lastNameColumnName)
              ? { lastName: data.lastNameColumnName }
              : {}),
            ...(!isBlank(data.mobilePhoneNumberColumnName)
              ? { mobilePhoneNumber: data.mobilePhoneNumberColumnName }
              : {})
          }
        });

        setLastModifiedReportName(data.name);
        return;
      }
      case EditReportForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        if (!isNil(editingReportID)) {
          navigate(paths.settingsReports);
          return;
        }

        setActionPanelKey('');
        setSelectedReportID(undefined);
        return;
      }
      case EditReportForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateReport(data.reportID, {
          name: data.name,
          columnMapping: {
            ...(!isBlank(data.contactIDColumnName)
              ? { contactID: data.contactIDColumnName }
              : {}),
            ...(!isBlank(data.firstNameColumnName)
              ? { firstName: data.firstNameColumnName }
              : {}),
            ...(!isBlank(data.lastNameColumnName)
              ? { lastName: data.lastNameColumnName }
              : {}),
            ...(!isBlank(data.mobilePhoneNumberColumnName)
              ? { mobilePhoneNumber: data.mobilePhoneNumberColumnName }
              : {})
          }
        });

        setLastModifiedReportName(
          data.name ? data.name : reportsKeyedByID[data.reportID].name
        );
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        setSearchTerm('');
        setShowSearchInput(false);
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        setPageNumber(1);
        setSearchTerm(data.searchTerm);
        return;
      }
    }
  }

  //
  // Render
  //

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_CREATE_FORM:
        return (
          <CreateReportForm
            isLoadingReportFolders={isLoadingSalesforceReportFolders}
            isLoadingReports={isLoadingSalesforceReportsByFolder}
            isLoadingReportColumnNames={isLoadingSalesforceReportColumnNames}
            isProcessing={isCreatingReport}
            message={renderAlert()}
            reportColumnNames={salesforceReportColumnNames}
            salesforceReportFolders={salesforceReportFolders}
            salesforceReports={salesforceReportsByFolder}
            onInteraction={handleInteraction}
          />
        );
      case ACTION_PANEL_EDIT_FORM:
        return (
          <EditReportForm
            isLoadingReportColumnNames={isLoadingReportColumnNames}
            isLoadingSalesforceReportName={isLoadingSalesforceReport}
            message={renderAlert()}
            report={editingReport}
            reportColumnNames={reportColumnNames}
            salesforceReportName={salesforceReport?.name}
            isProcessing={isUpdatingReport || isDeactivatingReport}
            onInteraction={handleInteraction}
          />
        );
      default:
        return null;
    }
  }

  function renderAlert(): ReactNode {
    if (
      ![
        ERROR_CREATING_REPORT,
        ERROR_LOADING_REPORT_COLUMN_NAMES,
        ERROR_LOADING_REPORT,
        ERROR_LOADING_SALESFORCE_REPORTS,
        ERROR_LOADING_SALESFORCE_REPORTS_BY_FOLDER,
        ERROR_UPDATING_REPORT_DUPLICATE_NAME
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton={alertKey !== ERROR_LOADING_SALESFORCE_REPORTS}
        title={copyText.ERROR_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderMenu({ reportID }: { reportID: string }): ReactNode {
    const menuOptions: MenuOption[] = [
      {
        text: copyText.menuOptionEditLabel,
        onClick: (): void => handleClickEditButton({ reportID })
      },
      {
        text: copyText.menuOptionDeactivateLabel,
        onClick: (): void => handleClickDeactivateButton({ reportID })
      }
    ];

    return (
      <Dropdown data={menuOptions} placement="bottom-end">
        <MinimalButton
          aria-label={copyText.openMenuButtonLabel}
          iconStart={<IconEllipsisVertical color={palette.gray[60]} />}
          size="small"
          type="button"
        />
      </Dropdown>
    );
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_DELETE_CONFIRMATION: {
        if (!selectedReportID) return;
        const { id: reportID, name } = reportsKeyedByID[selectedReportID];

        return (
          <ConfirmationDialog
            message={copyText.confirmationDialogDangerMessage.replace(
              '%name%',
              name
            )}
            title={copyText.confirmationDialogDangerTitle}
            variant="danger"
            onCancel={handleCloseModal}
            onConfirm={() => deactivateReport(reportID)}
          />
        );
      }
      default:
        return null;
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        ERROR_DEACTIVATING_REPORT,
        ERROR_INITIALIZING,
        ERROR_LOADING_REPORTS,
        SUCCESS_CREATING_REPORT,
        SUCCESS_DEACTIVATING_REPORT,
        SUCCESS_UPDATING_REPORT
      ].includes(alertKey)
    ) {
      return null;
    }

    let message = get(copyText, `${alertKey}_message`) || '';

    message = !isNil(lastModifiedReportName)
      ? message.replace('%reportName%', lastModifiedReportName)
      : message;

    const showCloseButton = ![
      ERROR_INITIALIZING,
      ERROR_LOADING_REPORTS
    ].includes(alertKey);

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={message}
        showCloseButton={showCloseButton}
        withMarkdown
      />
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: copyText.title, color: palette.gray[100] }
  ];

  function handleCloseModal(): void {
    if (actionPanelKey === '') setSelectedReportID('');
    setModalComponentKey('');
  }

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={!isNil(editingReportID) || actionPanelKey.length > 0}
        noTransition={!isNil(editingReportID)}
        right
        styles={bmStyles}
        width={500}
        onStateChange={handleChangeActionPanelState}
      >
        {renderActionPanel()}
      </Menu>
      <SettingsScreenLayout
        authenticatedUser={authenticatedUser}
        currentPath={paths.settingsReports}
        homeBaseURL={homeBaseURL}
        signOutPath={signOutPath}
      >
        <SettingsScreenLayout.Header>
          <Breadcrumb items={breadcrumbItems} />
          <FlexItem flex>
            {showSearchInput ? (
              <SearchInput
                aria-label={copyText.searchInputLabel}
                name="searchTerm"
                clearable
                onInteraction={handleInteraction}
              />
            ) : (
              <Button
                aria-label={copyText.searchToggleButtonLabel}
                iconStart={<IconSearch color={palette.gray[60]} />}
                minimal
                onClick={() => setShowSearchInput(true)}
              />
            )}
            <Button
              aria-label={copyText.createButtonLabel}
              iconStart={<IconPlus color={palette.green[60]} />}
              marginLeft={theme.space_inline_xs}
              minimal
              onClick={() => setActionPanelKey(ACTION_PANEL_CREATE_FORM)}
            />
          </FlexItem>
        </SettingsScreenLayout.Header>
        <SettingsScreenLayout.Body>
          {renderScreenAlert()}
          <ReportList
            isLoading={isLoadingReports}
            renderItemMenu={renderMenu}
            reports={reports}
          />
          {totalReports > pageSize ? (
            <Box
              alignSelf="end"
              marginVertical={theme.space_stack_md}
              width="100%"
            >
              <Pagination
                currentPage={pageNumber}
                onPageChange={setPageNumber}
                pageSize={pageSize}
                totalCount={totalReports}
              />
            </Box>
          ) : null}
        </SettingsScreenLayout.Body>
      </SettingsScreenLayout>
    </Box>
  );
}

export default ReportManagementScreen;
