/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';
import { ContactRecord } from './types';

interface State {
  readonly data?: ContactRecord;
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (rid: string) => Promise<void>;
}

export default function useDataAPIGetContactByRID(rid?: string): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(rid: string): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getContactByRID(rid);

      setState({ isLoading: false, data });
    } catch (error: any) {
      setState({ isLoading: false, error });
    }
  }

  useEffect(() => {
    if (!rid) return;

    fetch(rid);
  }, [rid]);

  return { ...state, refetch: fetch };
}
