import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import { FormField, FormFieldDivider } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { ReportType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import Select from '@targetx/tx-web-ui-lib/lib/components/Select';
import { InteractionDelegate } from '@targetx/tx-web-ui-lib/lib/types/Interaction';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import { ValueType } from 'react-select';
import isEmpty from 'validator/lib/isEmpty';
import { SalesforceReportFolderObject } from '../data-api/types';
import styleProps from '../styles/props';
import theme from '../theme';
import { Input, Option } from '../types/Form';
import { PartialState } from '../types/PartialState';
import isBlank from '../utils/isBlank';
import copyText from './CreateReportForm.copyText';
import MoreInfoPopover from './MoreInfoPopover';

export namespace CreateReportForm {
  export interface SalesforceReportObject {
    id: string;
    name: string;
  }

  export interface Props {
    isLoadingReportFolders?: boolean;
    isLoadingReports?: boolean;
    isLoadingReportColumnNames?: boolean;
    isProcessing?: boolean;
    message?: ReactNode;
    reportColumnNames?: string[];
    salesforceReportFolders?: SalesforceReportFolderObject[];
    salesforceReports?: SalesforceReportObject[];
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  contactIDColumnNameInput: Input;
  firstNameColumnNameInput: Input;
  folderIDInput: Input;
  lastNameColumnNameInput: Input;
  mobilePhoneNumberColumnNameInput: Input;
  nameInput: Input;
  reportTypeInput: Input;
  ridInput: Input;
}

const typeOptions = [
  {
    label: copyText.typeOptionLabelSalesforceReport,
    value: ReportType.SALESFORCE_REPORT
  }
];

const defaultOption = typeOptions[0];

const initialState = {
  contactIDColumnNameInput: { value: '', isValid: true },
  firstNameColumnNameInput: { value: '', isValid: true },
  folderIDInput: { value: '', isValid: true },
  lastNameColumnNameInput: { value: '', isValid: true },
  mobilePhoneNumberColumnNameInput: { value: '', isValid: true },
  nameInput: { value: '', isValid: false },
  reportTypeInput: { value: defaultOption?.value || '', isValid: true },
  ridInput: { value: '', isValid: false }
};

export function CreateReportForm({
  isLoadingReportFolders,
  isLoadingReports,
  isLoadingReportColumnNames,
  isProcessing,
  message,
  reportColumnNames,
  salesforceReportFolders,
  salesforceReports,
  onInteraction = noop
}: CreateReportForm.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    contactIDColumnNameInput,
    firstNameColumnNameInput,
    folderIDInput,
    lastNameColumnNameInput,
    mobilePhoneNumberColumnNameInput,
    nameInput,
    reportTypeInput,
    ridInput
  } = state;

  //
  // Interaction Handlers
  //

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    let derivedState = {};

    let isValid = true;

    switch (name) {
      case 'name':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case 'folderID':
        derivedState = {
          ridInput: { value: '', isValid: false }
        };
        isValid = !isEmpty(value);
        onInteraction({
          type: CreateReportForm.INTERACTION_SALESFORCE_REPORT_FOLDER_SELECTED,
          folderID: value
        });
        break;
      case 'rid':
        derivedState = {
          contactIDColumnNameInput: { value: '', isValid: true },
          firstNameColumnNameInput: { value: '', isValid: true },
          lastNameColumnNameInput: { value: '', isValid: true },
          mobilePhoneNumberColumnNameInput: { value: '', isValid: true }
        };
        isValid = !isEmpty(value);
        onInteraction({
          type: CreateReportForm.INTERACTION_SALESFORCE_REPORT_SELECTED,
          rid: value
        });
        break;
      default:
        break;
    }

    changeState({ ...derivedState, [`${name}Input`]: { value, isValid } });
  }

  function handleReset(): void {
    onInteraction({ type: CreateReportForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    onInteraction({
      type: CreateReportForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      name: nameInput.value.trim(),
      reportType: reportTypeInput.value,
      rid: ridInput.value,
      contactIDColumnName: contactIDColumnNameInput.value,
      firstNameColumnName: firstNameColumnNameInput.value,
      lastNameColumnName: lastNameColumnNameInput.value,
      mobilePhoneNumberColumnName: mobilePhoneNumberColumnNameInput.value
    });
  }

  //
  // Render
  //

  function renderColumnMappingInputs(): ReactElement {
    return (
      <Box marginTop={theme.space_stack_md}>
        <FormFieldDivider color={palette.gray[30]} marginVertical={20} />
        <Text fontSize={theme.fontSize_ui_sm}>
          {copyText.columnMappingDescription}
        </Text>
        <FormField
          label={<Text>{copyText.firstNameColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.firstNameColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            instanceId="firstNameColumnNameSelector"
            name="firstNameColumnName"
            isClearable
            isDisabled={reportColumnNames?.length === 0}
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={firstNameColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'firstNameColumnName',
                  value: (option as Option)?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={<Text>{copyText.lastNameColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.lastNameColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            instanceId="lastNameColumnNameSelector"
            name="lastNameColumnName"
            isClearable
            isDisabled={reportColumnNames?.length === 0}
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={lastNameColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'lastNameColumnName',
                  value: (option as Option)?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={<Text>{copyText.contactIDColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.contactIDColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            instanceId="contactIDColumnNameSelector"
            name="contactIDColumnName"
            isClearable
            isDisabled={reportColumnNames?.length === 0}
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={contactIDColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'contactIDColumnName',
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        <FormField
          label={<Text>{copyText.mobilePhoneNumberColumnNameInputLabel}</Text>}
          marginTop={theme.space_stack_md}
          secondaryText={
            <MoreInfoPopover color={palette.blue[60]} hasMarkdown>
              {copyText.mobilePhoneNumberColumnNameInputSecondaryText}
            </MoreInfoPopover>
          }
        >
          <Select
            instanceId="mobilePhoneNumberColumnNameSelector"
            name="mobilePhoneNumberColumnName"
            isClearable
            isDisabled={reportColumnNames?.length === 0}
            isLoading={isLoadingReportColumnNames}
            isSearchable
            options={reportColumnNameOptions}
            placeholder={copyText.optionPlaceholder}
            value={mobilePhoneNumberColumnNameOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'mobilePhoneNumberColumnName',
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
      </Box>
    );
  }

  const reportColumnNameOptions = reportColumnNames
    ?.sort()
    .map(reportColumnName => ({
      label: reportColumnName,
      value: reportColumnName
    }));

  const contactIDColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === contactIDColumnNameInput.value
    ) || null;

  const firstNameColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === firstNameColumnNameInput.value
    ) || null;

  const lastNameColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === lastNameColumnNameInput.value
    ) || null;

  const mobilePhoneNumberColumnNameOption =
    reportColumnNameOptions?.find(
      option => option.value === mobilePhoneNumberColumnNameInput.value
    ) || null;

  const salesforceReportOptions = salesforceReports?.map(report => ({
    label: report.name,
    value: report.id
  }));

  const salesforceReportOption =
    salesforceReportOptions?.find(option => option.value === ridInput.value) ||
    null;

  const salesforceReportFolderOptions = salesforceReportFolders?.map(
    folder => ({
      label: folder.name,
      value: folder.id
    })
  );

  const salesforceReportFolderOption =
    salesforceReportFolderOptions?.find(
      option => option.value === folderIDInput.value
    ) || null;

  const canSubmit = Object.values(state).every(
    (input): boolean => input.isValid
  );

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
      </Layout.Header>
      <Layout.Body {...styleProps.ActionPanelLayoutBody}>
        {message}
        <FormField
          name="name"
          input={TextInput}
          label={
            <Text color={isValidInput(nameInput) ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={nameInput.value}
          variant={isValidInput(nameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          label={
            <Text>
              {copyText.folderIDInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
        >
          <Select
            instanceId="salesforceReportFolderSelector"
            isLoading={isLoadingReportFolders}
            isSearchable
            options={salesforceReportFolderOptions}
            placeholder={copyText.optionPlaceholder}
            value={salesforceReportFolderOption}
            onChange={(option: ValueType<Option, false>): void =>
              handleChange({
                target: {
                  name: 'folderID',
                  value: option?.value || ''
                }
              } as ChangeEvent<HTMLInputElement>)
            }
          />
        </FormField>
        {!isBlank(folderIDInput.value) ? (
          <FormField
            name="rid"
            label={
              <Text>
                {copyText.salesforceReportIDInputLabel}
                <Asterisk color={palette.red[60]} />
              </Text>
            }
            marginTop={theme.space_stack_md}
          >
            <Select
              instanceId="reportSelector"
              isLoading={isLoadingReports}
              isSearchable
              options={salesforceReportOptions}
              placeholder={copyText.optionPlaceholder}
              value={salesforceReportOption}
              onChange={(option: ValueType<Option, false>): void =>
                handleChange({
                  target: {
                    name: 'rid',
                    value: option?.value || ''
                  }
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          </FormField>
        ) : null}
        {!isBlank(ridInput.value) ? renderColumnMappingInputs() : null}
      </Layout.Body>
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button
          type="reset"
          variant="grayscale"
          width="48%"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          primary
          type="submit"
          width="48%"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function isValidInput({ value, isValid }: Input): boolean {
  return isEmpty(value) || isValid;
}

CreateReportForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${CreateReportForm.name}/INTERACTION_CANCEL_BUTTON_CLICKED`;
CreateReportForm.INTERACTION_SALESFORCE_REPORT_FOLDER_SELECTED = `${CreateReportForm.name}.INTERACTION_SALESFORCE_REPORT_FOLDER_SELECTED`;
CreateReportForm.INTERACTION_SALESFORCE_REPORT_SELECTED = `${CreateReportForm.name}.INTERACTION_SALESFORCE_REPORT_SELECTED`;
CreateReportForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${CreateReportForm.name}/INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default CreateReportForm;
