import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import ButtonGroup from '@targetx/mineral-ui/ButtonGroup';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Pagination from '@targetx/mineral-ui/Pagination';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import CreateMessageTemplateForm from '../../components/CreateMessageTemplateForm';
import EditMessageTemplateForm from '../../components/EditMessageTemplateForm';
import MessageTemplateList from '../../components/MessageTemplateList';
import ScreenLevelAlert from '../../components/ScreenLevelAlert';
import paths from '../../constants/paths';
import { MessageTemplateEntity } from '../../data-api/types';
import useDataAPICreateMessageTemplate from '../../data-api/useDataAPICreateMessageTemplate';
import useDataAPIDeleteMessageTemplate from '../../data-api/useDataAPIDeleteMessageTemplate';
import useDataAPIGetMessageTemplatesByUserID from '../../data-api/useDataAPIGetMessageTemplatesByUserID';
import useDataAPIUpdateMessageTemplate from '../../data-api/useDataAPIUpdateMessageTemplate';
import SettingsScreenLayout from '../../layouts/SettingsScreenLayout';
import { bmStyles, modalStyles } from '../../styles';
import theme from '../../theme';
import { UserEntity } from '../../types';
import Interaction from '../../types/Interaction';
import { PartialState } from '../../types/PartialState';
import { hasMergeFields } from '../../utils/MergeFieldUtils';
import copyText from './copyText';

const ACTION_PANEL_CREATE_FORM = 'CREATE_FORM';
const ACTION_PANEL_EDIT_FORM = 'EDIT_FORM';

const ERROR_CREATING_MESSAGE_TEMPLATE = 'ERROR_CREATING_MESSAGE_TEMPLATE';
const ERROR_DELETING_MESSAGE_TEMPLATE = 'ERROR_DELETING_MESSAGE_TEMPLATE';
const ERROR_LOADING_MESSAGE_TEMPLATES = 'ERROR_LOADING_MESSAGE_TEMPLATES';
const ERROR_UPDATING_MESSAGE_TEMPLATE = 'ERROR_UPDATING_MESSAGE_TEMPLATE';

const MODAL_CREATE_CONFIRMATION = 'CREATE_CONFIRMATION';
const MODAL_DELETE_CONFIRMATION = 'DELETE_CONFIRMATION';
const MODAL_UPDATE_CONFIRMATION = 'UPDATE_CONFIRMATION';

const SUCCESS_CREATING_MESSAGE_TEMPLATE = 'SUCCESS_CREATING_MESSAGE_TEMPLATE';
const SUCCESS_DELETING_MESSAGE_TEMPLATE = 'SUCCESS_DELETING_MESSAGE_TEMPLATE';
const SUCCESS_UPDATING_MESSAGE_TEMPLATE = 'SUCCESS_UPDATING_MESSAGE_TEMPLATE';

const TEMPLATE_TYPE_GLOBAL = 'GLOBAL';
const TEMPLATE_TYPE_PERSONAL = 'PERSONAL';

const pageSize = 10;

interface Props {
  authenticatedUser: UserEntity;
  homeBaseURL: string;
  path?: string;
  signOutPath: string;
}

interface CreateMessageTemplateInterimParams {
  ownerID?: string;
  name: string;
  content: string;
}

interface UpdateMessageTemplateInterimParams {
  name?: string;
  content?: string;
}

interface State {
  actionPanelKey: string;
  alertKey: string;
  createMessageTemplateInterimParams: CreateMessageTemplateInterimParams | null;
  editingTemplateID: string;
  modalComponentKey: string;
  pageNumber: number;
  searchTerm: string;
  showSearchInput: boolean;
  typeFilter: string;
  updateMessageTemplateInterimParams: UpdateMessageTemplateInterimParams | null;
}

const initialState: State = {
  actionPanelKey: '',
  alertKey: '',
  createMessageTemplateInterimParams: null,
  editingTemplateID: '',
  modalComponentKey: '',
  pageNumber: 1,
  searchTerm: '',
  showSearchInput: false,
  typeFilter: TEMPLATE_TYPE_GLOBAL,
  updateMessageTemplateInterimParams: null
};

export function MessageTemplateManagementScreen({
  authenticatedUser,
  homeBaseURL,
  signOutPath
}: Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(prevState => ({ ...prevState, ...partialState }));
  }

  const {
    actionPanelKey,
    alertKey,
    createMessageTemplateInterimParams,
    editingTemplateID,
    modalComponentKey,
    pageNumber,
    searchTerm,
    showSearchInput,
    typeFilter,
    updateMessageTemplateInterimParams
  } = state;

  //
  // Hooks
  //

  const {
    data: _templates,
    isLoading: isLoadingTemplates,
    error: errorLoadingTemplates,
    refetch: refetchTemplates
  } = useDataAPIGetMessageTemplatesByUserID(authenticatedUser.id);

  const {
    data: lastCreatedTemplateID,
    error: errorCreatingMessageTemplate,
    invoke: createMessageTemplate,
    isProcessing: isCreatingMessageTemplate
  } = useDataAPICreateMessageTemplate();

  const {
    data: lastDeletedTemplateID,
    error: errorDeletingMessageTemplate,
    invoke: deleteMessageTemplate
  } = useDataAPIDeleteMessageTemplate();

  const {
    data: lastUpdatedTemplateID,
    error: errorUpdatingMessageTemplate,
    invoke: updateMessageTemplate,
    isProcessing: isUpdatingMessageTemplate
  } = useDataAPIUpdateMessageTemplate();

  //
  // Side Effects
  //

  useEffect(() => {
    if (!alertKey.startsWith('SUCCESS_')) return;

    refetchTemplates();
  }, [alertKey]);

  useEffect(() => {
    if (!lastCreatedTemplateID) return;
    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_CREATING_MESSAGE_TEMPLATE,
      createMessageTemplateInterimParams: null,
      modalComponentKey: ''
    });
  }, [lastCreatedTemplateID]);

  useEffect(() => {
    if (!lastDeletedTemplateID) return;

    changeState({
      alertKey: SUCCESS_DELETING_MESSAGE_TEMPLATE,
      editingTemplateID: '',
      modalComponentKey: ''
    });
  }, [lastDeletedTemplateID]);

  useEffect(() => {
    if (!lastUpdatedTemplateID) return;

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_UPDATING_MESSAGE_TEMPLATE,
      editingTemplateID: '',
      modalComponentKey: '',
      updateMessageTemplateInterimParams: null
    });
  }, [lastUpdatedTemplateID]);

  useEffect(() => {
    if (!errorLoadingTemplates) return;

    changeState({ alertKey: ERROR_LOADING_MESSAGE_TEMPLATES });
  }, [errorLoadingTemplates]);

  useEffect(() => {
    if (!errorCreatingMessageTemplate) return;

    changeState({
      alertKey: ERROR_CREATING_MESSAGE_TEMPLATE,
      createMessageTemplateInterimParams: null,
      modalComponentKey: ''
    });
  }, [errorCreatingMessageTemplate]);

  useEffect(() => {
    if (!errorDeletingMessageTemplate) return;

    changeState({
      alertKey: ERROR_DELETING_MESSAGE_TEMPLATE,
      editingTemplateID: '',
      modalComponentKey: ''
    });
  }, [errorDeletingMessageTemplate]);

  useEffect(() => {
    if (!errorUpdatingMessageTemplate) return;

    changeState({
      alertKey: ERROR_UPDATING_MESSAGE_TEMPLATE,
      modalComponentKey: '',
      updateMessageTemplateInterimParams: null
    });
  }, [errorUpdatingMessageTemplate]);

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;
    changeState({ alertKey: '', actionPanelKey: '' });
  }

  function handleChangePage(pageNumber: number): void {
    changeState({ pageNumber });
  }

  function handleClickOpenCreateForm(): void {
    changeState({ actionPanelKey: ACTION_PANEL_CREATE_FORM });
  }

  function handleClickRootContainer(): void {
    if (['', ERROR_LOADING_MESSAGE_TEMPLATES].includes(alertKey)) return;

    changeState({ alertKey: '' });
  }

  function handleClickToggleSearch(): void {
    changeState({ alertKey: '', searchTerm: '', showSearchInput: true });
  }

  function handleClickTypeFilter(event: ChangeEvent<HTMLInputElement>): void {
    changeState({ typeFilter: event.target.value });
  }

  function handleCloseModal(type: string): void {
    const state = { modalComponentKey: '' };

    switch (type) {
      case MODAL_CREATE_CONFIRMATION: {
        changeState({ ...state, createMessageTemplateInterimParams: null });
        return;
      }
      case MODAL_DELETE_CONFIRMATION: {
        changeState({ ...state, editingTemplateID: '' });
        return;
      }
      case MODAL_UPDATE_CONFIRMATION: {
        changeState({ ...state, updateMessageTemplateInterimParams: null });
        return;
      }
    }

    changeState(state);
  }

  async function handleInteraction({
    type,
    ...data
  }: Interaction): Promise<void> {
    switch (type) {
      case CreateMessageTemplateForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ alertKey: '', actionPanelKey: '' });
        return;
      }
      case CreateMessageTemplateForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (hasMergeFields(data.content)) {
          changeState({
            createMessageTemplateInterimParams: {
              ownerID: !data.isGlobal ? authenticatedUser.id : undefined,
              name: data.name,
              content: data.content
            },
            modalComponentKey: MODAL_CREATE_CONFIRMATION
          });
          return;
        }

        createMessageTemplate({
          orgID: authenticatedUser.orgID,
          ownerID: !data.isGlobal ? authenticatedUser.id : undefined,
          name: data.name,
          content: data.content
        });

        return;
      }
      case EditMessageTemplateForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({
          actionPanelKey: '',
          alertKey: '',
          editingTemplateID: ''
        });
        return;
      }
      case EditMessageTemplateForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        if (hasMergeFields(data.content)) {
          changeState({
            modalComponentKey: MODAL_UPDATE_CONFIRMATION,
            updateMessageTemplateInterimParams: {
              name: data.name,
              content: data.content
            }
          });
          return;
        }

        updateMessageTemplate(editingTemplateID, {
          name: data.name,
          content: data.content
        });

        return;
      }
      case MessageTemplateList.INTERACTION_DELETE_BUTTON_CLICKED: {
        changeState({
          editingTemplateID: data.templateID,
          modalComponentKey: MODAL_DELETE_CONFIRMATION
        });
        return;
      }
      case MessageTemplateList.INTERACTION_EDIT_BUTTON_CLICKED: {
        changeState({
          actionPanelKey: ACTION_PANEL_EDIT_FORM,
          editingTemplateID: data.templateID
        });
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        changeState({ alertKey: '', searchTerm: '', showSearchInput: false });
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        changeState({ pageNumber: 1, searchTerm: data.searchTerm });
        return;
      }
    }
  }

  //
  // Render
  //

  const templatesKeyedByID = useMemo(
    () => keyBy(_templates, 'id'),
    [_templates]
  );

  let templates = useMemo(
    () => orderBy(_templates, template => template.name.toLowerCase()),
    [_templates]
  );

  if (searchTerm.length > 0) {
    templates = templates.filter((template): boolean =>
      template.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const templatesGroupedByType = useMemo(
    (): { [key: string]: MessageTemplateEntity[] } => ({
      [TEMPLATE_TYPE_GLOBAL]: [],
      [TEMPLATE_TYPE_PERSONAL]: [],
      ...groupBy(templates, template =>
        isNil(template.ownerID) ? TEMPLATE_TYPE_GLOBAL : TEMPLATE_TYPE_PERSONAL
      )
    }),
    [templates]
  );

  templates = templatesGroupedByType[typeFilter];

  const totalTemplates = templates.length;

  templates = useMemo(
    () =>
      templates.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize),
    [templates, pageNumber]
  );

  function renderAlert(): ReactNode {
    if (
      ![
        ERROR_CREATING_MESSAGE_TEMPLATE,
        ERROR_UPDATING_MESSAGE_TEMPLATE
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton
        title={copyText.ERROR_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_CREATE_FORM: {
        return (
          <CreateMessageTemplateForm
            isProcessing={isCreatingMessageTemplate}
            message={renderAlert()}
            user={authenticatedUser}
            onInteraction={handleInteraction}
          />
        );
      }
      case ACTION_PANEL_EDIT_FORM: {
        return (
          <EditMessageTemplateForm
            isProcessing={isUpdatingMessageTemplate}
            message={renderAlert()}
            template={templatesKeyedByID[editingTemplateID]}
            onInteraction={handleInteraction}
          />
        );
      }
    }
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_CREATE_CONFIRMATION: {
        const params = {
          orgID: authenticatedUser.orgID,
          ...(createMessageTemplateInterimParams as CreateMessageTemplateInterimParams)
        };

        return (
          <ConfirmationDialog
            message={copyText.confirmationDialogWarningMessage}
            title={copyText.confirmationDialogWarningTitle}
            variant="warning"
            onCancel={handleCloseModal.bind({}, MODAL_CREATE_CONFIRMATION)}
            onConfirm={() => createMessageTemplate(params)}
          />
        );
      }
      case MODAL_DELETE_CONFIRMATION: {
        const { id: templateID, name } = templatesKeyedByID[editingTemplateID];

        return (
          <ConfirmationDialog
            message={copyText.confirmationDialogDangerMessage.replace(
              '%name%',
              name
            )}
            title={copyText.confirmationDialogDangerTitle}
            variant="danger"
            onCancel={handleCloseModal.bind({}, MODAL_DELETE_CONFIRMATION)}
            onConfirm={() => deleteMessageTemplate(templateID)}
          />
        );
      }
      case MODAL_UPDATE_CONFIRMATION: {
        const params =
          updateMessageTemplateInterimParams as UpdateMessageTemplateInterimParams;

        return (
          <ConfirmationDialog
            message={copyText.confirmationDialogWarningMessage}
            title={copyText.confirmationDialogWarningTitle}
            variant="warning"
            onCancel={handleCloseModal.bind({}, MODAL_UPDATE_CONFIRMATION)}
            onConfirm={() => updateMessageTemplate(editingTemplateID, params)}
          />
        );
      }
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        ERROR_DELETING_MESSAGE_TEMPLATE,
        ERROR_LOADING_MESSAGE_TEMPLATES,
        SUCCESS_CREATING_MESSAGE_TEMPLATE,
        SUCCESS_DELETING_MESSAGE_TEMPLATE,
        SUCCESS_UPDATING_MESSAGE_TEMPLATE
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={get(copyText, `${alertKey}_message`) || ''}
        showCloseButton={alertKey !== ERROR_LOADING_MESSAGE_TEMPLATES}
      />
    );
  }

  const breadcrumbItems = [
    { label: authenticatedUser.org.name },
    { label: copyText.title, color: palette.gray[100] }
  ];

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal.bind({}, '')}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={actionPanelKey.length > 0}
        right
        styles={bmStyles}
        width={500}
        onStateChange={handleChangeActionPanelState}
      >
        {renderActionPanel()}
      </Menu>
      <SettingsScreenLayout
        authenticatedUser={authenticatedUser}
        currentPath={paths.settingsMessageTemplates}
        homeBaseURL={homeBaseURL}
        signOutPath={signOutPath}
      >
        <SettingsScreenLayout.Header>
          <Breadcrumb items={breadcrumbItems} />
          <FlexItem flex>
            {showSearchInput ? (
              <SearchInput
                aria-label={copyText.searchInputLabel}
                name="searchTerm"
                clearable
                onInteraction={handleInteraction}
              />
            ) : (
              <Button
                aria-label={copyText.searchToggleButtonLabel}
                iconStart={<IconSearch color={palette.gray[60]} />}
                minimal
                onClick={handleClickToggleSearch}
              />
            )}
            <Button
              aria-label={copyText.createButtonLabel}
              iconStart={<IconPlus color={palette.green[60]} />}
              marginLeft={theme.space_inline_xs}
              minimal
              onClick={handleClickOpenCreateForm}
            />
          </FlexItem>
        </SettingsScreenLayout.Header>
        <SettingsScreenLayout.Body>
          {renderScreenAlert()}
          <Flex justifyContent="center" marginBottom={theme.space_stack_lg}>
            <ButtonGroup
              aria-label={copyText.templateFilterLabel}
              defaultChecked={0}
              mode="radio"
              size="medium"
              onClick={handleClickTypeFilter}
            >
              <Button value={TEMPLATE_TYPE_GLOBAL} width="110px">
                {`${copyText.globalTemplatesFilterLabel} (${templatesGroupedByType[TEMPLATE_TYPE_GLOBAL].length})`}
              </Button>
              <Button value={TEMPLATE_TYPE_PERSONAL} width="110px">
                {`${copyText.personalTemplatesFilterLabel} (${templatesGroupedByType[TEMPLATE_TYPE_PERSONAL].length})`}
              </Button>
            </ButtonGroup>
          </Flex>
          <MessageTemplateList
            isLoadingTemplates={isLoadingTemplates}
            templates={templates}
            user={authenticatedUser}
            onInteraction={handleInteraction}
          />
          {totalTemplates > pageSize ? (
            <FlexItem
              alignSelf="end"
              marginTop={theme.space_stack_md}
              width="100%"
            >
              <Pagination
                currentPage={pageNumber}
                onPageChange={handleChangePage}
                pageSize={pageSize}
                totalCount={totalTemplates}
              />
            </FlexItem>
          ) : null}
        </SettingsScreenLayout.Body>
      </SettingsScreenLayout>
    </Box>
  );
}

export default MessageTemplateManagementScreen;
