/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';

interface State {
  readonly data?: string;
  readonly error?: DataAPIClientError;
  readonly isProcessing: boolean;
}

interface Result extends State {
  readonly invoke: (inboxID: string) => Promise<void>;
}

export default function useDataAPIActivateInbox(): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isProcessing: false });

  async function invoke(inboxID: string): Promise<void> {
    setState({ isProcessing: true });

    try {
      const data = await client.activateInbox(inboxID);

      setState({ data, isProcessing: false });
    } catch (error: any) {
      setState({ error, isProcessing: false });
    }
  }

  return { ...state, invoke };
}
