import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import { FlexItem } from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import { CheckboxGroup } from '@targetx/mineral-ui/Checkbox';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import IconChevronLeft from '@targetx/tx-web-ui-lib/lib/icons/IconChevronLeft';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import styleProps from '../styles/props';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import theme from '../theme';
import React, { ReactElement } from 'react';
import { BaseUserEntity } from '../types';
import copyText from './EmailRecipientsForm.copyText';

export namespace EmailRecipientsForm {
  export interface Props {
    handleClickBack?: React.MouseEventHandler<HTMLButtonElement>;
    handleChange: React.ChangeEventHandler<HTMLInputElement>;
    additionalRecipients: {
      value: string;
      isValid: boolean;
      hasChanged: boolean;
    };
    inboxRecipients: BaseUserEntity['id'][];
    users: BaseUserEntity[];
  }
}

export function EmailRecipientsForm({
  handleClickBack,
  handleChange,
  additionalRecipients,
  inboxRecipients,
  users
}: EmailRecipientsForm.Props): ReactElement {
  return (
    <Layout as={Form} backgroundColor={palette.white}>
      <Layout.Header
        flex
        alignItems="center"
        justifyContent="flex-start"
        minHeight={theme.space_stack_xxl}
      >
        <FlexItem flex alignItems="center">
          <Button
            iconEnd={<IconChevronLeft color={palette.gray[60]} />}
            marginHorizontal={theme.space_inline_xs}
            minimal
            size="small"
            title={'Close'}
            onClick={handleClickBack}
          />
          <Text appearance="h3" as="h1" bold>
            Recipients (
            {inboxRecipients.length +
              additionalRecipients.value
                .split(/[ ,;]+/)
                .filter(email => !!email.trim()).length}
            )
          </Text>
        </FlexItem>
      </Layout.Header>
      <Layout.Body {...styleProps.ActionPanelLayoutBody}>
        <FormField
          name="additionalRecipients"
          input={TextInput}
          label={copyText.additionalRecipientsLabel}
          marginTop={theme.space_stack_md}
          marginBottom={theme.space_stack_md}
          value={additionalRecipients.value}
          onChange={handleChange}
          caption={
            additionalRecipients.isValid
              ? copyText.additionalRecipientsCaption
              : additionalRecipients.value.length > 255
              ? copyText.invalidEmailsLength
              : copyText.invalidEmailsCaption
          }
          type="email"
          variant={additionalRecipients.isValid ? undefined : 'danger'}
        />

        <FormField
          input={CheckboxGroup}
          label={copyText.inboxRecipientsLabel}
          name="inboxRecipients"
          required
          onChange={handleChange}
          data={users.map(user => ({
            label: (
              <Box key={user.id}>
                <Text bold>
                  {user.firstName || ''} {user.lastName || ''}
                </Text>
                <Text appearance="mouse">{user.username}</Text>
              </Box>
            ),
            value: user.id,
            checked: inboxRecipients.includes(user.id)
          }))}
        />

        {users.length === 0 && (
          <Text color={palette.gray[70]}>{copyText.noUsersMessage}</Text>
        )}
      </Layout.Body>
    </Layout>
  );
}

export default EmailRecipientsForm;
