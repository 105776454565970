export default {
  cancelButtonLabel: 'CANCEL',
  activateInbox: 'ACTIVATE',
  emailNotificationsLabel: 'Enable Email Notifications',
  idLabel: 'ID',
  nameInputLabel: 'Name',
  notificationTimerLabel: 'Notification Timer',
  phoneNumbersDescription:
    'The following phone numbers are associated with this messaging service:',
  providerRIDInputCaption_0001:
    'Your organization is currently using Twilio as a messaging provider.',
  providerRIDInputLabel: 'Messaging Service',
  submitButtonLabel: 'SAVE',
  title: 'Edit Inbox',
  viewRecipientsListTitle: 'View Recipients List'
};
