import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import Box from '@targetx/mineral-ui/Box';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { InteractionDelegate } from '@targetx/tx-web-ui-lib/lib/types/Interaction';
import { format } from 'date-fns-tz';
import React, { ReactElement } from 'react';
import copyText from './BroadcastDetailsExecutionList.copyText';
import theme from '../theme';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

export namespace BroadcastDetailsExecutionList {
  export interface BroadcastExecutionEntity {
    id: string;
    status: string;
    timeCreated: string;
    timeModified?: string;
  }

  export interface RowData {
    id: string;
    row: () => ReactElement;
  }

  export interface Props {
    executions: BroadcastExecutionEntity[];
    isLoading: boolean;
    onInteraction?: InteractionDelegate;
  }
}

const headerStyle = {
  padding: '1.1666em 1.3333em',
  fontSize: '0.75em'
};

const cellStyle = {
  padding: '1.1666em 1.125em',
  fontSize: '0.875em'
};

export function BroadcastDetailsExecutionList({
  executions,
  isLoading
}: BroadcastDetailsExecutionList.Props): ReactElement {
  const tableHeight = 350;
  const itemHeight = 50;
  const scrollable = itemHeight * executions.length > tableHeight;

  function renderItem({ index, style }: ListChildComponentProps) {
    const execution = executions[index];

    return (
      <BroadcastExecutionItem
        key={execution.id}
        execution={execution}
        style={{
          background: index % 2 ? palette.gray[10] : theme.backgroundColor,
          overflow: 'auto',
          ...style
        }}
      />
    );
  }

  return (
    <>
      <Box
        border={`1px solid ${palette.gray[50]}`}
        borderRadius={theme.space_inline_xs}
        overflow="hidden"
      >
        <Flex
          style={{
            scrollbarGutter: scrollable ? 'stable' : 'auto',
            overflow: 'auto',
            background: theme.backgroundColor,
            textTransform: 'uppercase',
            fontWeight: '600',
            borderBottom: `1px solid ${palette.gray[50]}`
          }}
        >
          <FlexItem style={{ width: '65%', ...headerStyle }}>Date</FlexItem>
          <FlexItem style={{ width: '35%', ...headerStyle }}>Status</FlexItem>
        </Flex>
        {executions.length === 0 && (
          <Flex
            style={{
              background: theme.backgroundColor,
              padding: theme.space_stack_sm
            }}
          >
            <FlexItem>
              {isLoading ? (
                <Flex justifyContent="center" padding={theme.space_inset_md}>
                  <SVGSpinner size="2.5em" />
                </Flex>
              ) : (
                copyText.noExecutionsMessage
              )}
            </FlexItem>
          </Flex>
        )}
        <FixedSizeList
          height={Math.min(tableHeight, itemHeight * executions.length)}
          itemCount={executions.length}
          itemSize={50}
          width="100%"
          style={{ scrollbarGutter: scrollable ? 'stable' : 'auto' }}
        >
          {renderItem}
        </FixedSizeList>
      </Box>
    </>
  );
}

function BroadcastExecutionItem({
  execution,
  style
}: {
  execution: BroadcastDetailsExecutionList.BroadcastExecutionEntity;
  style: React.CSSProperties;
}): ReactElement {
  const { timeCreated, timeModified } = execution;

  let statusColor;
  let statusText;

  switch (execution.status) {
    case 'POST_PROCESSING':
    case 'POST_PROCESSING_COMPLETED':
    case 'COMPLETED':
      statusText = copyText.statusComplete;
      statusColor = palette.green[80];
      break;
    case 'CREATION_FAILED':
    case 'QUEUING_FAILED':
    case 'VALIDATION_FAILED':
    case 'PROCESSING_FAILED':
    case 'POST_PROCESSING_FAILED':
      statusText = copyText.statusFailed;
      statusColor = palette.red[80];
      break;
    case 'PENDING':
    case 'PROCESSING':
    case 'QUEUED':
      statusText = copyText.statusProcessing;
      statusColor = palette.blue[80];
      break;
    case 'ZERO_RECIPIENTS':
      statusText = copyText.zeroRecipients;
      statusColor = palette.gray[80];
      break;
    default:
      statusText = copyText.statusUnknown;
      break;
  }

  return (
    <Flex style={{ ...style }}>
      <FlexItem verticalAlign="middle" style={{ width: '65%', ...cellStyle }}>
        {timeModified
          ? format(new Date(timeModified), 'MMMM dd, yyyy p')
          : format(new Date(timeCreated), 'MMMM  dd, yyyy p')}
      </FlexItem>
      <FlexItem verticalAlign="middle" style={{ width: '35%', ...cellStyle }}>
        <Text color={statusColor} fontWeight={theme.fontWeight_semiBold}>
          {statusText}
        </Text>
      </FlexItem>
    </Flex>
  );
}

export default BroadcastDetailsExecutionList;
