export default {
  actionMenuItemActivateInbox: 'Activate Inbox',
  actionMenuItemDeactivateInbox: 'Deactivate Inbox',
  actionMenuItemManageUsers: 'Manage Users',
  activeInboxesFilterLabel: 'ACTIVE',
  createButtonLabel: 'CREATE',
  deactivateConfirmationMessage:
    'You are about to deactivate the inbox: **%inboxName%**. It will not be able to send or receive messages until you re-activate. Any scheduled bulk SMS messages will be disabled.',
  deactivateConfirmationTitle: 'DEACTIVATE INBOX',
  discardTitle: 'Discard Changes',
  discardBody:
    'The changes made will be discarded. Are you sure you want to proceed?',
  ERROR_ACTIVATING_INBOX_message:
    'An error occured while attempting to activate the inbox. Please try again.',
  ERROR_CREATING_INBOX_DUPLICATE_PROVIDER_RID_message:
    'The messaging service you specified is already in use. Please try again.',
  ERROR_CREATING_INBOX_UNEXPECTED_message:
    'An error occured while attempting to create the inbox. Please try again.',
  ERROR_DEACTIVATING_INBOX_message:
    'An error occured while attempting to deactivate the inbox. Please try again.',
  ERROR_LOADING_API_KEY_message:
    'Sorry, the system failed to load your messaging provider api key. Please reload the current browser window and try again.',
  ERROR_LOADING_INBOXES_message:
    'There was an error loading inboxes. Please try again.',
  ERROR_LOADING_MESSAGING_SERVICES_message:
    'There was an error loading messaging services. Please try again.',
  ERROR_LOADING_MESSAGING_SERVICE_PHONE_NUMBERS_message:
    'There was an error loading phone numbers related to this messaging service. Please try again.',
  ERROR_LOADING_ORG_SETTINGS_message:
    'An unexpected error occured while attempting to load settings for your org. Please reload the current browser window and try again.',
  ERROR_title: 'Operation Failed.',
  ERROR_UPDATING_INBOX_DUPLICATE_PROVIDER_RID_message:
    'The messaging service you specified is already in use. Please try again.',
  ERROR_UPDATING_INBOX_UNEXPECTED_message:
    'An error occured while attempting to update the inbox. Please try again.',
  inactiveInboxesFilterLabel: 'INACTIVE',
  inboxFilterLabel: 'filter inbox by status',
  openMenuButtonLabel: 'Open Menu',
  searchInputLabel: 'Search Inboxes',
  searchToggleButtonLabel: 'Toggle Search',
  title: 'Inboxes',
  SUCCESS_INBOX_ACTIVATED_message: 'The Inbox has been successfully activated.',
  SUCCESS_INBOX_CREATED_message: 'The Inbox has been successfully created.',
  SUCCESS_INBOX_DEACTIVATED_message:
    'The Inbox has been successfully deactivated.',
  SUCCESS_INBOX_UPDATED_message: 'The Inbox has been successfully updated.',
  SUCCESS_title: 'Operation Successful!'
};
