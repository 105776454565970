/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { DataAPIClientError } from './DataAPIClient';
import { useDataAPIClient } from './DataAPIClientProvider';
import { ReportEntity } from './types';

interface State {
  readonly data?: ReportEntity;
  readonly error?: DataAPIClientError;
  readonly isLoading: boolean;
}

interface Result extends State {
  readonly refetch: (id: string) => Promise<void>;
}

export default function useDataAPIGetReportByID(id?: string): Result {
  const client = useDataAPIClient();
  const [state, setState] = useState<State>({ isLoading: false });

  async function fetch(id: string): Promise<void> {
    setState({ isLoading: true });

    try {
      const data = await client.getReportByID(id);

      setState({ data, isLoading: false });
    } catch (error: any) {
      setState({ error, isLoading: false });
    }
  }

  useEffect(() => {
    if (!id) return;

    fetch(id);
  }, [id]);

  return { ...state, refetch: fetch };
}
