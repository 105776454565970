import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextArea from '@targetx/mineral-ui/TextArea';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import isEmpty from 'validator/lib/isEmpty';
import styleProps from '../styles/props';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './EditMessageTemplateForm.copyText';

const MESSAGE_TEMPLATE_CONTENT_MAX_LENGTH = 1000;

export namespace EditMessageTemplateForm {
  export interface MessageTemplateEntity {
    id: string;
    ownerID?: string;
    name: string;
    content: string;
  }

  export interface Props {
    isProcessing?: boolean;
    message?: ReactNode;
    template: MessageTemplateEntity;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  contentInput: { value: string; isValid: boolean; hasChanged: boolean };
  nameInput: { value: string; isValid: boolean; hasChanged: boolean };
}

export function EditMessageTemplateForm({
  isProcessing,
  message,
  template,
  onInteraction = noop
}: EditMessageTemplateForm.Props): ReactElement {
  const initialState = {
    contentInput: { value: template.content, isValid: true, hasChanged: false },
    nameInput: { value: template.name, isValid: true, hasChanged: false }
  };

  const [state, setState] = useState<State>(initialState);

  const { contentInput, nameInput } = state;

  const canSubmit =
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    let isValid = false;

    const hasChanged =
      initialState[`${name}Input` as keyof State].value !== value;

    switch (name) {
      case 'name':
        isValid =
          !isEmpty(value, { ignore_whitespace: true }) && value.length < 101;
        break;
      case 'content':
        isValid =
          !isEmpty(value, { ignore_whitespace: true }) && value.length < 1001;
        break;
    }

    setState(currentState => ({
      ...currentState,
      [`${name}Input`]: { value, isValid, hasChanged }
    }));
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: EditMessageTemplateForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      templateID: template.id,
      ...(nameInput.hasChanged ? { name: nameInput.value.trim() } : {}),
      ...(contentInput.hasChanged ? { content: contentInput.value.trim() } : {})
    });
  }

  function handleReset(): void {
    onInteraction({
      type: EditMessageTemplateForm.INTERACTION_CANCEL_BUTTON_CLICKED
    });
  }

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header {...styleProps.ActionPanelLayoutHeader}>
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleReset}
        />
      </Layout.Header>
      <Layout.Body {...styleProps.ActionPanelLayoutBody}>
        {message}
        <FormField
          name="name"
          input={TextInput}
          label={
            <Text color={nameInput.isValid ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={nameInput.value}
          variant={nameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="content"
          input={TextArea}
          caption={
            <Flex justifyContent="end">
              <Text color={palette.gray[80]} fontSize={theme.fontSize_mouse}>
                {getContentInputCount(contentInput.value.length)}
              </Text>
            </Flex>
          }
          label={
            <Text color={contentInput.isValid ? undefined : palette.red[60]}>
              {copyText.contentInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginVertical={theme.space_stack_md}
          required
          resizeable={false}
          value={contentInput.value}
          variant={contentInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
      </Layout.Body>
      <Layout.Footer {...styleProps.ActionPanelLayoutFooter}>
        <Button
          width="48%"
          type="reset"
          variant="grayscale"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          width="48%"
          primary
          type="submit"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function getContentInputCount(length: number): string {
  return copyText.contentInputCaption.replace(
    '%count%',
    `${MESSAGE_TEMPLATE_CONTENT_MAX_LENGTH - length}`
  );
}

EditMessageTemplateForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${EditMessageTemplateForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
EditMessageTemplateForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditMessageTemplateForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default EditMessageTemplateForm;
