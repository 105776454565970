import { Option } from '../types';

export function getDaysOfMonthOptions(): Option[] {
  const days = [];

  for (let i = 1; i <= 31; i++) {
    if (i === 1 || i === 21 || i === 31) {
      days.push({
        label: `${i}st`,
        value: i.toString()
      });
    } else if (i === 2 || i === 22) {
      days.push({
        label: `${i}nd`,
        value: i.toString()
      });
    } else if (i === 3 || i === 23) {
      days.push({
        label: `${i}rd`,
        value: i.toString()
      });
    } else {
      days.push({
        label: `${i}th`,
        value: i.toString()
      });
    }
  }

  return days;
}

export default getDaysOfMonthOptions;
