import { ContactMappingEntity } from '../types';

export function getFullName(contactMapping?: ContactMappingEntity): string {
  if (!contactMapping || !contactMapping.attributes) return '';

  const { firstName, lastName } = contactMapping.attributes;
  const firstNameInitial = firstName ? firstName : '';
  const lastNameInitial = lastName ? lastName : '';

  return `${firstNameInitial} ${lastNameInitial}`.trim();
}

export function getInitials(contactMapping: ContactMappingEntity): string {
  if (!contactMapping || !contactMapping.attributes) return '';

  const { firstName, lastName } = contactMapping.attributes;
  const firstNameInitial = firstName ? firstName[0] : '';
  const lastNameInitial = lastName ? lastName[0] : '';

  return `${firstNameInitial}${lastNameInitial}`;
}

export default {
  getFullName,
  getInitials
};
