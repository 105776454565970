import { Redirect, Router } from '@reach/router';
import Box from '@targetx/mineral-ui/Box';
import { APP_ID_SMS } from '@targetx/tx-usermgmt-api-lib/lib/constants/apps';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import UserQuery, {
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import ErrorScreen from '@targetx/tx-web-ui-lib/lib/screens/ErrorScreen';
import LoadingScreen from '@targetx/tx-web-ui-lib/lib/screens/LoadingScreen';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import copyText from './App.copyText';
import RedirectCommand from './commands/RedirectCommand';
import paths from './constants/paths';
import DispatcherContext from './DispatcherContext';
import BroadcastsScreen from './screens/BroadcastsScreen';
import InboxManagementScreen from './screens/InboxManagementScreen';
import InboxUserManagementScreen from './screens/InboxUserManagementScreen';
import MessageTemplateManagementScreen from './screens/MessageTemplateManagementScreen';
import MessageTranscriptScreen from './screens/MessageTranscriptScreen';
import MessagingProviderConfigScreen from './screens/MessagingProviderConfigScreen';
import MessagingScreen from './screens/MessagingScreen';
import OtherSettingsScreen from './screens/OtherSettingsScreen';
import ReportManagementScreen from './screens/ReportManagementScreen';
import { PartialState } from './types/PartialState';
import trackUser from './utils/trackUser';

ReactModal.setAppElement('#root');

const LOADING_SCREEN_TIMEOUT = 2000;

const ERROR_PERMISSION_DENIED = 'ERROR_PERMISSION_DENIED';

interface Props {
  authBaseURL: string;
  homeBaseURL: string;
}

interface State {
  authenticatedUser: UserQueryResult.UserEntity | null;
  isAuthenticating: boolean;
  messageKey: string;
  showLoadingScreen: boolean;
}

const initialState = {
  authenticatedUser: null,
  isAuthenticating: false,
  messageKey: '',
  showLoadingScreen: true
};

export function App({ authBaseURL, homeBaseURL }: Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(prevState => ({ ...prevState, ...partialState }));
  }

  const { isAuthenticating, messageKey, showLoadingScreen } = state;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function dispatchUserQuery(): Promise<void> {
    changeState({ isAuthenticating: true });

    const result = await dispatcher.dispatch(new UserQuery());

    if (result instanceof UserQueryResult) {
      if (
        !result.user.permissions.find(
          permission => permission.targetID === APP_ID_SMS
        )
      ) {
        changeState({
          messageKey: ERROR_PERMISSION_DENIED,
          isAuthenticating: false,
          authenticatedUser: result.user
        });
        return;
      }

      trackUser(result.user.id, {
        'First Name': result.user.firstName,
        'Last Name': result.user.lastName,
        Username: result.user.username,
        Role: result.user.role,
        'Time Last Authenticated': result.user.timeLastAuthenticated,
        'Org ID': result.user.org.id,
        'Org Name': result.user.org.name,
        'Org SID': result.user.org.sid,
        ...(result.user.salesforceProfile
          ? {
              'Salesforce User ID': result.user.salesforceProfile.user_id,
              'Salesforce Org ID': result.user.salesforceProfile.organization_id
            }
          : {})
      });

      changeState({ isAuthenticating: false, authenticatedUser: result.user });
      return;
    }

    changeState({ isAuthenticating: false });
  }

  async function initialize(): Promise<void> {
    dispatchUserQuery();

    const timer = setTimeout((): void => {
      clearTimeout(timer);
      changeState({ showLoadingScreen: false });
    }, LOADING_SCREEN_TIMEOUT);
  }

  //
  // Side Effects
  //

  useEffect((): void => {
    initialize();
  }, []);

  //
  // Render
  //

  if (messageKey === ERROR_PERMISSION_DENIED) {
    dispatcher.dispatch(new RedirectCommand({ url: homeBaseURL }));
    return <Box />;
  }

  if (showLoadingScreen || isAuthenticating) {
    return <LoadingScreen />;
  }

  const authenticatedUser =
    state.authenticatedUser as UserQueryResult.UserEntity;

  const signOutPath = `${authBaseURL}/${authenticatedUser.org.sid}/sign-in`;

  return (
    <Router>
      <BroadcastsScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.broadcasts}
        signOutPath={signOutPath}
      />
      <InboxManagementScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsInboxes}
        signOutPath={signOutPath}
      />
      <InboxUserManagementScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsInboxUsers}
        signOutPath={signOutPath}
      />
      <MessagingScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.inboxes}
        signOutPath={signOutPath}
      />
      <MessagingScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        messageTranscriptsBasePath={paths.transcripts}
        path={paths.inbox}
        signOutPath={signOutPath}
      />
      <MessageTemplateManagementScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsMessageTemplates}
        signOutPath={signOutPath}
      />
      <MessageTranscriptScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.transcripts}
        signOutPath={signOutPath}
      />
      <MessagingProviderConfigScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsMessagingProvider}
        signOutPath={signOutPath}
      />
      <OtherSettingsScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsOther}
        signOutPath={signOutPath}
      />
      <ReportManagementScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsReport}
        signOutPath={signOutPath}
      />
      <ReportManagementScreen
        authenticatedUser={authenticatedUser}
        homeBaseURL={homeBaseURL}
        path={paths.settingsReports}
        signOutPath={signOutPath}
      />
      <Redirect
        from={paths.settings}
        to={
          authenticatedUser.role === UserRole.ORG_ADMIN
            ? paths.settingsInboxes
            : paths.settingsMessageTemplates
        }
        noThrow
      />
      <Redirect from="/" to={paths.inboxes} noThrow />
      <ErrorScreen
        default
        heading={copyText.ERROR_PERMISSION_DENIED_title}
        message={copyText.ERROR_PERMISSION_DENIED_message}
        returnButtonLabel={copyText.returnButtonLabel}
        returnButtonPath={homeBaseURL}
      />
    </Router>
  );
}

export default App;
