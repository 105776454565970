export default {
  editButtonLabel: 'EDIT',
  headerAccountID: 'ACCOUNT ID',
  headerActions: 'ACTIONS',
  headerName: 'NAME',
  headerNumber: 'NUMBER',
  headerServiceID: 'SERVICE ID',
  headerServiceType: 'SERVICE TYPE',
  headerTimeCreated: 'TIME CREATED',
  NA: 'N/A',
  noInboxesMessage: 'No inboxes to display at this time.',
  serviceType_0001: 'Twilio Messaging Service',
  title: 'Inbox List'
};
