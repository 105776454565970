/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  BaseReportEntity,
  ContactMappingEntity,
  ContactRecord,
  InboxEntity,
  MessageEntity,
  MessageTemplateEntity,
  MessagingAPIKeyEntity,
  MessagingServiceEntity,
  OrgSettingEntity,
  ReportEntity,
  ReportType,
  SalesforceReportFolderObject,
  SalesforceReportObject,
  TwilioServiceObject,
  UserEntity,
  UserPermissionEntity
} from './types';

export interface CreateInboxParameters {
  orgID: string;
  providerRID: string;
  name: string;
  attributes?: { [key: string]: any };
}

export interface CreateMessageTemplateParameters {
  orgID: string;
  ownerID?: string;
  name: string;
  content: string;
}

export interface CreateReportParameters {
  orgID: string;
  name: string;
  type: ReportType;
  rid: string;
  columnMapping?: { [key: string]: string };
}

export interface UpdateInboxParameters {
  providerRID?: string;
  name?: string;
  attributes?: { [key: string]: any };
}

export interface UpdateMessageTemplateParameters {
  name?: string;
  content?: string;
}

export interface UpdateReportParameters {
  name?: string;
  columnMapping?: { [key: string]: string };
}

export default interface DataAPIClient {
  activateInbox(inboxID: string): Promise<string>;

  createInbox(parameters: CreateInboxParameters): Promise<string>;

  createMessageTemplate(
    parameters: CreateMessageTemplateParameters
  ): Promise<string>;

  createReport(parameters: CreateReportParameters): Promise<string>;

  deactivateInbox(inbox: InboxEntity): Promise<string>;

  deactivateReport(reportID: string): Promise<string>;

  deleteMessageTemplate(templateID: string): Promise<string>;

  getContactByRID(rid: string): Promise<ContactRecord>;

  getContactMappingsByPhoneNumbers(
    phoneNumbers: string[]
  ): Promise<ContactMappingEntity[]>;

  getInboxesByIDs(inboxIDs: string[]): Promise<InboxEntity[]>;

  getInboxesByOrgID(orgID: string): Promise<InboxEntity[]>;

  getMessagesByConversationID(conversationID: string): Promise<MessageEntity[]>;

  getMessageSendersByInboxID(inboxID: string): Promise<UserEntity[]>;

  getMessageTemplatesByUserID(userID: string): Promise<MessageTemplateEntity[]>;

  getMessagingAPIKeyByOrgID(orgID: string): Promise<MessagingAPIKeyEntity>;

  getMessagingServicesByOrgID(
    orgID: string,
    options?: { isConnectedToInbox?: boolean }
  ): Promise<MessagingServiceEntity[]>;

  getOrgSettingsByOrgID(orgID: string): Promise<OrgSettingEntity[]>;

  getReportByID(id: string): Promise<ReportEntity>;

  getReportColumnNamesByReportID(reportID: string): Promise<string[]>;

  getReportsByOrgID(orgID: string): Promise<BaseReportEntity[]>;

  getSalesforceReportColumnNamesByReportID(reportID: string): Promise<string[]>;

  getSalesforceReportFoldersByOrgID(
    orgID: string
  ): Promise<SalesforceReportFolderObject[]>;

  getSalesforceReportByOrgIDAndRID(
    rid: string,
    orgID: string
  ): Promise<SalesforceReportObject>;

  getSalesforceReportsByOrgIDAndFolderID(
    folderID: string,
    orgID: string
  ): Promise<SalesforceReportObject[]>;

  getTwilioMessagingServiceBySID(
    serviceID: string
  ): Promise<TwilioServiceObject>;

  getUserPermissionsByUserID(userID: string): Promise<UserPermissionEntity[]>;

  updateInbox(
    inboxID: string,
    parameters: UpdateInboxParameters
  ): Promise<string>;

  updateMessageTemplate(
    templateID: string,
    parameters: UpdateMessageTemplateParameters
  ): Promise<string>;

  updateReport(
    reportID: string,
    parameters: UpdateReportParameters
  ): Promise<string>;

  updateOrgSetting(orgID: string, name: string, value: string): Promise<string>;
}

export class DataAPIClientError extends Error {
  public static readonly FAILED_ACTIVATING_INBOX = `${DataAPIClientError.name}/FAILED_ACTIVATING_INBOX`;
  public static readonly FAILED_CREATING_INBOX = `${DataAPIClientError.name}/FAILED_CREATING_INBOX`;
  public static readonly FAILED_CREATING_MESSAGE_TEMPLATE = `${DataAPIClientError.name}/FAILED_CREATING_MESSAGE_TEMPLATE`;
  public static readonly FAILED_CREATING_REPORT = `${DataAPIClientError.name}/FAILED_CREATING_REPORT`;
  public static readonly FAILED_DEACTIVATING_INBOX = `${DataAPIClientError.name}/FAILED_DEACTIVATING_INBOX`;
  public static readonly FAILED_DEACTIVATING_REPORT = `${DataAPIClientError.name}/FAILED_DEACTIVATING_REPORT`;
  public static readonly FAILED_DELETING_MESSAGE_TEMPLATE = `${DataAPIClientError.name}/FAILED_DELETING_MESSAGE_TEMPLATE`;
  public static readonly FAILED_LOADING_CONTACT = `${DataAPIClientError.name}/FAILED_LOADING_CONTACT`;
  public static readonly FAILED_LOADING_CONTACT_MAPPINGS = `${DataAPIClientError.name}/FAILED_LOADING_CONTACT_MAPPINGS`;
  public static readonly FAILED_LOADING_INBOXES = `${DataAPIClientError.name}/FAILED_LOADING_INBOXES`;
  public static readonly FAILED_LOADING_MESSAGE_SENDERS = `${DataAPIClientError.name}/FAILED_LOADING_MESSAGE_SENDERS`;
  public static readonly FAILED_LOADING_MESSAGE_TEMPLATES = `${DataAPIClientError.name}/FAILED_LOADING_MESSAGE_TEMPLATES`;
  public static readonly FAILED_LOADING_MESSAGES = `${DataAPIClientError.name}/FAILED_LOADING_MESSAGES`;
  public static readonly FAILED_LOADING_MESSAGING_API_KEY = `${DataAPIClientError.name}/FAILED_LOADING_MESSAGING_API_KEY`;
  public static readonly FAILED_LOADING_MESSAGING_SERVICES = `${DataAPIClientError.name}/FAILED_LOADING_MESSAGING_SERVICES`;
  public static readonly FAILED_LOADING_ORG_SETTINGS = `${DataAPIClientError.name}/FAILED_LOADING_ORG_SETTINGS`;
  public static readonly FAILED_LOADING_REPORT = `${DataAPIClientError.name}/FAILED_LOADING_REPORT`;
  public static readonly FAILED_LOADING_REPORT_COLUMN_NAMES = `${DataAPIClientError.name}/FAILED_LOADING_REPORT_COLUMN_NAMES`;
  public static readonly FAILED_LOADING_REPORTS = `${DataAPIClientError.name}/FAILED_LOADING_REPORTS`;
  public static readonly FAILED_LOADING_SALESFORCE_REPORT_COLUMN_NAMES = `${DataAPIClientError.name}/FAILED_LOADING_SALESFORCE_REPORT_COLUMN_NAMES`;
  public static readonly FAILED_LOADING_SALESFORCE_REPORT = `${DataAPIClientError.name}/FAILED_LOADING_SALESFORCE_REPORT`;
  public static readonly FAILED_LOADING_SALESFORCE_REPORTS = `${DataAPIClientError.name}/FAILED_LOADING_SALESFORCE_REPORTS`;
  public static readonly FAILED_LOADING_TWILIO_MESSAGING_SERVICE = `${DataAPIClientError.name}/FAILED_LOADING_TWILIO_MESSAGING_SERVICE`;
  public static readonly FAILED_LOADING_USER_PERMISSIONS = `${DataAPIClientError.name}/FAILED_LOADING_USER_PERMISSIONS`;
  public static readonly FAILED_UNEXPECTEDLY = `${DataAPIClientError.name}/FAILED_UNEXPECTEDLY`;
  public static readonly FAILED_UPDATING_INBOX = `${DataAPIClientError.name}/FAILED_UPDATING_INBOX`;
  public static readonly FAILED_UPDATING_MESSAGE_TEMPLATE = `${DataAPIClientError.name}/FAILED_UPDATING_MESSAGE_TEMPLATE`;
  public static readonly FAILED_UPDATING_ORG_SETTING = `${DataAPIClientError.name}/FAILED_UPDATING_ORG_SETTING`;
  public static readonly FAILED_UPDATING_REPORT = `${DataAPIClientError.name}/FAILED_UPDATING_REPORT`;
  static readonly messages: { [key: string]: string } = {
    [DataAPIClientError.FAILED_ACTIVATING_INBOX]:
      'Failed while activating inbox',
    [DataAPIClientError.FAILED_CREATING_INBOX]: 'Failed while creating inbox',
    [DataAPIClientError.FAILED_CREATING_MESSAGE_TEMPLATE]:
      'Failed while creating message template',
    [DataAPIClientError.FAILED_CREATING_REPORT]: 'Failed while creating report',
    [DataAPIClientError.FAILED_DEACTIVATING_INBOX]:
      'Failed while deactivating inbox',
    [DataAPIClientError.FAILED_DEACTIVATING_REPORT]:
      'Failed while deleting report',
    [DataAPIClientError.FAILED_DELETING_MESSAGE_TEMPLATE]:
      'Failed while deleting message template',
    [DataAPIClientError.FAILED_LOADING_CONTACT]:
      'Failed while loading contact record',
    [DataAPIClientError.FAILED_LOADING_CONTACT_MAPPINGS]:
      'Failed while loading contact mappings',
    [DataAPIClientError.FAILED_LOADING_INBOXES]: 'Failed while loading inboxes',
    [DataAPIClientError.FAILED_LOADING_MESSAGE_SENDERS]:
      'Failed while loading message senders',
    [DataAPIClientError.FAILED_LOADING_MESSAGE_TEMPLATES]:
      'Failed while loading message templates',
    [DataAPIClientError.FAILED_LOADING_MESSAGES]:
      'Failed while loading messages',
    [DataAPIClientError.FAILED_LOADING_MESSAGING_API_KEY]:
      'Failed while loading messaging api key',
    [DataAPIClientError.FAILED_LOADING_TWILIO_MESSAGING_SERVICE]:
      'Failed while loading Twilio messaging service',
    [DataAPIClientError.FAILED_LOADING_MESSAGING_SERVICES]:
      'Failed while loading messaging services',
    [DataAPIClientError.FAILED_LOADING_ORG_SETTINGS]:
      'Failed while loading org settings',
    [DataAPIClientError.FAILED_LOADING_REPORT]: 'Failed while loading report',
    [DataAPIClientError.FAILED_LOADING_REPORT_COLUMN_NAMES]:
      'Failed while loading report column names',
    [DataAPIClientError.FAILED_LOADING_REPORTS]: 'Failed while loading reports',
    [DataAPIClientError.FAILED_LOADING_SALESFORCE_REPORT_COLUMN_NAMES]:
      'Failed while loading salesforce report column names',
    [DataAPIClientError.FAILED_LOADING_SALESFORCE_REPORTS]:
      'Failed while loading salesforce reports',
    [DataAPIClientError.FAILED_LOADING_USER_PERMISSIONS]:
      'Failed while loading user permissions',
    [DataAPIClientError.FAILED_UNEXPECTEDLY]: 'Failed unexpectedly',
    [DataAPIClientError.FAILED_UPDATING_INBOX]: 'Failed while updating inbox',
    [DataAPIClientError.FAILED_UPDATING_MESSAGE_TEMPLATE]:
      'Failed while updating message template',
    [DataAPIClientError.FAILED_UPDATING_ORG_SETTING]:
      'Failed while updating org setting',
    [DataAPIClientError.FAILED_UPDATING_REPORT]: 'Failed while updating report'
  };
  public readonly reason?: string;
  public readonly type: string;

  constructor(type: string, properties?: { reason?: string }) {
    super(
      DataAPIClientError.messages[type] ??
        DataAPIClientError.messages[DataAPIClientError.FAILED_UNEXPECTEDLY]
    );

    if (properties?.reason) this.reason = properties.reason;
    this.type = type;
  }
}
