import Checkbox from '@targetx/mineral-ui/Checkbox';
import Flex from '@targetx/mineral-ui/Flex';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserInboxPermissionType } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import UserIdentityComponent from '@targetx/tx-web-ui-lib/lib/components/UserIdentityComponent';
import IconTrash from '@targetx/tx-web-ui-lib/lib/icons/IconTrash';
import SVGSpinner from '@targetx/tx-web-ui-lib/lib/svg/SVGSpinner';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import groupBy from 'lodash.groupby';
import noop from 'lodash.noop';
import React, { ReactElement } from 'react';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './InboxUserList.copyText';

export namespace InboxUserList {
  export interface UserEntity {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
  }

  export interface UserPermissionEntity {
    id: string;
    userID: string;
    targetID: string;
    type: UserInboxPermissionType;
  }

  export interface Props {
    isLoading?: boolean;
    permissions: UserPermissionEntity[];
    users: UserEntity[];
    onInteraction?: InteractionDelegate;
  }
}

interface RowData {
  id: string;
  row: () => ReactElement;
}

const columns = [
  {
    key: copyText.headerUser,
    content: copyText.headerUser,
    borderless: true
  },
  {
    key: copyText.headerBroadcast,
    content: copyText.headerBroadcast,
    borderless: true,
    minWidth: 100,
    width: 100
  },
  {
    key: copyText.headerActions,
    content: <Text hidden>{copyText.headerActions}</Text>,
    label: copyText.headerActions,
    borderless: true,
    minWidth: 60,
    width: 60
  }
];

export function InboxUserList({
  isLoading,
  permissions,
  users,
  onInteraction = noop
}: InboxUserList.Props): ReactElement {
  const permissionsGroupedByUserID = groupBy(permissions, 'userID');

  let data: RowData[];

  if (isLoading || users.length === 0) {
    const render = (): ReactElement => (
      <TableRow>
        <TableCell colSpan={3}>
          <Flex alignItems="center" height="75px" justifyContent="center">
            {isLoading ? (
              <SVGSpinner size="2.5em" />
            ) : (
              <Text color={palette.gray[70]}>{copyText.noUsersMessage}</Text>
            )}
          </Flex>
        </TableCell>
      </TableRow>
    );

    data = [{ id: '_', row: render }];
  } else {
    data = users.map(user => {
      const render = (): ReactElement => (
        <InboxUserListItem
          permissions={permissionsGroupedByUserID[user.id]}
          user={user}
          onInteraction={onInteraction}
        />
      );

      return { id: user.id, row: render };
    });
  }

  return (
    <Table
      rowKey="id"
      border={`1px solid ${palette.gray[50]}`}
      borderRadius={theme.borderRadius_2}
      columns={columns}
      data={data}
      striped
      title={copyText.title}
      titleAs="h2"
      width="60%"
    />
  );
}

export namespace InboxUserListItem {
  export interface Props {
    permissions: InboxUserList.UserPermissionEntity[];
    user: InboxUserList.UserEntity;
    onInteraction: InteractionDelegate;
  }
}

function InboxUserListItem({
  permissions,
  user,
  onInteraction
}: InboxUserListItem.Props): ReactElement {
  const allowBroadcast = permissions.some(
    ({ type }) => type === UserInboxPermissionType.CAN_SEND_BROADCAST
  );

  function handleClickCheckbox(): void {
    onInteraction({
      type: InboxUserList.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED,
      userID: user.id,
      checked: !allowBroadcast
    });
  }

  function handleClickRemoveButton(): void {
    onInteraction({
      type: InboxUserList.INTERACTION_REMOVE_BUTTON_CLICKED,
      userID: user.id
    });
  }

  return (
    <TableRow>
      <TableCell verticalAlign="middle">
        <UserIdentityComponent user={user} />
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex justifyContent="end">
          <Checkbox
            checked={allowBroadcast}
            label={copyText.headerBroadcast}
            hideLabel
            onChange={handleClickCheckbox}
          />
        </Flex>
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex justifyContent="end">
          <MinimalButton
            aria-label={copyText.editButtonLabel}
            iconStart={<IconTrash color={palette.gray[60]} />}
            size="small"
            onClick={handleClickRemoveButton}
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
}

InboxUserList.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED = `${InboxUserList.name}.INTERACTION_ALLOW_BROADCAST_CHECKBOX_CLICKED`;
InboxUserList.INTERACTION_REMOVE_BUTTON_CLICKED = `${InboxUserList.name}.INTERACTION_REMOVE_BUTTON_CLICKED`;

export default InboxUserList;
