import { CampaignScheduleFrequency } from '@targetx/tx-sms-api-lib/lib/constants/enums';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import isNil from 'lodash.isnil';
import getDaysOfMonthOptions from './getDaysOfMonthOptions';
import copyText from '../components/CreateCampaignForm.copyText';
import timeZones from '../constants/timeZones';

const DEFAULT_SCHEDULE_END_DATE_INCREMENT = 100; // NOTE: in years

export function getScheduleSummary(
  schedule: {
    frequency: string;
    dayOfMonth?: number;
    daysOfWeek?: number[];
    executionTimeZone: string;
    executionTime: string;
  },
  options?: { scheduleStartDate?: string; scheduleEndDate?: string }
): string {
  // NOTE: not accounting for daylight-savings time yet

  const {
    dayOfMonth,
    daysOfWeek,
    executionTimeZone,
    executionTime,
    frequency
  } = schedule;

  const executionTimeText = `${getExecutionTimeText(executionTime)} ${
    timeZones
      .flatMap(obj => obj.options)
      .find(tz => tz.value === executionTimeZone)?.label
  }`;
  const startDateText = options?.scheduleStartDate
    ? getDateText(options.scheduleStartDate)
    : '';

  let frequencyText = '';

  switch (frequency) {
    case CampaignScheduleFrequency.MONTHLY:
      if (isNil(dayOfMonth)) break;

      const daysOfMonthOptions = getDaysOfMonthOptions()[dayOfMonth - 1].label;

      frequencyText = copyText.frequencyMonthlyScheduleSummary
        .replace('%dayOfMonth%', daysOfMonthOptions)
        .replace('%executionTimeText%', executionTimeText);
      break;
    case CampaignScheduleFrequency.WEEKLY:
      if (isNil(daysOfWeek)) break;

      const daysOfWeekText = getDaysOfTheWeekText(daysOfWeek, copyText);

      frequencyText = copyText.frequencyWeeklyScheduleSummary
        .replace('%daysOfWeek%', daysOfWeekText)
        .replace('%executionTimeText%', executionTimeText);
      break;
    case CampaignScheduleFrequency.DAILY:
      frequencyText = copyText.frequencyDailyScheduleSummary.replace(
        '%executionTimeText%',
        executionTimeText
      );
      break;
    case CampaignScheduleFrequency.ONCE:
    default:
      frequencyText = copyText.frequencyOnceScheduleSummary
        .replace('%executionTimeText%', executionTimeText)
        .replace('%startDateText%', startDateText);
      break;
  }

  return `${frequencyText}${
    frequency !== CampaignScheduleFrequency.ONCE ? ' ' : ''
  }${
    frequency !== CampaignScheduleFrequency.ONCE
      ? copyText.startDateScheduleSummary.replace(
          '%startDateText%',
          startDateText
        )
      : ''
  }${
    frequency !== CampaignScheduleFrequency.ONCE &&
    options?.scheduleEndDate &&
    options.scheduleStartDate
      ? getEndDateScheduleSummaryText(
          copyText.endDateScheduleSummary,
          options.scheduleStartDate,
          options.scheduleEndDate
        )
      : ''
  }`;
}

function getDateText(isoString: string): string {
  return format(parseISO(isoString), "MMMM do',' yyy");
}

function getDaysOfTheWeekText(
  daysSelected: number[],
  copyText: { [key: string]: string }
): string {
  if (daysSelected.length === 1) {
    return copyText[`dayOfWeekLabel_${daysSelected[0]}`];
  }

  const daysLabeled = daysSelected.map(
    daySelected => copyText[`dayOfWeekLabel_${daySelected}`]
  );

  return `${daysLabeled.slice(0, daysSelected.length - 1).join(', ')} and ${
    daysLabeled[daysSelected.length - 1]
  }`;
}

function getEndDateScheduleSummaryText(
  copyText: string,
  scheduleStartDate: string,
  scheduleEndDate: string
): string {
  const scheduleEndDateYear = new Date(scheduleEndDate).getFullYear();
  const scheduleStartDateYear = new Date(scheduleStartDate).getFullYear();

  if (
    scheduleEndDateYear - scheduleStartDateYear <
    DEFAULT_SCHEDULE_END_DATE_INCREMENT
  ) {
    return copyText.replace('%endDateText%', getDateText(scheduleEndDate));
  }

  return '';
}

function getExecutionTimeText(time: string): string {
  const inputTime = time.split(':');
  const hours = Number(inputTime[0]);
  let friendlyHour: number;
  if (hours > 12) {
    friendlyHour = hours - 12;
  } else if (hours === 0) {
    friendlyHour = 12;
  } else {
    friendlyHour = hours;
  }
  return `${friendlyHour}:${inputTime[1]} ${hours >= 12 ? 'PM' : 'AM'}`;
}

export default getScheduleSummary;
