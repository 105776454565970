import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext
} from 'react';
import DataAPIClient from './DataAPIClient';

const context = createContext<DataAPIClient | undefined>(undefined);

export interface DataAPIClientProviderProps {
  client: DataAPIClient;
}

export default function DataAPIClientProvider({
  client,
  children
}: PropsWithChildren<DataAPIClientProviderProps>): ReactElement {
  return <context.Provider value={client}>{children}</context.Provider>;
}

export function useDataAPIClient(): DataAPIClient {
  const client = useContext(context);

  if (!client) {
    throw new Error(
      `${useDataAPIClient.name} must be wrapped by ${DataAPIClientProvider.name}`
    );
  }

  return client;
}
