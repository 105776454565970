import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, { ChangeEvent, FormEvent, ReactElement, useState } from 'react';
import isAlphanumeric from 'validator/lib/isAlphanumeric';
import isEmpty from 'validator/lib/isEmpty';
import theme from '../theme';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './UpdateMessagingAPIKeyForm.copyText';

const UNICODE_BULLET = '\u2022';

export namespace UpdateMessagingAPIKeyForm {
  export interface Props {
    apiKey?: string;
    apiSecretPreview?: string;
    isProcessing?: boolean;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  apiKeyInput: { value: string; isValid: boolean; hasChanged: boolean };
  apiSecretInput: { value: string; isValid: boolean; hasChanged: boolean };
}

export function UpdateMessagingAPIKeyForm({
  apiKey,
  apiSecretPreview,
  isProcessing,
  onInteraction = noop
}: UpdateMessagingAPIKeyForm.Props): ReactElement {
  const initialState = {
    apiKeyInput: { value: apiKey ?? '', isValid: true, hasChanged: false },
    apiSecretInput: {
      value: apiSecretPreview ? formatSecretPreview(apiSecretPreview) : '',
      isValid: apiSecretPreview ? false : true,
      hasChanged: false
    }
  };

  const [state, setState] = useState<State>(initialState);

  const { apiKeyInput, apiSecretInput } = state;

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    setState(currentState => ({
      ...currentState,
      [`${name}Input`]: {
        value,
        isValid: !isEmpty(value) && isAlphanumeric(value),
        hasChanged: get(currentState, `${name}Input.value`) !== value
      }
    }));
  }

  function handleReset(event: FormEvent): void {
    event.preventDefault();

    setState({
      apiKeyInput: {
        value: initialState.apiKeyInput.value,
        isValid: true,
        hasChanged: false
      },
      apiSecretInput: {
        value: initialState.apiSecretInput.value,
        isValid: false,
        hasChanged: false
      }
    });
  }

  function handleSubmit(event: FormEvent): void {
    event.preventDefault();

    onInteraction({
      type: UpdateMessagingAPIKeyForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      key: apiKeyInput.value.trim(),
      secret: apiSecretInput.value.trim()
    });
  }

  const canSubmit =
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  return (
    <Box as={Form} minWidth={400} width="100%" onSubmit={handleSubmit}>
      <Box
        backgroundColor={palette.white}
        border={`1px solid ${palette.gray[50]}`}
        borderRadius={theme.borderRadius_2}
        paddingHorizontal={theme.space_inline_md}
        paddingVertical={theme.space_stack_lg}
      >
        <Text fontSize="16px">{copyText.header}</Text>
        <FormField
          name="apiKey"
          input={TextInput}
          label={
            <Text>
              {copyText.apiKeyInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          value={apiKeyInput.value}
          onChange={handleChange}
        />
        <FormField
          name="apiSecret"
          caption={
            <Box marginTop={theme.space_stack_xs}>
              <Text color={palette.gray[80]} fontSize={theme.fontSize_ui_sm}>
                {copyText.apiSecretInputCaption}
              </Text>
            </Box>
          }
          input={TextInput}
          label={
            <Text>
              {copyText.apiSecretInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          value={apiSecretInput.value}
          onChange={handleChange}
        />
      </Box>
      <Flex justifyContent="end" marginTop={theme.space_stack_lg}>
        <Button
          marginRight={theme.space_inline_md}
          minimal
          size="medium"
          type="reset"
          width="100px"
          onClick={handleReset}
        >
          {copyText.resetButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          primary
          size="medium"
          width="100px"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Box>
  );
}

function formatSecretPreview(text: string): string {
  const [start, charCount, end] = text.split(':');

  return `${start}${UNICODE_BULLET.repeat(parseInt(charCount))}${end}`;
}

UpdateMessagingAPIKeyForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${UpdateMessagingAPIKeyForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default UpdateMessagingAPIKeyForm;
